import { Box, Text, useToast, Flex } from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import { includes, isEmpty } from 'lodash';
import { ArrowUpIconBlack, ArrowLeftIcon } from '@asset/icons';
import FieldMenu from '@components/fieldMenu';
import ModalSuccess from '@components/modalSuccess';
import ModalConfirm from '@components/modalConfirm';
import ModalFailed from '@components/modalFailed';
import ScreenTemplate from '@components/screenTemplateV2';
import { onChangeFormData, validationForm, handleSelectedRole, onSubmitForm } from './functions';
import { formDefaultValue, listNeedMaterial, listNormalForm, listSupplierForm } from './const';
import NormalForm from './form/NormalForm';
import SupplierForm from './form/SupplierForm';
import { UserManagementContext } from '@pages/userManagement';

import './styles.scss';

const CreateAndEditModal = ({
    onClose,
    roleSelected,
    setRoleSelected,
    isOpen,
    roles,
    reFetchUserHandler,
    selectedUser,
    isEditModal,
    formData,
    setFormData,
}) => {
    const toast = useToast();
    const { jenisMaterial, materials } = useContext(UserManagementContext);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState({ open: false, msg: 'Fail' });
    const [modalConfirm, setModalConfirm] = useState({ open: false, typeform: '' });
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);

    const onResetForm = () => {
        setFormData(formDefaultValue);
        setIsSubmitted(false);
        setRoleSelected({});
    };

    const onCloseSuccessSubmit = () => {
        setIsSuccessSubmit(false);
        onClose();
    };
    const isChoosingMaterial = () => includes(listNeedMaterial, formData?.role?.role);
    const handleSubmit = () => {
        onSubmitForm({
            formData,
            setIsSubmitLoading,
            setIsSuccessSubmit,
            reFetchUserHandler,
            isChoosingMaterial,
            onResetForm,
            setModalConfirm,
            isEditModal,
            toast,
            typeform: modalConfirm.typeform,
        });
    };
    const handleConfirm = (typeform) => {
        validationForm({
            toast,
            formData,
            onClose,
            setIsSubmitLoading,
            reFetchUserHandler,
            setIsSubmitted,
            isChoosingMaterial,
            materials,
            typeform,
            setModalConfirm,
        });
    };
    // useEffect(() => {
    //     if (isEditModal && !isEmpty(materials) && !isEmpty(roles)) {
    //         mappedSelectedUser({ setFormData, selectedUser, materials, roles });
    //     }
    // }, [isEditModal, selectedUser, materials, roles]);

    return (
        <ScreenTemplate sx={{ top: isOpen && '56px', height: '100vh' }} isOpen={isOpen}>
            <Box className="create-and-edit-modal__wrapper__usermanagement">
                <Box className="create-and-edit-modal__container__usermanagement">
                    <Flex className="section-head__container">
                        <Flex
                            onClick={() => {
                                onClose();
                                setRoleSelected({});
                            }}
                            cursor="pointer"
                            align="center"
                        >
                            <ArrowLeftIcon />
                            <Text className="arrow-back__text">{isEditModal ? 'Edit Account' : 'Add New Account'}</Text>
                        </Flex>
                    </Flex>

                    {/** ------------------------- SECTION ROLE --------------------------------- */}

                    <Box className="section-body__container">
                        <Box className="modal-item__container">
                            <Box width="359px">
                                <Text className="label-title">Pilih Role</Text>
                                <Text className="label-description">
                                    Pilih Role mu dengan tepat, untuk melanjutkan pembuatan account baru
                                </Text>
                            </Box>
                            <Box width="706px">
                                <FieldMenu
                                    onClick={(value) => {
                                        handleSelectedRole({
                                            value,
                                            setRoleSelected,
                                            setFormData,
                                            onResetForm,
                                            formData,
                                        });
                                    }}
                                    buttonName={
                                        <span>
                                            <span>
                                                {roleSelected?.role ? (
                                                    <span className="selected-role-text">{roleSelected?.role}</span>
                                                ) : (
                                                    <span className="FieldMenu-placeholder">Pilih role</span>
                                                )}
                                            </span>
                                            <span className="entitas-text">
                                                {roleSelected?.name ? `- ${roleSelected?.name}` : null}
                                            </span>
                                        </span>
                                    }
                                    menuList={roles}
                                    keyName="role"
                                    withEntity
                                    menuClassName="menu-container__user-management"
                                    menuButtonClassName="menu-button"
                                    menuListClassName="menu-list-container"
                                    icon={<ArrowUpIconBlack />}
                                />
                                {isSubmitted && !formData?.role?.id && (
                                    <Text className="required-field__text">Field role required!</Text>
                                )}
                            </Box>
                        </Box>
                        <Box height="0.5px" bgColor="#D7D7D7" />
                        {/* FORM IF USER CHOOSE ROLE SUPER ADMIN OR ADMIN */}
                        {Boolean(listNormalForm.find((item) => item === roleSelected?.role)) && (
                            <NormalForm
                                setFormData={setFormData}
                                isSubmitted={isSubmitted}
                                formData={formData}
                                isEditModal={isEditModal}
                                roleSelected={roleSelected}
                                onChangeFormData={onChangeFormData}
                                handleSubmit={() => handleConfirm('normal')}
                                jenisMaterial={jenisMaterial}
                                isChoosingMaterial={isChoosingMaterial}
                                materials={materials}
                            />
                        )}
                        {/* FORM IF USER CHOOSE ROLE SUPPLIER */}
                        {Boolean(listSupplierForm.find((item) => item === roleSelected?.role)) && (
                            <SupplierForm
                                isEditModal={isEditModal}
                                setFormData={setFormData}
                                isSubmitted={isSubmitted}
                                formData={formData}
                                roleSelected={roleSelected}
                                onChangeFormData={onChangeFormData}
                                handleSubmit={() => handleConfirm('supplier')}
                                jenisMaterial={jenisMaterial}
                            />
                        )}
                    </Box>
                    {/* {formData?.role?.id && (
                        <Flex className="section-footer__container">
                            <Button
                                border={isSubmitLoading ? '1px solid grey' : 'none'}
                                isLoading={isSubmitLoading}
                                className={`button-add__container ${
                                    !onValidation({
                                        data: isChoosingMaterial() ? { ...formData } : { ...formData, materials },
                                    }) && 'disable-button-add__container'
                                }`}
                                onClick={() =>
                                    onSubmitForm({
                                        toast,
                                        formData,
                                        onClose,
                                        setIsSubmitLoading,
                                        reFetchUserHandler,
                                        isEditModal,
                                        selectedUser,
                                        setIsSubmitted,
                                        onResetForm,
                                        isChoosingMaterial,
                                        materials,
                                        setIsSuccessSubmit,
                                    })
                                }
                            >
                                Submit
                            </Button>
                            <Button className="button-cancel__container" onClick={onClose}>
                                Cancel
                            </Button>
                        </Flex>
                    )} */}
                </Box>
            </Box>

            <ModalConfirm
                loading={isSubmitLoading}
                isOpen={modalConfirm.open}
                onClose={() => {
                    setModalConfirm({ open: false, typeform: '' });
                    setIsSubmitLoading(false);
                }}
                onSubmit={handleSubmit}
                submitLabel="Lanjut"
                title="Apa anda yakin telah mengisi
                dengan benar?"
            />
            <ModalSuccess
                isOpen={isSuccessSubmit}
                onClose={onCloseSuccessSubmit}
                title={`Sukses ${isEditModal ? 'Mengubah' : 'Membuat'} Account`}
                description={`Akun berhasil ${
                    isEditModal ? 'diubah' : 'dibuat'
                }, kembali ke table untuk melihat dan mengatur account`}
            />
            <ModalFailed
                disabledRightButton
                isOpen={isError.open}
                onLeftBtnClick={() => setIsError({ open: false, msg: 'Failed', code: '' })}
                title={isError.code || ''}
                description={isError.msg || 'Failed'}
            />
        </ScreenTemplate>
    );
};

export default CreateAndEditModal;

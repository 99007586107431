import { Box, Flex } from '@chakra-ui/react';
import TableHead from '../../components/tableHead';
import TableColumn from '../../components/tableColumn'
import './styles.scss';

const SectionTable = ({ deliveryRecap }) => {
    return (
        <>
            <Flex
                className={`section-table__container__table-rekap-delivery ${
                    deliveryRecap.length <= 0 && 'hide-scrollbar-section-table__container__table-rekap-delivery'
                }`}
            >
                <Box className="section-table">
                    <TableHead />
                    <Box className="columns__container" bg="white">
                        {deliveryRecap.map((item, index) => {
                            return <TableColumn key={index} datasRekap={item}/>
                        })}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;

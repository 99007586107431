import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onRemoveAsset = async ({
    selectedView,
    selectedAsset,
    onClose,
    reFetchAssetHandler,
    toast,
    setIsLoading,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        setIsLoading(true);

        await Axios({
            method: 'DELETE',
            url: `/${selectedView}/${selectedAsset.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await reFetchAssetHandler();

        await toast({
            position: 'center-top',
            description: 'Berhasil menghapus assets',
            status: 'success',
            duration: 1500,
        });
    } catch (error) {

        const message = error?.response?.data?.errors?.length ? error?.response?.data?.errors[0]?.message : null;

        toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        onClose();
        setIsLoading(false);
    }
};

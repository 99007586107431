export function FilterIcon({ color = '#2D2D2D' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
            <path
                stroke={color}
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M2.25 3.375l5.4 6.307v4.735l2.7 1.333V9.682l5.4-6.307H2.25z"
            ></path>
        </svg>
    );
}

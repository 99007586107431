import { useState } from 'react';
import { Box, Center, Divider } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { onLogout, sideMenuList } from '@components/sideMenu/functions';
import { toggleLoginHandler } from '@store/list/login';
import { onChangeSupplierType, onChangeSelectedMaterial, onChangeSupplierRef } from '@store/list/globalFilter';

import { UserIcon, ExpandRightIcon } from '../sideMenu/icons';
import './styles.scss';

const HideSidebar = ({ togglerHideLeftSide }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState('/');

    useEffect(() => {
        const { pathname } = location;
        setPage(pathname);
    }, [location]);
    return (
        <Box className={`side-menu-hide__wrapper`}>
            <Box className="side-menu__container">
                <Box className="section-head__side-menu">
                    <Box className="icon-expand__container" onClick={togglerHideLeftSide}>
                        <ExpandRightIcon />
                    </Box>
                </Box>
                <Box className="section-body__side-menu">
                    {sideMenuList().map(({ icon, link, name }) => (
                        <Center
                            onClick={() => navigate(link)}
                            className={`section-body-item__side-menu ${
                                page === link && 'section-body-item__side-menu__active'
                            }`}
                        >
                            <Box onClick={() => navigate(link)}>{icon({ isActive: page === link })}</Box>
                        </Center>
                    ))}

                    <Divider />

                    <Center
                        // onClick={() => navigate('/profile')}
                        cursor={'pointer'}
                        mt="30px"
                        onClick={() =>
                            onLogout({
                                dispatch,
                                onChangeSelectedMaterial,
                                onChangeSupplierType,
                                onChangeSupplierRef,
                                toggleLoginHandler,
                                navigate,
                            })
                        }
                        alignItems="center"
                    >
                        <UserIcon />
                    </Center>
                </Box>
            </Box>
        </Box>
    );
};

export default HideSidebar;

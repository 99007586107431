import { useState } from 'react';
import { useToast, Text } from '@chakra-ui/react';
import ModalDelete from '@components/modalDelete';

const DeleteModal = ({
    isOpen,
    onClose,
    selectedItem,
    onDeletedAccount,
    togglerDeleteModal,
    reFetchMaterialItemSetHandler,
}) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <ModalDelete
            isOpen={isOpen}
            onClose={onClose}
            title="Delete Account"
            isLoading={isLoading}
            onSubmit={() =>
                onDeletedAccount({
                    setIsLoading,
                    selectedItem,
                    togglerDeleteModal,
                    reFetchMaterialItemSetHandler,
                    toast,
                })
            }
            descriptionComponent={
                <Text className="description-text">
                    Apakah anda yakin ingin menghapus akun{' '}
                    <span>{selectedItem?.['set_component.trade_name'] || selectedItem?.set_component_id}</span>?
                </Text>
            }
        />
    );
};

export default DeleteModal;

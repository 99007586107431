export const formNewItem = [
    {
        name: 'Nama Supplier',
        type: 'select',
        placeholder: 'Masukan Nama Supplier',
        isRequired: true,
    },
    {
        name: 'Kode Supplier',
        type: 'unselect',
        placeholder: 'Terisi Ototmatis',
        isRequired: false,
    },
];


export const formDefaultValue = {
    supplier_name: '',
    supplier_ref: '',
    main_component_kode_item: '',
    main_component_internal_name: '',
    main_component_id: null,
    field_set_components: [
        {
            id_dummy: Math.floor(Math.random() * 10000) + 1,
            set_component_internal_name: '',
            set_component_kode_item: '',
            set_component_id: null,
        },
    ],
};
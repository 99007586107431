import { Box, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { IconSuccess } from './icons';
import './styles.scss';

const SectionSuccessSubmit = () => {
    const navigate = useNavigate();
    return (
        <Box className="section-success-upload__container__orderplanning">
            <IconSuccess />
            <Text className="title">FIle Berhasil diupload</Text>
            <Text className="desc">
                File anda berhasil diupload dan siap digunakan, file yang anda upload bisa dilhat pada table Order
                Planning
            </Text>
            <Button className="back-button" onClick={() => navigate('/order-planning')}>
                Kembali ke Table
            </Button>
        </Box>
    );
};

export default SectionSuccessSubmit;

import { Modal, ModalOverlay, ModalContent, Box, Button, Text, Flex } from '@chakra-ui/react';
import { FailedIcon } from './icons';
import './styles.scss';

const ModalFailedScroll = ({
    isOpen,
    title,
    onClose,
    description,
    leftLabelButton = 'Kembali',
    rightLabelButton,
    onLeftBtnClick,
    onRightBtnClick,
    disabledRightButton = false,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderRadius="3.515px" bgColor="#fff">
                <Box className="modal-failed-scroll-global__container">
                    <FailedIcon />
                    <Text className="failed-title">{title}</Text>
                    <Box className="failed-description-scroll">
                        <Box className="failed-description">{description}</Box>
                    </Box>
                    <Flex gap="14.26px" mt={5}>
                        <Button className="left-button" onClick={onLeftBtnClick} _hover="none">
                            {leftLabelButton}
                        </Button>
                        {disabledRightButton ? null : (
                            <Button className="right-button" onClick={onRightBtnClick} _hover="none">
                                {rightLabelButton}
                            </Button>
                        )}
                    </Flex>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ModalFailedScroll;

import { Box } from '@chakra-ui/react';
import TableHead from '../../component/tableHead';
import TableColumn from '../../component/tableColumn';
import './styles.scss';

const SectionTable = ({ streamlines }) => {
    return (
        <Box className="section-table__container__streamline-management" px="16px">
            <TableHead />
            <Box className="columns-container" bg="white">
                {streamlines.map((streamline, idx) => (
                    <TableColumn idx={idx} streamline={streamline} />
                ))}
            </Box>
        </Box>
    );
};

export default SectionTable;

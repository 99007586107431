import React from 'react';
import { Box, CircularProgress, useToast } from '@chakra-ui/react';
import FieldMenu from '@components/fieldMenu';
import { optionValueStatus } from '@pagesPhase2/demandAnticipation/const';
import { ArrowUpIconBlack } from '@pagesPhase2/demandAnticipation/icons';

import './styles.scss';
import { onChangeStatus } from './function';

function Index({ dataItem, value }) {
    const toast = useToast();
    const [stateValue, setStateValue] = React.useState({ value: '', name: value || '' });
    const [loading, setLoading] = React.useState(false);

    return (
        <Box className="container-column-status-demand-anticipation">
            <FieldMenu
                onClick={(val) => {
                    onChangeStatus({ status: val, dataItem, toast, setLoading, setStateValue });
                }}
                isLoading={loading}
                menuClassName="menu-container__asset-management"
                buttonName={<span className="fieldmenu-placeholder">{stateValue?.name}</span>}
                menuList={optionValueStatus}
                keyName="name"
                menuListClassName="menuList-container"
                menuButtonClassName={`fieldmenu-input`}
                icon={
                    loading ? <CircularProgress size="10px" isIndeterminate color="green.300" /> : <ArrowUpIconBlack />
                }
            />
        </Box>
    );
}

export default Index;

import { Flex, Box, Text } from '@chakra-ui/react';
import { tableList } from '../../const';
import { AssetManagementContext } from '@pages/assetManagement';
import { onChangeTabName } from '@pages/assetManagement/functions';
import { useContext } from 'react';
import './styles.scss';

const TableHead = () => {
    const { selectName } = useContext(AssetManagementContext);

    return (
        <Flex className="table-head__container__asset-management">
            {tableList.map((head) => (
                <Box
                    flex={head.flex}
                    className="table-head__item"
                    id={`table-head__item-${head.value}`}
                    width={head.width}
                    key={head.name}
                >
                    <Text className={`table-head__item-${head.value}`}>{head.name || onChangeTabName(selectName)}</Text>
                </Box>
            ))}
        </Flex>
    );
};

export default TableHead;

import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { formListStockSupplier } from '../const';
import { handleClickMenu, rightLabelHelper } from '../function';

import './styles.scss';
import { FormCreateNewItemContext } from '..';

function Index({ formData, setFormData }) {
    const { dataMaterialRaci, isSubmitted } = React.useContext(FormCreateNewItemContext);
    const handleChange = ({ event, item }) => {
        handleClickMenu({
            setFormData,
            valueOnChange: event?.target?.value,
            itemForm: item,
            dataMaterialRaci: dataMaterialRaci?.materialRaci,
        });
    };
    return (
        <Flex className="stock-suppliers">
            <Box width="267px">
                <Text className="title-stock-suppliers">Stock Supplier</Text>
                <Text className="tag-stock-suppliers">
                    Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                </Text>
            </Box>
            <Box className="stock-suppliers-container">
                {formListStockSupplier.map((itemForm, index) => {
                    if (itemForm.children) {
                        return (
                            <Box key={index}>
                                <Text className="label">
                                    {itemForm.title}
                                    {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                                <Box className="child-container">
                                    {itemForm.children?.map((child, childIndex) => {
                                        const showErrorMustBeInteger =
                                            !child.isDisabled &&
                                            formData?.dataLengkap?.jenis_material === 'PM' &&
                                            (String(formData?.stock_supplier?.max_stock_pcs)?.includes('.') ||
                                                String(formData?.stock_supplier?.max_stock_pcs)?.includes(','));
                                        return (
                                            <Box key={childIndex}>
                                                <Box position="relative">
                                                    <input
                                                        min={0}
                                                        type={
                                                            child?.name === 'max_stock_koli' ||
                                                            child?.name === 'max_stock_pallet'
                                                                ? 'text'
                                                                : 'number'
                                                        }
                                                        onChange={(event) => handleChange({ event, item: child })}
                                                        value={formData?.stock_supplier?.[child?.name] || ''}
                                                        placeholder={child.placeholder}
                                                        disabled={child.isDisabled}
                                                        style={{
                                                            padding: child.rightLabel
                                                                ? '12px 80px 12px 16px'
                                                                : '12px 16px',
                                                        }}
                                                        className={`input-style-add-new-item-MC ${
                                                            child?.isDisabled && 'input-style-add-new-item-MC-disabled'
                                                        }`}
                                                    />
                                                    {child.rightLabel && (
                                                        <Text className="right-label-input">
                                                            {rightLabelHelper({
                                                                name: child.rightLabel,
                                                                jenis_material: formData?.dataLengkap?.jenis_material,
                                                            })}
                                                        </Text>
                                                    )}
                                                </Box>
                                                {isSubmitted &&
                                                    !Boolean(formData?.stock_supplier[child.name]?.toString()) &&
                                                    child?.isRequired && (
                                                        <Text className="required-field__text">Field required!</Text>
                                                    )}
                                                {showErrorMustBeInteger && (
                                                    <Text className="required-field__text">Must be integer</Text>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        );
                    }
                    return (
                        <Box key={index}>
                            <Text className="label">
                                {itemForm.title}
                                {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                            </Text>
                            <Box className="child-container">
                                <Box position="relative">
                                    <input
                                        min={0}
                                        type="number"
                                        onChange={(event) => handleChange({ event, item: itemForm })}
                                        value={formData?.stock_supplier[itemForm?.name] || ''}
                                        placeholder={itemForm.placeholder}
                                        disabled={itemForm.isDisabled}
                                        style={{
                                            padding: itemForm.rightLabel ? '12px 80px 12px 16px' : '12px 16px',
                                        }}
                                        className={`input-style-add-new-item-MC ${
                                            itemForm?.isDisabled && 'input-style-add-new-item-MC-disabled'
                                        }`}
                                    />
                                    {itemForm.rightLabel && (
                                        <Text className="right-label-input">
                                            {rightLabelHelper({
                                                name: itemForm.rightLabel,
                                                jenis_material: formData?.dataLengkap?.jenis_material,
                                            })}
                                        </Text>
                                    )}
                                </Box>
                            </Box>
                            {isSubmitted &&
                                !Boolean(formData?.stock_supplier[itemForm.name]?.toString()) &&
                                itemForm?.isRequired && <Text className="required-field__text">Field required!</Text>}
                            {formData?.dataLengkap?.jenis_material === 'PM' &&
                                (String(formData?.stock_supplier?.rop)?.includes('.') ||
                                    String(formData?.stock_supplier?.rop)?.includes(',')) && (
                                    <Text className="required-field__text">Must be integer</Text>
                                )}
                        </Box>
                    );
                })}
            </Box>
        </Flex>
    );
}

export default Index;

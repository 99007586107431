import { Box, Flex } from '@chakra-ui/react';
import TableHead from '../../components/tableHead';
import TableColumn from '../../components/tableColumn';
import './styles.scss';

const SectionTable = ({ archivedList, handleClickRestore, handleClickNote }) => {
    return (
        <>
            <Flex
                className={`section-table-archived-list-configuration ${
                    archivedList.length <= 0 &&
                    'hide-scrollbar-section-table__container__table-archived-list-configuration'
                }`}
            >
                <Box className="section-table">
                    <TableHead />
                    <Box className="columns__container" bg="white">
                        {archivedList?.map((list, index) => {
                            return (
                                <TableColumn
                                    key={index}
                                    list={list}
                                    handleClickRestore={() => handleClickRestore(list)}
                                    handleClickNote={() => handleClickNote({ note: list })}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;

import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const saveNoteHandler = async ({
    toast,
    setValueAfterSuccessUpdate,
    notes,
    dataItem,
    setLoading,
    setModalOpen,
    setEnable,
    setValue,
    prevNotes,
}) => {
    setLoading(true);
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        await Axios({
            method: 'PATCH',
            url: `/stock-supplier/${dataItem?.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: { notes },
        })
            .then(() => {
                toast({
                    position: 'center-top',
                    description: 'Berhasil Meng-Update Data',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setValueAfterSuccessUpdate(notes);
            })
            .finally(() => {
                setLoading(false);
                setModalOpen(false);
                setEnable(false);
            });
    } catch (error) {
        console.log(error);
        setValue(prevNotes);
        setLoading(false);
        setModalOpen(false);
        setEnable(false);
        toast({
            position: 'top-center',
            description: error?.response?.data?.errors?.join(', ') || 'Something wrong!',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }
};

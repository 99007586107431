export const statusCondition = [
    {
        name: 'Ok',
        color: '#267346',
        bgColor: '#D9F2E3',
    },
    {
        name: 'Produksi',
        color: '#004199',
        bgColor: '#CCE2FF',
    },
    {
        name: 'Overstock',
        color: '#EA4F4F',
        bgColor: '#FDBDC3',
    },
];
export const enabledEdit = ['soh supplier', 'on process', 'notes'];

export const dateDummy = [
    {
        date: 'Last 7 days',
    },
    {
        date: 'This week',
    },
    {
        date: 'This month',
    },
    {
        date: 'This year',
    },
    {
        date: 'Last week',
    },
    {
        date: 'Last month',
    },
    {
        date: 'Last year',
    },
    {
        date: 'Custome date',
    },
];

export const streamlineDummy = [
    {
        id: 0,
        name: 'LA1',
    },
    {
        id: 1,
        name: 'LA2',
    },
    {
        id: 2,
        name: 'LA3',
    },
    {
        id: 3,
        name: 'LA4',
    },
    {
        id: 4,
        name: 'LA5',
    },
    {
        id: 5,
        name: 'LA6',
    },
    {
        id: 6,
        name: 'LA7',
    },
    {
        id: 7,
        name: 'LA8',
    },
    {
        id: 8,
        name: 'LA9',
    },
    {
        id: 9,
        name: 'LA10',
    },
    {
        id: 10,
        name: 'LB1',
    },
    {
        id: 11,
        name: 'LB2',
    },
    {
        id: 12,
        name: 'LB3',
    },
    {
        id: 13,
        name: 'LB4',
    },
    {
        id: 14,
        name: 'LB5',
    },
    {
        id: 15,
        name: 'LB6',
    },
    {
        id: 16,
        name: 'LC1',
    },
    {
        id: 17,
        name: 'LC2',
    },
    {
        id: 18,
        name: 'LC3',
    },
    {
        id: 19,
        name: 'LC4',
    },
    {
        id: 20,
        name: 'LC5',
    },
    {
        id: 21,
        name: 'LC6',
    },
    {
        id: 22,
        name: 'LC7',
    },
];

export const head = [
    {
        id: 'no',
        subHead: '-',
        name: 'NO',
        is_freeze: true,
        freezePosition: '0px',
        align: 'left',
        cellWidth: '57px',
    },
    {
        id: 'week',
        subHead: '-',
        name: 'WEEK',
        is_freeze: true,
        freezePosition: '57px',
        align: 'left',
        cellWidth: '61px',
    },
    {
        id: 'data_rekap',
        subHead: '-',
        name: 'DATA REKAP',
        is_freeze: true,
        freezePosition: '118px',
        align: 'left',
        cellWidth: '138px',
    },
    {
        id: 'kode_odoo',
        subHead: '-',
        name: 'KODE ODOO',
        is_freeze: true,
        freezePosition: '256px',
        align: 'left',
        cellWidth: '95px',
    },
    {
        id: 'kode_item',
        subHead: '-',
        name: 'KODE ITEM',
        is_freeze: true,
        freezePosition: '351px',
        align: 'left',
        cellWidth: '102px',
    },
    {
        id: 'nama_item',
        subHead: '-',
        name: 'NAMA ITEM',
        is_freeze: true,
        freezePosition: '453px',
        align: 'left',
        cellWidth: '356px',
        isRightBorder: true,
    },
    {
        id: 'streamline',
        subHead: '-',
        name: 'STREAMLINE',
        cellWidth: '116px',
    },
    {
        id: 'jenis_storang_ndc',
        subHead: '-',
        name: 'JENIS STORANG NDC',
        cellWidth: '184px',
    },
    {
        id: 'qty_order_1',
        subHead: 'Pcs',
        name: 'QTY ORDER',
        cellWidth: '116px',
    },
    {
        id: 'qty_order_2',
        subHead: 'Storage',
        name: 'QTY ORDER',
        cellWidth: '116px',
    },
    {
        id: 'qty_realisasi_1',
        subHead: 'Pcs',
        name: 'QTY REALISASI',
        cellWidth: '139px',
    },
    {
        id: 'qty_realisasi_2',
        subHead: 'Storage',
        name: 'QTY REALISASI',
        cellWidth: '139px',
    },
    {
        id: 'kode_batch',
        subHead: 'Storage',
        name: 'KODE BATCH',
        cellWidth: '139px',
    },
    {
        id: 'snfg',
        subHead: '-',
        name: 'SNFG',
        cellWidth: '163px',
    },
    {
        id: 'nomo',
        subHead: '-',
        name: 'NOMO',
        cellWidth: '193px',
    },
    {
        id: 'timestamp_start_timbang',
        subHead: '-',
        name: 'TIMESTAMP START TIMBANG',
        cellWidth: '241px',
    },
    {
        id: 'timestamp_done_kemas',
        subHead: '-',
        name: 'TIMESTAMP DONE KEMAS',
        cellWidth: '241px',
    },
    {
        id: 'timestamp_done_ndc',
        subHead: '-',
        name: 'TIMESTAMP DONE NDC',
        cellWidth: '241px',
    },
    {
        id: 'due_date_planner',
        subHead: '-',
        name: 'DUE DATE PLANNER',
        cellWidth: '174px',
    },
];

export const body = new Array(20)
    .fill({
        no: '1',
        week: '18',
        data_rekap: '04 September 2023',
        kode_odoo: '80012',
        kode_item: 'L-MLCL-OMG',
        nama_item: 'OMG Oh My Glam Matte Kiss Lip Cream 12 Scarlet 3.5 G Testing text long here',
        streamline: 'VA1',
        jenis_storang_ndc: 'Trolley',
        qty_order_1: '12,027',
        qty_order_2: '4.0',
        qty_realisasi_1: '12,027',
        qty_realisasi_2: '4.0',
        kode_batch: 'BI04A',
        snfg: 'L-MLCL-OMG/I0023',
        nomo: 'MO-L-MLCL-BULK/I0023',
        timestamp_start_timbang: '02 Mei 2023  10 :10 : 00',
        timestamp_done_kemas: '02 Mei 2023  13 :20 : 00',
        timestamp_done_ndc: '02 Mei 2023  15 :20 : 00',
        due_date_planner: '05 Mei 2023 ',
    })
    .map((item, index) => ({ ...item, no: index + 1, week: Math.floor(Math.random() * 20) + 1 }));

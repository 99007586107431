import { Box, Flex } from '@chakra-ui/react';
import TableHead from '@pages/skuConfiguration/components/tableHead';
import TableColumn from '@pages/skuConfiguration/components/tableColumn';
import './styles.scss';

const SectionTable = ({ skus, setSortName }) => {
    return (
        <Flex
            className={`section-table__container__sku-configuration ${
                skus.length <= 0 && 'hide-scrollbar-section-table__container__sku-configuration'
            }`}
        >
            <Box className="section-table">
                <TableHead setSortName={setSortName} />
                <Box className="columns__container" bg="white">
                    {skus.map((item, idx) => (
                        <TableColumn idx={idx} sku={item} />
                    ))}
                </Box>
            </Box>
        </Flex>
    );
};

export default SectionTable;

import { Box, Text, Flex } from '@chakra-ui/react';
import { RightArrowIcon, LeftArrowIcon } from '@pages/skuConfiguration/icons';
import ReactPaginate from 'react-paginate';
import './styles.scss';

const SectionFooter = ({totalData, totalPage, handlePageClick, page, count}) => {
    return (
        <Box className="section-footer__container__sku-configuration">
            <Text className="showing-text">Showing {count} out of {totalData} entris</Text>
            <Flex className="pagination-sku-configuration__container">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RightArrowIcon />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalPage}
                    forcePage={page - 1}
                    previousLabel={<LeftArrowIcon />}
                />
            </Flex>
        </Box>
    );
};

export default SectionFooter;

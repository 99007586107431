import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Box,
    Text,
    Flex,
    Spinner,
    Checkbox,
    Menu,
    MenuList,
    InputGroup,
    Input,
    InputRightElement,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon, CalendarIcon } from './icons';
import { onFormatDate, handleCheckboxChange } from '../function';
import { formNewItem } from './const';
import DatePicker from 'react-date-picker';
import './styles.scss';

function Index({
    onClose,
    isOpen,
    handleResetFilter,
    handleApplyFilter,
    outlineLabel,
    setFilter,
    loadingSearch,
    search,
    setSearch,
    data,
    setData,
    dataChoosed,
    setDataChoosed,
}) {
    const [formData, setFormData] = useState({ no_po: '' });
    const [selectDate, setSelectDate] = useState(null);
    const [openInfiniteScroll, setOpenInfiniteScroll] = useState(false);

    const onChangeInputDate = (selectDate) => {
        setSelectDate(selectDate);
        setFilter((prev) => ({ ...prev, inputDate: onFormatDate(selectDate) }));
    };

    const handleClickNumberPO = (item) => {
        setSearch(item?.name);
        setFormData((prev) => ({
            ...prev,
            no_po: item?.name,
        }));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} position="relative">
            <Box className="modal-container-filter-multiple-rekap-delivery">
                <ModalOverlay />
                <ModalContent maxW="410px" maxH="max-content">
                    <Flex className="modal-filter-multiple-header">
                        <Text className="modal-header-text">Filter</Text>
                        <Box onClick={onClose} cursor="pointer">
                            <CloseIcon />
                        </Box>
                    </Flex>
                    <ModalBody className="modal-body">
                        <Flex className="filter-no-po__container">
                            {formNewItem.map((formList) => {
                                if (formList.type === 'select') {
                                    return (
                                        <>
                                            <Box>
                                                <Text className="label-title">{formList?.title}</Text>
                                                <Box position="relative">
                                                    <InputGroup>
                                                        <Input
                                                            className="fieldmenu-input"
                                                            placeholder={formList?.placeholder}
                                                            onFocus={() => setOpenInfiniteScroll(true)}
                                                            value={formData.no_po || search}
                                                            onChange={(e) => {
                                                                setSearch(e.target.value);
                                                            }}
                                                        />
                                                        <InputRightElement h="32px">
                                                            <Box className="divider" />
                                                            {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    <Menu
                                                        isOpen={openInfiniteScroll}
                                                        onClose={() => setOpenInfiniteScroll(false)}
                                                    >
                                                        <MenuList mt="38px" zIndex={20} w="374px">
                                                            <div id="scrollableDiv" className="scroll-container">
                                                                {data?.map((item, index) => {
                                                                    return (
                                                                        <Flex
                                                                            key={index}
                                                                            justifyContent="space-between"
                                                                            className="list_item_infinite"
                                                                            onClick={() => handleClickNumberPO(item)}
                                                                        >
                                                                            <Text>{item?.name}</Text>
                                                                            <Checkbox
                                                                                isChecked={item.checked}
                                                                                onChange={(checked) =>
                                                                                    handleCheckboxChange({
                                                                                        checked: checked.target.checked,
                                                                                        selectedData: item,
                                                                                        data,
                                                                                        setData,
                                                                                        dataChoosed,
                                                                                        setDataChoosed,
                                                                                    })
                                                                                }
                                                                                className="checkbox-custom-style"
                                                                            />
                                                                        </Flex>
                                                                    );
                                                                })}
                                                            </div>
                                                        </MenuList>
                                                    </Menu>
                                                </Box>
                                            </Box>
                                        </>
                                    );
                                }
                            })}
                        </Flex>

                        <Box className="datepicker-update-eta__container">
                            <Text className="label-title-update">Update ETA</Text>
                            <Box className="calender_update-eta">
                                <DatePicker
                                    value={selectDate}
                                    onChange={onChangeInputDate}
                                    className="date-picker-update-eta"
                                    openCalendarOnFocus={false}
                                    clearIcon={null}
                                    calendarIcon={<CalendarIcon color={'#2D2D2D'} />}
                                    calendarClassName="calendar-update-eta"
                                    format="yyyy-MM-dd"
                                    dayPlaceholder="ETA"
                                    monthPlaceholder="Update"
                                    yearPlaceholder="Input"
                                />
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter className="ModalFooter">
                        <Box className="btn-modal-close" onClick={handleResetFilter}>
                            <Text className="text-modal-close">{outlineLabel}</Text>
                        </Box>
                        <Box className={`btn-apply-filter`} onClick={handleApplyFilter}>
                            <Text className="text-apply-filter">Apply Filter</Text>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { forEach, isEmpty } from 'lodash';
import queryString from 'query-string';

import { routeList as routeListPhase1, routeListPhase2 } from '@routers/const';
import { routes } from '@routers';

import {
    viewDistplanObj,
    viewNdcObj,
    viewPpcObj,
    viewProduksiObj,
    viewSuperAdminObj,
    viewViewerObj,
    viewLogisticObj,
    viewBedaPpcObj,
    viewBedaProduksiObj,
} from '@store/const/access';

const userMaterialRoleAccess = ({ selectedMaterial, userMaterial }) => {
    return !isEmpty(userMaterial?.filter((material) => material?.name === selectedMaterial?.name));
};

const getRouterListHandler = ({ appType }) => {
    switch (appType) {
        case 'ndcToManufacture':
            return routeListPhase1;
        case 'warehouseToSupplier':
            return routeListPhase2();
        default:
            break;
    }
};

const setAccessPermission = ({ roles = '', selectedMaterial, userMaterial }) => {
    const rolesUpper = roles.toString().toUpperCase();

    switch (rolesUpper) {
        case 'SUPER ADMIN':
            return viewSuperAdminObj;
        case 'PPC':
            const ppcObj = userMaterialRoleAccess({ selectedMaterial, userMaterial }) ? viewPpcObj : viewBedaPpcObj;
            return ppcObj;

        case 'PRODUKSI':
            const productionObj = userMaterialRoleAccess({ selectedMaterial, userMaterial })
                ? viewProduksiObj
                : viewBedaProduksiObj;
            return productionObj;

        case 'LOGISTIK':
            return viewLogisticObj;
        case 'DISTPLAN':
            return viewDistplanObj;
        case 'NDC':
            return viewNdcObj;
        case 'VIEWER':
            return viewViewerObj;
        default:
            break;
    }
};

const setRouteList = ({ roles = '', selectedMaterial, userMaterial }) => {
    const rolesUpper = roles.toString().toUpperCase();

    const cookies = new Cookies();
    const appType = cookies.get('appType');

    const routeList = getRouterListHandler({ appType });

    switch (rolesUpper) {
        case 'SUPER ADMIN':
            return {
                routeList,
                routes,
            };
        case 'PPC':
            return userMaterialRoleAccess({ selectedMaterial, userMaterial })
                ? {
                      routeList: [...routeList].filter((item) => item.name !== 'User Management'),
                      routes: routes.filter((item) => item.path !== '/user-management'),
                  }
                : {
                      routeList: [...routeList].filter(
                          (item) =>
                              item.name !== 'User Management' &&
                              item.name !== 'SKU Configuration' &&
                              item.name !== 'Asset Management'
                      ),
                      routes: routes.filter(
                          (item) =>
                              item.path !== '/user-management' &&
                              item.path !== '/sku-configuration' &&
                              item.path !== '/asset-management'
                      ),
                  };
        case 'PRODUKSI':
            return {
                routeList: [...routeList].filter(
                    (item) =>
                        item.name !== 'User Management' &&
                        item.name !== 'SKU Configuration' &&
                        item.name !== 'Streamline Management' &&
                        item.name !== 'Asset Management'
                ),
                routes: routes.filter(
                    (item) =>
                        item.path !== '/user-management' &&
                        item.path !== '/sku-configuration' &&
                        item.path !== '/streamline-management' &&
                        item.path !== '/asset-management'
                ),
            };
        case 'LOGISTIK':
            return {
                routeList: [...routeList].filter(
                    (item) =>
                        item.name !== 'User Management' &&
                        item.name !== 'SKU Configuration' &&
                        item.name !== 'Streamline Management' &&
                        item.name !== 'Asset Management'
                ),
                routes: routes.filter(
                    (item) =>
                        item.path !== '/user-management' &&
                        item.path !== '/sku-configuration' &&
                        item.path !== '/streamline-management' &&
                        item.path !== '/asset-management'
                ),
            };
        case 'VIEWER':
            return {
                routeList: [...routeList].filter(
                    (item) =>
                        item.name !== 'User Management' &&
                        item.name !== 'SKU Configuration' &&
                        item.name !== 'Streamline Management' &&
                        item.name !== 'Asset Management'
                ),
                routes: routes.filter(
                    (item) =>
                        item.path !== '/user-management' &&
                        item.path !== '/sku-configuration' &&
                        item.path !== '/streamline-management' &&
                        item.path !== '/asset-management'
                ),
            };
        case 'DISTPLAN':
            return {
                routeList: [...routeList].filter(
                    (item) => item.name !== 'User Management' && item.name !== 'Asset Management'
                ),
                routes: routes.filter((item) => item.path !== '/user-management' && item.path !== '/asset-management'),
            };
        case 'NDC':
            return {
                routeList: [...routeList].filter(
                    (item) =>
                        item.name !== 'User Management' &&
                        item.name !== 'SKU Configuration' &&
                        item.name !== 'Streamline Management' &&
                        item.name !== 'Asset Management'
                ),
                routes: routes.filter(
                    (item) =>
                        item.path !== '/user-management' &&
                        item.path !== '/sku-configuration' &&
                        item.path !== '/streamline-management' &&
                        item.path !== '/asset-management'
                ),
            };
        default:
            return {
                routeList: [],
                routes: [routes.find((i) => i.path === '/login')],
            };
    }
};

export const fetchUserProfile = createAsyncThunk('userPermission/access', async ({ selectedMaterial }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    try {
        const { data } = await Axios({
            method: 'GET',
            url: '/user/profile',
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const userMaterial = data?.data?.user?.sediaans;
        const roles = cookies.get('role');

        const routeList = setRouteList({ roles, selectedMaterial, userMaterial }).routeList;
        const routes = setRouteList({ roles, selectedMaterial, userMaterial }).routes;
        const access = setAccessPermission({ roles: roles, selectedMaterial, userMaterial });

        cookies.set('userMaterial', userMaterial);

        const features = data?.data?.user?.features;
        if (data?.data?.user?.role?.includes('Supplier')) {
            cookies.set('supplier_name', data?.data?.user?.name, { path: '/' });
        }
        forEach(features, (item) => {
            cookies.set(item?.name, queryString.stringify({ permissions: item?.permissions }));
        });

        cookies.set(
            'jenisMaterial',
            queryString.stringify({
                id: data?.data?.user?.jenis_material_id,
                name: data?.data?.user?.jenis_material,
            })
        );

        return {
            roles,
            routeList,
            routes,
            access,
            userMaterial,
        };
    } catch (error) {
        console.log(error);
    }
});

export const accessPermissionSlice = createSlice({
    name: 'userPermission',
    initialState: {
        accessPermission: {},
        roles: '',
        isLoadingSetAccessPermission: true,
        routesList: [],
        routes: [],
    },
    reducers: {
        resetAccessPermission: (state) => {
            state.accessPermission = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            const roles = action.payload?.roles;

            state.roles = roles;
            state.userMaterial = action?.payload?.userMaterial;
            state.accessPermission = action.payload?.access;
            state.routesList = action.payload?.routeList;
            state.routes = action.payload?.routes;
            state.isLoadingSetAccessPermission = false;
        });
    },
});

export const { resetAccessPermission, setAccess } = accessPermissionSlice.actions;

export default accessPermissionSlice.reducer;

import { Box, Text } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';

const FieldInput = ({ title = '', keyWord, formData, setFormData, onChangeFormData }) => {
    return (
        <Box className="modal-item__container" experimental_spaceY={2}>
            <Text className="label">Masukkan {title}</Text>
            <Box flex={1}>
                <Textarea
                    placeholder={`Tulis ${title}`}
                    _focus={{ boxShadow: 'none' }}
                    className="input-container"
                    onChange={(e) =>
                        onChangeFormData({
                            type: keyWord,
                            value: e.target.value,
                            setFormData,
                            formData,
                        })
                    }
                    value={formData[keyWord]}
                />
            </Box>
        </Box>
    );
};

export default FieldInput;

import { isEmptyString } from '@helpers/functions';
import Axios from '@services/axios';
import { round } from 'lodash';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { initialValue, supplierCategoryOption } from './const';

export const handleClickMenu = ({ itemForm, selected, setFormData, valueOnChange, dataMaterialRaci = {} }) => {
    setFormData((prev) => {
        if (itemForm?.class?.includes('baselinePerWeek')) {
            return {
                ...prev,
                baselinePerWeek: {
                    pcs: valueOnChange,
                    koli: round(Number(valueOnChange) / dataMaterialRaci?.qty_per_kemasan, 1),
                    pallet: round(Number(valueOnChange) / dataMaterialRaci?.qty_per_storage, 1),
                },
                avgPerDay: {
                    pcs: round(Number(valueOnChange) / 6, 1),
                    koli: round(Number(valueOnChange) / dataMaterialRaci?.qty_per_kemasan / 6, 1),
                    pallet: round(Number(valueOnChange) / dataMaterialRaci?.qty_per_storage / 6, 1),
                },
                critical_point:
                    prev?.critical_point?.stdev > 0
                        ? {
                              ...prev?.critical_point,
                              safety_by_demand_hari: round(
                                  prev?.critical_point?.safety_by_demand_pcs / (Number(valueOnChange) / 6),
                                  1
                              ),
                          }
                        : { ...prev?.critical_point },
            };
        }
        if (itemForm?.class?.includes('critical_point_controller')) {
            let angka = Number(prev?.critical_point?.lead_time_produksi) / 6;
            let akar = angka ** 0.5;
            return {
                ...prev,
                critical_point: {
                    ...prev?.critical_point,
                    stdev: valueOnChange,
                    safety_by_demand_pcs: round(Number(1.65 * valueOnChange * akar), 1),
                    safety_by_demand_hari: round(Number(1.65 * valueOnChange * akar) / prev?.avgPerDay?.pcs, 1),
                },
            };
        }
        if (itemForm?.name?.includes('lead_time_produksi')) {
            let angka = Number(valueOnChange) / 6;
            let stDevValue = Number(prev?.critical_point?.stdev);
            let akar = angka ** 0.5;
            return {
                ...prev,
                critical_point: {
                    ...prev?.critical_point,
                    lead_time_produksi: valueOnChange,
                    safety_by_demand_pcs: round(Number(1.65 * stDevValue * akar), 1),
                    safety_by_demand_hari: round(Number(1.65 * stDevValue * akar) / prev?.avgPerDay?.pcs, 1),
                },
            };
        }
        if (itemForm?.class?.includes('stock_pti_controller')) {
            return {
                ...prev,
                stock_pti: {
                    ...prev?.stock_pti,
                    max_stock_pcs: valueOnChange,
                    max_stock_koli: round(Number(valueOnChange / dataMaterialRaci?.qty_per_kemasan), 1),
                    max_stock_pallet: round(Number(valueOnChange / dataMaterialRaci?.qty_per_storage), 1),
                },
            };
        }
        if (itemForm?.class?.includes('stock_supplier_controller')) {
            return {
                ...prev,
                stock_supplier: {
                    ...prev?.stock_supplier,
                    max_stock_pcs: valueOnChange,
                    max_stock_koli: round(Number(valueOnChange / dataMaterialRaci?.qty_per_kemasan), 1),
                    max_stock_pallet: round(Number(valueOnChange / dataMaterialRaci?.qty_per_storage), 1),
                },
            };
        }
        if (itemForm?.class === 'raw_material') {
            prev?.stock_raw_material?.map((item) => {
                if (item?.id === selected) {
                    item[itemForm.key] = valueOnChange;
                }
            });
        }
        return {
            ...prev,
            [itemForm?.class]: { ...prev[itemForm?.class], [itemForm?.name]: selected?.name || valueOnChange },
        };
    });
};

export const handleSetValueForm = ({ setFormData, data, setSearchSupplierName, setSearchTradeName }) => {
    const isWarning = !Boolean(data?.is_draft) && Boolean(data?.is_warning);
    const isTransition = isWarning ? data?.transition_trade_name : data?.trade_name;
    setSearchSupplierName(data?.supplier?.name);
    setSearchTradeName(isTransition);
    setFormData((prev) => {
        let angka = Number(data?.production_leadtime) / 6;
        let akar = angka ** 0.5;
        return {
            ...prev,
            dataLengkap: {
                ...prev?.dataLengkap,
                supplier_name: data?.supplier?.name,
                supplier_category: supplierCategoryOption.find(
                    (item) => item.value === data?.supplier?.supplierCategory?.category
                )?.name,
                supplier_code: data?.supplier?.supplier_ref,
                trade_name: isTransition,
                code_item: isWarning ? data?.transition_code : data?.kode_item,
                internal_name: data?.internal_name,
                jenis_material: data?.materialRaci?.category,
                mto_mts: data?.is_mto ? 'MTO' : 'MTS',
                close_po_by: data?.close_po_by,
            },
            konfigurasiSatuan: {
                ...prev?.konfigurasiSatuan,
                satuan_order: data?.satuan_order,
            },
            baselinePerWeek: {
                pcs: data?.baseline_pcs,
                koli: data?.baseline_koli,
                pallet: data?.baseline_pallet,
            },
            avgPerDay: {
                ...prev?.baselinePerWeek,
                pcs: round(Number(data?.avg_baseline_pcs), 1),
                koli: data?.avg_baseline_koli,
                pallet: data?.avg_baseline_pallet,
            },
            critical_point: {
                ...prev?.critical_point,
                stdev: data?.stdev,
                frekuensi_delivery: data?.delivery_freq,
                lead_time_produksi: data?.production_leadtime,
                lead_time_delivery: data?.delivery_leadtime,
                safety_by_demand_pcs: round(Number(1.65 * data?.stdev * akar), 1),
                safety_by_demand_hari: round(Number(1.65 * data?.stdev * akar) / data?.avg_baseline_pcs, 1),
                mpq_pti: data?.mpq_pti,
                mpq_supplier: data?.mpq_supplier,
                mdq_supplier: data?.mdq_supplier,
                safety_vulnerability: Number(data?.safety_vulnerability),
                safety_by_lead_time: Number(data?.safety_leadtime),
                rop_ospo: Number(data?.rop_ospo),
            },
            stock_pti: {
                rop: data?.stock_pti_rop,
                max_stock_pcs: data?.stock_pti_max,
                max_stock_koli: round(Number(data?.stock_pti_max / data?.materialRaci?.qty_per_kemasan), 1),
                max_stock_pallet: round(Number(data?.stock_pti_max / data?.materialRaci?.qty_per_storage), 1),
            },
            stock_supplier: {
                rop: data?.stock_supp_rop,
                max_stock_pcs: data?.stock_supp_max,
                max_stock_koli: round(Number(data?.stock_supp_max / data?.materialRaci?.qty_per_kemasan), 1),
                max_stock_pallet: round(Number(data?.stock_supp_max / data?.materialRaci?.qty_per_storage), 1),
            },
            stock_raw_material: Boolean(data?.materialRawmats?.length)
                ? data?.materialRawmats?.map((item, index) => ({
                      id: item?.id,
                      material_name: item?.material_name,
                      rop: item?.rop,
                      max_stock: item?.max_stock,
                      delete: false,
                  }))
                : [
                      {
                          id: 1,
                          material_name: '',
                          rop: '',
                          max_stock: '',
                          delete: false,
                          is_new: true,
                      },
                  ],
        };
    });
};
export const handleSaveItem = ({
    setIsSubmitted,
    setOpenModal,
    formData,
    isDraf,
    isEmptyRaci,
    toast,
    isDataMaterialIdNotFound,
}) => {
    if (isEmptyRaci || isDataMaterialIdNotFound) return;
    validationForm({ setIsSubmitted, setOpenModal, formData, isDraf, isEmptyRaci, toast });
};

export const validationForm = ({ setIsSubmitted, formData, setOpenModal, isDraft, isEmptyRaci, toast }) => {
    if (!isEmptyRaci && !isDraft) {
        setIsSubmitted(true);
        if (
            Boolean(formData?.dataLengkap?.supplier_name?.toString()) &&
            Boolean(formData?.dataLengkap?.trade_name?.toString()) &&
            Boolean(formData?.dataLengkap?.supplier_category?.toString()) &&
            Boolean(formData?.dataLengkap?.mto_mts?.toString()) &&
            Boolean(formData?.dataLengkap?.close_po_by?.toString()) &&
            Boolean(formData?.konfigurasiSatuan?.satuan_order?.toString()) &&
            Boolean(formData?.baselinePerWeek?.pcs?.toString()) &&
            Boolean(formData?.critical_point?.stdev?.toString()) &&
            Boolean(formData?.critical_point?.frekuensi_delivery?.toString()) &&
            Boolean(formData?.critical_point?.lead_time_produksi?.toString()) &&
            Boolean(formData?.critical_point?.lead_time_delivery?.toString()) &&
            Boolean(formData?.critical_point?.mpq_pti?.toString()) &&
            Boolean(formData?.critical_point?.mpq_supplier?.toString()) &&
            Boolean(formData?.critical_point?.mdq_supplier?.toString()) &&
            Boolean(formData?.stock_pti?.rop?.toString()) &&
            Boolean(formData?.stock_pti?.max_stock_pcs?.toString()) &&
            Boolean(formData?.stock_supplier?.rop?.toString()) &&
            Boolean(formData?.stock_supplier?.max_stock_pcs?.toString()) &&
            !isDraft
        ) {
            /// VALIDASI JIKA JENIS MATERIAL = PM
            let errorIfPmTypeFloat = false;
            const mustIntegerIfPm = [
                formData?.critical_point?.stdev,
                formData?.baselinePerWeek?.pcs,
                formData?.critical_point?.mpq_pti,
                formData?.critical_point?.mpq_supplier,
                formData?.critical_point?.mdq_supplier,
                formData?.stock_pti?.rop,
                formData?.stock_pti?.max_stock_pcs,
                formData?.stock_supplier?.rop,
                formData?.stock_supplier?.max_stock_pcs,
            ];

            for (let indexRawMat = 0; indexRawMat < formData.stock_raw_material.length; indexRawMat++) {
                if (
                    String(formData.stock_raw_material[indexRawMat]?.rop)?.includes('.') ||
                    String(formData.stock_raw_material[indexRawMat]?.rop)?.includes(',') ||
                    String(formData.stock_raw_material[indexRawMat]?.max_stock)?.includes('.') ||
                    String(formData.stock_raw_material[indexRawMat]?.max_stock)?.includes(',')
                ) {
                    errorIfPmTypeFloat = true;
                }
            }
            for (let index = 0; index < mustIntegerIfPm.length; index++) {
                if (String(mustIntegerIfPm[index])?.includes('.') || String(mustIntegerIfPm[index])?.includes(',')) {
                    errorIfPmTypeFloat = true;
                }
            }
            if (errorIfPmTypeFloat && formData?.dataLengkap?.jenis_material === 'PM') {
                toast({
                    description: 'Jenis Material PM, value number tidak boleh ada yang bertipe float!',
                    status: 'error',
                    duration: 7000,
                    isClosable: true,
                    position: 'top-center',
                });
                return;
            }
            setOpenModal((prev) => ({ ...prev, type: 'confirm', title: 'Apa anda yakin telah mengisi dengan benar?' }));
        } else {
            toast({
                description: 'Field tidak boleh kosong!',
                status: 'error',
                duration: 7000,
                isClosable: true,
                position: 'top-center',
            });
        }
    }
};
export const handleSubmit = async ({
    formData,
    toast,
    setOpenModal,
    setIsSubmitLoading,
    isSubmitLoading,
    isDraft,
    section,
    setIsDraftPost,
    materialConfigId,
    isEditTransition,
}) => {
    if (isSubmitLoading) return;
    setIsSubmitLoading(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const body = {
        materialConfig: {
            baseline_pcs: Number(formData?.baselinePerWeek?.pcs),
            supplier_name: formData?.dataLengkap?.supplier_name,
            supplier_ref: formData?.dataLengkap?.supplier_code,
            trade_name: formData?.dataLengkap?.trade_name,
            internal_name: formData?.dataLengkap?.internal_name,
            kode_item: formData?.dataLengkap?.code_item,
            supplier_cat: formData?.dataLengkap?.supplier_category?.toUpperCase(),
            is_mto: Boolean(formData?.dataLengkap?.mto_mts?.includes('MTO')),
            close_po_by: formData?.dataLengkap?.close_po_by?.toUpperCase(),
            satuan_order: formData?.konfigurasiSatuan?.satuan_order?.toUpperCase(),
            stock_pti_rop: Number(formData?.stock_pti?.rop),
            stock_pti_max: Number(formData?.stock_pti?.max_stock_pcs),
            stock_supp_rop: Number(formData?.stock_supplier?.rop),
            stock_supp_max: Number(formData?.stock_supplier?.max_stock_pcs),
        },
        criticalPoint: {
            stdev: Number(formData?.critical_point?.stdev),
            delivery_freq: Number(formData?.critical_point?.frekuensi_delivery),
            production_leadtime: Number(formData?.critical_point?.lead_time_produksi),
            delivery_leadtime: Number(formData?.critical_point?.lead_time_delivery),
            mpq_pti: Number(formData?.critical_point?.mpq_pti),
            mpq_supplier: Number(formData?.critical_point?.mpq_supplier),
            mdq_supplier: Number(formData?.critical_point?.mdq_supplier),
            rop_ospo:
                formData?.critical_point?.rop_ospo == '' || formData?.critical_point?.rop_ospo === null
                    ? null
                    : Number(formData?.critical_point?.rop_ospo),
            safety_vulnerability:
                formData?.critical_point?.safety_vulnerability === '' ||
                formData?.critical_point?.safety_vulnerability === null
                    ? null
                    : Number(formData?.critical_point?.safety_vulnerability),
            safety_leadtime:
                formData?.critical_point?.safety_by_lead_time === '' ||
                formData?.critical_point?.safety_by_lead_time === null
                    ? null
                    : Number(formData?.critical_point?.safety_by_lead_time),
        },
        rawmat: [],
    };
    for (const key in body.criticalPoint) {
        if (body.criticalPoint[key] === null) delete body.criticalPoint[key];
    }
    delete body.materialConfig.supplier_name;
    delete body.materialConfig.trade_name;
    delete body.materialConfig.internal_name;
    for (let index = 0; index < formData?.stock_raw_material.length; index++) {
        if (!isEmptyString(formData?.stock_raw_material[index]?.material_name)) {
            body.rawmat.push(formData?.stock_raw_material[index]);
        }
    }

    if (isEditTransition) {
        body.rawmat = body.rawmat?.map((item) => ({
            material_name: item?.material_name,
            rop: Number(item?.rop),
            max_stock: Number(item?.max_stock),
        }));
    }

    if (!section?.includes('item-update')) {
        body.rawmat = body.rawmat
            ?.filter((list) => !list?.delete)
            ?.map((item) => ({
                material_name: item?.material_name,
                rop: Number(item?.rop),
                max_stock: Number(item?.max_stock),
            }));
    }
    if (section?.includes('item-update') && !isEditTransition) {
        body.rawmat = body.rawmat
            ?.filter((newItem) => !newItem?.delete)
            ?.map((item) => {
                if (item.is_new) {
                    return {
                        material_name: item?.material_name,
                        rop: Number(item?.rop),
                        max_stock: Number(item?.max_stock),
                    };
                } else {
                    return {
                        id: item?.id,
                        material_name: item?.material_name,
                        rop: Number(item?.rop),
                        max_stock: Number(item?.max_stock),
                        delete: item?.delete,
                    };
                }
            });
    }
    if (isDraft) {
        for (const key in body?.criticalPoint) {
            if (body?.criticalPoint[key] === '' || body?.criticalPoint[key] === 0) {
                delete body?.criticalPoint[key];
            }
        }
        for (const key in body?.materialConfig) {
            if (body?.materialConfig[key] === '' || body?.materialConfig[key] === 0) {
                delete body?.materialConfig[key];
            }
        }
    }
    await Axios({
        method: section?.includes('item-update') && !isEditTransition ? 'PATCH' : 'POST',
        url:
            section?.includes('item-update') && !isEditTransition
                ? `/material/config/${materialConfigId}?isDraft=false`
                : `material/config?isDraft=${isDraft}`,
        headers: { Authorization: 'Bearer ' + userToken },
        data: body,
    })
        .then((res) => {
            setOpenModal((prev) => ({ ...prev, type: 'success' }));
            setIsSubmitLoading(false);
            if (isDraft) setIsDraftPost(true);
        })
        .catch((error) => {
            console.log(error);
            toast({
                position: 'top-center',
                title: Boolean(error?.response?.data?.errors?.[0]?.message)
                    ? error?.response?.data?.errors?.map((item) => item?.message)?.join()
                    : error?.response?.data?.errors?.join() || 'Failed',
                description: Boolean(error?.response?.data?.errors?.[0]?.code === 3004)
                    ? error?.response?.data?.errors?.map((item) => item?.errors)?.join()
                    : Boolean(error?.response?.data?.errors?.[0]?.code === 3001)
                    ? error?.response?.data?.errors?.errors?.errors?.map((item) => item?.message)?.join()
                    : 'Failed',
                status: 'error',
                duration: 4500,
                isClosable: true,
            });
            setIsSubmitLoading(false);
        });
};
export const handleConfirmOut = ({
    formData,
    navigate,
    setConfirmOut,
    setFormData,
    setSearchSupplierName,
    setSearchTradeName,
}) => {
    const field = Object.values(formData?.dataLengkap || {});
    const isDataLengkapNotFullFilled = field.filter((i) => i !== '');
    if (isDataLengkapNotFullFilled.length > 8) {
        setConfirmOut(true);
    } else {
        setFormData(initialValue);
        setSearchSupplierName('');
        setSearchTradeName('');
        navigate('/material-configuration');
    }
};
export const afterFetchRaci = ({ res, setIsEmptyRaci, toast }) => {
    if (!Boolean(res?.data?.data?.materialRaci)) {
        setIsEmptyRaci(true);
        toast({
            title: 'Gagal Mengambil Material RACI.',
            description: 'Sepertinya material RACI tidak tersedia',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-center',
        });
    } else {
        setIsEmptyRaci(false);
    }
};
export const fetchDataInfiniteSupplierName = async ({
    page,
    setDataSupplierName,
    setLoadingSearch,
    setPage,
    moreData = false,
    setTotalData,
    searchSupplierName,
    api,
    toast,
    loadingOnUserTyping,
    setLoadingUserTyping,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const filterStringify = queryString.stringify(
        { q: searchSupplierName, page, source: 'INTERNAL', limit: 15 },
        { skipEmptyString: true }
    );
    setLoadingSearch(true);
    setLoadingUserTyping(loadingOnUserTyping);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `${api}?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { suppliers, totalData } = data.data;
        if (moreData && !searchSupplierName) {
            setDataSupplierName((prev) => [...prev, ...(suppliers || [])]);
            setPage(page + 1);
        } else {
            setDataSupplierName(suppliers || []);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    } catch (error) {
        console.log(error);
        toast({
            title: 'Error',
            description: 'Something wrong',
            status: 'error',
            duration: 7000,
            isClosable: true,
            position: 'top-center',
        });
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    }
};
export const fetchDataInfiniteTradeName = async ({
    setDataTradeName,
    setLoadingSearch,
    moreData = false,
    setTotalData,
    searchTradeName,
    api,
    toast,
    loadingOnUserTyping,
    setLoadingUserTyping,
    isPureFetch = false,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const filterStringify = queryString.stringify(
        { materialName: searchTradeName, raci: true },
        { skipEmptyString: true }
    );
    setLoadingSearch(true);
    if (!isPureFetch) {
        setLoadingUserTyping(loadingOnUserTyping);
    }
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `${api}${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { materials, totalData } = data.data;
        if (Boolean(materials?.length === 0) && isPureFetch) {
            toast({
                title: 'Error',
                description: 'Trade name tidak tersedia!',
                status: 'error',
                duration: 7000,
                isClosable: true,
                position: 'top-center',
            });
        }

        if (!isPureFetch) {
            if (moreData && !searchTradeName) {
                setDataTradeName((prev) => [...prev, ...(materials || [])]);
            } else {
                setDataTradeName(materials || []);
            }
            setTotalData(totalData);
            setLoadingUserTyping(false);
        }
        setLoadingSearch(false);
    } catch (error) {
        console.log(error);
        toast({
            title: 'Error',
            description: 'Something wrong',
            status: 'error',
            duration: 7000,
            isClosable: true,
            position: 'top-center',
        });
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    }
};

export const handleClickInput = ({ itemForm, setOpenInfiniteScroll, supplierRef }) => {
    setOpenInfiniteScroll((prev) => {
        return {
            ...prev,
            [itemForm.name]: true,
            api: itemForm.name?.includes('trade_name')
                ? `${itemForm?.api}?supplierRef=${supplierRef || ''}&`
                : itemForm?.api,
        };
    });
};

export const rightLabelHelper = ({ name, jenis_material }) => {
    const list = [
        {
            name: 'Pcs',
            to: 'Kg',
        },
        {
            name: 'Koli',
            to: 'Kemasan',
        },
        {
            name: 'Pcs/Koli',
            to: 'Kg/Kemasan',
        },
        {
            name: 'Pcs/Pallet',
            to: 'Kg/Pallet',
        },
        {
            name: 'Koli/Pallet',
            to: 'Kemasan/Pallet',
        },
    ];
    const isChangeLabel = list.find((item) => item.name === name);
    if (isChangeLabel && jenis_material?.includes('RM')) {
        return isChangeLabel.to;
    }
    return name;
};
export const handleFocus = ({ itemForm, setOpenInfiniteScroll, supplierRef }) => {
    if (Boolean(itemForm?.name?.includes('trade_name') && !Boolean(supplierRef))) {
        return;
    } else {
        handleClickInput({ itemForm, setOpenInfiniteScroll, supplierRef });
    }
};
export const handleChangeKonfigurasiSatuan = ({ setFormData, dataMaterialRaci, formData }) => {
    if (Boolean(dataMaterialRaci?.materialRaci?.id)) {
        setFormData((prev) => ({
            ...prev,
            konfigurasiSatuan: {
                ...prev?.konfigurasiSatuan,
                jenis_kemasan: dataMaterialRaci?.materialRaci?.jenis_kemasan,
                jenis_kemasan_pcs_koli: dataMaterialRaci?.materialRaci?.qty_per_kemasan,
                jenis_kemasan_koli_pallet: dataMaterialRaci?.materialRaci?.kemasan_per_storage,
                jenis_kemasan_pcs_pallet: dataMaterialRaci?.materialRaci?.qty_per_storage,
            },
            stock_pti: {
                ...prev?.stock_pti,
                max_stock_koli: round(
                    Number(prev?.stock_pti?.max_stock_pcs / dataMaterialRaci?.materialRaci?.qty_per_kemasan),
                    1
                ),
                max_stock_pallet: round(
                    Number(prev?.stock_pti?.max_stock_pcs / dataMaterialRaci?.materialRaci?.qty_per_storage),
                    1
                ),
            },
            stock_supplier: {
                ...prev?.stock_supplier,
                max_stock_koli: round(
                    Number(prev?.stock_supplier?.max_stock_pcs / dataMaterialRaci?.materialRaci?.qty_per_kemasan),
                    1
                ),
                max_stock_pallet: round(
                    Number(prev?.stock_supplier?.max_stock_pcs / dataMaterialRaci?.materialRaci?.qty_per_storage),
                    1
                ),
            },
            baselinePerWeek: {
                ...prev?.baselinePerWeek,
                koli: round(Number(prev?.baselinePerWeek?.pcs) / dataMaterialRaci?.materialRaci?.qty_per_kemasan, 1),
                pallet: round(Number(prev?.baselinePerWeek?.pcs) / dataMaterialRaci?.materialRaci?.qty_per_storage, 1),
            },
            avgPerDay: {
                ...prev?.avgPerDay,
                koli: round(
                    Number(prev?.baselinePerWeek?.pcs) / dataMaterialRaci?.materialRaci?.qty_per_kemasan / 6,
                    1
                ),
                pallet: round(
                    Number(prev?.baselinePerWeek?.pcs) / dataMaterialRaci?.materialRaci?.qty_per_storage / 6,
                    1
                ),
            },
        }));
    }
    if (Boolean(formData?.dataLengkap?.trade_name) && !Boolean(dataMaterialRaci?.materialRaci?.id)) {
        setFormData((prev) => ({
            ...prev,
            konfigurasiSatuan: {
                ...prev?.konfigurasiSatuan,
                jenis_kemasan: '',
                jenis_kemasan_pcs_koli: '',
                jenis_kemasan_koli_pallet: '',
                jenis_kemasan_pcs_pallet: '',
            },
            stock_pti: {
                ...prev?.stock_pti,
                max_stock_koli: '',
                max_stock_pallet: '',
            },
            stock_supplier: {
                ...prev?.stock_supplier,
                max_stock_koli: '',
                max_stock_pallet: '',
            },
            baselinePerWeek: {
                ...prev?.baselinePerWeek,
                koli: '',
                pallet: '',
            },
            avgPerDay: {
                ...prev?.avgPerDay,
                koli: '',
                pallet: '',
            },
        }));
    }
};

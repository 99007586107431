import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { isEmpty } from 'lodash';

export const passwordHidden = ({ password }) => {
    let result = '';
    for (let i = 0; i < password?.length; i++) {
        result += '*';
    }
    return result;
};

export const fetchDataInfinite = async ({
    page,
    setData,
    setLoadingSearch,
    setPage,
    moreData = false,
    setTotalData,
    search,
    materialCat,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    /// MATERIALCAT FILTER
    const paramMaterialCat = [
        { name: 'Packaging Material', param: 'PM' },
        { name: 'Raw Material', param: 'RM' },
        { name: 'General', param: '' },
    ].find((item) => item.name === materialCat?.name);
    ///
    const filterStringify = queryString.stringify(
        { supplierName: search, page, source: 'MDM', materialCat: paramMaterialCat?.param },
        { skipEmptyString: true }
    );
    setLoadingSearch(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/supplier/list?limit=15&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { suppliers, totalData } = data.data;
        if (moreData && !search) {
            setData((prev) => [...prev, ...suppliers]);
            setPage(page + 1);
        } else {
            setData(suppliers);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
    } catch (error) {
        console.log(error);
        setLoadingSearch(false);
    }
};

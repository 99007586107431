import { useSelector } from 'react-redux';
import { useState, createContext, useEffect } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import SectionTable from '@pagesPhase2/materialConfiguration/section/sectionTable';
import SectionHead from './section/sectionHead';
import TableSkeleton from '@components/tableSkeleton';
import Pagination from '@components/pagination';
import EmptyState from '@components/emptyState';
import { initialValueFormTransition, tableList } from './const';
import { numToArr } from '@helpers/functions';
import UploadRACI from './section/sectionHead/additional/uploadRACI';
import ModalConfirm from '@components/modalConfirm';
import Modalsuccess from '@components/modalSuccess';
import ModalTransition from './components/modalTransition';
import { useNavigate } from 'react-router-dom';
import DeleteModal from './components/deleteModal';
import DownloadLoader from '@components/downloadLoader';
import { fetchMaterialHandler, onEditTransition, onRemoveMaterial, onDownloadDataMaterialConfig } from './function';
import './styles.scss';

export const MaterialConfigurationContext = createContext();

const MaterialConfiguration = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [tableListMC, setTableListMC] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [reFetchTrigger, setReFetchTrigger] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [multipleFilter, setMultipleFilter] = useState({
        transition_status: '',
        transition_code: '',
        production_leadtime: '',
        mpq_v_baseline: '',
        mpq_supp_v_mpq_pti: '',
        max_stock_v_baseline: '',
    });
    const [section, setSection] = useState('main');
    const [selectedItem, setSelectedItem] = useState({});
    const [isopenModal, setOpenModal] = useState(false);
    const [sort, setSort] = useState({ type: 'desc', name: '' });
    const [openModalTransition, setOpenModalTransition] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [storeMultipleFilterTemp, setStoreMultipleFilterTemp] = useState({});
    const [loadingState, setLoadingState] = useState(false);
    const [listMaterial, setListMaterial] = useState([]);
    const [filter, setFilter] = useState({ itemCode: [], trade_name: [] });
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [searchSupplierName, setSearchSupplierName] = useState('');
    const [searchTradeName, setSearchTradeName] = useState('');
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [formTransition, setFormTransition] = useState(initialValueFormTransition);
    const onOpenModal = ({ value }) => setOpenModal(value);
    const onSelectedValue = ({ value }) => setSelectedItem(value);
    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const handleClickWarning = ({ value }) => {
        onEditTransition({
            selectedData: value,
            setOpenModalTransition,
            setFormTransition,
        });
    };
    const togglerDeleteMaterialModal = () => {
        setIsShowDeleteModal(!isShowDeleteModal);
    };

    const handleDownloadDataMc = () =>
        onDownloadDataMaterialConfig({ setLoadingState, toast, filter, supplier, jenisMaterial });

    const handleSubmitTransition = () => {
        navigate(`/add-new-item-material-config/${formTransition?.id_material}`);
    };
    const handleNext = () => {
        setOpenModalSuccess(false);
    };
    const onResetFilter = () => {
        setPage(1);
        searchQuery('');
    };
    const reFetchMaterialHandler = () => {
        page === 1 && searchQuery === ''
            ? fetchMaterialHandler({
                  filter,
                  searchQuery,
                  setListMaterial,
                  setTotalPage,
                  setTotalData,
                  setIsLoading,
                  selectedPerPage,
                  page,
                  multipleFilter,
                  jenisMaterial,
                  supplier,
                  setTableListMC,
                  sort,
              })
            : onResetFilter();
    };

    useEffect(() => {
        if (Boolean(supplier?.length !== 0) || jenisMaterial?.id) {
            setPage(1);
        }
    }, [supplier?.length, jenisMaterial?.id]);

    useEffect(() => {
        if (jenisMaterial?.id)
            fetchMaterialHandler({
                filter,
                searchQuery,
                setListMaterial,
                setTotalPage,
                setTotalData,
                setIsLoading,
                selectedPerPage,
                page,
                multipleFilter,
                jenisMaterial,
                supplier,
                setTableListMC,
                sort,
            });
    }, [
        page,
        searchQuery,
        filter,
        selectedPerPage,
        reFetchTrigger,
        multipleFilter.max_stock_v_baseline,
        multipleFilter.mpq_supp_v_mpq_pti,
        multipleFilter.mpq_v_baseline,
        multipleFilter.transition_code,
        multipleFilter.transition_status,
        multipleFilter.production_leadtime,
        jenisMaterial,
        supplier,
        sort,
    ]);

    return (
        <MaterialConfigurationContext.Provider
            value={{
                filter,
                setFilter,
                listMaterial,
                storeMultipleFilterTemp,
                loadingState,
                setLoadingState,
                setReFetchTrigger,
                setMultipleFilter,
                multipleFilter,
                searchSupplierName,
                setSearchSupplierName,
                searchTradeName,
                setSearchTradeName,
                toast,
                setPage,
                tableListMC,
                section,
                setStoreMultipleFilterTemp,
            }}
        >
            {section?.includes('upload_raci') && <UploadRACI section={section} setSection={setSection} />}
            {section?.includes('main') && (
                <Box className="material-configuration__container">
                    <SectionHead section={section} setSection={setSection} onDownload={handleDownloadDataMc} />
                    {loadingState && <DownloadLoader />}
                    {!isLoading && listMaterial?.length > 0 && (
                        <SectionTable
                            listMaterial={listMaterial}
                            onEditHandler={(selectedData) =>
                                navigate(`/add-new-item-material-config/${selectedData?.id}`)
                            }
                            onOpenModal={onOpenModal}
                            togglerDeleteMaterialModal={togglerDeleteMaterialModal}
                            onSelectedValue={onSelectedValue}
                            onClickWarning={handleClickWarning}
                            setSort={setSort}
                            sort={sort}
                        />
                    )}
                    {!isLoading && !listMaterial.length && (
                        <EmptyState
                            description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                            text="Tidak ada data yg di tampilkan"
                        />
                    )}
                    {isLoading && (
                        <TableSkeleton
                            tableList={tableList}
                            count={numToArr(10)}
                            tableHeadHeight="50px"
                            tablColumnHeight="70px"
                        />
                    )}
                    <Pagination
                        page={page}
                        totalPage={totalPage}
                        totalData={totalData}
                        selectedPerPage={selectedPerPage}
                        setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                        onPageChange={handlePageClick}
                    />

                    {/* ========== Modals ============ */}
                    <ModalConfirm
                        isOpen={isopenModal}
                        onClose={() => onOpenModal({ value: false })}
                        onSubmit={() => onOpenModal({ value: false })}
                        submitLabel="Lanjut"
                        title="Apa anda yakin ingin merestor item ?"
                    />
                    <DeleteModal
                        isOpen={isShowDeleteModal}
                        onClose={togglerDeleteMaterialModal}
                        selectedItem={selectedItem}
                        onRemoveMaterial={onRemoveMaterial}
                        reFetchMaterialHandler={reFetchMaterialHandler}
                        togglerDeleteMaterialModal={togglerDeleteMaterialModal}
                    />
                    <Modalsuccess
                        title="Submit Sukses"
                        isOpen={openModalSuccess}
                        labelButton="Next"
                        nextAction={handleNext}
                        onClose={() => setOpenModalSuccess(false)}
                    />
                    <ModalTransition
                        formTransition={formTransition}
                        isOpen={openModalTransition}
                        onClose={() => setOpenModalTransition(false)}
                        outlineLabel="Ignore"
                        onSubmit={handleSubmitTransition}
                    />
                </Box>
            )}
        </MaterialConfigurationContext.Provider>
    );
};

export default MaterialConfiguration;

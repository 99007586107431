import { Box, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react';
import { initialForm } from './const';
import './styles.scss';

function Index({ outlineLabel, onSubmit, onClose, isOpen, formTransition }) {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Box className="modal-container-transition">
                <ModalOverlay />
                <ModalContent maxW="607px" borderRadius="4px">
                    <ModalBody className="modal-body">
                        <Box className="container-form">
                            {initialForm?.map((item, index) => (
                                <Flex key={index} className="container-field">
                                    <Text className="label">{item.title}</Text>
                                    <input
                                        disabled={item.disabled}
                                        value={formTransition[item.name]}
                                        className={`field ${item.disabled && 'field-disabled'}`}
                                        placeholder="isi disini"
                                    />
                                </Flex>
                            ))}
                        </Box>
                    </ModalBody>

                    <ModalFooter className="modal-footer">
                        <Box className="btn-modal-ignore" onClick={onClose}>
                            <Text className="text-modal-ignore">{outlineLabel}</Text>
                        </Box>
                        <Box className={`btn-apply-submit`} onClick={onSubmit}>
                            <Text className="text-apply-submit">submit</Text>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;

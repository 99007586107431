import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onRemoveStreamline = async ({
    selectedStreamline,
    onClose,
    reFetchStreamlineHandler,
    toast,
    setIsLoading,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        setIsLoading(true);

        const { id } = selectedStreamline;

        //----------------------- DELETE STREAMLINE ----------------------------

        await Axios({
            method: 'DELETE',
            url: `/streamline/${id}?includeLineConnection=true`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await reFetchStreamlineHandler();

        await toast({
            position: 'center-top',
            description: 'Berhasil menghapus Streamline',
            status: 'success',
            duration: 1500,
        });
    } catch (error) {
        const err = error?.response?.data?.errors[0]?.message || 'err';
        toast({
            position: 'top-center',
            description: err,
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        onClose();
        setIsLoading(false);
    }
};

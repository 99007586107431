import React from 'react';
import { Button } from '@chakra-ui/react';

import './styles.scss';

function Index({
    children,
    disabled = false,
    isLoading = false,
    variant = 'filled',
    btnClassName,
    height = '42px',
    ...props
}) {
    const isFilledVariant = variant == 'filled' ? 'component-button' : 'component-button-outline';
    return (
        <Button
            variant="ghost"
            disabled={disabled}
            isLoading={isLoading}
            height={height}
            className={disabled ? 'component-button-disabled' : `${isFilledVariant} ${btnClassName}`}
            {...props}
        >
            {children}
        </Button>
    );
}

export default Index;

/// variable ini memiliki koneksi dengan fungsi getStatus
/// src\@pages\mainDashboard\section\SectionListOrderStatus\components\listCardOrderStatus\functions.js
/// jika ada perubahan divariable ini, bisa dicek juga fungsi tersebut agar fungsi berjalan dengan lancar
export const tabMenuOrderStatus = [
    {
        name: 'Antrian Order',
        index: 1,
        value: 'QUEUE',
        filter: [
            {
                name: 'Pilih status BR',
                selected: true,
                isHidden: true,
            },
            {
                name: 'All',
                endpoint: null,
                selected: false,
            },
            {
                name: 'BR Ready',
                endpoint: 'br_ready',
                value: true,
                selected: false,
            },
            {
                name: 'BR Not Ready',
                endpoint: 'br_ready',
                value: false,
                selected: false,
            },
        ],
    },
    {
        name: 'Antrian Konfirmasi',
        value: 'INBOUND',
        index: 2,
        filter: false,
        isKetersediaanMaterial: true,
    },
    {
        name: 'Antrian Pending',
        value: 'PENDING',
        isKetersediaanMaterial: true,
        index: 3,
        filter: [
            {
                name: 'Pilih Jenis Pending',
                selected: true,
                isHidden: true,
            },
            {
                name: 'All',
                endpoint: null,
                selected: false,
            },
            {
                name: 'Pending BB',
                endpoint: 'status_bb',
                value: false,
                selected: false,
            },
            {
                name: 'Pending Packaging',
                endpoint: 'status_packaging',
                value: false,
                selected: false,
            },
        ],
    },

    {
        name: 'Kanban Selesai',
        value: 'DONE',
        index: 4,
        filter: [
            {
                name: 'Ketepatan Waktu',
                selected: true,
                isHidden: true,
            },
            {
                name: 'All',
                endpoint: null,
                selected: false,
            },
            {
                name: 'Overdue',
                endpoint: 'on_time',
                value: false,
                selected: false,
            },
            {
                name: 'Ontime',
                endpoint: 'on_time',
                value: true,
                selected: false,
            },
        ],
    },
];

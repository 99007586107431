import queryString from 'query-string';

export const protectedUserManagementRoute = ({ currentAppType, navigate, pathname }) => {
    if (currentAppType === 'userManagement' && pathname !== '/user-management') {
        navigate('/user-management');
    }
};

export const jenisMaterialCookieSet = ({ jenisMaterialCookies }) => {
    if (!Boolean(queryString.parse(jenisMaterialCookies)?.id) || queryString.parse(jenisMaterialCookies)?.id === 3) {
        return { id: 2, name: 'Raw Material' };
    } else {
        return queryString.parse(jenisMaterialCookies);
    }
};

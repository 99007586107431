import { createSlice } from '@reduxjs/toolkit';

export const assetManagementSlice = createSlice({
    name: 'assetManagement',
    initialState: {
        searchQuery: '',
    },
    reducers: {
        onChangeSearchInput: (state, action) => {
            state.searchQuery = action.payload;
        },
    },
});

export const { onChangeSearchInput } = assetManagementSlice.actions;

export default assetManagementSlice.reducer;

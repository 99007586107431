export const IconDownload = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.75 6.75V7.25H11.25H12.4425C12.6562 7.25 12.7774 7.51551 12.6139 7.67895L9.17145 11.1214C9.07421 11.2187 8.91829 11.2187 8.82105 11.1214L5.37855 7.67895C5.22267 7.52306 5.33314 7.25 5.5575 7.25H6.75H7.25V6.75V3C7.25 2.86364 7.36364 2.75 7.5 2.75H10.5C10.6364 2.75 10.75 2.86364 10.75 3V6.75ZM4.5 14.5C4.36364 14.5 4.25 14.3864 4.25 14.25C4.25 14.1136 4.36364 14 4.5 14H13.5C13.6364 14 13.75 14.1136 13.75 14.25C13.75 14.3864 13.6364 14.5 13.5 14.5H4.5Z"
            fill="#0154A1"
            stroke="#0154A1"
        />
    </svg>
);

export const IconUpload = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.25 7H10.75V7.5V11.25C10.75 11.3864 10.6364 11.5 10.5 11.5H7.5C7.36364 11.5 7.25 11.3864 7.25 11.25V7.5V7H6.75H5.5575C5.3438 7 5.22262 6.73449 5.38605 6.57105L8.82855 3.12855L8.82892 3.12819C8.85186 3.1052 8.87911 3.08696 8.90911 3.07451L8.71753 2.61267L8.90911 3.07451C8.93911 3.06207 8.97127 3.05566 9.00375 3.05566C9.03623 3.05566 9.06839 3.06207 9.09839 3.07451C9.12839 3.08696 9.15564 3.1052 9.17859 3.12819L9.17895 3.12855L12.6214 6.57105C12.7773 6.72693 12.6669 7 12.4425 7H11.25ZM4.5 14H13.5C13.6364 14 13.75 14.1136 13.75 14.25C13.75 14.3864 13.6364 14.5 13.5 14.5H4.5C4.36364 14.5 4.25 14.3864 4.25 14.25C4.25 14.1136 4.36364 14 4.5 14Z"
            fill="#4163A4"
            stroke="#4163A4"
        />
    </svg>
);

import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import dateFormat from 'dateformat';

export const redirectPathByAppType = ({ navigate }) => {
    const cookies = new Cookies();
    const appType = cookies.get('appType');
    switch (appType) {
        case 'warehouseToSupplier':
            navigate('/order-management');
            break;
        case 'ndcToManufacture':
            navigate('/');
            break;
        default:
            throw new Error(`Ups, there in no ${appType} in this app. Check redirectPathByAppType funtion!`);
    }
};
export const FilterDataByStatus = ({ data = [], status = '', setData }) => {
    const results = data.filter((obj) => obj.kardOrders.some((v) => v.status === status));
    setData(results);
};

export const FormateDateTime = ({ date }) => {
    const formattedDate = dateFormat(date, 'dd/mm/yyyy');
    console.log(formattedDate);
    return formattedDate;
};

export const getWeekNumber = () => {
    const now = new Date();
    const onejan = new Date(now.getFullYear(), 0, 1);
    const week = Math.ceil(((now - onejan) / 86400000 + onejan.getDay() + 1) / 7);
    return week;
};

export const getBgColorHandler = ({ valueColor }) => {
    switch (valueColor) {
        case 'LA1':
            return '#D599FD';
        case 'LA2':
            return '#F3EB94';
        case 'LA3':
            return '#B5DA92';
        case 'LA4':
            return '#FD71AF';
        case 'LA5':
            return '#FFD9C0';
        case 'VA1':
            return '#F0D9FF';
        case 'VA2':
            return '#87d1d1';
        case 'VA3':
            return '#6fa8dc';
        case 'VA4':
            return '#ffd966';
        case 'VA10':
            return '#ea9999';
        case 'SA1':
            return '#D599FD';
        case 'SA3':
            return '#B5DA92';
        case 'SA4':
            return '#FD71AF';
        case 'SB1':
            return '#BDE0EB';
        case 'SB2':
            return '#E0C1F4';
        case 'PA2':
            return '#F3EB94';
        case 'PA4':
            return '#FD71AF';
        case 'PA5':
            return '#FFD9C0';
        case 'PC1':
            return '#DEDAB1';
        case 'PC2':
            return '#CCFF9A';
        default:
            return '#BDCFEB';
    }
};

export const sortingStreamlineByNameHandler = ({ arr }) => {
    //----------- SORTING ALPANUMERICAL --------------
    const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
    });

    const sorted = arr.sort((curr, prev) => {
        return collator.compare(curr.name, prev.name);
    });

    return sorted;
};

export const getStreamline = async ({ setStreamlines, setIsLoading, sediaanId }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/streamline/list?includeLine=true&sediaan_id=${sediaanId}&sort=name,asc`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { streamline } = data.data;

        const sortingStreamlineByName = sortingStreamlineByNameHandler({ arr: streamline });
        setStreamlines(sortingStreamlineByName);
    } catch (error) {
        console.log(error);
        setStreamlines([]);
    } finally {
        setIsLoading(false);
    }
};

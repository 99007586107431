import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Box, Text } from '@chakra-ui/react';
import { initialForm } from './const';
import FieldMenu from '@components/fieldMenu';
import { ArrowUpIconBlack } from '@asset/icons';
import './styles.scss';

function Index({ onClick, value, onClose, isOpen }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} position="relative">
            <Box className="modal-container-filter-multiple-supplier-stock">
                <ModalOverlay />
                <ModalContent maxW="216px" position="absolute" top="80px" right="88px">
                    <ModalBody className="modal-body">
                        <Box>
                            {initialForm.map((form, index) => {
                                return (
                                    <Box key={index} className="container-input">
                                        <Box className="fieldMenu-container-single">
                                            <Text className="label">{form.title}</Text>
                                            <FieldMenu
                                                onClick={(selected) => onClick({ selected })}
                                                buttonName={
                                                    <span>
                                                        <span>
                                                            {value.name ? (
                                                                <span className="selected-role-text">{value.name}</span>
                                                            ) : (
                                                                <span className="FieldMenu-placeholder">
                                                                    {form.placeholder}
                                                                </span>
                                                            )}
                                                        </span>
                                                    </span>
                                                }
                                                menuList={form.option}
                                                keyName="name"
                                                menuClassName="menu-container__user-management"
                                                menuButtonClassName="menu-button"
                                                menuItemClassNam="menu-item"
                                                stylePropsMenuButtonClassName={{
                                                    width: '179px',
                                                }}
                                                menuListClassName="menuList-container"
                                                icon={<ArrowUpIconBlack />}
                                            />
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;

import { multipleFilterKey } from './const';
import { initialForm } from './modal/const';

export const handleClickMenu = ({ item, selected, setFormData }) => {
    const findinitialValue = initialForm.find((formName) => formName.name === item?.name);
    if (findinitialValue) {
        setFormData((prev) => {
            return { ...prev, [findinitialValue.name]: selected?.name };
        });
    }
    if (item?.extra) {
        setFormData((prev) => {
            return {
                ...prev,
                [selected?.form?.name + '_extra']: {
                    ...prev?.[selected?.form?.name + '_extra'],
                    [item?.extra?.name]: item?.input,
                },
            };
        });
    }
};

export const handleApplyFilter = ({
    setOpenModal,
    formData,
    setFilterSelected,
    setMultipleFilter,
    setMainPage,
    setStoreMultipleFilterTemp,
}) => {
    const production_leadtime = multipleFilterKey.find((item) => item.name === formData?.lt_Produksi)?.query;
    const mpq_supp_v_mpq_pti = multipleFilterKey.find((item) => item.name === formData?.index_mpq_s_vs_mpq_pti)?.query;
    const mpq_v_baseline = multipleFilterKey.find((item) => item.name === formData?.index_mpq_vs_baseline)?.query;
    const max_stock_v_baseline = multipleFilterKey.find(
        (item) => item.name === formData?.index_maks_vs_baseline
    )?.query;
    selectedFilter({ value: formData, setFilterSelected, setStoreMultipleFilterTemp });
    setOpenModal(false);
    setMultipleFilter({
        transition_status: formData?.status || '',
        transition_code: formData?.transition || '',
        production_leadtime: Boolean(production_leadtime)
            ? `${production_leadtime}${
                  !production_leadtime?.includes('between:')
                      ? `${formData?.lt_Produksi_extra?.from}`
                      : `${formData?.lt_Produksi_extra?.from},${formData?.lt_Produksi_extra?.to}`
              }`
            : '',
        mpq_v_baseline: Boolean(mpq_v_baseline)
            ? `${mpq_v_baseline}${
                  !mpq_v_baseline?.includes('between:')
                      ? `${formData?.index_mpq_vs_baseline_extra?.from}`
                      : `${formData?.index_mpq_vs_baseline_extra?.from},${formData?.index_mpq_vs_baseline_extra?.to}`
              }`
            : '',
        mpq_supp_v_mpq_pti: Boolean(mpq_supp_v_mpq_pti)
            ? `${mpq_supp_v_mpq_pti}${
                  !mpq_supp_v_mpq_pti?.includes('between:')
                      ? `${formData?.index_mpq_s_vs_mpq_pti_extra?.from}`
                      : `${formData?.index_mpq_s_vs_mpq_pti_extra?.from},${formData?.index_mpq_s_vs_mpq_pti_extra?.to}`
              }`
            : '',
        max_stock_v_baseline: Boolean(max_stock_v_baseline)
            ? `${max_stock_v_baseline}${
                  !max_stock_v_baseline?.includes('between:')
                      ? `${formData?.index_maks_vs_baseline_extra?.from}`
                      : `${formData?.index_maks_vs_baseline_extra?.from},${formData?.index_maks_vs_baseline_extra?.to}`
              }`
            : '',
    });
    setMainPage(1);
};

export const handleClearFilter = ({ setFormData, form }) => {
    setFormData((prev) => {
        /// https://stackoverflow.com/a/21119696/18038473
        delete prev[`${[form?.name]}_extra`];
        return { ...prev, [form?.name]: '' };
    });
};

export const selectedFilter = ({ value, setFilterSelected, setStoreMultipleFilterTemp }) => {
    const filterSelected = Object.values(value);
    const keyFilterSelected = Object.keys(value);
    let result = [];
    for (let index = 0; index < filterSelected.length; index++) {
        if (Boolean(filterSelected[index]) && value[keyFilterSelected[index]] === filterSelected[index]) {
            result.push({ [keyFilterSelected[index]]: filterSelected[index] });
        }
    }
    setFilterSelected(result);
    setStoreMultipleFilterTemp({ filterSelected: result, formData: value });
};

export const handleCloseModal = ({ filterSelected, setFormData, initialValue, setOpenModal }) => {
    let prevInitialValue = { ...initialValue };
    let keyValue = Object.keys(initialValue);
    let currentSelect = [...filterSelected];
    keyValue.map((key) => {
        currentSelect.map((current) => {
            const keyObject = Object.keys(current);

            if (key === keyObject[0]) {
                prevInitialValue[key] = current[keyObject[0]];
            }
            prevInitialValue[keyObject[0]] = current[keyObject[0]];
        });
    });
    setFormData(prevInitialValue);
    setOpenModal(false);
};

export const findBadgeSelectedFilter = (value) => {
    let result = value?.filter((item) => {
        const keyObject = Object.keys(item);
        if (typeof item[keyObject[0]] === 'string') {
            return item;
        }
    });
    return result;
};

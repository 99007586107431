import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchKodeItemInfiniteScroll = async ({
    page,
    setKodeItem,
    setPage,
    moreData = false,
    setTotalData,
    search,
    kodeItemChoosed,
    setLoadingSearch,
    supplierRef,
    jenisMaterial,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const ref = supplierRef?.join(';');
    const filterStringify = queryString.stringify(
        { q: search, page, supplier_ref: ref, jenis_material_id: jenisMaterial?.id },
        { skipEmptyString: true }
    );
    setLoadingSearch(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/filter?limit=15&attributes=kode_item&sort=kode_item asc&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { list, totalData } = data.data;
        list?.map((item) => {
            item['checked'] = false;
            kodeItemChoosed?.map((choosedItem) => {
                if (item?.kode_item === choosedItem?.kode_item && choosedItem?.checked) {
                    item['checked'] = true;
                }
            });
            return item;
        });
        if (moreData && !search) {
            setKodeItem((prev) => [...prev, ...list]);
            setPage(page + 1);
        } else {
            setKodeItem(list);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
    } catch (error) {
        console.log(error);
        setLoadingSearch(true);
    }
};

export const handleCheckboxChange = ({
    checked,
    selectedData,
    kodeItem,
    setKodeItem,
    kodeItemChoosed,
    setKodeItemChoosed,
}) => {
    let clone = [...kodeItem];
    let cloneKodeItemChoosed = [...kodeItemChoosed];
    if (checked) {
        cloneKodeItemChoosed.push({ ...selectedData, checked });
    }
    if (!checked) {
        const unChecked = cloneKodeItemChoosed?.filter((item) => item?.kode_item !== selectedData?.kode_item);
        cloneKodeItemChoosed = unChecked;
    }
    clone.map((userItem) => {
        if (userItem?.kode_item === selectedData?.kode_item) {
            userItem.checked = checked;
        }
        return userItem;
    });
    setKodeItemChoosed(cloneKodeItemChoosed);
    setKodeItem(clone);
};

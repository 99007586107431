import { Text, Box, Input, Flex } from '@chakra-ui/react';
import { NotesIcon } from './icons';
import { onChangeEditTable } from './function';
import EditableColumn from './components/editableColumn';

export const tableListOrderDelivery = [
    {
        name: 'No Order',
        value: 'id',
        width: 100,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'No PO',
        value: 'no_po',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Kode Item',
        value: 'orderManagement.materialConfig.kode_item',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Nama Item',
        value: 'orderManagement.materialConfig.trade_name',
        flex: 1,
        width: 367,
        // width: 80,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Qty Order',
        value: 'qty_order',
        width: 180,
        children: ['Pcs'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Revisi Qty',
        value: 'qty_revisi',
        width: 180,
        children: ['Pcs'],
        component: ({ value, orderData }) => (
            <Flex alignItems="center">
                <EditableColumn currentValue={value} id={orderData?.id} orderData={orderData} keyName={'revisi_qty'} />
            </Flex>
        ),
    },
    {
        name: 'Notes',
        value: 'notes',
        width: 400,
        children: ['-'],
        component: ({ value, orderData }) => (
            <Flex alignItems="center">
                <EditableColumn currentValue={value} id={orderData?.id} orderData={orderData} keyName={'notes'} />
            </Flex>
        ),
    },
];

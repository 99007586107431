// import { find } from 'lodash';
import ProductStatusCard from '../../components/productStatusCard';
import EmptyCard from '../../components/emptyCard';
import StreamlineCard from '../../components/streamlineCard';
import Available from '@pages/mainDashboard/components/available';

export const tableList = [
    {
        name: 'Stream line',
        value: 'name',
        width: 77,
        isSticky: true,
        component: ({ value, idx, streamlineColor }) => (
            <StreamlineCard value={value} idx={idx} streamlineColor={streamlineColor} />
        ),
    },

    {
        name: 'TIMBANG',
        width: 270,
        value: 'timbang',
        component: ({ value, onSelectedDataProductStatus, setOpenDetail, streamlineColor, streamlineName }) => {
            return (
                <>
                    {value?.map((item) => {
                        return item?.type === 'empty' ? (
                            <EmptyCard />
                        ) : (
                            <ProductStatusCard
                                type="timbang"
                                value={item}
                                onClick={() => {
                                    onSelectedDataProductStatus({
                                        value: { ...item, type: 'timbang', streamlineName },
                                    });
                                    setOpenDetail(true);
                                }}
                                streamlineColor={streamlineColor}
                            />
                        );
                    })}
                    <Available available={value?.available} />
                </>
            );
        },
    },
    {
        name: 'OLAH 1',
        width: 270,
        value: 'olah1',
        component: ({ value, onSelectedDataProductStatus, setOpenDetail, streamlineColor }) => {
            return (
                <>
                    {value?.map((item) => {
                        return item?.type === 'empty' ? (
                            <EmptyCard />
                        ) : (
                            <ProductStatusCard
                                type="olah_1"
                                value={item}
                                onClick={() => {
                                    onSelectedDataProductStatus({ value: item });
                                    setOpenDetail(true);
                                }}
                                streamlineColor={streamlineColor}
                            />
                        );
                    })}
                    <Available available={value?.available} />
                </>
            );
        },
    },
    {
        name: 'OLAH 2',
        width: 270,
        value: 'olah2',
        component: ({ value, onSelectedDataProductStatus, setOpenDetail, streamlineColor }) => {
            return (
                <>
                    {value?.map((item) => {
                        return item?.type === 'empty' ? (
                            <EmptyCard />
                        ) : (
                            <ProductStatusCard
                                type="olah_2"
                                value={item}
                                onClick={() => {
                                    onSelectedDataProductStatus({ value: item });
                                    setOpenDetail(true);
                                }}
                                streamlineColor={streamlineColor}
                            />
                        );
                    })}
                    <Available available={value?.available} />
                </>
            );
        },
    },
    {
        name: 'STAGING OLAH',
        width: 270,
        value: 'qcbulks',
        component: ({ value, onSelectedDataProductStatus, setOpenDetail, streamlineColor }) => {
            // const thereIsEmptyCard = find(value, { type: 'empty' });

            return (
                <>
                    {value?.map((item) => {
                        return item?.type === 'empty' ? (
                            <EmptyCard />
                        ) : (
                            <ProductStatusCard
                                type="qcbulk"
                                value={item}
                                onClick={() => {
                                    onSelectedDataProductStatus({ value: item });
                                    setOpenDetail(true);
                                }}
                                streamlineColor={streamlineColor}
                            />
                        );
                    })}
                </>
            );
        },
    },
    {
        name: 'KEMAS',
        width: 270,
        value: 'kemas',
        component: ({ value, onSelectedDataProductStatus, setOpenDetail, streamlineColor }) => {
            // const thereIsEmptyCard = find(value, { type: 'empty' });

            return (
                <>
                    {value?.map((item) => {
                        return item?.type === 'empty' ? (
                            <EmptyCard />
                        ) : (
                            <ProductStatusCard
                                type="kemas"
                                value={item}
                                onClick={() => {
                                    onSelectedDataProductStatus({ value: item });
                                    setOpenDetail(true);
                                }}
                                streamlineColor={streamlineColor}
                            />
                        );
                    })}
                    <Available available={value?.available} />
                </>
            );
        },
    },
    {
        name: 'NDC',
        width: 270,
        value: 'ndcs',
        component: ({ value, onSelectedDataProductStatus, setOpenDetail, streamlineColor }) => {
            return (
                <>
                    {value?.map((item) => {
                        return item?.type === 'empty' ? (
                            <EmptyCard />
                        ) : (
                            <ProductStatusCard
                                type="ndc"
                                value={item}
                                onClick={() => {
                                    onSelectedDataProductStatus({ value: item });
                                    setOpenDetail(true);
                                }}
                                streamlineColor={streamlineColor}
                            />
                        );
                    })}
                </>
            );
        },
    },
];

export const obj = {
    errorStatus: false,
    data: [
        {
            name: 'LA 1',
            timbangKard: [
                {
                    product:
                        'Wardah Instaperfect BROWFESSIONAL 3D Brow Mascara 02. DEEP GREY 5.5 G fweifweuf fewefewfwe',
                    line: '1',
                },
                {
                    product: 'Emina BROWFESSIONAL 3D Brow Mascara 02. DEEP GREY 5.5 G',
                    line: '2',
                },
            ],
            olah1Kard: [
                {
                    product: 'Emina',
                    line: 'timbang 2',
                },
                {
                    product: 'Wardah BROWFESSIONAL',
                    line: 'timbang 1',
                },
            ],
            olah2Kard: [
                {
                    product: 'MakeOver',
                    line: 'timbang 2',
                },
            ],
        },
        {
            name: 'LA 2',
            timbangKard: [
                {
                    product: 'Wardah  Brow Mascara 02. DEEP GREY 5.5 G',
                    line: 'timbang 1',
                },
                {
                    product: 'Emina BROWFESSIONAL 3D Brow Mascara 02. DEEP GREY 5.5 G',
                    line: 'timbang 2',
                },
            ],
            olah1Kard: [],
            olah2Kard: [
                {
                    product: 'Wardah Instaperfect BROWFESSIONAL 3D Brow Mascara 02. DEEP GREY 5.5 G',
                },
            ],
        },
    ],
};


import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const fetchUploadRaci = async ({ files, setState, setOpenModalSuccess, toast, setSubmitError }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const formData = new FormData();
    formData.append('file', files);

    try {
        const { data } = await Axios({
            method: 'POST',
            url: '/material/raci/upload',
            headers: { Authorization: 'Bearer ' + userToken },
            data: formData,
        });
        setState('upload');
        if (data?.data?.error?.length > 0) {
            const err = data?.data?.error?.map((res) => `row ${res.row} ${res.message}`);
            setSubmitError((prev) => ({
                ...prev,
                open: true,
                message: (
                    <>
                        <ul>
                            {err?.map((item, idx) => (
                                <li key={idx} style={{ textAlign: 'left', listStyle: 'inside' }}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </>
                ),
            }));
            return;
        }
        setOpenModalSuccess(true);
    } catch (err) {
        setState('upload');
        const message = err?.response?.data?.error;
        setSubmitError((prev) => ({
            ...prev,
            open: true,
            message: message,
        }));
    }
};

export const onChangeFile = ({ files, setState, setOpenModalSuccess, toast, setSubmitError }) => {
    if (files) {
        fetchUploadRaci({ files, setState, setOpenModalSuccess, toast, setSubmitError });
    }
};

export const onDownloadTemplateRACI = async ({ toast, setIsLoading }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/raci/file/template`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'template_upload_material_config_raci';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        await toast({
            position: 'center-top',
            description: 'Berhasil Download Template RACI',
            status: 'success',
            duration: 1500,
            isClosable: true,
        });
        setIsLoading(false);
    } catch (error) {
        console.log(error);
        toast({
            position: 'top-center',
            description: 'Gagal Download Template RACI',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        setIsLoading(false);
    }
};

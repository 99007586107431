import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Box, Text, Flex, Checkbox } from '@chakra-ui/react';
import CloseIcon from '@pages/mainDashboard/components/AutoRefreshNotification/Icon';
import FieldMenu from '@components/fieldMenu';
import { ArrowUpIconBlack } from '@asset/icons';
import { optionInputWeek } from '../../functions';

import './styles.scss';

function index({ setOpenModalInputWeek, openModalInputWeek, handleNext }) {
    return (
        <Modal
            isOpen={openModalInputWeek.isOpen}
            onClose={() => setOpenModalInputWeek((prev) => ({ ...prev, isOpen: false, selected: {} }))}
        >
            <Box className="modal-container-input-week-demand-anticipation">
                <ModalOverlay />
                <ModalContent maxW="544px">
                    <Flex className="modal-input-week-header">
                        <Text className="modal-header-text">Input week</Text>
                        <Box
                            onClick={() => setOpenModalInputWeek((prev) => ({ ...prev, isOpen: false, selected: {} }))}
                            cursor="pointer"
                        >
                            <CloseIcon />
                        </Box>
                    </Flex>
                    <ModalBody className="modal-body">
                        <Box>
                            <FieldMenu
                                onClick={(value) => {
                                    setOpenModalInputWeek((prev) => ({ ...prev, selected: value }));
                                }}
                                buttonName={
                                    <span>
                                        <span>
                                            {Boolean(openModalInputWeek?.selected?.week) ? (
                                                <span className="selected-week-text">
                                                    {`${openModalInputWeek?.selected?.title} - ${openModalInputWeek?.selected?.name} `}
                                                </span>
                                            ) : (
                                                <span className="fieldMenu-placeholder">Input week</span>
                                            )}
                                        </span>
                                    </span>
                                }
                                menuList={optionInputWeek()}
                                keyName="title"
                                withEntity
                                menuButtonClassName="menu-button"
                                menuListClassName="menu-list-container"
                                icon={<ArrowUpIconBlack />}
                            />
                        </Box>
                    </ModalBody>

                    <ModalFooter className="modal-Footer">
                        <Box
                            onClick={handleNext}
                            className={`btn-submit ${
                                !Boolean(openModalInputWeek?.selected?.week) && 'disabled-btn-submit'
                            }`}
                        >
                            <Text className={`text-submit`}>Lanjut</Text>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default index;

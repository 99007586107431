import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchArchivedListHandler = async ({
    filter,
    page,
    setArchivedList,
    setTotalData,
    setTotalPage,
    setIsLoading,
    searchQuery,
    selectedPerPage,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    const filterStringify = queryString.stringify(
        { q: searchQuery, page, limit: selectedPerPage },
        { skipEmptyString: true }
    );
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/config/list?archived=true&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { listMaterial, totalData, totalPage } = data.data;
        setTotalPage(totalPage);
        setTotalData(totalData);
        setArchivedList(listMaterial);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setArchivedList([]);
    } finally {
        setIsLoading(false);
    }
};
export const handleSubmitRestore = async ({
    selectedItem,
    toast,
    setLoading,
    setOpenModalConfirm,
    setOpenModalSuccess,
    setRefetch,
}) => {
    setLoading(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    await Axios({
        method: 'PATCH',
        url: `/material/config/${selectedItem?.id}/restore`,
        headers: { Authorization: 'Bearer ' + userToken },
    })
        .then((res) => {
            setOpenModalSuccess({ isOpen: true, message: 'Sukses Merestore Item' });
            setRefetch((prev) => !prev);
        })
        .catch((error) => {
            toast({
                title: error?.response?.data?.errorType || 'Gagal merestore',
                description: error?.response?.data?.errors?.map((item) => item?.message)?.join() || '-',
                status: 'error',
                duration: 7000,
                isClosable: true,
                position: 'top-center',
            });
        })
        .finally((fin) => {
            setOpenModalConfirm(false);
            setLoading(false);
        });
};
export const handleSaveNote = async ({
    selectedItem,
    toast,
    setLoading,
    setOpenModalSuccess,
    setRefetch,
    note,
    setOpenModalEditNote,
}) => {
    setLoading(true);

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    await Axios({
        method: 'PATCH',
        url: `/material/config/${selectedItem?.id}?archived=true`,
        headers: { Authorization: 'Bearer ' + userToken },
        data: {
            materialConfig: {
                notes: note,
            },
        },
    })
        .then((res) => {
            setOpenModalEditNote((prev) => ({ ...prev, isOpen: false }));
            setRefetch((prev) => !prev);
            setOpenModalSuccess({ isOpen: true, message: 'Note tersimpan' });
        })
        .catch((error) => {
            console.log(error);
            toast({
                title: error?.response?.data?.errorType || 'Error',
                description: error?.response?.data?.errors?.map((item) => item?.message)?.join() || '-',
                status: 'error',
                duration: 7000,
                isClosable: true,
                position: 'top-center',
            });
        })
        .finally((fin) => {
            setLoading(false);
        });
};

//---------------- 3RD PARTY LIBRARY ------------------------------------------
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

//---------------- GLOBAL COMPONENT ------------------------------------------

import DashboardContainer from '@components/dashboardContainer';
import ModalFailedDownload from '@components/modalFailedDownload';
import ModalSuccesDownload from '@components/modalSuccesDownload';
import { ErrorBoundary } from 'react-error-boundary';
import PageError from '@components/pageError';
import { routes } from '@routers';
import '@styles/App.scss';
import '@styles/baseColor.scss';
import { datadogRum } from '@datadog/browser-rum';


function App() {
    const downloadList = useSelector(({ downloadManagement }) => downloadManagement?.downloadList);
    return (
        <div className="App">
            <ChakraProvider>
                <Router>
                    <ErrorBoundary fallback={<PageError />}>
                        <DashboardContainer>
                            <Routes>
                                {routes?.map((route) => (
                                    <Route
                                        exact
                                        path={route.path}
                                        element={route.element}
                                        key={route.path}
                                        errorElement={route.errorElement}
                                    />
                                ))}
                            </Routes>
                        </DashboardContainer>

                        {/**---------------- GLOBAL COMPONENT --------------------- */}

                        <div
                            style={{
                                position: 'fixed',
                                left: '24px',
                                bottom: '164px',
                            }}
                        >
                            {downloadList?.map((item) => {
                                return item?.type === 'loading' ? (
                                    <ModalSuccesDownload description={item?.title} />
                                ) : (
                                    <ModalFailedDownload description={item?.title} downloadId={item.id} />
                                );
                            })}
                        </div>
                    </ErrorBoundary>
                </Router>
            </ChakraProvider>
        </div>
    );
}

process.env.REACT_APP_PUBLIC_DEPLOYMENT_ENV === 'production' &&
    process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN &&
    datadogRum.init({
        applicationId: 'd488cb92-137e-44cb-8f72-98c954d424df',
        clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
        site: 'ap1.datadoghq.com',
        service: 'dpps-web-ui',
        env: process.env.REACT_APP_PUBLIC_DEPLOYMENT_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 50,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [`${process.env.REACT_APP_API_URL}`],
    });

export default App;

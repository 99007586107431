import { Flex, Text, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { IconBack, UploadIconSm, IconFile } from './icons';
import SectionUploadError from '../sectionUploadError';
import SectionSuccessSubmit from '../sectionSuccessSubmit';
import { onChangeFile } from './functions';
import './styles.scss';
import UplaodLoader from '@pages/orderPlanning/components/uploadLoader';
import { useNavigate } from 'react-router-dom';

const SectionUpload = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [hasUploaded, setHasUploaded] = useState(false);
    const [isSuccessUpload, setIsSuccessUpload] = useState(false);
    const [errorData, setErrorData] = useState([]);

    const onResetErrorData = () => {
        setErrorData([]);
        setHasUploaded(false);
    };

    return (
        <Flex className="section-upload__container__orderplanning">
            {isLoading && <UplaodLoader />}
            {!isLoading && !hasUploaded && (
                <>
                    {/**------------------------------------  MAIN SECTION --------------------------------------------- */}

                    <Flex className="section-title" onClick={() => navigate('/order-planning')}>
                        <IconBack />
                        <Text className="text">Upload File</Text>
                    </Flex>
                    <Flex className="section-body">
                        <Flex className="upload-box">
                            <FileUploader
                                handleChange={(files) =>
                                    onChangeFile({
                                        files,
                                        setIsLoading,
                                        setHasUploaded,
                                        setIsSuccessUpload,
                                        setErrorData,
                                    })
                                }
                                name="file"
                                classes="file-uploader__drag-and-drop-only"
                                types={['xlsx', 'ods']}
                                disabled
                            />

                            <IconFile />
                            <Text className="upload-text">
                                Drag and Drop atau pilih file CSV atau Exel untuk di upload
                            </Text>
                            <Button className="upload-button">
                                <FileUploader
                                    handleChange={(files) =>
                                        onChangeFile({
                                            files,
                                            setIsLoading,
                                            setHasUploaded,
                                            setIsSuccessUpload,
                                            setErrorData,
                                        })
                                    }
                                    name="file"
                                    classes="file-uploader"
                                    types={['xlsx', 'ods']}
                                />
                                <UploadIconSm />
                                Upload File
                            </Button>
                        </Flex>
                    </Flex>
                </>
            )}

            {/**------------------------------------  SECTION ADDITIONAL  --------------------------------------------- */}
            {errorData?.length > 0 && <SectionUploadError errorData={errorData} onResetErrorData={onResetErrorData} />}
            {isSuccessUpload && <SectionSuccessSubmit />}
        </Flex>
    );
};

export default SectionUpload;

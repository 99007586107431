import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { DottedIcon, EditIcon, PackageIcon } from '@asset/icons';
import DropDown from '@pagesPhase2/supplierStock/components/dropDownMenu';
import { optionMenuDot } from './const';
import CodeItemSearch from './codeItem';
import TradeNameSearch from './tradeName';
import { SupplierStockContext } from '@pagesPhase2/supplierStock';
import { onDownloadDataSupplierStock } from '@pagesPhase2/supplierStock/function';
import ModalFilterStatus from './modalFilterStatus';
import './styles.scss';
import { isAllowed } from '@helpers/roleAccess';
import { useNavigate } from 'react-router-dom';

function SectionHead() {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const { isEdit, setIsEdit, filter, filterStatus } = React.useContext(SupplierStockContext);

    const handleOptionMenu = (name) => {
        switch (name) {
            case 'Download Data':
                onDownloadDataSupplierStock({ dispatch, filter, jenisMaterial, supplier, filterStatus });
                break;
            case 'Upload File':
                navigate('/supplier-stock/upload-item');
                break;
            default:
                break;
        }
    };

    return (
        <Box>
            <Flex className="supplier-stock-box-header">
                <Flex gap="10px" alignItems="center">
                    <PackageIcon />
                    <Text className="text-supplier-stock">Supplier’s Stock</Text>
                </Flex>
                {isEdit ? (
                    <Flex className="wrapper-btn">
                        <Button className="btn-cancel" onClick={() => setIsEdit(false)}>
                            Cancel
                        </Button>
                    </Flex>
                ) : (
                    <Box>
                        <Flex className="action-button-container" alignItems="center">
                            <CodeItemSearch />
                            <Box mx="12px">
                                <TradeNameSearch />
                            </Box>
                            <ModalFilterStatus />
                            {isAllowed({
                                pageName: "Supplier's Stock",
                                permissionName: "Edit Supplier's Stock",
                            }) && (
                                <>
                                    <Box className="divider" />
                                    <Button className="button-edit" onClick={() => setIsEdit(true)}>
                                        <Box mr="5px">
                                            <EditIcon />
                                        </Box>
                                        edit
                                    </Button>
                                </>
                            )}

                            <DropDown
                                fontWeightListMenu={600}
                                width="160px"
                                placement="left-start"
                                customBodyContentClassName="box"
                                option={optionMenuDot()}
                                open={open}
                                onClickMenu={(item) => {
                                    handleOptionMenu(item?.name);
                                }}
                                onClose={() => setOpen(false)}
                            >
                                <Button
                                    onClick={() => setOpen(true)}
                                    sx={{
                                        width: '32px',
                                        padding: 1,
                                        height: '32px',
                                    }}
                                    variant="ghost"
                                >
                                    <DottedIcon />
                                </Button>
                            </DropDown>
                        </Flex>
                    </Box>
                )}
            </Flex>
        </Box>
    );
}
export default SectionHead;

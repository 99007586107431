import { Tr, Th } from '@chakra-ui/react';

import './styles.scss';

function Index({ tableHead }) {
    return (
        <>
            <Tr className="top-subhead-row">
                {tableHead?.map((head, index) => {
                    return (
                        <Th
                            className="top-subhead-cell-table"
                            sx={{
                                position: head.is_freeze && 'sticky',
                                [head.align || 'left']: head.is_freeze && head.freezePosition,
                                zIndex: head.is_freeze && 1,
                                borderRight: head.isRightBorder && '1px solid  #cecece',
                            }}
                            key={index}
                        ></Th>
                    );
                })}
            </Tr>
            <Tr className="subhead-row">
                {tableHead?.map((head, index) => {
                    /// KONDISI JIKA TABLE YANG TIDAK FREEZE (STICKY)
                    const isCellNotFreeze = !head.is_freeze && index === 6 ? '13px' : '0px';
                    return (
                        <Th
                            className="subhead-cell-table"
                            sx={{
                                position: head.is_freeze && 'sticky',
                                [head.align || 'left']: head.is_freeze && head.freezePosition,
                                zIndex: head.is_freeze && 1,
                                textAlign: head.is_action ? 'center' : 'left',
                                borderRight: head.isRightBorder && '1px solid  #cecece',
                                pl: index === 0 ? '12px' : isCellNotFreeze,
                            }}
                            key={index}
                        >
                            {head.subHead}
                        </Th>
                    );
                })}
            </Tr>
        </>
    );
}

export default Index;

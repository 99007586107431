import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { formListCriticalPoint } from '../const';

import './styles.scss';
import { handleClickMenu, rightLabelHelper } from '../function';
import { FormCreateNewItemContext } from '..';

function Index({ formData, setFormData }) {
    const { isSubmitted } = React.useContext(FormCreateNewItemContext);
    const handleChange = ({ event, child }) => {
        handleClickMenu({ setFormData, valueOnChange: event?.target?.value, itemForm: child });
    };
    return (
        <Flex className="critical-point">
            <Box width="267px">
                <Text className="title-critical-point">Critical Point</Text>
                <Text className="tag-critical-point">
                    Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                </Text>
            </Box>
            <Box display="grid" rowGap="40px">
                {formListCriticalPoint.map((form, index) => (
                    <Box key={index} className="root-container-critical-point">
                        {form.children.map((child, childIndex) => {
                            const showErrorMustBeInteger =
                                formData?.dataLengkap?.jenis_material === 'PM' &&
                                child?.mustIntegerIfPm &&
                                (String(formData?.critical_point[child.name])?.includes('.') ||
                                    String(formData?.critical_point[child.name])?.includes(','));
                            return (
                                <Box key={childIndex}>
                                    <Box className="crital-point-field-container">
                                        <Text className="label">
                                            {child.title}
                                            {child.isRequired && <span style={{ color: 'red' }}>*</span>}
                                        </Text>
                                        <Box position="relative">
                                            <input
                                                min={0}
                                                type={
                                                    child?.name === 'safety_by_demand_pcs' ||
                                                    child?.name === 'safety_by_demand_hari'
                                                        ? 'text'
                                                        : 'number'
                                                }
                                                onChange={(event) => handleChange({ event, child })}
                                                value={formData?.critical_point[child?.name] || ''}
                                                placeholder={child.placeholder}
                                                disabled={child?.isDisabled}
                                                style={{
                                                    padding: child.rightLabel ? '12px 80px 12px 16px' : '12px 16px',
                                                }}
                                                className={`input-style-add-new-item-MC ${
                                                    child?.isDisabled && 'input-style-add-new-item-MC-disabled'
                                                }`}
                                            />
                                            {child.rightLabel && (
                                                <Text className="right-label-input">
                                                    {' '}
                                                    {rightLabelHelper({
                                                        name: child.rightLabel,
                                                        jenis_material: formData?.dataLengkap?.jenis_material,
                                                    })}
                                                </Text>
                                            )}
                                        </Box>
                                    </Box>
                                    {isSubmitted &&
                                        !Boolean(formData?.critical_point[child.name]?.toString()) &&
                                        child?.isRequired && (
                                            <Text className="required-field__text">Field required!</Text>
                                        )}
                                    {showErrorMustBeInteger && (
                                        <Text className="required-field__text">Must be integer</Text>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                ))}
            </Box>
            ;
        </Flex>
    );
}

export default Index;

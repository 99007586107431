export const Attention = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z"
            stroke={color}
            stroke-width="2"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5597 12.6904 16 12 16C11.3097 16 10.75 16.5597 10.75 17.25C10.75 17.9404 11.3097 18.5 12 18.5Z"
            fill={color}
        />
        <path d="M12 6V14" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const EditIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3609_32012)">
            <path
                d="M6.41669 2.33301H2.33335C2.02393 2.33301 1.72719 2.45592 1.5084 2.67472C1.2896 2.89351 1.16669 3.19026 1.16669 3.49967V11.6663C1.16669 11.9758 1.2896 12.2725 1.5084 12.4913C1.72719 12.7101 2.02393 12.833 2.33335 12.833H10.5C10.8094 12.833 11.1062 12.7101 11.325 12.4913C11.5438 12.2725 11.6667 11.9758 11.6667 11.6663V7.58301"
                stroke="#4163A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.7917 1.45814C11.0238 1.22608 11.3385 1.0957 11.6667 1.0957C11.9949 1.0957 12.3096 1.22608 12.5417 1.45814C12.7738 1.6902 12.9041 2.00495 12.9041 2.33314C12.9041 2.66133 12.7738 2.97608 12.5417 3.20814L7.00002 8.74981L4.66669 9.33314L5.25002 6.99981L10.7917 1.45814Z"
                stroke="#4163A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_3609_32012">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const RemoveIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.29688 5.32306V4.97259L1.96743 4.85305C1.67162 4.74572 1.42295 4.53783 1.26488 4.26574C1.10682 3.99364 1.04941 3.67464 1.1027 3.36451C1.15598 3.05438 1.31658 2.77284 1.5564 2.56911C1.79577 2.36577 2.09888 2.25285 2.41291 2.25H11.0037C11.3178 2.25285 11.6209 2.36577 11.8602 2.56911C12.1001 2.77284 12.2606 3.05438 12.3139 3.36451C12.3672 3.67464 12.3098 3.99364 12.1517 4.26574C11.9937 4.53783 11.745 4.74572 11.4492 4.85305L11.1197 4.97259V5.32306V10.9589C11.1197 11.3148 10.9784 11.6561 10.7267 11.9077C10.4751 12.1593 10.1338 12.3007 9.77796 12.3007H3.63867C3.28281 12.3007 2.94152 12.1593 2.68988 11.9077C2.43825 11.6561 2.29688 11.3148 2.29688 10.9589V5.32306ZM3.02474 4.93357H2.52474V5.43357V10.9589C2.52474 11.2544 2.6421 11.5377 2.851 11.7466C3.05991 11.9555 3.34324 12.0729 3.63867 12.0729H9.77796C10.0734 12.0729 10.3567 11.9555 10.5656 11.7466C10.7745 11.5377 10.8919 11.2544 10.8919 10.9589V5.43357V4.93357H10.3919H3.02474ZM7.93617 7.38929H5.48046C5.45024 7.38929 5.42126 7.37728 5.3999 7.35592C5.37853 7.33455 5.36653 7.30557 5.36653 7.27536C5.36653 7.24514 5.37853 7.21616 5.3999 7.1948C5.42126 7.17343 5.45024 7.16143 5.48046 7.16143H7.93617C7.96639 7.16143 7.99536 7.17343 8.01673 7.1948C8.0381 7.21616 8.0501 7.24514 8.0501 7.27536C8.0501 7.30557 8.0381 7.33455 8.01673 7.35592C7.99536 7.37728 7.96639 7.38929 7.93617 7.38929ZM2.41081 4.70571H11.0058C11.3012 4.70571 11.5846 4.58835 11.7935 4.37945C12.0024 4.17055 12.1197 3.88722 12.1197 3.59179C12.1197 3.29635 12.0024 3.01302 11.7935 2.80412C11.5846 2.59522 11.3012 2.47786 11.0058 2.47786H2.41081C2.11538 2.47786 1.83205 2.59522 1.62315 2.80412C1.41425 3.01302 1.29688 3.29635 1.29688 3.59179C1.29688 3.88722 1.41425 4.17055 1.62315 4.37945C1.83205 4.58835 2.11538 4.70571 2.41081 4.70571Z"
            fill="#2D2D2D"
            stroke="#FF3E3E"
        />
    </svg>
);

export const SortHeaderIcon = () => (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 7H0L3 10V0H2V7ZM5 1.5V10H6V3H8L5 0V1.5Z" fill="black" />
    </svg>
);

export const LeftArrowIcon = ({color}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4141 11.9998L14.0001 15.5858C14.3911 15.9768 14.3911 16.6098 14.0001 16.9998C13.6091 17.3908 12.9761 17.3908 12.5861 16.9998L9.00006 13.4138C8.21906 12.6328 8.21906 11.3668 9.00006 10.5858L12.5861 6.99979C12.9761 6.60879 13.6091 6.60879 14.0001 6.99979C14.3911 7.39079 14.3911 8.02379 14.0001 8.41379L10.4141 11.9998Z"
            fill={color}
        />
    </svg>
);

export const RightArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5859 11.9998L9.99991 15.5858C9.60991 15.9758 9.60991 16.6088 9.99991 16.9998C10.3899 17.3908 11.0239 17.3908 11.4139 16.9998L14.9999 13.4138C15.7809 12.6328 15.7809 11.3668 14.9999 10.5858L11.4149 6.99979C11.0239 6.60879 10.3909 6.60879 9.99991 6.99979C9.60891 7.39079 9.60991 8.02379 9.99991 8.41379L13.5859 11.9998Z"
            fill="#686868"
        />
    </svg>
);

export const PlusBlueIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 3.75V14.25" stroke="#4163A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.75 9H14.25" stroke="#4163A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const TrashIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.375 3.75V16.5H14.625V3.75H3.375Z" stroke="#EF5350" stroke-width="2" stroke-linejoin="round" />
        <path d="M7.5 7.5V12.375" stroke="#EF5350" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.5 7.5V12.375" stroke="#EF5350" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.5 3.75H16.5" stroke="#EF5350" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 3.75L7.23337 1.5H10.7914L12 3.75H6Z" stroke="#EF5350" stroke-width="2" stroke-linejoin="round" />
    </svg>
);

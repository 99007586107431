import { useLocation } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import Cookies from 'universal-cookie';
import { getCurrentPage } from '@helpers/functions';

import './styles.scss';

const SectionHeadGlobal = () => {
    const location = useLocation();
    const cookies = new Cookies();
    return (
        <Box
            className={location.pathname === '/user-management' ? '' : 'section-head-global__container'}
            marginBottom={location.pathname === '/lobby' && '0px'}
            display={cookies.get('appType') === 'warehouseToSupplier' ? 'none' : 'block'}
        >
            <Flex>
                <Text className="title-text__head-info">{getCurrentPage({ location }).name}</Text>
            </Flex>
            {/* <Text className="description-text">{getCurrentPage({ location }).description}</Text> */}
        </Box>
    );
};

export default SectionHeadGlobal;

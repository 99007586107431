import { Fragment, useEffect, useState } from 'react';
import ModalTemplate from '@components/modalTemplate';
import FieldMenu from '@components/fieldMenu';
import { ArrowDownIcon } from '@asset/icons';
import { onChangeFormData, onSubmitReport } from './function';
import { Box, Button, Text, Textarea, useToast } from '@chakra-ui/react';
import Modalsuccess from '@components/modalSuccess';
import { fetchListJenisProblem } from './function';
import { defaultValue } from './const';
import './styles.scss';

const CreateReportProblemModal = ({ isOpen, onClose, selectedDataProductStatus, refetchProductStatusHandler }) => {
    const toast = useToast();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState(defaultValue);
    const [isSuccess, setIsSuccess] = useState(false);
    const [listJenisProblem, setListJenisProblem] = useState([]);

    const onCloseReportCreate = () => {
        setIsSubmitted(false);
        setIsSuccess(false);
        onClose();
        setFormData({});
    };

    useEffect(() => {
        fetchListJenisProblem({ setListJenisProblem });
    }, []);

    return (
        <ModalTemplate width={543} isOpen={isOpen} onClose={onCloseReportCreate}>
            <Fragment>
                <Box className="section-head-create-report-problem__container">
                    <Text className="head-title-create-report-problem__container">Report Problem</Text>
                </Box>
                <Box className="create-modal__container__create-report">
                    <Box className="section-body__container">
                        <Box className="modal-item__container">
                            <Text className="label">Jenis Problem</Text>
                            <Box flex={1}>
                                <FieldMenu
                                    onClick={(value) =>
                                        onChangeFormData({
                                            keyValue: 'jenis_problem',
                                            value: value['name'],
                                            setFormData,
                                            formData,
                                        })
                                    }
                                    buttonName={formData?.jenis_problem || 'Pilih jenis problem'}
                                    menuList={listJenisProblem}
                                    keyName="name"
                                    menuClassName="menu-container__user-management"
                                    menuButtonClassName="menu-button"
                                    icon={<ArrowDownIcon />}
                                />
                                {isSubmitted && !formData?.jenis_problem && (
                                    <Text className="required-field__text">Field jenis problem required!</Text>
                                )}
                            </Box>
                        </Box>

                        <Box className="modal-item__container" experimental_spaceY={2}>
                            <Text className="label">Detail Problem</Text>
                            <Box flex={1}>
                                <Textarea
                                    placeholder={`Tulis Problem`}
                                    _focus={{ boxShadow: 'none' }}
                                    className="input-container"
                                    onChange={(e) =>
                                        onChangeFormData({
                                            keyValue: 'detail_problem',
                                            value: e.target.value,
                                            setFormData,
                                            formData,
                                        })
                                    }
                                    value={formData['detail_problem']}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className="section-footer__container">
                        <Button border={'1px solid grey'} onClick={onClose} className="button-close__container">
                            Cancel
                        </Button>
                        <Button
                            border={isSubmitLoading ? '1px solid grey' : 'none'}
                            isLoading={isSubmitLoading}
                            onClick={() => {
                                onSubmitReport({
                                    formData,
                                    setIsSubmitLoading,
                                    setIsSubmitted,
                                    selectedDataProductStatus,
                                    refetchProductStatusHandler,
                                    listJenisProblem,
                                    setIsSuccess,
                                    toast,
                                });
                            }}
                            className="button-add__container"
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Fragment>
            {isSuccess && (
                <Modalsuccess
                    isOpen
                    onClose={onCloseReportCreate}
                    title="Laporan Berhasil Dibuat"
                    description={`Report problem berhasil dibuat, kembali ke 
                       detail card untuk melihat lebih detail`}
                />
            )}
        </ModalTemplate>
    );
};

export default CreateReportProblemModal;

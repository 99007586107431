import Axios from '../../@services/axios';
import Cookies from 'universal-cookie';

export const loginHandler = async ({
    isRememberMe,
    setIsError,
    setIsLoading,
    navigate,
    formData,
    dispatch,
    toggleLoginHandler,
}) => {
    const cookies = new Cookies();
    setIsLoading(true);
    try {
        const { data } = await Axios({
            method: 'POST',
            url: '/login',
            data: { ...formData },
        });

        const { token, role, id } = data.data;

        cookies.set('role', role);
        cookies.set('userToken', token);
        cookies.set('userId', id);

        await dispatch(toggleLoginHandler(true));

        if (isRememberMe) {
            cookies.set('email', formData?.email);
        }

        navigate('/lobby');
    } catch (error) {
        console.log(error);
        setIsError(true);
    } finally {
        setIsLoading(false);
    }
};

export const handleChange = ({ value, type, setFormData, formData, e, setIsCapsLock }) => {
    const obj = { ...formData };
    obj[type] = value;
    setFormData({ ...obj });
};

export const onKeyEnter = ({ key, onLoginHandler }) => {
    if (key === 'Enter') {
        onLoginHandler();
    }
};

export const checkCapsLock = ({ e, setIsCapsLock, isCapslock }) => {
    if (e.getModifierState('CapsLock')) {
        setIsCapsLock(true);
    } else {
        setIsCapsLock(false);
    }
};

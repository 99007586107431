import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchSupplierDataHandler = async ({
    page,
    setData,
    setPage,
    moreData = false,
    setTotalData,
    search,
    jenisMaterial,
    setIsLoading,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const filterStringify = queryString.stringify(
        { q: search, page, jenis_material_id: jenisMaterial?.id },
        { skipEmptyString: true }
    );
    try {
        setIsLoading(true);
        const { data } = await Axios({
            method: 'GET',
            url: `/supplier/list?limit=15&source=INTERNAL&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        let { suppliers, totalData } = data.data;
        if (moreData && !search.length) {
            setData((prev) => [...prev, ...suppliers]);
            setPage(page + 1);
        } else {
            setData(suppliers);
        }
        setTotalData(totalData);
        setPage(page + 1);
    } catch (error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
};

import { Box, Button, Flex, Text, useToast, Spinner } from '@chakra-ui/react';
import ModalTemplate from '@components/modalTemplate';
import { PlusBlueIcon } from '@pagesPhase2/materialConfiguration/icons';
import { CalendarIcon, RemoveIcon } from '@pagesPhase2/supplierStock/icons';
import { isEmpty, isNull } from 'lodash';
import { useState } from 'react';
import DatePicker from 'react-date-picker';
import { handleAddMore, handleChangeDates, onRemoveDate } from './function';
import './styles.scss';

const EditDatePicker = ({
    isOpen,
    onClose,
    selectedItem,
    idDate,
    setIdDate,
    addDatePicker,
    setAddDatePicker,
    setIsDateRefetch,
    indexDate,
    setIndexDate,
    setDisabledLoadingMain
}) => {
    const toast = useToast();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idLoad, setIdLoad] = useState({});
    return (
        <ModalTemplate
            width={252}
            isOpen={isOpen}
            onClose={onClose}
            modalContentCustomStyle={{
                borderRadius: '4px',
                paddingTop: '23px',
                paddingLeft: '17px',
                paddingRight: '17px',
                paddingBottom: '14px',
                position: 'absolute',
                top: '166px',
                left: '888px',
            }}
        >
            <Box className="modal-edit-datepicker__container">
                <Box mb="51px">
                    {addDatePicker.map((item, index) => {
                        return (
                            <Box key={item.index} mt="24px" mb="24px" position="relative">
                                <DatePicker
                                    value={item.value}
                                    onChange={(value) =>
                                        handleChangeDates({
                                            addDatePicker,
                                            setAddDatePicker,
                                            value,
                                            index,
                                            setIsSubmitted,
                                            selectedItem,
                                            setIsLoading,
                                            toast,
                                            setIsDateRefetch,
                                        })
                                    }
                                    openCalendarOnFocus={false}
                                    className="date-picker-content"
                                    clearIcon={null}
                                    disabled={item.value !== '' ? true : false}
                                    calendarIcon={<CalendarIcon />}
                                    calendarClassName="calendar-first"
                                    format="yyyy-MM-dd"
                                />
                                { indexDate >= index && !isNull(indexDate) &&(
                                    <Box
                                        position="absolute"
                                        top="1px"
                                        right="-8px"
                                        cursor="pointer"
                                        onClick={() => {
                                            setIdLoad(item.id);
                                            onRemoveDate({
                                                selectedItem: item,
                                                setLoading,
                                                toast,
                                                setIsDateRefetch,
                                                addDatePicker,
                                                setAddDatePicker,
                                                setIndexDate,
                                                indexDate,
                                                setDisabledLoadingMain
                                            });
                                        }}
                                    >
                                        {selectedItem?.stockManufDates?.[index]?.id === idLoad && loading ? (
                                            <Spinner size="sm" />
                                        ) : (
                                            <RemoveIcon />
                                        )}
                                    </Box>
                                )}
                                {item.id === idDate && isSubmitted && isEmpty(item.value) && (
                                    <Text className="required-field__text">*Pilih Tanggal</Text>
                                )}
                            </Box>
                        );
                    })}
                </Box>
                <Flex justifyContent="flex-end">
                    {addDatePicker?.length <= 9 && (
                        <Button
                            className={`btn-add__more ${!isLoading && 'b-bottom'}`}
                            borderRadius="none"
                            onClick={() =>
                                handleAddMore({
                                    addDatePicker,
                                    setAddDatePicker,
                                    setIsSubmitted,
                                    setIdDate,
                                    selectedItem,
                                    setIsLoading,
                                    toast,
                                    setIsDateRefetch,
                                    setDisabledLoadingMain
                                })
                            }
                            isLoading={isLoading}
                        >
                            <Box>
                                <PlusBlueIcon />
                            </Box>
                            Add More
                        </Button>
                    )}
                </Flex>
            </Box>
        </ModalTemplate>
    );
};

export default EditDatePicker;

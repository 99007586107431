import React from 'react';
import { Box } from '@chakra-ui/react';
import './styles.scss';

function index({ data }) {
    return (
        <Box className="container-error-detail-upload-demand-anticipation">
            <Box>Periksa kembali file mu, dan jaringan internet, untuk melanjutkan upload file</Box>
            <Box style={{ textAlign: 'left', width: '100%' }}>
                <strong>Detail : </strong>
            </Box>
            {data?.type === 'catch' && (
                <Box role="ul" className="error-detail">
                    {data?.message?.errorDetail?.length === 0 ? (
                        <li style={{ textAlign: 'left' }}>
                            {data?.message?.error?.response?.data?.errors?.[0] || 'Internal server error'}
                        </li>
                    ) : (
                        data?.message?.errorDetail?.map((item, index) => (
                            <li key={index} style={{ textAlign: 'left' }}>
                                {item?.msg}
                            </li>
                        ))
                    )}
                </Box>
            )}
        </Box>
    );
}

export default index;

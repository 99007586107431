import { useContext } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { tableListAddItem } from '../../const';
import { tableAddNewItemContext } from '../..';
import './styles.scss';

const TableColumn = ({ idx, onOpenModal, dataItem, onChangeModalState }) => {
    const { onSelectedItem, togglerDeleteModal, onSelectedData } = useContext(tableAddNewItemContext);

    const onEditHandler = () => {
        onSelectedData({ item: dataItem });
    };

    const onRemoveHandler = () => {
        onSelectedItem({ value: dataItem });
        togglerDeleteModal();
    };

    return (
        <Flex className="table-column-sticky table-column__container__table-add-item">
            {tableListAddItem.map((item) => {
                return (
                    <Box
                        background={'white'}
                        key={item.name}
                        w={item.width}
                        flex={item.flex}
                        id={`column__item--${idx}`}
                        borderBottom="1px solid #B7B7B7"
                        className="column-sticky__item"
                    >
                        {item.component({
                            value: dataItem[item.value] || '-',
                            dataItem,
                            onSelectedItem,
                            togglerDeleteModal,
                            onRemoveHandler,
                            onChangeModalState,
                            onOpenModal,
                            onSelectedData,
                            onEditHandler,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

export const status = [
    {
        val: 'Ready',
        label: 'Ready',
    },
    {
        val: 'Not Ready',
        label: 'Not Ready',
    },
];
export const formDefaultValue = {
    data_send_to_be: {
        raw_material_status: '',
        raw_material_note: '',
        raw_packaging_status: '',
        raw_packaging_note: '',
    },
    label_value: {
        status_bahan_baku: '',
        status_packaging: '',
    },
};

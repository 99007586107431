import { Box, Text } from '@chakra-ui/react';
import './styles.scss';

const SectionTitle = () => {
    return (
        <Box className="title__container__login-page">
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="40" height="40" rx="2" fill="#1746A2" />
                <path
                    d="M18.4 9.036C20.768 9.036 22.8373 9.50533 24.608 10.444C26.3787 11.3827 27.744 12.7053 28.704 14.412C29.6853 16.0973 30.176 18.0493 30.176 20.268C30.176 22.4653 29.6853 24.4173 28.704 26.124C27.744 27.8307 26.368 29.1533 24.576 30.092C22.8053 31.0307 20.7467 31.5 18.4 31.5H9.984V9.036H18.4ZM18.048 26.764C20.1173 26.764 21.728 26.1987 22.88 25.068C24.032 23.9373 24.608 22.3373 24.608 20.268C24.608 18.1987 24.032 16.588 22.88 15.436C21.728 14.284 20.1173 13.708 18.048 13.708H15.456V26.764H18.048Z"
                    fill="white"
                />
            </svg>
            <Text className="logo-text">DPPS</Text>
        </Box>
    );
};

export default SectionTitle;

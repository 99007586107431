import { ArrowDownIcon } from '@asset/icons';
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Skeleton, Text, Tooltip } from '@chakra-ui/react';
import { FullScreenIcon, MinimazeScreenIcon, RefreshIcon } from '@routers/icons';
import { fetchMaterialsHandler, onToggleLoadingMaterial } from '@store/list/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import RealTimeClock from './realTImeClock';
import MenuFilterHeaderSupplier from '@components/header/filterSupplier/index';
import MenuFilterHeaderJenisMaterial from '@components/header/filterJenisMaterial/index';
import { onChangeSupplierType } from '@store/list/globalFilter';
import {
    getFullDateHandler,
    getWeekHandler,
    isHideFullScreenReload,
    isShowMaterialFilter,
    onSelectedMaterial,
    reloadPageHandler,
    togglerFullscreenHandler,
    showFilterJenisMaterialByRole,
    disabledFilterSupplierByRole,
} from './functions';
import { InfoIcon } from './icons';
import './styles.scss';
const Header = () => {
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const appType = cookies.get('appType');
    const userRole = cookies.get('role');
    const supplierName = cookies.get('supplier_name');
    const location = useLocation();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { materials, isLoadingFetchMaterial, selectedMaterial } = useSelector((reducer) => reducer.materialSlice);
    const { manualShowGlobalFilterByGivenId } = useSelector((reducer) => reducer.globalFilterSlice);
    useEffect(() => {
        dispatch(fetchMaterialsHandler());
    }, [dispatch]);
    useEffect(() => {
        if (supplierName) {
            dispatch(onChangeSupplierType([supplierName]));
        }
    }, [supplierName]);

    useEffect(() => {
        if (!isEmpty(materials)) {
            dispatch(onToggleLoadingMaterial(false));
        }
    }, [materials, dispatch]);

    useEffect(() => {
        document.addEventListener('fullscreenchange', (event) => {
            setIsFullscreen(document.fullscreenElement);
        });

        return document.removeEventListener('fullscreenchange', () => {});
    });

    return (
        <Box
            className="global-header__container"
            style={
                location.pathname === '/lobby'
                    ? { background: '#F8F8F8', borderBottom: 'none', paddingTop: '18px' }
                    : { background: 'none' }
            }
        >
            {/** ------------------------------------ SECTION LEFT ----------------------------------------- */}
            <Flex className="section-left__head-info">
                {isLoadingFetchMaterial && <Skeleton height="40px" width="112px" />}
                {/* ----- TEXT HEADER DPPS -----*/}
                {location.pathname === '/lobby' && <Text className="dpps-text">DPPS</Text>}

                {isShowMaterialFilter({ location, paramId: manualShowGlobalFilterByGivenId }) &&
                    !isLoadingFetchMaterial && (
                        <>
                            {appType === 'ndcToManufacture' && (
                                <>
                                    <Text className="material-text">Pilih sediaan</Text>
                                    <Tooltip
                                        hasArrow
                                        label="Jika sediaan diubah, akan mempengaruhi ke semua page di aplikasi DPPS"
                                        color={'#2D2D2D'}
                                        p="11px"
                                        fontWeight={400}
                                        bg="#F8ED8E"
                                        rounded={'8px'}
                                        fontSize="12px"
                                        w="235px"
                                        lineHeight={'18px'}
                                    >
                                        <Box>
                                            <InfoIcon />
                                        </Box>
                                    </Tooltip>
                                    <Menu className="menu-container">
                                        <MenuButton
                                            className="menu-button"
                                            bg="none"
                                            as={Button}
                                            rightIcon={<ArrowDownIcon />}
                                        >
                                            {selectedMaterial?.name}
                                        </MenuButton>
                                        <MenuList>
                                            {materials.map((material) => (
                                                <MenuItem
                                                    className="menu-item"
                                                    onClick={() => onSelectedMaterial({ material, dispatch })}
                                                >
                                                    {material.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                </>
                            )}

                            {appType === 'warehouseToSupplier' && (
                                <>
                                    {!Boolean(disabledFilterSupplierByRole({ userRole })) && (
                                        <Box display="flex">
                                            <Text className="material-text">pilih supplier</Text>
                                            <Tooltip
                                                hasArrow
                                                label="Jika supplier diubah, akan mempengaruhi ke semua page di aplikasi DPPS"
                                                color={'#2D2D2D'}
                                                p="11px"
                                                fontWeight={400}
                                                bg="#F8ED8E"
                                                rounded={'8px'}
                                                fontSize="12px"
                                                w="235px"
                                                lineHeight={'18px'}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <InfoIcon />
                                                </Box>
                                            </Tooltip>

                                            <MenuFilterHeaderSupplier />
                                        </Box>
                                    )}

                                    {showFilterJenisMaterialByRole({ userRole }) && (
                                        <Box display="flex" ml={'30px'}>
                                            {/* Pilih Jenis Material */}

                                            <Text className="material-text">pilih jenis material</Text>
                                            <Tooltip
                                                hasArrow
                                                label="Jika jenis material diubah, akan mempengaruhi ke semua page di aplikasi DPPS"
                                                color={'#2D2D2D'}
                                                p="11px"
                                                fontWeight={400}
                                                bg="#F8ED8E"
                                                rounded={'8px'}
                                                fontSize="12px"
                                                w="235px"
                                                lineHeight={'18px'}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <InfoIcon />
                                                </Box>
                                            </Tooltip>
                                            <MenuFilterHeaderJenisMaterial />
                                        </Box>
                                    )}
                                </>
                            )}
                        </>
                    )}
            </Flex>

            {/** ------------------------------------ SECTION RIGHT ----------------------------------------- */}

            <Flex className="section-right__head-info">
                <Text
                    className="week-text__head-info"
                    style={location.pathname === '/lobby' ? { color: '#223647', fontSize: '14px' } : { color: 'none' }}
                >
                    Week {getWeekHandler()}
                </Text>
                <Text
                    className="date-text__head-info"
                    style={location.pathname === '/lobby' ? { color: '#223647' } : { color: 'none' }}
                >
                    {getFullDateHandler()}
                </Text>
                <RealTimeClock />

                {isHideFullScreenReload({ location }) && (
                    <>
                        <Box mr="24px" cursor="pointer">
                            <Box onClick={() => reloadPageHandler()}>
                                <RefreshIcon />
                            </Box>
                        </Box>
                        <Box cursor="pointer">
                            {isFullscreen ? (
                                <Box onClick={() => togglerFullscreenHandler({ setIsFullscreen, isFullscreen })}>
                                    <MinimazeScreenIcon />
                                </Box>
                            ) : (
                                <Box onClick={() => togglerFullscreenHandler({ setIsFullscreen, isFullscreen })}>
                                    <FullScreenIcon />
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </Flex>
        </Box>
    );
};

export default Header;

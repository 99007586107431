import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Box, Button, Text, Input, Flex } from '@chakra-ui/react';
import ModalTemplate from '@components/modalTemplate';
import Modalsuccess from '@components/modalSuccess';
import { onSubmitForm } from './function';

import './styles.scss';
import { IconMinus, IconPlus } from './icons';

const InputBatchBookingModal = ({ isOpen, onClose, selectedOrderPlanning, reFetchOrderPlanningHandler }) => {
    const toast = useToast();
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [counter, setCounter] = useState(0);

    const onCloseSuccessSubmit = () => {
        setIsSuccessSubmit(false);
        onClose();
        setCounter(0);
    };

    const onResetForm = () => {
        setCounter(0);
    };

    return (
        <>
            <ModalTemplate width={323} isOpen={isOpen && !isSuccessSubmit} onClose={onClose}>
                <Box className="input-batch-booking__container__orderplanning">
                    <Box className="section-head__container">
                        <Text className="head-title__container">Additional Batch Booking</Text>
                    </Box>
                    <Box className="section-body__container">
                        <Box
                            className="modal-item__container"
                            p="0 44px 24px"
                            borderBottom="0.5px solid #D7D7D7"
                            mb="24px"
                        >
                            <Text className="label">Input Batch Booking</Text>
                            <Flex w="235px">
                                <Box
                                    className="counter-button-minus__container "
                                    onClick={() => setCounter(Number(counter) - 1)}
                                >
                                    <IconMinus />
                                </Box>
                                <Input
                                    className="input-container"
                                    placeholder="Input Batch Booking"
                                    type="number"
                                    onChange={(e) => setCounter(e.target.value)}
                                    value={counter}
                                />
                                <Box
                                    className="counter-button-plus__container"
                                    onClick={() => setCounter(Number(counter) + 1)}
                                >
                                    <IconPlus />
                                </Box>
                            </Flex>
                        </Box>
                        <Box className="modal-item__container" m="0 auto" w="235px" mb="18px">
                            <Text className="label">Current Batch Booking</Text>
                            <Box className="input-container__disabled">{selectedOrderPlanning?.batch_booking}</Box>
                        </Box>
                        <Box className="modal-item__container" m="0 auto" w="235px">
                            <Text className="label">Final Batch Booking</Text>
                            <Box className="input-container__disabled">
                                {selectedOrderPlanning?.batch_booking + Number(counter)}
                            </Box>
                        </Box>
                    </Box>

                    <Box className="section-footer__container">
                        <Button onClick={onClose} border={'1px solid grey'} className="button-close__container">
                            Cancel
                        </Button>
                        <Button
                            className="button-add__container"
                            border={isSubmitLoading ? '1px solid grey' : 'none'}
                            isLoading={isSubmitLoading}
                            onClick={() => {
                                onSubmitForm({
                                    batchBooking: Number(selectedOrderPlanning?.batch_booking) + Number(counter),
                                    toast,
                                    onClose: onCloseSuccessSubmit,
                                    setIsSubmitLoading,
                                    onResetForm,
                                    setIsSuccessSubmit,
                                    setIsSubmitted,
                                    id: selectedOrderPlanning?.orderPlannings[0]?.id,
                                    reFetchOrderPlanningHandler,
                                });
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </ModalTemplate>
            <Modalsuccess
                isOpen={isSuccessSubmit}
                onClose={onCloseSuccessSubmit}
                title={'Sukses Menginput'}
                description={`Batch booking berhasil diinput, kembali ke table untuk melihat data`}
            />
        </>
    );
};

export default InputBatchBookingModal;

import { routeList } from '@routers/const';
import { find, forEach, isEmpty } from 'lodash';
import AWS from 'aws-sdk';

export const getCurrentPage = ({ location }) => {
    const { pathname } = location;
    const route = find(routeList, { link: pathname });

    return route || { name: '', link: '' };
};

export const numToArr = (num) => {
    const arr = [];
    for (let i = 0; i < num; i++) {
        arr.push(i);
    }
    return arr;
};

export const removeEmptyValue = (obj) => {
    const temp = {};

    forEach(obj, (value, key) => {
        if (!isEmpty(value)) {
            temp[key] = value;
        }
    });

    return temp;
};

export const isForbiddenPage = ({ routeList, type }) => {
    return !Boolean(find(routeList, ({ path }) => type === path));
};

/// https://stackoverflow.com/a/10232792
export function isEmptyString(arg) {
    return arg?.toString() === null || arg?.toString()?.match(/^ *$/) !== null;
}

export const FormatTableNumber = ({ page }) => {
    const defaultPaginationPerPage = 10;
    return Array.from(Array(defaultPaginationPerPage * page).keys()).slice(-defaultPaginationPerPage);
};

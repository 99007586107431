export const handleCloseModalFilterByName = ({
    setOpenModalFilterName,
    setUser,
    selectedUser,
    user,
    type = 'close',
}) => {
    let selectedUserBeforeApply = [...selectedUser];
    let cloneAllData = [...user];
    cloneAllData.map((user_item) => {
        user_item.checked = false;
        if (type !== 'reset') {
            selectedUserBeforeApply?.map((before_apply) => {
                if (user_item.id === before_apply?.id) {
                    user_item.checked = true;
                }
                return before_apply;
            });
        }
        return user_item;
    });
    setUser(cloneAllData);
    if (type === 'close') {
        setOpenModalFilterName(false);
    }
};

import Axios from '@services/axios';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import Cookies from 'universal-cookie';

export const handleChangeDates = ({
    addDatePicker,
    setAddDatePicker,
    value,
    index,
    setIsSubmitted,
    selectedItem,
    setIsLoading,
    setIsDateRefetch,
    setDisabledLoadingMain
}) => {
    setIsSubmitted(false);
    const data = [...addDatePicker];
    data[index]['value'] = value;
    setAddDatePicker(data);
    if (addDatePicker?.length === 10) {
        handleSubmitDate({
            currentDate: value,
            selectedItem,
            setIsLoading,
            setIsDateRefetch,
            setDisabledLoadingMain
        });
    }
};

export const handleGetManufDates = ({ manufDates, setState }) => {
    const lengthDatePicker = `${manufDates?.length + 1}ids`;

    const newManufDates = manufDates.map((item) => {
        const tempDate = moment(item.manufacturing_date).format('YYYY-M-DD').split('-');
        const formatDate = new Date(tempDate[0], tempDate[1] - 1, tempDate[2]);
        return {
            id: item.id,
            value: formatDate,
        };
    });
    const newDatePicker = { id: lengthDatePicker, value: '' };
    if (manufDates?.length < 10) newManufDates.push(newDatePicker);
    setState(newManufDates);
};

const formatDate = ({ date }) => moment(date).format('YYYY-MM-DD');

export const handleAddMore = ({
    addDatePicker,
    setAddDatePicker,
    setIsSubmitted,
    setIdDate,
    selectedItem,
    setIsLoading,
    setIsDateRefetch,
    setDisabledLoadingMain,
}) => {
    const limitDatePicker = 10;
    const lengthDatePicker = addDatePicker?.length;
    const newDatePicker = { id: Math.floor(Math.random() * 10000) + 1, value: '' };
    const currentDateValue = addDatePicker[lengthDatePicker - 1].value;
    if (isEmpty(String(currentDateValue))) {
        setIsSubmitted(true);
        return;
    }
    handleSubmitDate({
        currentDate: addDatePicker[lengthDatePicker - 1].value,
        selectedItem,
        setIsLoading,
        setIsDateRefetch,
        setDisabledLoadingMain,
    }).then(() => {
        if (addDatePicker?.length <= limitDatePicker) {
            setAddDatePicker([...addDatePicker, newDatePicker]);
            setIdDate(newDatePicker.id);
        }
    });
};

export const handleSubmitDate = async ({
    selectedItem,
    currentDate,
    setIsLoading,
    toast,
    setIsDateRefetch,
    setDisabledLoadingMain,
}) => {
    setIsLoading(true);
    setIsDateRefetch(true);
    const data = {
        manufacturing_date: formatDate({ date: currentDate }),
        supplier_stock_id: selectedItem?.stockSupplier?.id,
    };
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    try {
        await Axios({
            url: '/stock-supplier/date',
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data,
        });
    } catch (err) {
        toast({
            position: 'top-center',
            description: 'something wrong!',
            status: 'error',
            duration: 500,
            isClosable: true,
        });
        console.log(err);
    } finally {
        setDisabledLoadingMain(true);
        setIsLoading(false);
    }
};

export const onRemoveDate = async ({
    selectedItem,
    setLoading,
    toast,
    setIsDateRefetch,
    addDatePicker,
    setAddDatePicker,
    setIndexDate,
    indexDate,
    setDisabledLoadingMain,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        setLoading(true);
        setIsDateRefetch(true);
        await Axios({
            method: 'DELETE',
            url: `/stock-supplier/date/${selectedItem.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const filterAddDatePicker = addDatePicker.filter((item) => item.id !== selectedItem.id);
        setAddDatePicker(filterAddDatePicker);
        setIndexDate(indexDate - 1 )
        await toast({
            position: 'center-top',
            description: 'Deleted Date berhasil',
            status: 'success',
            duration: 1500,
            isClosable: true,
        });
    } catch (error) {
        console.log(error);
        const errMessage = error?.message;
        await toast({
            position: 'top-center',
            description: errMessage || 'Deleted Date gagal',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        setDisabledLoadingMain(true)
        setLoading(false);
    }
};

export const handleCheckboxChange = ({
    checked,
    selectedData,
    data,
    setData,
    setTradeNameChoosed,
    tradeNameChoosed,
}) => {
    let clone = [...data];
    let cloneTradeNameChoosed = [...tradeNameChoosed];
    if (checked) {
        cloneTradeNameChoosed.push({ ...selectedData, checked });
    }
    if (!checked) {
        const unChecked = cloneTradeNameChoosed?.filter((item) => item?.trade_name !== selectedData?.trade_name);
        cloneTradeNameChoosed = unChecked;
    }
    clone.map((userItem) => {
        if (userItem.trade_name === selectedData?.trade_name) {
            userItem.checked = checked;
        }
        return userItem;
    });
    setTradeNameChoosed(cloneTradeNameChoosed);
    setData(clone);
};

import React from 'react';
import { Flex, Box, IconButton } from '@chakra-ui/react';
import { SortHeaderIcon } from '@pagesPhase2/materialConfiguration/icons';
import './styles.scss';
import { MaterialConfigurationContext } from '@pagesPhase2/materialConfiguration';

const TableHead = ({ setSort, sort }) => {
    const { tableListMC } = React.useContext(MaterialConfigurationContext);

    return (
        <Flex className="table-head__container__material-configuration">
            {tableListMC.map((head, idx, it) => {
                if (head.children) {
                    return (
                        <Box className="table-head__item" flexDirection="column" width={head.width} key={head.name}>
                            <Flex className="table-head__parent" w="fit-content" pl="6px">
                                {head.name}
                                {head.sortValue && (
                                    <IconButton
                                        zIndex={1}
                                        minW={3}
                                        h={4}
                                        pr={0}
                                        onClick={() =>
                                            setSort({
                                                name: head.sortValue,
                                                type: sort?.type === 'desc' ? 'asc' : 'desc',
                                            })
                                        }
                                        icon={<SortHeaderIcon />}
                                        className={`${head.sortValue !== 'kode_item' && 'active-margin__right'}`}
                                        style={{ marginRight: head.sortValue === 'kode_item' ? '0px' : undefined }}
                                    />
                                )}
                            </Flex>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}
        </Flex>
    );
};

export default TableHead;

import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie';
import Axios from '@services/axios';

export const onChangeFormData = ({ type, setFormData, value, formData }) => {
    const obj = { ...formData };
    obj[type] = value;

    setFormData({ ...obj });
};

const onValidation = ({ data }) => {
    const { raw_material_status, raw_packaging_status, raw_material_note, raw_packaging_note } = data;
    return (
        !isEmpty(raw_material_note) &&
        !isEmpty(raw_material_status) &&
        !isEmpty(raw_packaging_note) &&
        !isEmpty(raw_packaging_status)
    );
};

export const onSubmitForm = async ({
    formData,
    toast,
    setIsSubmitted,
    onClose,
    setIsSubmitLoading,
    onResetForm,
    setIsSuccessSubmit,
    selectedOrderPlanning,
    reFetchOrderPlanningHandler,
}) => {
    try {
        setIsSubmitted(true);
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const isValid = onValidation({ data: formData });

        if (!isValid) {
            toast({
                position: 'top-center',
                description: 'Field tidak boleh kosong!',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        const { raw_material_status, raw_packaging_status, raw_material_note, raw_packaging_note } = formData;
        await Axios({
            method: 'PATCH',
            url: `/order-planning/${selectedOrderPlanning?.id}?order_planning_id=${selectedOrderPlanning?.order_planning_id}&updateKetersediaan=true&`,
            headers: { Authorization: 'Bearer' + userToken },
            data: {
                status_bb: raw_material_status === 'Ready',
                status_packaging: raw_packaging_status === 'Ready',
                notes_status_bb: raw_material_note,
                notes_status_packaging: raw_packaging_note,
            },
        });

        setIsSubmitLoading(true);
        setIsSuccessSubmit(true);
        onClose();
        setIsSubmitted(false);
        setIsSubmitLoading(false);
        onResetForm();
        reFetchOrderPlanningHandler();
    } catch (err) {
        toast({
            position: 'top-center',
            description: 'Submit Ketersediaan Failed',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }
};

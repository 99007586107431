export const handleCloseModalFilter = ({
    setOpenModalFilter,
    tradeName,
    setTradeName,
    selectedData,
    type = 'close',
    setTradeNameChoosed,
}) => {
    let selectedUserBeforeApply = [...selectedData];
    let cloneAllData = [...tradeName];
    cloneAllData.map((user_item) => {
        user_item.checked = false;
        if (type !== 'reset') {
            selectedUserBeforeApply?.map((before_apply) => {
                if (user_item?.trade_name === before_apply?.trade_name) {
                    user_item.checked = true;
                }
                return before_apply;
            });
        }
        return user_item;
    });
    setTradeName(cloneAllData);
    setTradeNameChoosed([]);
    if (type === 'close') {
        setTradeNameChoosed(selectedData);
        setOpenModalFilter(false);
    }
};

import { useEffect, useState, createContext } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { numToArr } from '@helpers/functions';
import TableSkeleton from '@components/tableSkeleton';

import SectionHead from './sections/sectionHead';
import SectionTable from './sections/sectionTable';
import SectionFooter from './sections/sectionFooter';

import CreateAndEditModal from './component/createAndEditScreen';
import DeleteModal from './component/deleteModal';
import EmptyState from '@components/emptyState';

import { fetchStreamlineHandler, fetchAllModalDataHandler } from './functions';
import { tableList } from './const';

import './styles.scss';

export const StreamlineManagementContext = createContext();

const StreamlineManagement = () => {
    const { selectedMaterial } = useSelector((reducer) => reducer.materialSlice);

    const [streamlines, setStreamlines] = useState([]);
    const [selectedStreamline, setSelectedStreamline] = useState({});
    const [modalAttribute, setModalAttribute] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState({ zone: '' });

    const [modalToggler, setModalToggler] = useState(null);

    const onChangeModalState = ({ value }) => setModalToggler(value);
    const onSearchHandler = ({ value }) => setSearchQuery(value);
    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const onSelectedStreamline = ({ value }) => setSelectedStreamline(value);

    const reFetchStreamlineHandler = () => {
        fetchStreamlineHandler({
            searchQuery,
            setTotalData,
            setTotalPage,
            setStreamlines,
            setIsLoading,
            page,
            filter,
            selectedMaterial,
        });
    };

    useEffect(() => {
        if (selectedMaterial?.id) {
            fetchStreamlineHandler({
                searchQuery,
                setTotalData,
                setTotalPage,
                setStreamlines,
                setIsLoading,
                page,
                filter,
                selectedMaterial,
            });
        }
    }, [searchQuery, page, filter, selectedMaterial]);

    useEffect(() => {
        if (selectedMaterial?.id) {
            fetchAllModalDataHandler({
                setModalAttribute,
                selectedMaterial,
            });
        }
    }, [selectedMaterial]);

    return (
        <StreamlineManagementContext.Provider
            value={{
                streamlines,
                onChangeModalState,
                onSelectedStreamline,
                selectedStreamline,
            }}
        >
            <Box className="streamline-management__container">
                {/**-------------------- MAIN COMPONENT ------------------------ */}
                <SectionHead
                    modalAttribute={modalAttribute}
                    onSearchHandler={onSearchHandler}
                    onChangeModalState={onChangeModalState}
                    filter={filter}
                    setFilter={setFilter}
                />

                {isLoading && (
                    <TableSkeleton
                        tableList={tableList}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                )}
                {!isLoading && <SectionTable streamlines={streamlines} />}
                {!isLoading && streamlines.length === 0 && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan, silakan memilih menu yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}

                <SectionFooter
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                    handlePageClick={handlePageClick}
                    count={streamlines.length}
                />
            </Box>

            {/** ----------------------MODALS ---------------------------- */}
            <CreateAndEditModal
                isEditModal={false}
                isOpen={modalToggler === 'modal-create'}
                reFetchStreamlineHandler={reFetchStreamlineHandler}
                modalAttribute={modalAttribute}
            />

            <CreateAndEditModal
                isEditModal
                isOpen={modalToggler === 'modal-edit'}
                reFetchStreamlineHandler={reFetchStreamlineHandler}
                selectedStreamline={selectedStreamline}
                modalAttribute={modalAttribute}
            />

            {modalToggler === 'modal-delete' && (
                <DeleteModal
                    isOpen={modalToggler === 'modal-delete'}
                    reFetchStreamlineHandler={reFetchStreamlineHandler}
                />
            )}
        </StreamlineManagementContext.Provider>
    );
};

export default StreamlineManagement;

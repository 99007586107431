import * as ExcelJS from 'exceljs';
import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { onDownloadGlobalHandler } from '@store/list/downloadManagement';
import { Box, Flex, Input, Text } from '@chakra-ui/react';
import { NotesIcon } from './icons';
import { tableList } from './const';
import { isEmpty } from 'lodash';
import EditableColumn from './components/editableColumn';
import { isAllowed } from '@helpers/roleAccess';
import EditableStatus from './components/editableStatus';
import EditableNotes from './components/editableNotes';

import './styles.scss';

export const onDownloadDemandAnticipation = async ({ dispatch, filter, jenisMaterial, supplier }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const downloadId = Date.now() * Math.floor(Math.random() * 100000);
    const { itemCode, trade_name } = filter;
    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
        },
        { skipEmptyString: true }
    );
    dispatch(
        onDownloadGlobalHandler({
            name: 'demand anticipation',
            type: 'loading',
            id: downloadId,
            title: 'Menyiapkan dowload data Demand Anticipation table',
        })
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/demand-anticipation/download?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });

        const fileName = 'download_data_demand_anticipation';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        dispatch(
            onDownloadGlobalHandler({
                name: 'demand anticipation',
                type: 'done',
                id: downloadId,
            })
        );
    } catch (error) {
        console.log(error);
        dispatch(
            onDownloadGlobalHandler({
                name: 'demand anticipation',
                type: 'error',
                title: 'Dowload data Demand Anticipation table gagal',
                id: downloadId,
            })
        );
    }
};

const onFormatTable = ({ materials }) => {
    let formatTable = [
        {
            name: 'no',
            value: 'index',
            width: 70,
            children: ['-'],
            component: ({ index, numbers }) => <Text className="column-text">{numbers[index] + 1}</Text>,
        },
        {
            name: 'kode item',
            value: 'kode_item',
            width: 140,
            children: ['-'],
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'nama item',
            width: 288,
            value: 'trade_name',
            children: ['-'],
            component: ({ value }) => <Text className="column-text text-ellipsis">{value}</Text>,
        },
        {
            name: 'supplier',
            value: 'supplier_name',
            width: 180,
            children: ['-'],
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'UOM',
            value: 'uom',
            width: 75,
            children: ['-'],
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'baseline',
            value: 'baseline_pcs',
            width: 79,
            children: ['pcs'],
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'status',
            width: 150,
            value: 'status',
            children: ['act/npd'],
            component: ({ value, dataItem, id }) => (
                <Box className="td-status-container">
                    {Boolean(dataItem?.demand_anticipation_id) &&
                    isAllowed({
                        pageName: 'Demand Anticipation',
                        permissionName: 'Edit Demand Anticipation',
                    }) ? (
                        <EditableStatus dataItem={dataItem} id={id} value={value} />
                    ) : (
                        <Text className="column-text">{value || '-'}</Text>
                    )}
                </Box>
            ),
        },
        {
            name: 'notes',
            width: 250,
            value: 'notes',
            children: ['Free Text'],
            component: ({ value, dataItem }) => <EditableNotes value={value} dataItem={dataItem} />,
        },
        {
            name: 'addtional order',
            width: 120,
            value: 'additional_order',
            children: ['pcs'],
            component: ({ value, dataItem }) => (
                <EditableColumn
                    currentValue={value}
                    demand_anticipation_id={dataItem?.demand_anticipation_id}
                    id={dataItem?.id}
                    isAdditional={true}
                />
            ),
        },
    ];

    const week = [];
    const month = [];
    for (let i = 0; i < materials.length; i++) {
        if (materials[i]?.demandPerWeek?.length > 0) {
            for (let j = 0; j < materials[i].demandPerWeek.length; j++) {
                week.push(materials[i].demandPerWeek[j]);
            }
        }
    }

    for (let i = 0; i < materials.length; i++) {
        if (materials[i]?.demandPerMonth?.length > 0) {
            for (let j = 0; j < materials[i].demandPerMonth.length; j++) {
                month.push(materials[i].demandPerMonth[j]);
            }
        }
    }

    if (month?.length) {
        const uniqueValues = new Set();
        const resultArray = [];
        for (const item of month) {
            const uniqueKey = `${item.month}_${item.year}`;
            if (!uniqueValues.has(uniqueKey)) {
                uniqueValues.add(uniqueKey);
                resultArray.push(item);
            }
        }
        const dMonth = resultArray.sort((a, b) => a.year - b.year || a.month - b.month);
        for (let i = 0; i < dMonth?.length; i++) {
            const obj = {
                name: `${dMonth[i].month}`,
                year: `${dMonth[i].year}`,
                width: 40,
                value: 'value_w',
                children: ['pcs'],
                component: ({ demandPerMonth }) => (
                    <Text className="column-text">
                        {findMonthYear(dMonth[i].month, dMonth[i].year, demandPerMonth) || '-'}
                    </Text>
                ),
            };
            formatTable.push(obj);
        }
    } else if (week?.length) {
        const uniqueValues = new Set();
        const resultArray = [];
        for (const item of week) {
            const uniqueKey = `${item.week_n}_${item.year}`;
            if (!uniqueValues.has(uniqueKey)) {
                uniqueValues.add(uniqueKey);
                resultArray.push(item);
            }
        }
        const dWeek = resultArray.sort((a, b) => a.year - b.year || a.week_n - b.week_n);
        for (let i = 0; i < dWeek?.length; i++) {
            const obj = {
                name: `W${dWeek[i].week_n}`,
                year: `${dWeek[i].year}`,
                width: 40,
                value: 'value_w',
                children: ['pcs'],
                component: ({ demandPerWeek, dataItem }) => (
                    <EditableColumn
                        currentWeek={dWeek[i].week_n}
                        currentYear={dWeek[i].year}
                        currentValue={findWeekYear(dWeek[i].week_n, dWeek[i].year, demandPerWeek)}
                        demand_anticipation_id={dataItem?.demand_anticipation_id}
                        id={dataItem?.id}
                    />
                ),
            };
            formatTable.push(obj);
        }
    } else {
        formatTable = tableList;
    }

    return formatTable;
};

export const fetchDataDemandAnticipation = async ({
    filter,
    page,
    setItems,
    setTotalData,
    setTotalPage,
    setIsLoading,
    selectedPerPage,
    setTable,
    jenisMaterial,
    supplier,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    const { itemCode, trade_name, sortBy, status } = filter;

    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            page,
            limit: selectedPerPage,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            showing: sortBy,
            status_id: status,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/demand-anticipation/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { materials, totalData, totalPage } = data.data;

        const formatTable = onFormatTable({ materials });

        setTable(formatTable);
        setTotalPage(totalPage);
        setTotalData(totalData);
        setItems(materials);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setItems([]);
    } finally {
        setIsLoading(false);
    }
};

const onFormattedData = ({ formData }) => {
    const formatData = {
        ...formData,
    };

    if (isEmpty(formData.notes)) {
        delete formData.notes;
    }
    if (isEmpty(String(formData.status_id))) {
        delete formData.status_id;
    }
    return { ...formData };
};

const onDataFormattedExcel = ({ data = [] }) => {
    const newData = [];
    const headers = [];
    data.forEach((v) => {
        newData.push({
            NO: v.no || '-',
            'KODE ITEM': v.kode_item || '-',
            'NAMA ITEM': v.nama_item || '-',
            BASELINE: v.baseline || '-',
            STATUS: v.status || '-',
            'ADDTIONAL ORDER': v.addtional_order || '-',
            W4: v.value_w || '-',
            W5: v.value_w || '-',
            W6: v.value_w || '-',
            W7: v.value_w || '-',
            W8: v.value_w || '-',
            W8: v.value_w || '-',
            W9: v.value_w || '-',
            W10: v.value_w || '-',
            W11: v.value_w || '-',
            W12: v.value_w || '-',
            W13: v.value_w || '-',
            W14: v.value_w || '-',
            W15: v.value_w || '-',
            W16: v.value_w || '-',
            W17: v.value_w || '-',
            W18: v.value_w || '-',
            W19: v.value_w || '-',
            W20: v.value_w || '-',
            W21: v.value_w || '-',
            W22: v.value_w || '-',
            W23: v.value_w || '-',
            W24: v.value_w || '-',
            W25: v.value_w || '-',
            W26: v.value_w || '-',
            W27: v.value_w || '-',
            W28: v.value_w || '-',
            W29: v.value_w || '-',
            W30: v.value_w || '-',
            W31: v.value_w || '-',
            W32: v.value_w || '-',
            W33: v.value_w || '-',
            W34: v.value_w || '-',
            W35: v.value_w || '-',
            W36: v.value_w || '-',
            W37: v.value_w || '-',
            W38: v.value_w || '-',
            W39: v.value_w || '-',
            W40: v.value_w || '-',
            W41: v.value_w || '-',
            W42: v.value_w || '-',
            W43: v.value_w || '-',
            W44: v.value_w || '-',
            W45: v.value_w || '-',
            W46: v.value_w || '-',
            W47: v.value_w || '-',
            W48: v.value_w || '-',
            W49: v.value_w || '-',
            W50: v.value_w || '-',
            W51: v.value_w || '-',
            W52: v.value_w || '-',
            W53: v.value_w || '-',
            W54: v.value_w || '-',
            W55: v.value_w || '-',
            W56: v.value_w || '-',
            W57: v.value_w || '-',
        });
    });

    return { newData, headers };
};

export const onExportExcelDMA = ({ setLoading }) => {
    setLoading(true);
    const dummyDatas = [
        {
            no: 57,
            kode_item: 1234567,
            nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
            baseline: '12.3456',
            status: 'Act & NPD',
            notes: 'Isi notes......',
            addtional_order: 2578,
            value_w: 999.21,
        },
        {
            no: 57,
            kode_item: 1234567,
            nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
            baseline: '12.3456',
            status: 'Act & NPD',
            notes: 'Isi notes......',
            addtional_order: 2578,
            value_w: 999.21,
        },
    ];

    const { newData, headers } = onDataFormattedExcel({ data: dummyDatas });
    Object.keys(newData[0]).forEach((v) => {
        headers.push(v);
    });
    // INITILIZE EXCELJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.addRow(headers);

    // looping for value excel
    newData.forEach((item, _idx) => {
        worksheet.addRow(Object.values(item));
    });
    // set width for each column
    for (let i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 52;
    }

    // set bold for header
    const selectedRows = [];
    worksheet.eachRow((row, rowNumber) => {
        if ([1].indexOf(rowNumber) !== -1) {
            selectedRows.push(row);
        }
    });

    selectedRows.forEach((row) => {
        row.eachCell((cell) => {
            cell.font = {
                bold: true,
            };
            cell.alignment = { horizontal: 'center' };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `demand_anticipation_${new Date().toDateString()}.xlsx`;
        link.click();
        setLoading(false);
    });
};

export const findWeekYear = (week, year, demandArray) =>
    demandArray?.find((item) => item.week_n === week && item.year === year)?.demand || '-';

export const findMonthYear = (month, year, demandArray) =>
    demandArray?.find((item) => item.month === month && item.year === year)?.demand || '-';
    
export const currentYearDemand = new Date().getFullYear();
export const pastYearDemand = currentYearDemand - 1;

import { Box, Flex, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { ArrowRightIcon } from '@asset/icons';
import { PyramidHamburgerIcon } from './nameFilter/icons';
import FieldMenu from '@components/fieldMenu';
import { AddIconBlue } from '@pages/userManagement/icons';
import ModalFilterByName from './nameFilter';
import { handleCloseModalFilterByName } from './function';
import { UserManagementContext } from '@pages/userManagement';
import './styles.scss';

const SectionHead = ({ roles, togglerCreateUserModal, onSearchHandler, filter, setFilter }) => {
    const { jenisMaterial, setPage } = useContext(UserManagementContext);
    const [selectedUser, setSelectedUser] = useState([]);
    const [user, setUser] = useState([]);
    const [openModalFilterName, setOpenModalFilterName] = useState(false);
    const [pilihJenisMaterial, setPilihJenisMaterial] = useState({});
    const [pilihRole, setPilihRole] = useState({});
    const userSelected = user?.filter((item) => item.checked);
    const handleResetFilter = () => {
        handleCloseModalFilterByName({
            selectedUser,
            setUser,
            user,
            type: 'reset',
        });
    };

    const handleApplyFilter = () => {
        setPage(1);
        setSelectedUser(userSelected);
        const selectedUsetTemp = userSelected?.map((item) => item.name);
        setFilter({ ...filter, name: selectedUsetTemp });
        setOpenModalFilterName(false);
    };

    return (
        <>
            <ModalFilterByName
                user={user}
                setUser={setUser}
                isOpen={openModalFilterName}
                onClose={() =>
                    handleCloseModalFilterByName({
                        setOpenModalFilterName,
                        selectedUser,
                        setUser,
                        user,
                    })
                }
                selectedUser={selectedUser}
                handleApplyFilter={handleApplyFilter}
                handleResetFilter={handleResetFilter}
            />
            <Flex className="section-head__container__user-management">
                <Flex className="section_head_button_action">
                    {/* <Box className="section-search__container__user-management">
                    <input
                        className="search-input"
                        placeholder="Cari nama dan email..."
                        onChange={(e) => setSearchKey(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearchHandler({ value: searchKey });
                            }
                        }}
                    />
                    <Box className="search-icon__container" onClick={() => onSearchHandler({ value: searchKey })}>
                        <SearchIcon />
                    </Box>
                </Box> */}
                    {roles && (
                        <Box
                            onClick={() => {
                                setOpenModalFilterName(true);
                            }}
                            className={`btn-filter-by-name-container ${
                                selectedUser?.length > 0 && 'has-user-selected'
                            }`}
                        >
                            <Text
                                className={`btn-filter-text  ${selectedUser?.length > 0 && 'has-user-selected-text'}`}
                            >
                                Filter by name
                            </Text>
                            <Box
                                className={`btn-filter-name-icon ${
                                    selectedUser?.length > 0 && 'has-user-selected-icon'
                                }`}
                            >
                                <PyramidHamburgerIcon fill={selectedUser?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                            </Box>
                            {selectedUser?.length > 0 && (
                                <Text className="btn-filter-badge">{selectedUser?.length}</Text>
                            )}
                        </Box>
                    )}
                    {roles && (
                        <FieldMenu
                            onClick={(value) => {
                                setPage(1);
                                setPilihRole(value);
                                setFilter({ ...filter, role: value });
                            }}
                            buttonName={
                                <span>
                                    <span>
                                        {pilihRole?.role?.split('-')[0] ? (
                                            <span className="selected-role-text">{pilihRole?.role?.split('-')[0]}</span>
                                        ) : (
                                            <span className="FieldMenu-placeholder">Role</span>
                                        )}
                                    </span>
                                </span>
                            }
                            menuList={[...roles, { role: 'All' }]}
                            keyName="role"
                            withEntity
                            menuClassName="menu-container__user-management"
                            menuButtonClassName="menu-button-role"
                            menuListClassName={`menuList-container-role`}
                            icon={<ArrowRightIcon />}
                        />
                    )}
                    {roles && (
                        <FieldMenu
                            onClick={(value) => {
                                setPage(1);
                                setPilihJenisMaterial(value);
                                setFilter({ ...filter, id: value.id });
                            }}
                            buttonName={
                                <span>
                                    <span>
                                        {pilihJenisMaterial?.name ? (
                                            <span className="selected-role-text">{pilihJenisMaterial?.name}</span>
                                        ) : (
                                            <span className="FieldMenu-placeholder">Pilih jenis material</span>
                                        )}
                                    </span>
                                </span>
                            }
                            menuList={jenisMaterial}
                            keyName="name"
                            menuClassName="menu-container__user-management"
                            menuButtonClassName="menu-button-JM"
                            menuListClassName="menuList-container-JM"
                            icon={<ArrowRightIcon />}
                        />
                    )}
                    <Box className="divider"></Box>
                    <Box className="button-add-account__container" onClick={togglerCreateUserModal}>
                        <Box mr="8px">
                            <AddIconBlue />
                        </Box>
                        Add New User
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};

export default SectionHead;

export const defaultValue = {
    jenis_problem: '',
    detail_problem: '',
};

export const listJenisProblem = [
    {
        jenis_problem: 'Kendala Bahan Baku',
    },
    {
        jenis_problem: 'Kendala Packaging',
    },
    {
        jenis_problem: 'Kendala Quality',
    },
    {
        jenis_problem: 'Kendala Mesin',
    },
    {
        jenis_problem: 'Kendala Operator',
    },
    {
        jenis_problem: 'Kendala Lain-lain',
    },
];

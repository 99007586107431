import { Box, Button, Text, useToast, Flex } from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useContext } from 'react';

import ModalSuccess from '@components/modalSuccess';
import { InfoIcon } from '@pages/assetManagement/icons';
import FieldMenu from '@components/fieldMenu';
import ModalTemplate from '@components/modalTemplate';
import { AssetManagementContext } from '@pages/assetManagement';

import { onChangeFormData, onSubmitForm, mappedSelectedAsset } from './functions';
import { formDefaultValue } from './const';

import './styles.scss';
import { RemoveIcon } from '@pages/userManagement/icons';

const CreateAndEditModal = ({ isOpen, isEditModal, reFetchAssetHandler, selectedAsset }) => {
    const { materials } = useSelector((reducer) => reducer.materialSlice);

    const { onChangeModalState, selectedView, zoneList } = useContext(AssetManagementContext);
    const [nameAsset, setNameAsset] = useState([]);
    const [errorSetName, setErrorSetName] = useState(false);

    const toast = useToast();
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState(formDefaultValue);

    const onClose = () => {
        setFormData({ ...formDefaultValue });
        onChangeModalState({ value: null });
        setNameAsset([]);
        setErrorSetName(false);
    };

    const onResetForm = () => {
        setFormData({ ...formDefaultValue });
        setIsSubmitted(false);
    };

    const onCloseSuccessSubmit = () => {
        setIsSuccessSubmit(false);
        onClose();
    };

    const onSetNameAsset = (nameAsset) => {
        if (!nameAsset) {
            setErrorSetName(true);
            return;
        }
        setNameAsset((prev) => [...prev, nameAsset]);
        setFormData((i) => ({ ...i, name: '' }));
        setErrorSetName(false);
    };

    const onRemoveNameAsset = (nameAsset) => {
        setNameAsset((prev) => prev.filter((el) => el !== nameAsset));
    };
    useEffect(() => {
        if (isEditModal) {
            mappedSelectedAsset({ setFormData, selectedAsset, zoneList, materials });
        }
    }, [isEditModal, selectedAsset, zoneList, materials]);

    return (
        <>
            <ModalTemplate width={543} isOpen={isOpen} onClose={onClose}>
                <Box className="create-and-edit-modal__container__assetmanagement">
                    <Box className="section-head__container">
                        <Text className="head-title__container"> {isEditModal ? 'Edit' : 'Add New'} Asset</Text>
                    </Box>
                    <Box className="wrap-asset-body">
                        <Box className="section-body__container">
                            <Box className="modal-item__container">
                                <Text className="label">
                                    Nama Asset<Text color="red">*</Text>
                                </Text>
                                {nameAsset.map((i, k) => (
                                    <Flex key={k} justify="space-between" experimental_spaceX={'14px'}>
                                        <input readOnly className="input-container" value={i} />
                                        <Button className="button-delete-asset" onClick={() => onRemoveNameAsset(i)}>
                                            Hapus asset
                                            <RemoveIcon />
                                        </Button>
                                    </Flex>
                                ))}
                                <Flex justify="space-between" experimental_spaceX={'14px'}>
                                    <input
                                        className={!errorSetName ? 'input-container' : 'input-container-invalid'}
                                        style={{ width: isEditModal && '100%' }}
                                        placeholder="Masukan nama"
                                        required
                                        onChange={(e) => {
                                            onChangeFormData({
                                                type: 'name',
                                                value: e.target.value,
                                                setFormData,
                                                formData,
                                            });
                                            setErrorSetName(false);
                                        }}
                                        value={formData.name}
                                    />
                                    {!isEditModal && (
                                        <Button
                                            className="button-add-asset"
                                            onClick={() => onSetNameAsset(formData.name)}
                                        >
                                            Tambah asset
                                            <AddIcon />
                                        </Button>
                                    )}
                                </Flex>
                            </Box>
                        </Box>
                        <Box className="modal-fieldmenu__container">
                            {selectedView !== 'zona' && (
                                <>
                                    <Text className="text-material">
                                        Pilih Zona Streamline
                                        <Text color="red" mr={2}>
                                            *
                                        </Text>
                                        <InfoIcon />
                                    </Text>
                                    <FieldMenu
                                        onClick={(value) =>
                                            onChangeFormData({
                                                type: 'zone',
                                                value,
                                                setFormData,
                                                formData,
                                            })
                                        }
                                        buttonName={
                                            formData?.zone?.name || (
                                                <Text className="text-default">Pilih Zona Streamline</Text>
                                            )
                                        }
                                        menuList={zoneList}
                                        keyName="name"
                                        menuClassName="menu-container__asset-management"
                                        menuButtonClassName="menu-field-asset-button"
                                        icon={<ChevronDownIcon w={25} h={12} />}
                                    />
                                </>
                            )}

                            {selectedView === 'zona' && (
                                <>
                                    <Text className="text-material">
                                        Pilih Sediaan
                                        <Text color="red" mr={2}>
                                            *
                                        </Text>
                                        <InfoIcon />
                                    </Text>
                                    <FieldMenu
                                        onClick={(value) =>
                                            onChangeFormData({
                                                type: 'material',
                                                value,
                                                setFormData,
                                                formData,
                                            })
                                        }
                                        buttonName={
                                            formData?.material?.name || (
                                                <Text className="text-default">Pilih Sediaan</Text>
                                            )
                                        }
                                        menuList={materials}
                                        keyName="name"
                                        menuClassName="menu-container__asset-management"
                                        menuButtonClassName="menu-field-asset-button"
                                        icon={<ChevronDownIcon w={25} h={12} />}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                    {/* BUTTON */}
                    <Box className="button-wrapper" style={{ marginTop: isSubmitted && '65px' }}>
                        <Button className="button-cancel" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            className="button-save"
                            border={isSubmitLoading ? '1px solid grey' : 'none'}
                            isLoading={isSubmitLoading}
                            onClick={() =>
                                onSubmitForm({
                                    toast,
                                    formData,
                                    onClose,
                                    setIsSubmitLoading,
                                    reFetchAssetHandler,
                                    isEditModal,
                                    selectedAsset,
                                    setIsSubmitted,
                                    onResetForm,
                                    selectedView,
                                    setIsSuccessSubmit,
                                    nameAsset,
                                })
                            }
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </ModalTemplate>

            {/** ----------------------MODAL SUCCESS ---------------------------- */}

            <ModalSuccess
                isOpen={isSuccessSubmit}
                onClose={onCloseSuccessSubmit}
                title={`Sukses ${isEditModal ? 'Mengubah' : 'Membuat'} Asset`}
                description={`Asset berhasil ${
                    isEditModal ? 'diubah' : 'dibuat'
                }, kembali ke table untuk melihat dan mengatur asset`}
            />
        </>
    );
};

export default CreateAndEditModal;

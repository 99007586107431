import { useToast, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import ModalDelete from '@components/modalDelete';
import { onRemoveAsset } from './functions';
import { AssetManagementContext } from '@pages/assetManagement';
import './styles.scss';

const DeleteModal = ({ isOpen, reFetchAssetHandler }) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const { selectedAsset, onChangeModalState, selectedView, selectName } = useContext(AssetManagementContext);

    const onClose = () => onChangeModalState({ value: null });
    return (
        <ModalDelete
            isOpen={isOpen}
            onClose={onClose}
            title={`Delete ${selectName}`}
            isLoading={isLoading}
            onSubmit={() =>
                onRemoveAsset({
                    setIsLoading,
                    selectedAsset,
                    selectedView,
                    onClose,
                    reFetchAssetHandler,
                    toast,
                })
            }
            descriptionComponent={<Text className="desc-text">Apakah anda yakin ingin menghapus item ?</Text>}
        ></ModalDelete>
    );
};

export default DeleteModal;

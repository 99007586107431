import { Box, Flex, Text } from '@chakra-ui/react';
import { LeftArrowIcon } from '@pagesPhase2/materialConfiguration/icons';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { isAllowed } from '@helpers/roleAccess';

const SectionHead = ({ onChangeModalState }) => {
    const navigate = useNavigate();
    return (
        <Box>
            <Flex className="section-head__container__head-add-item-configuration">
                <Box display="flex" cursor="pointer" onClick={() => navigate('/material-configuration')}>
                    <LeftArrowIcon color={'#2D2D2D'} />
                    <Text className="text-head">add item set</Text>
                </Box>
                {isAllowed({ pageName: 'Material Config', permissionName: 'Create Item Set' }) && (
                    <Box className="section-right">
                        <Box
                            className="button-add section-right__button"
                            cursor="pointer"
                            onClick={() => onChangeModalState({ value: 'modal-create' })}
                        >
                            <Text className="button-text">add new set</Text>
                        </Box>
                    </Box>
                )}
            </Flex>
        </Box>
    );
};

export default SectionHead;

import React, { useContext } from 'react';
import { Box, Flex, Text, Skeleton } from '@chakra-ui/react';
import { formListPerWeek } from '../const';
import { handleClickMenu, rightLabelHelper } from '../function';
import { FormCreateNewItemContext } from '..';

import './styles.scss';

function Index({ formData, setFormData }) {
    const { dataMaterialRaci, isSubmitted, isLoadingMaterialRaci } = useContext(FormCreateNewItemContext);
    const handleChange = ({ event, itemForm }) => {
        handleClickMenu({
            setFormData,
            valueOnChange: event?.target?.value,
            itemForm,
            dataMaterialRaci: dataMaterialRaci?.materialRaci,
        });
    };
    return (
        <Flex className="baseline-per-week">
            <Box width="267px">
                <Text className="title-baseline-per-week">Baseline Per Week</Text>
                <Text className="tag-baseline-per-week">
                    Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                </Text>
            </Box>
            <Box className="baseline-per-week-container">
                {formListPerWeek.map((itemForm, index) => (
                    <Box key={index} className="child-container-per-week">
                        {itemForm.children?.map((child, childIndex) => {
                            const showErrorMustBeInteger =
                                formData?.dataLengkap?.jenis_material === 'PM' &&
                                (String(formData?.baselinePerWeek[child?.name])?.includes('.') ||
                                    String(formData?.baselinePerWeek[child?.name])?.includes(',')) &&
                                child?.name === 'pcs';
                            return (
                                <Box key={childIndex}>
                                    <Box position="relative">
                                        {isLoadingMaterialRaci && child.isDisabled ? (
                                            <Box width="213px" height="48px">
                                                <Skeleton height="48px" />
                                            </Box>
                                        ) : (
                                            <input
                                                type={child?.name === 'pcs' ? 'number' : 'text'}
                                                min={0}
                                                onChange={(event) => handleChange({ event, itemForm })}
                                                value={formData?.baselinePerWeek[child?.name] || ''}
                                                placeholder={child.placeholder}
                                                disabled={child.isDisabled}
                                                style={{
                                                    padding: child.rightLabel ? '12px 80px 12px 16px' : '12px 16px',
                                                }}
                                                className={`input-style-add-new-item-MC ${
                                                    child?.isDisabled && 'input-style-add-new-item-MC-disabled'
                                                }`}
                                            />
                                        )}

                                        {child.rightLabel && (
                                            <Text className="right-label-input">
                                                {rightLabelHelper({
                                                    name: child.rightLabel,
                                                    jenis_material: formData?.dataLengkap?.jenis_material,
                                                })}
                                            </Text>
                                        )}
                                    </Box>
                                    {isSubmitted &&
                                        !Boolean(formData?.baselinePerWeek?.pcs?.toString()) &&
                                        child?.name === 'pcs' && (
                                            <Text className="required-field__text">Field required!</Text>
                                        )}
                                    {showErrorMustBeInteger && (
                                        <Text className="required-field__text">Must be integer</Text>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                ))}
            </Box>
        </Flex>
    );
}

export default Index;

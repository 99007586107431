import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import SectionFooter from './sections/sectionFooter';
import Table from './sections/table';
import { head, body } from './const';
import './styles.scss';
import SectionHead from './sections/sectionHead';

function RecapOrder() {
    const [orders, setOrders] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({ date: null });
    
    const handlePageClick = ({ selected }) => setPage(selected + 1);

    return (
        <>
            <Box className="recap_order_container">
                <SectionHead filter={filter} setFilter={setFilter}/>
                <Table tableBody={body} tableHead={head} />
                <SectionFooter
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                    handlePageClick={handlePageClick}
                    count={orders?.length}
                />
            </Box>
        </>
    );
}

export default RecapOrder;

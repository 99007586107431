import { Box, Text } from '@chakra-ui/react';

const CardStatus = ({ title = '', value = '', bg, color }) => (
    <Box experimental_spaceY={'6px'} className="card-status__container">
        <Text className="card-status__title">{title}</Text>
        <Box className="card" bg={bg} color={color}>
            {value}
        </Box>
    </Box>
);

export default CardStatus;

import { Text } from '@chakra-ui/react';

export const tableListOrderConfirmation = [
    {
        name: 'Kode Item',
        value: 'kode_item',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Nama Item',
        value: 'nama_item',
        width: 80,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Qty/Kanban',
        value: 'qty_kanban',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Order Psc',
        value: 'order_psc',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Order Kanban',
        value: 'order_kanban',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Konfirmasi Pcs',
        value: 'konfirmasi_pcs',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Konfirmasi Kanban',
        value: 'konfirmasi_kanban',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Konfirmasi ETA',
        value: 'konfirmasi_eta',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Suggestion',
        value: 'suggestion',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
];

export const objDummyOrderConfirmation = {
    kode_item: '22134432e',
    nama_item: 'Plug Crystallure SR Rich Cream 50 g Wardah',
    qty_kanban: 100,
    order_psc: 100,
    order_kanban: 100,
    konfirmasi_pcs: 100,
    konfirmasi_kanban: 100,
    konfirmasi_eta: '30 Maret 2023',
    suggestion: '100',
};

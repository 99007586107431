import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Button, useToast } from '@chakra-ui/react';

import { SearchIcon } from '@pages/orderPlanning/icons';
import { onDownloadTemplateUpload } from './functions';
import { IconDownload, IconUpload } from './icons';

import './styles.scss';
import DownloadLoader from '@components/downloadLoader';

const SeactionHead = ({ onSearchHandler }) => {
    const [searchKey, setSearchKey] = useState('');
    const toast = useToast();
    const navigate = useNavigate();
    const [loadingState, setLoadingState] = useState({ download: false });

    return (
        <Box className="section-head__container__order-planning">
            {loadingState?.download && <DownloadLoader />}
            <Box className="section-search__container__order-planning">
                <input
                    className="search-input"
                    placeholder="Cari item...."
                    onChange={(e) => {
                        onSearchHandler({ value: e.target.value });
                        setSearchKey(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onSearchHandler({ value: searchKey });
                        }
                    }}
                />
                <Box className="search-icon__container" onClick={() => onSearchHandler({ value: searchKey })}>
                    <SearchIcon />
                </Box>
            </Box>
            <Box className="section-button">
                <Button
                    className="button-container"
                    onClick={() =>
                        onDownloadTemplateUpload({
                            setIsLoading: setLoadingState,
                            toast,
                        })
                    }
                >
                    <IconDownload />
                    <Text className="button-text">Download Template</Text>
                </Button>
                <Box className="button-container" onClick={() => navigate('/order-planning/upload')}>
                    <IconUpload />
                    <Text className="button-text">Upload FIle</Text>
                </Box>
            </Box>
        </Box>
    );
};

export default SeactionHead;

import { Flex, Text, Box } from '@chakra-ui/react';
import { EditIcon, RemoveIcon } from './icons';

export const tableList = [
    {
        name: 'ID',
        value: 'id',
        width: 162,
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: '',
        width: null,
        flex: 1,
        value: 'name',
        component: ({ value }) => (
            <Flex w="100%" justify="flex-start">
                <Text className="column-text" textAlign="left !important">
                    {value}
                </Text>
            </Flex>
        ),
    },
    {
        name: 'ACTION',
        value: null,
        width: 226,
        component: ({ onSelectedAsset, asset, onChangeModalState, accessPermission }) => {
            const canAccess = accessPermission?.daftarZonaLineProduksi?.isCanTimbangLine;

            // 1 : 0.2
            return (
                <Flex>
                    <Box
                        cursor={canAccess ? 'pointer' : 'not-allowed'}
                        opacity={canAccess ? 1 : 0.2}
                        mr="10px"
                        onClick={() => {
                            if (canAccess) {
                                onSelectedAsset({ value: asset });
                                onChangeModalState({ value: 'modal-edit' });
                            }
                        }}
                        border="1px solid #1746A2"
                        borderRadius="4px"
                        p="6px"
                    >
                        <EditIcon />
                    </Box>
                    <Box
                        opacity={canAccess ? 1 : 0.2}
                        cursor={canAccess ? 'pointer' : 'not-allowed'}
                        onClick={() => {

                            if (canAccess) {
                                onSelectedAsset({ value: asset });
                                onChangeModalState({ value: 'modal-delete' });

                            }
                        }}
                        border="1px solid #EF5350"
                        borderRadius="4px"
                        p="6px"
                    >
                        <RemoveIcon />
                    </Box>
                </Flex>
            );
        },
    },
];

export const tabList = [
    { name: 'Zona Streamline', value: 'zona' },
    { name: 'Line Timbang', value: 'timbang' },
    { name: 'LINE PREMIX & OLAH 1   ', value: 'olah1' },
    { name: 'Line Olah 2', value: 'olah2' },
    { name: 'Line Kemas', value: 'kemas' },
];

export const initialValue = {
    name: 'All',
    status: '',
};
export const dummyNoPO = [
    {
        name: 'O000001',
        checked: false,
    },
    {
        name: 'O000002',
        checked: false,
    },
    {
        name: 'O000003',
        checked: false,
    },
    {
        name: 'O000004',
        checked: false,
    },
    {
        name: 'O000005',
        checked: false,
    },
];

import { useContext } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { DemandAnticipationContext } from '@pagesPhase2/demandAnticipation';
import './styles.scss';

const TableColumn = ({ dataItem, demandPerWeek, numbers, index, demandPerMonth }) => {
    const { formData, table } = useContext(DemandAnticipationContext);
    return (
        <Flex className="table-column table-column__container__demand_anticipation">
            {table?.map((item, idx) => {
                return (
                    <Box
                        background={'white'}
                        key={item.idx}
                        w={item.width}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: dataItem[item.value] || '-',
                            id: dataItem['id'],
                            dataItem,
                            formData,
                            demandPerWeek,
                            demandPerMonth,
                            numbers,
                            index,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

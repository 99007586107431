import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { read, utils } from 'xlsx';

const mappingWorksheetToPayload = ({ json }) => {
    const result = json?.map((item) => {
        return {
            odooCode: `${item?.odoo_code}`,
            qty: item?.qty_batch_booking,
        };
    });

    return {
        listData: [...result],
    };
};

export const onChangeFile = async ({ files, setIsLoading, setIsSuccessUpload, setHasUploaded, setErrorData }) => {
    setIsLoading(true);
    setHasUploaded(true);

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    if (files) {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = utils.sheet_to_json(worksheet);

            const arr = mappingWorksheetToPayload({ json });

            try {
                const { data } = await Axios({
                    method: 'POST',
                    url: '/order-planning/upload/batch-booking',
                    data: arr,
                    headers: { Authorization: 'Bearer' + userToken },
                });

                const isSuccessUpload = data?.data?.validationStatus;
                if (!isSuccessUpload) {
                    setErrorData(data?.data?.errorMessage);
                }
                setIsSuccessUpload(isSuccessUpload);
            } catch (error) {
                console.log(error, '<<<<<');
            }
        };
        reader.readAsArrayBuffer(files);
    }
    setIsLoading(false);
};

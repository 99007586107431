import { Modal, ModalOverlay, ModalContent, Box, Button, Flex, Text, Spinner } from '@chakra-ui/react';
import CustomButton from '../button';
import { ConfirmIcon } from './icons';

import './styles.scss';

const Modalsuccess = ({ isOpen, title, onClose, onSubmit, submitLabel = 'text', loading }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderRadius="3.51471px">
                <Box className="modal-confirm-global__container">
                    <ConfirmIcon />
                    <Text className="confirm-title">{title}</Text>

                    <Flex mt="52.8px" gap="14.26px">
                        <Button className="back-button" onClick={onClose} _hover="none">
                            Kembali
                        </Button>
                        <CustomButton
                            btnClassName={loading ? 'disabledbtn' : 'btnClassName'}
                            onClick={loading ? () => null : onSubmit}
                            _hover="none"
                        >
                            {loading ? <Spinner size="sm" /> : submitLabel}
                        </CustomButton>
                    </Flex>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default Modalsuccess;

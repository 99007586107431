import { Box, Text, Button } from '@chakra-ui/react';
import './styles.scss';

const EmptyProblem = ({ onClick, isDisabled }) => {
    return (
        <Box className="empty-problem__container">
            <Text className="title">Terjadi masalah dalam produksi</Text>
            <Text className="desc">
                Jika terjadi masalah dalam produksi, segera lakukan Report problem, agar setiap kendala terdata
            </Text>
            <Button className="report-problem__button" onClick={onClick} isDisabled={isDisabled}>
                Report Problem
            </Button>
        </Box>
    );
};

export default EmptyProblem;

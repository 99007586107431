import { CircularProgress, Flex, Box, Text } from '@chakra-ui/react';

import './styles.scss';

const DownloadLoader = () => (
    <Box className="download-loader__container">
        <Flex className="download-loader__box">
            <CircularProgress isIndeterminate />
            <Text className="label">Menyiapkan data...</Text>
        </Flex>
    </Box>
);

export default DownloadLoader;

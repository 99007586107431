import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import OutsideClickHandler from 'react-outside-click-handler';
import Cookies from 'universal-cookie';
import { Input, Text, Box, Spinner, useToast, Tooltip } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import Axios from '@services/axios';

import { EditDateIcon } from '@pagesPhase2/supplierStock/icons';
import { DemandAnticipationContext } from '@pagesPhase2/demandAnticipation';

const EditableColumn = ({
    currentValue,
    id,
    demand_anticipation_id,
    currentWeek,
    currentYear,
    isAdditional = false,
}) => {
    const { isEdit } = useContext(DemandAnticipationContext);
    const [isFocus, setIsFocus] = useState(false);
    const toast = useToast();
    const [value, setValue] = useState(currentValue);
    const [updatedValue, setUpdatedValue] = useState(currentValue);
    const [hasUpdated, setHasUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (e) => {
        setValue(e.target.value);
    };
    const onSubmit = async () => {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        let data = {
            demand_per_weeks: [
                {
                    week_n: Number(currentWeek),
                    year: Number(currentYear),
                    demand: Number(value),
                },
            ],
        };
        if (isAdditional) data = { additional_order: Number(value) };
        try {
            if (value !== updatedValue) {
                setIsLoading(true);
                setHasUpdated(false);

                await Axios({
                    method: 'PATCH',
                    url: `/demand-anticipation/${demand_anticipation_id}`,
                    headers: { Authorization: 'Bearer ' + userToken },
                    data: { ...data, material_config_id: id },
                }).then(() => {
                    toast({
                        position: 'center-top',
                        description: 'Berhasil Meng-Update Data',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                });
                setUpdatedValue(value);
                setHasUpdated(true);
            }
        } catch (error) {
            toast({
                title: 'Error',
                description:
                    error?.response?.data?.errors?.map((item) => item?.message)?.join() ||
                    error?.response?.data?.errors?.join() ||
                    'Failed',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-center',
            });
        } finally {
            setIsLoading(false);
            setIsFocus(false);
        }
    };

    const onClickOutside = () => {
        if (isFocus && value !== updatedValue) {
            onSubmit();
        }

        if (isFocus) {
            setIsFocus(false);
        }
    };

    return (
        <>
            {isLoading && <Spinner color="blue" size="sm" />}
            {!isLoading && isFocus && (
                <OutsideClickHandler onOutsideClick={onClickOutside}>
                    <Box position="relative">
                        <Input
                            value={value}
                            onChange={onChange}
                            className="column-editable"
                            fontSize={12}
                            border="1px solid #3e5ba6"
                        />
                        <CloseIcon
                            cursor="pointer"
                            color="red"
                            position="absolute"
                            right={'30px'}
                            top="14px"
                            zIndex={3}
                            fontSize={10}
                            onClick={() => setIsFocus(false)}
                        />
                        <CheckIcon
                            cursor="pointer"
                            color="green"
                            position="absolute"
                            zIndex={3}
                            right={'8px'}
                            top="12px"
                            fontSize={14}
                            onClick={onSubmit}
                        />
                    </Box>
                </OutsideClickHandler>
            )}
            {!isLoading && !isFocus && (
                <Tooltip
                    hasArrow
                    label={isEdit ? 'Click the numbers to edit' : ''}
                    color={'#2D2D2D'}
                    p="11px"
                    fontWeight={400}
                    bg="#F8ED8E"
                    rounded={'8px'}
                    fontSize="12px"
                    w="235px"
                    lineHeight={'18px'}
                >
                    <Box
                        cursor={Boolean(demand_anticipation_id) && isEdit ? 'pointer' : 'default'}
                        onClick={() => {
                            if (!Boolean(demand_anticipation_id) || !isEdit) return;
                            setIsFocus(true);
                        }}
                        display="flex"
                        alignItems="center"
                    >
                        <Text className="column-text" width="100%" color={hasUpdated ? 'green !important' : 'black'}>
                            {updatedValue}
                        </Text>
                        {Boolean(demand_anticipation_id) && isEdit && (
                            <Box ml={2}>
                                <EditDateIcon size="12" />
                            </Box>
                        )}
                    </Box>
                </Tooltip>
            )}
        </>
    );
};

export default EditableColumn;

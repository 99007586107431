import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import SectionHead from './sections/sectionHead';
import SectionTable from './sections/sectionTable';
import SectionFooter from './sections/sectionFooter';
import CreateAndEditModal from './components/createAndEditScreen';
import { fetchSkuHandler, onExportToExcel } from './function';
import TableSkeleton from '@components/tableSkeleton';
import { tableList } from './const';
import { numToArr } from '@helpers/functions';
import EmptyState from '@components/emptyState';

import './styles.scss';
import DeleteModal from './components/deleteModal';

export const skuConfigurationContext = createContext();

const SkuConfiguration = () => {
    const { selectedMaterial } = useSelector((reducer) => reducer.materialSlice);

    const [isEditModal, setIsEditModal] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowEditSkuModal, setIsShowEditSkuModal] = useState(null);
    const [selectedSku, setSelectedSku] = useState({});
    const [selectedSkuExport, setSelectedSkuExport] = useState([]);

    const [totalPage, setTotalPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [sortName, setSortName] = useState({ sort: 'nama_item', typeBol: true });
    const [sku, setSku] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [isCheckBox, setIsCheckBox] = useState(false);

    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const onSelectedSku = ({ value }) => setSelectedSku(value);

    const reFecthSkuHandler = () => {
        fetchSkuHandler({
            selectedMaterial,
            setSku,
            sortName,
            setIsLoading,
            setTotalData,
            setTotalPage,
            page,
            searchQuery,
        });
    };

    const onSearchHandler = ({ value }) => setSearchQuery(value);
    const onChangeModalState = ({ value, isEdit }) => {
        setIsShowEditSkuModal(value);
        setIsEditModal(isEdit);
    };

    const onSelectedSkuExport = ({ value, isRemove = false }) => {
        if (isRemove) {
            const newSelectedSkuExport = selectedSkuExport.filter((item) => item !== value);
            setSelectedSkuExport(newSelectedSkuExport);
            return;
        }

        setSelectedSkuExport((v) => [...v, value]);
    };

    const onSkuExportData = () => {
        if (isCheckBox) {
            onExportToExcel({ selectedData: selectedSkuExport });
        } else {
            onExportToExcel({ selectedData: sku });
        }
    };

    useEffect(() => {
        if (!selectedSkuExport.length) {
            setIsCheckBox(false);
        } else {
            setIsCheckBox(true);
        }
    }, [selectedSkuExport]);

    useEffect(() => {
        if (selectedMaterial?.id) {
            fetchSkuHandler({
                selectedMaterial,
                setSku,
                sortName,
                setIsLoading,
                setTotalData,
                setTotalPage,
                page,
                searchQuery,
            });
        }
    }, [page, searchQuery, selectedMaterial, sortName]);

    return (
        <skuConfigurationContext.Provider
            value={{
                sku,
                onSelectedSku,
                selectedSku,
                onChangeModalState,
                onSelectedSkuExport,
                setIsShowDelete,
                isShowDelete,
            }}
        >
            <Box className="sku-management__container">
                <Box className="sku-configuration__container">
                    <SectionHead
                        onChangeModalState={onChangeModalState}
                        onSkuExportData={onSkuExportData}
                        onSearchHandler={onSearchHandler}
                    />
                    {isLoading && (
                        <TableSkeleton
                            tableList={tableList}
                            count={numToArr(10)}
                            tableHeadHeight="50px"
                            tablColumnHeight="70px"
                        />
                    )}
                    {!isLoading && <SectionTable setSortName={setSortName} skus={sku} />}
                    {!isLoading && !sku.length && (
                        <EmptyState
                            description="Maaf, tidak ada data yang bisa ditamplikan, silakan memilih kategori yang lain."
                            text="Tidak ada data yg di tampilkan"
                        />
                    )}

                    <SectionFooter
                        page={page}
                        totalData={totalData}
                        totalPage={totalPage}
                        handlePageClick={handlePageClick}
                        count={sku.length}
                    />
                </Box>

                {/* ------ MODAL ------- */}
                {isShowEditSkuModal === 'modal-create' && (
                    <CreateAndEditModal
                        isOpen={isShowEditSkuModal === 'modal-create'}
                        reFecthSkuHandler={reFecthSkuHandler}
                        selectedSku={selectedSku}
                    />
                )}

                {isShowEditSkuModal === 'modal-edit' && (
                    <CreateAndEditModal
                        isOpen={isShowEditSkuModal === 'modal-edit'}
                        isEditModal={isEditModal}
                        reFecthSkuHandler={reFecthSkuHandler}
                        selectedSku={selectedSku}
                    />
                )}

                <DeleteModal isOpen={isShowDelete} reFecthSkuHandler={reFecthSkuHandler} />
            </Box>
        </skuConfigurationContext.Provider>
    );
};

export default SkuConfiguration;

import { Menu, MenuButton, MenuList, MenuItem, Button, Box } from '@chakra-ui/react';
import { ArrowRightIcon } from '@asset/icons';
import './styles.scss';

export const EmptyField = ({ disabledWidthBox, disabledHeightBox, buttonName, className }) => {
    return (
        <Box width={disabledWidthBox} height={disabledHeightBox} className={`root-disabled-menu-list ${className}`}>
            {buttonName}
        </Box>
    );
};
const FieldMenu = ({
    icon,
    onClick,
    buttonName,
    menuList,
    keyName,
    menuClassName,
    menuButtonClassName,
    menuListClassName,
    isDisabled,
    disabledWidthBox = '500px',
    disabledHeightBox = '48px',
    withEntity = false,
    flip = false,
    stylePropsMenuButtonClassName,
    menuItemClassNam,
    customMenuItem = false,
    isLoading = false,
}) => {
    return (
        <Menu flip={flip} className={`field-menu__container ${menuClassName}`}>
            {isDisabled ? (
                <EmptyField
                    buttonName={buttonName}
                    disabledWidthBox={disabledWidthBox}
                    disabledHeightBox={disabledHeightBox}
                />
            ) : (
                <MenuButton
                    className={`field-menu-button__container ${menuButtonClassName}`}
                    sx={{ ...stylePropsMenuButtonClassName }}
                    bg="none"
                    as={Button}
                    rightIcon={icon || <ArrowRightIcon />}
                >
                    {buttonName}
                </MenuButton>
            )}

            <MenuList className={`field-menu-list__container ${menuListClassName}`}>
                {isLoading ? (
                    <Box sx={{ height: '32px', textAlign: 'center' }}>Loading</Box>
                ) : (
                    customMenuItem ||
                    menuList?.map((menu, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => onClick(menu)}
                            className={`field-menu-item__container ${menuItemClassNam}`}
                        >
                            {menu[keyName]}
                            {withEntity && (
                                <span className="entitas-text">{menu?.name ? '- ' + menu?.name : null}</span>
                            )}
                        </MenuItem>
                    ))
                )}
            </MenuList>
        </Menu>
    );
};

export default FieldMenu;

import { InputGroup, InputRightAddon } from '@chakra-ui/input';
import './styles.scss';

const InputGroups = ({ text = '', disable = true, rightText = '', ...rest }) => {
    return (
        <InputGroup w={'500px'}>
            <input
                {...rest}
                style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', borderRight: '0px' }}
            />
            <InputRightAddon
                children={rightText || text}
                height={'38px'}
                minW={'116px'}
                maxW={'fit-content'}
                bg={'#F4F4F4'}
                color={'#959595'}
                fontSize={'14px'}
                className={!disable && 'input-enable'}
                textAlign={'left'}
            />
        </InputGroup>
    );
};

export default InputGroups;

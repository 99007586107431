import { DownloadBlackIcon } from '@asset/icons';
import { Box, Button, CircularProgress, Flex, Text, useToast, Spinner } from '@chakra-ui/react';
import CustomButton from '@components/button';
import ModalFormat from '@components/modalFormat';
import ModalSuccess from '@components/modalSuccess';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { onChangeFile, onDownloadTemplateRACI } from './functions';
import { IconBack, IconFile, UploadIconSm } from './icons';
import ModalFailedScroll from '@components/modalFailedScroll';
import './styles.scss';

const Index = ({ setSection }) => {
    const toast = useToast();
    const [state, setState] = useState('upload');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [onRange, setOnrange] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [submitError, setSubmitError] = useState({ open: false, message: '' });

    /// https://stackoverflow.com/a/53090848
    const handleUpload = ({ files }) => {
        setState('loading');
        onChangeFile({ files, setState, setOpenModalSuccess, toast, setSubmitError });
    };
    const handleCancelLoading = () => {
        setState('upload');
    };
    return (
        <Box className="section-upload-raci__container__material-configuration">
            <ModalSuccess
                title="Berhasil Mengupload Raci"
                isOpen={openModalSuccess}
                labelButton="Kembali"
                onClose={() => {
                    setOpenModalSuccess(false);
                    setState('upload');
                }}
            />
            <Flex className="head">
                <Flex
                    onClick={() =>
                        setSection((prev) => {
                            return 'main';
                        })
                    }
                    className="left-section"
                >
                    <IconBack />
                    <Text className="title-label">Upload RACI</Text>
                </Flex>
                {!state?.includes('loading') && (
                    <Flex className="right-section" onClick={() => onDownloadTemplateRACI({ toast, setIsLoading })}>
                        <Box>{isLoading ? <Spinner size="sm" /> : <DownloadBlackIcon color="#1746A2" />}</Box>
                        <Text className="title-download">
                            {isLoading ? 'Downloading File...' : 'Download Template'}
                        </Text>
                    </Flex>
                )}
            </Flex>
            <Box>
                {state?.includes('loading') && (
                    <Box className="parent">
                        <Box className="child">
                            <CircularProgress size="70px" isIndeterminate color="#1746a2" />
                            <Text className="text-loading">Uploading File.....</Text>
                            <CustomButton onClick={handleCancelLoading} btnClassName="cancel-button" variant="outline">
                                Cancel
                            </CustomButton>
                        </Box>
                    </Box>
                )}
                {state?.includes('upload') && (
                    <Box className="body">
                        <Flex className="section-body">
                            <Flex className="upload-box">
                                <Box className="parent-line-vertical">
                                    {Array(11)
                                        .fill(1)
                                        .map((item, index) => (
                                            <Box key={index} className="child-line-vertical" />
                                        ))}
                                </Box>
                                <Box className="parent-line-horizon">
                                    {Array(8)
                                        .fill(1)
                                        .map((item, index) => (
                                            <Box key={index} className="child-line-horizon" />
                                        ))}
                                </Box>
                                <FileUploader
                                    name="file"
                                    onDraggingStateChange={(dragging) => setOnrange(dragging)}
                                    handleChange={(files) => handleUpload({ files })}
                                    classes="file-uploader__drag-and-drop-only"
                                    types={['xlsx', 'csv']}
                                    onTypeError={(err) => setError(true)}
                                    disabled
                                />
                                <Box opacity={!onRange ? 0 : 10} className="after-drop">
                                    <Text className="after-drop-title">" Letakan Disini "</Text>
                                </Box>
                                <Box opacity={onRange ? 0 : 10} className="before-drop">
                                    <IconFile />
                                    <Text className="upload-text">
                                        Drag and Drop atau pilih file CSV atau Exel untuk di upload
                                    </Text>
                                    <Button className="upload-button">
                                        <FileUploader
                                            handleChange={(files) => handleUpload({ files })}
                                            name="file"
                                            classes="file-uploader"
                                            types={['xlsx', 'csv']}
                                        />
                                        <UploadIconSm />
                                        Upload File
                                    </Button>
                                </Box>
                            </Flex>
                        </Flex>
                    </Box>
                )}
            </Box>
            {error && (
                <ModalFormat
                    isOpen={error}
                    onClose={() => setError(false)}
                    title="Format File salah"
                    description="Maaf, format file yang Anda masukkan tidak sesuai. 
                    Mohon pastikan menggunakan format yang benar."
                />
            )}

            <ModalFailedScroll
                isOpen={submitError.open}
                onClose={() => {
                    setSubmitError((prev) => ({ ...prev, open: false }));
                }}
                onRightBtnClick={() => setSubmitError((prev) => ({ ...prev, open: false }))}
                onLeftBtnClick={() => {
                    setSubmitError({ open: false, message: '' });
                    setState('upload');
                }}
                title="Uplod file gagal"
                classNameDescription="description"
                description={submitError.message}
                leftLabelButton="Upload Ulang"
                rightLabelButton="Kembali"
            />
        </Box>
    );
};

export default Index;

export const DeleteIcon = () => (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="75" cy="75" r="75" fill="#FEA9A7" />
        <path
            d="M53.125 54.5835V104.167H96.875V54.5835H53.125Z"
            stroke="#2D2D2D"
            stroke-width="5"
            stroke-linejoin="round"
        />
        <path
            d="M69.1667 69.1665V88.1248"
            stroke="#2D2D2D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M80.8333 69.1665V88.1248"
            stroke="#2D2D2D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M45.8333 54.5835H104.167"
            stroke="#2D2D2D"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M63.3333 54.5835L68.1298 45.8335H81.9666L86.6667 54.5835H63.3333Z"
            stroke="#2D2D2D"
            stroke-width="5"
            stroke-linejoin="round"
        />
    </svg>
);

import { useState } from 'react';
import { Box, Text, Flex, IconButton } from '@chakra-ui/react';
import { RightArrowIcon, LeftArrowIcon } from '@pagesPhase2/materialConfiguration/icons';
import ReactPaginate from 'react-paginate';
import FieldMenu from '@components/fieldMenu';
import './styles.scss';

const SectionFooter = ({
    totalData,
    totalPage,
    onPageChange,
    page,
    selectedPerPage,
    setSelectedPerpage,
    labelOrder,
    labelPallet,
    labelKoli,
    labelPcs,
    valuePallet,
    valueKoli,
    valuePcs,
}) => {
    return (
        <Box className="pagination-container-style">
            <Flex alignItems="center" gap="13px">
                <Text className="label">Item per page</Text>
                <FieldMenu
                    flip
                    buttonName={selectedPerPage || 10}
                    menuList={[{ num: 10 }, { num: 20 }, { num: 25 }, { num: 50 }, { num: 100 }]}
                    keyName="num"
                    onClick={(item) => setSelectedPerpage(item?.num)}
                    menuButtonClassName="menu-button"
                    menuItemClassNam="menu-item"
                    menuListClassName="menuList-container"
                    icon={
                        <Box
                            sx={{
                                mr: '-18px',
                            }}
                        >
                            <RightArrowIcon />
                        </Box>
                    }
                />
                <Text className="label">
                    1 - {Math.ceil(totalData / selectedPerPage)} of {totalData} items
                </Text>
            </Flex>
            <Flex className="pagination-material-configuration__container">
                <Box className="wrapper-label-text">
                    <Text className="label-text-order">{labelOrder}</Text>
                    <Text className="pallet-text">
                        {labelPallet} <span className="label-value">{valuePallet}</span>
                    </Text>
                    <Text className="koli-text">
                        {labelKoli} <span className="label-value">{valueKoli}</span>
                    </Text>
                    <Text className="pcs-text">
                        {labelPcs} <span className="label-value">{valuePcs}</span>
                    </Text>
                </Box>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={<RightArrowIcon />}
                    onPageChange={onPageChange}
                    pageRangeDisplayed={1}
                    pageCount={totalPage}
                    forcePage={page - 1}
                    previousLabel={<LeftArrowIcon color={'#6B6B6B'} />}
                />
            </Flex>
        </Box>
    );
};

export default SectionFooter;

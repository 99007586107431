export const ChevronDownIcon = () => (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0L7 8L14 0H0Z" fill="#2D2D2D" />
    </svg>
);

export const RefreshIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            width="24"
            height="24"
            rx="12"
            fill="#1746A2"
        />
        <path
            d="M18 11.2486C17.8166 9.92629 17.2043 8.70108 16.2575 7.76171C15.3107 6.82233 14.0818 6.22089 12.7603 6.05005C11.4387 5.87921 10.0977 6.14843 8.9439 6.81625C7.79009 7.48407 6.88744 8.51344 6.375 9.74579M6 6.74018V9.74579H9M6 12.7514C6.18342 14.0737 6.7957 15.2989 7.74252 16.2383C8.68934 17.1777 9.91818 17.7791 11.2397 17.9499C12.5613 18.1208 13.9023 17.8516 15.0561 17.1837C16.2099 16.5159 17.1126 15.4866 17.625 14.2542M18 17.2598V14.2542H15"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const FullScreenIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            width="24"
            height="24"
            rx="12"
            fill="#1746A2"
        />
        <path
            d="M6 6L9.33333 9.3M6 6V9M6 6H9M6 17.9667L9.33333 14.6667M18 17.9667L14.7 14.6667M17.9667 6L14.6667 9.3M15 6H18V9M18 15V18H15M9 18H6V15"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


export const MinimazeScreenIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#1746A2" />
        {/* <circle cx="12" cy="12" r="12" fill="#1746A2" /> */}
        <path d="M14.4531 9.6927L17.4531 6.6927" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M17.2795 9.7993L14.4531 9.69271L14.5597 6.8663"
            stroke="white"
            strokeWidth="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M8.88035 14.6438L5.8335 17.5962" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M6.05597 14.4927L8.88036 14.6438L8.72931 17.4682"
            stroke="white"
            strokeWidth="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M14.4211 14.6405L17.6771 17.3606" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M14.5674 17.4652L14.4211 14.6405L17.2457 14.4942"
            stroke="white"
            strokeWidth="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M9.81738 9.73607L6.65174 6.91141" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M9.76318 6.90817L9.81739 9.73608L6.98949 9.7903"
            stroke="white"
            strokeWidth="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
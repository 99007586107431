import { Modal, ModalOverlay, ModalContent, Box, Button, Text, Flex } from '@chakra-ui/react';
import { DeleteIcon } from './icons';

import './styles.scss';

const ModalDelete = ({ isOpen, title, onClose, descriptionComponent, onSubmit, isLoading }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <Box className="modal-delete-global__container">
                    <DeleteIcon />
                    <Text className="delete-title">{title}</Text>
                    <Box className="delete-description">{descriptionComponent}</Box>
                    <Flex className="button-container">
                        <Button className="delete-cancel" onClick={onClose} _hover="none">
                            Cancel
                        </Button>
                        <Button
                            className="delete-button"
                            onClick={onSubmit}
                            isLoading={isLoading}
                            loadingText="Deleting"
                            _hover="none"
                        >
                            Delete
                        </Button>
                    </Flex>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ModalDelete;

import { Flex, Box, useToast, Input } from '@chakra-ui/react';
import { NotesIcon } from '../../icons';
import React from 'react';
import CreateModalEditNotes from '../createModalEditNotes';
import { isAllowed } from '@helpers/roleAccess';
import { DemandAnticipationContext } from '@pagesPhase2/demandAnticipation';
import { saveNoteHandler } from './function';

import './styles.scss';

function Index({ dataItem }) {
    const toast = useToast();
    const { setEnable } = React.useContext(DemandAnticipationContext);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(dataItem.notes);
    const [valueAfterSuccessUpdate, setValueAfterSuccessUpdate] = React.useState(dataItem.notes);
    const onCloseNotes = () => {
        setModalOpen(false);
        setEnable(false);
        setValue(dataItem.notes);
    };
    const handleSaveNote = () => {
        if (loading) return;
        saveNoteHandler({
            toast,
            notes: value,
            dataItem,
            setLoading,
            setModalOpen,
            setEnable,
            setValueAfterSuccessUpdate,
            prevNotes: dataItem?.notes,
            setValue,
        });
    };
    const handleClearNotes = () => {
        if (loading) return;
        setValue('');
    };
    const handleChangeNotes = (i) => {
        if (loading) return;
        setValue(i.target.value);
    };
    return (
        <Box className="container-column-notes-demand-anticipation">
            <CreateModalEditNotes
                isLoadingSubmit={loading}
                value={value}
                isOpen={modalOpen}
                onChange={(i) => handleChangeNotes(i)}
                onEditNotes={() => setEnable(true)}
                onClose={onCloseNotes}
                onSaveNotes={handleSaveNote}
                onClearNotes={handleClearNotes}
            />
            <Flex justifyContent="space-between" alignItems="center">
                <Input
                    readOnly
                    className="input-text"
                    value={valueAfterSuccessUpdate || '-'}
                    placeholder={'Isi notes......'}
                    size="xs"
                />
                {Boolean(dataItem?.demand_anticipation_id) &&
                    isAllowed({
                        pageName: 'Demand Anticipation',
                        permissionName: 'Edit Demand Anticipation',
                    }) && (
                        <Box
                            cursor="pointer"
                            ml="14px"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            <NotesIcon />
                        </Box>
                    )}
            </Flex>
        </Box>
    );
}

export default Index;

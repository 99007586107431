import { useState, createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import SectionHead from './section/sectionHead';
import SectionTable from './section/sectionTable';
import Pagination from '@components/pagination';
import TableSkeleton from '@components/tableSkeleton';
import EmptyState from '@components/emptyState';
import { tableList } from './const';
import { FormatTableNumber, numToArr } from '@helpers/functions';
import { fetchDataDemandAnticipation } from './function';
import './styles.scss';

export const DemandAnticipationContext = createContext();

const DemandAnticipation = () => {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [filter, setFilter] = useState({ itemCode: [], trade_name: [], sortBy: '', status: '' });
    const [items, setItems] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [table, setTable] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [formData, setFormData] = useState({ notes: '', status_id: '' });
    const [enable, setEnable] = useState(false);

    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const onChangeFilterStatus = ({ key, value }) => {
        setFilter((prev) => ({ ...prev, [key]: value }));
    };

    const numbers = FormatTableNumber({ page });
    useEffect(() => {
        if (Boolean(supplier?.length !== 0) || jenisMaterial?.id) {
            setPage(1);
        }
    }, [supplier?.length, jenisMaterial?.id]);
    useEffect(() => {
        if (jenisMaterial?.id) {
            fetchDataDemandAnticipation({
                filter,
                page,
                setItems,
                setTotalData,
                setTotalPage,
                setIsLoading,
                selectedPerPage,
                setTable,
                jenisMaterial,
                supplier,
            });
        }
    }, [page, selectedPerPage, filter, refetch, jenisMaterial, supplier]);

    return (
        <DemandAnticipationContext.Provider
            value={{
                filter,
                setFilter,
                open,
                setOpen,
                formData,
                setFormData,
                enable,
                setEnable,
                refetch,
                setRefetch,
                table,
                items,
                onChangeFilterStatus,
                isEdit,
                setIsEdit,
                setPage,
            }}
        >
            <Box className="demand-anticipation__container">
                <SectionHead />
                {isLoading && (
                    <TableSkeleton
                        tableList={tableList}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                )}
                {!isLoading && items?.length > 0 && <SectionTable items={items} numbers={numbers} />}
                {!isLoading && !items.length && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}
                <Pagination
                    page={page}
                    totalPage={totalPage}
                    totalData={totalData}
                    selectedPerPage={selectedPerPage}
                    setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                    onPageChange={handlePageClick}
                />
            </Box>
        </DemandAnticipationContext.Provider>
    );
};

export default DemandAnticipation;

import { Flex } from '@chakra-ui/react';
import { useContext } from 'react';
import { SectionProductionStatusContext } from '@pages/mainDashboard/section/sectionProductionStatus';
import './styles.scss';

const TableColumn = ({ tableList, obj, idx, streamlineColor }) => {
    const { onSelectedDataProductStatus, setOpenDetail } = useContext(SectionProductionStatusContext);

    return (
        <Flex className="table-column__container__main-dashboard" id={`table-column__container__main-dashboard_${idx}`}>
            {tableList.map((item) => {
                console.log(idx, '<<< ke render');
                return (
                    <Flex
                        left={0}
                        position={item.isSticky ? 'sticky' : 'static'}
                        h="max-content"
                        flex={item.flex}
                        key={item.id}
                        w={item.width}
                        mr="20px"
                        flexDirection="column"
                        gap="10px"
                    >
                        {item.component?.({
                            idx,
                            value: obj[item.value],
                            onSelectedDataProductStatus,
                            setOpenDetail,
                            streamlineColor,
                            streamlineName: item?.name,
                        })}
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

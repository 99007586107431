import { Flex, Box } from '@chakra-ui/react';
import { useContext } from 'react';
import { UserManagementContext } from '@pages/userManagement';

import { changeTableListHandler, tableList } from '../../const';
import './styles.scss';

const TableColumn = ({ idx, user }) => {
    const { onSelectedUser, togglerDeleteUserModal, togglerEditUserModal } = useContext(UserManagementContext);

    const onEditHandler = () => {
        onSelectedUser({ value: user });
        togglerEditUserModal();
    };

    const onDeleteHandle = () => {
        onSelectedUser({ value: user });
        togglerDeleteUserModal();
    };

    return (
        <Flex className="table-column-sticky table-column__container__user-management">
            {changeTableListHandler()?.map((item) => {
                return (
                    <Box flex={item.flex} key={item.name} w={item.width} className="column-sticky__item">
                        {item.component({ 
                            onDeleteHandle, 
                            onEditHandler, 
                            user, 
                            value: user[item.value] || '-' })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

import { useSelector } from 'react-redux';
import { useState, createContext, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import SectionTable from '../sectionOrderDelivery/sections/sectionTable';
import SectionHead from '../sectionOrderDelivery/sections/sectionHead';
import TableSkeleton from '@components/tableSkeleton';
import CreateModalEditNotes from './components/modalEditNotes';
import Pagination from '@components/pagination';
import ModalSuccess from '@components/modalSuccess';
import EmptyState from '@components/emptyState';
import { tableListOrderDelivery } from './const';
import { fetchDataOrderDelivery } from './function';
import { numToArr } from '@helpers/functions';
import './styles.scss';

export const tableOrderDeliveryContext = createContext();

const SectionOrderDelivery = () => {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [filter, setFilter] = useState({ itemCode: [], trade_name: [], inputDate: ''  });
    const [orderDelivery, setOrderDelivery] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({ notes: '' });
    const [enable, setEnable] = useState(false);
    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const openModalEdit = ({ value, note }) => {
        setIsOpen(value);
        setFormData((prev) => ({ ...prev, notes: note }));
    };
    const onChangeNotes = (e) => {
        setFormData((prev) => ({ ...prev, notes: e.target.value }));
    };
    const onClearNotes = () => setFormData({ notes: '' });
    const onEditNotes = () => setEnable(true);
    const onSaveNotes = () => setIsOpen(false);
    const onCloseNotes = () => {
        setEnable(false);
        setIsOpen(false);
    };

    const reFetchDataOrderDelivery = () => {
        fetchDataOrderDelivery({
            setOrderDelivery,
            setIsLoading,
            setTotalData,
            setTotalPage,
            selectedPerPage,
            jenisMaterial,
            supplier,
            setPage,
            filter,
            page,
        });
    }

    useEffect(() => {
        fetchDataOrderDelivery({
            setOrderDelivery,
            setIsLoading,
            setTotalData,
            setTotalPage,
            selectedPerPage,
            jenisMaterial,
            supplier,
            setPage,
            filter,
            page,
        });
    }, [jenisMaterial, supplier, selectedPerPage, page, filter]);
    return (
        <tableOrderDeliveryContext.Provider
            value={{
                enable,
                totalData,
                openModalEdit,
                filter,
                setFilter,
                isEdit,
                setIsEdit,
                reFetchDataOrderDelivery,
            }}
        >
            <Box className="section-table-order-delivery__configuration">
                <SectionHead />
                {!isLoading && <SectionTable orderDelivery={orderDelivery} />}
                {!isLoading && !orderDelivery.length && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}
                {isLoading && (
                    <TableSkeleton
                        tableList={tableListOrderDelivery}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                )}
                <CreateModalEditNotes
                    value={formData.notes}
                    isOpen={isOpen}
                    onChange={onChangeNotes}
                    onClose={onCloseNotes}
                    onClearNotes={onClearNotes}
                    onEditNotes={onEditNotes}
                    onSaveNotes={onSaveNotes}
                />
                <Pagination
                    page={page}
                    totalPage={totalPage}
                    totalData={totalData}
                    selectedPerPage={selectedPerPage}
                    setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                    onPageChange={handlePageClick}
                />
                <ModalSuccess
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    title="File Berhasil Diupload"
                    labelButton="Kembali"
                />
            </Box>
        </tableOrderDeliveryContext.Provider>
    );
};

export default SectionOrderDelivery;

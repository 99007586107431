import React from 'react';
import { Box, Button, Text, useToast } from '@chakra-ui/react';

import FieldInput from '../fieldInput';
import { onChangeFormData, onSubmitForm } from './function';
import { ArrowDownIcon } from '@asset/icons';
import FieldMenu from '@components/fieldMenu';
import ModalTemplate from '@components/modalTemplate';
import Modalsuccess from '@components/modalSuccess';

import { formDefaultValue, textModalCreateOrderPlanning, dummyStatus } from './const';

import './styles.scss';

const ModalCreateConfirmMaterial = ({ onClose, isOpen, selectedOrderPlanning, reFetchOrderPlanningHandler }) => {
    const tTitle = textModalCreateOrderPlanning;
    const toast = useToast();
    const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [formData, setFormData] = React.useState(formDefaultValue);
    const [isSuccessSubmit, setIsSuccessSubmit] = React.useState(false);

    const onCloseSuccessSubmit = () => {
        setIsSuccessSubmit(false);
    };

    const onResetForm = () => {
        setFormData(formDefaultValue);
        setIsSubmitted(false);
    };

    return (
        <>
            <ModalTemplate width={543} isOpen={isOpen && !isSuccessSubmit} onClose={onClose}>
                <Box className="create-modal__container__orderplanning">
                    <Box className="section-head__container">
                        <Text className="head-title__container">{tTitle.header}</Text>
                    </Box>
                    <Box className="section-body__container">
                        <Box className="section-card_container">
                            <Box className="section-card-input_container">
                                <Text className="text-semi__container">{tTitle.title_one}</Text>

                                {/* FIELD SELECT STATUS 1 */}
                                <Box className="modal-item__container" experimental_spaceY={4}>
                                    <Text className="label">Pilih Status</Text>

                                    <Box flex={1}>
                                        <FieldMenu
                                            onClick={(value) =>
                                                onChangeFormData({
                                                    type: 'raw_material_status',
                                                    value: value.label,
                                                    setFormData,
                                                    formData,
                                                })
                                            }
                                            buttonName={formData?.raw_material_status || 'Select Status'}
                                            menuList={dummyStatus}
                                            keyName="label"
                                            menuClassName="menu-container__user-management"
                                            menuButtonClassName="menu-button"
                                            icon={<ArrowDownIcon />}
                                        />
                                        {isSubmitted && !formData?.raw_material_status && (
                                            <Text className="required-field__text">Field status required!</Text>
                                        )}
                                    </Box>
                                </Box>

                                {/* FIELD INPUT NOTES 1 */}
                                <FieldInput
                                    formData={formData}
                                    setFormData={setFormData}
                                    keyWord={'raw_material_note'}
                                    title={'Notes'}
                                    isSubmitted={isSubmitted}
                                    onChangeFormData={onChangeFormData}
                                />
                            </Box>
                        </Box>
                        {/* FIELD SELECT STATUS 2 */}
                        <Box className="section-card-input_container">
                            <Text className="text-semi__container">{tTitle.title_two}</Text>

                            <Box className="modal-item__container" experimental_spaceY={2}>
                                <Text className="label">Pilih Status</Text>
                                <Box flex={1}>
                                    <FieldMenu
                                        onClick={(value) =>
                                            onChangeFormData({
                                                type: 'raw_packaging_status',
                                                value: value.label,
                                                setFormData,
                                                formData,
                                            })
                                        }
                                        buttonName={formData?.raw_packaging_status || 'Select Status'}
                                        menuList={dummyStatus}
                                        keyName="label"
                                        menuClassName="menu-container__user-management"
                                        menuButtonClassName="menu-button"
                                        icon={<ArrowDownIcon />}
                                    />
                                    {isSubmitted && !formData?.raw_packaging_status && (
                                        <Text className="required-field__text">Field status required!</Text>
                                    )}
                                </Box>
                            </Box>
                            {/* FIELD INPUT NOTES 2 */}
                            <FieldInput
                                formData={formData}
                                setFormData={setFormData}
                                keyWord={'raw_packaging_note'}
                                title={'Notes'}
                                isSubmitted={isSubmitted}
                                onChangeFormData={onChangeFormData}
                            />
                        </Box>
                    </Box>

                    <Box className="section-footer__container">
                        <Button
                            border={isSubmitLoading ? '1px solid grey' : 'none'}
                            isLoading={isSubmitLoading}
                            onClick={() => {
                                onSubmitForm({
                                    formData,
                                    toast,
                                    setIsSubmitted,
                                    onClose,
                                    setIsSubmitLoading,
                                    onResetForm,
                                    setIsSuccessSubmit,
                                    selectedOrderPlanning,
                                    reFetchOrderPlanningHandler,
                                });
                            }}
                            className="button-add__container"
                        >
                            Save
                        </Button>
                        <Button border={'1px solid grey'} onClick={onClose} className="button-close__container">
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </ModalTemplate>
            <Modalsuccess
                isOpen={isSuccessSubmit}
                onClose={onCloseSuccessSubmit}
                title={'Sukses Mengupdate'}
                description={`Status ketersediaan material, berhasil diupdate, kembali 
            ke table, untuk melihat status`}
            />
        </>
    );
};

export default ModalCreateConfirmMaterial;

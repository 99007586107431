import { Box, Flex } from '@chakra-ui/react';
import TableHead from '../../components/tableHead';
import TableColumn from '../../components/tableColumn';
import './styles.scss';

const SectionTable = ({ items }) => {
    return (
        <>
            <Flex
                className={`section-table-order_confirmation section-table__container__table-order-confirmation ${
                    items.length <= 0 && 'hide-scrollbar-section-table__container__table-order-confirmation'
                }`}
            >
                <Box className="section-table">
                    <TableHead />
                    <Box className="columns__container" bg="white">
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                            <TableColumn key={i} />
                        ))}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;

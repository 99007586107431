import { useEffect, useState } from 'react';
import { Flex, Box } from '@chakra-ui/layout';
import StreamlineHead from './components/streamlineHead';
import { useSelector } from 'react-redux';

import EmptyState from '@components/emptyState';
import ListCardOrderStatus from './components/listCardOrderStatus';
import { fetchOrderByStreamline } from './functions';
import SkeletonOrderStatus from './components/skeleton';

import './styles.scss';

const SectionOrder = ({
    streamlines,
    selectedMaterial,
    selectedFilterStreamline,
    searchStreamline,
    filterOrderStatus,
}) => {
    const { isHide } = useSelector((state) => state.sideMenuSlice);
    const [orderByStreamline, setOrderByStreamline] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (selectedMaterial?.id) {
            fetchOrderByStreamline({
                setOrderByStreamline,
                streamlines,
                sediaan_id: selectedMaterial?.id,
                searchStreamline,
                selectedFilterStreamline,
                setIsLoading,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamlines, searchStreamline, selectedFilterStreamline, filterOrderStatus]);

    useEffect(() => {
        if (orderByStreamline?.length) {
            const parentEl = document.getElementById('section-order__container__main-dashboard');

            if (parentEl) {

                const num = parentEl.clientWidth / 291;

                if (num > orderByStreamline?.length) {
                    parentEl.style.justifyContent = 'center';
                } else {
                    parentEl.style.justifyContent = 'flex-start';
                }
            }
        }
    }, [orderByStreamline, isHide]);

    return (
        <Flex className="section-order__container__main-dashboard" id="section-order__container__main-dashboard">
            {isLoading && <SkeletonOrderStatus />}
            {!isLoading &&
                orderByStreamline?.map((streamline) => {
                    return (
                        <Box className="order-streamline">
                            <StreamlineHead streamline={streamline} />
                            <ListCardOrderStatus orders={streamline?.orders} />
                        </Box>
                    );
                })}
            {!isLoading && orderByStreamline?.length === 0 && (
                <EmptyState
                    text="Tidak ada data yg di tampilkan"
                    description="Maaf, tidak ada data yang bisa ditamplikan"
                />
            )}
        </Flex>
    );
};

export default SectionOrder;

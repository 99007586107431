import { Box, Button, Text, useToast, Flex, Divider } from '@chakra-ui/react';
import { useState } from 'react';
import { ArrowRightIconBlue, ArrowUpIconBlack } from '@asset/icons';
import FieldMenu from '@components/fieldMenu';
import { onChangeFormData } from '../../functions';
import { IconInfo } from '../../icons';
import { listDisabledJenisMaterial } from '../../const';
import CustomButton from '@components/button';
import ModalSelectLine from '@components/modalSelected';
import { isEmpty } from 'lodash';

import './styles.scss';

function Index({
    setFormData,
    isEditModal,
    roleSelected,
    handleSubmit,
    isSubmitted,
    formData,
    jenisMaterial,
    isChoosingMaterial,
    materials,
}) {
    const [showMultipleSelectModal, setShowMultipleSelectModal] = useState(false);
    const onToggleSelectMaterial = () => setShowMultipleSelectModal(!showMultipleSelectModal);
    return (
        <>
            {showMultipleSelectModal && (
                <ModalSelectLine
                    isOpen={showMultipleSelectModal}
                    onClose={onToggleSelectMaterial}
                    items={{
                        type: 'materials',
                        arr: materials,
                        title: 'Pilih Sediaan',
                        labelTop: 'Nama sediaan yang dipilih',
                        currentArr: formData?.materials,
                    }}
                    onSubmitSelected={({ type, arr }) =>
                        onChangeFormData({
                            type,
                            value: arr,
                            setFormData,
                            formData,
                        })
                    }
                />
            )}

            <Box className="modal-item__container_firstForm">
                <Box width="359px">
                    <Text className="label-title">Isi data lengkap</Text>
                    <Text className="label-description">Isi data mu dengan lengkap, untuk pembuatan account baru</Text>
                </Box>
                <Box width="706px">
                    <Flex className="box-warn__container">
                        <IconInfo />
                        <Text className="warn-email__text">Gunakan domain email pti cosmetic atau parama</Text>
                    </Flex>
                    <Box mt="40px">
                        <Text className="label">
                            Jenis Material<span style={{ color: 'red' }}>*</span>
                        </Text>
                        <Box flex={1}>
                            <FieldMenu
                                onClick={(value) => {
                                    setFormData((prev) => ({ ...prev, jenis_material: value }));
                                }}
                                buttonName={
                                    <span>
                                        {formData?.jenis_material?.name ? (
                                            <span className="selected-role-text">{formData?.jenis_material?.name}</span>
                                        ) : (
                                            <span className="FieldMenu-placeholder">Masukan nama</span>
                                        )}
                                    </span>
                                }
                                isDisabled={Boolean(
                                    listDisabledJenisMaterial.find((item) => item === roleSelected?.role)
                                )}
                                menuList={jenisMaterial}
                                keyName="name"
                                disabledWidthBox="706px"
                                disabledHeightBox="48px"
                                menuClassName="menu-container__user-management"
                                menuButtonClassName={`menu-button ${
                                    isSubmitted && !formData?.jenis_material?.id && `menu-button-error`
                                }`}
                                menuListClassName="menuList-container"
                                icon={<ArrowUpIconBlack />}
                            />
                            {isSubmitted && !formData?.jenis_material?.id && (
                                <Text className="required-field__text">Field jenis material required!</Text>
                            )}
                        </Box>
                    </Box>
                    <Box mt="40px">
                        <Text className="label">
                            Nama<span style={{ color: 'red' }}>*</span>
                        </Text>
                        <Box flex={1}>
                            <input
                                className={`input-container uppercase-text ${
                                    isSubmitted && !formData.name && 'error-empty__field'
                                }`}
                                placeholder="Masukan nama"
                                onChange={(e) =>
                                    onChangeFormData({
                                        type: 'name',
                                        value: e.target.value,
                                        setFormData,
                                        formData,
                                    })
                                }
                                value={formData.name}
                            />
                            {isSubmitted && !formData.name && (
                                <Text className="required-field__text">*Masukan nama</Text>
                            )}
                        </Box>
                    </Box>
                    <Box mt="40px">
                        <Text className="label">
                            Email
                            <span style={{ color: 'red' }}>*</span>
                        </Text>
                        <Box flex={1}>
                            <input
                                className={`input-container ${isSubmitted && !formData.email && 'error-empty__field'}`}
                                onChange={(e) =>
                                    onChangeFormData({
                                        type: 'email',
                                        value: e.target.value,
                                        setFormData,
                                        formData,
                                    })
                                }
                                value={formData.email}
                                placeholder="Masukan alamat email"
                            />
                            {isSubmitted && !formData.email && (
                                <Text className="required-field__text">*Masukan Email</Text>
                            )}
                        </Box>
                    </Box>
                    <Box mt="40px">
                        {isChoosingMaterial() && (
                            <Box className="form-pilih-sediaan">
                                <Box flex={1}>
                                    <Text className="label">
                                        Sediaan
                                        <span style={{ color: 'red' }}>*</span>
                                    </Text>
                                    <Flex className="menu-input__multiple-container">
                                        <Flex
                                            className={`selected-multiple-input__container ${
                                                isSubmitted && isEmpty(formData.materials) && 'error-empty__field'
                                            }`}
                                        >
                                            {formData?.materials?.length === 0 && (
                                                <Text className="empty-material__text">Belum dipilih</Text>
                                            )}
                                            {formData?.materials?.slice(0, 3)?.map((line, index) => (
                                                <Flex key={index} className="selected-multiple-input__item">
                                                    {line.name}
                                                </Flex>
                                            ))}
                                            {formData?.materials.length > 3 && (
                                                <Box className="text-show-more__container">
                                                    {formData?.materials?.length - 3} More +
                                                </Box>
                                            )}
                                        </Flex>
                                        <Flex className="button-choosed__container" onClick={onToggleSelectMaterial}>
                                            <Text className="material-text__button">Sediaan</Text>
                                            <ArrowRightIconBlue />
                                        </Flex>
                                    </Flex>
                                    {isSubmitted && isEmpty(formData?.materials) && (
                                        <Text className="required-field__text">*Pilih sediaan</Text>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>

                {/** ------------------------- SECTION MATERIAL  --------------------------------- */}
            </Box>
            <Box height="0.5px" bgColor="#D7D7D7" />
            <Flex gap="60px" padding="0px 38px 32px 38px">
                <Box width="359px"></Box>
                <Box width="706px">
                    <CustomButton onClick={handleSubmit} marginTop="32px" float="right">
                        {isEditModal ? 'Update Account' : 'Create Account'}
                    </CustomButton>
                </Box>
            </Flex>
        </>
    );
}

export default Index;

import { useState, createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import SectionHead from './section/sectionHead';
import SectionTable from './section/sectionTable';
import Pagination from '@components/pagination';
import TableSkeleton from '@components/tableSkeleton';
import EmptyState from '@components/emptyState';
import { tableList, formDefaultValues, initialValueFilter } from './const';
import { numToArr } from '@helpers/functions';
import ModalConfirm from '@components/modalConfirm';
import Modalsuccess from '@components/modalSuccess';
import EditDatePicker from './components/editDatePicker';
import { fetchDataSupplierStock } from './function';
import { initialValueDates } from './components/editDatePicker/const';
import { handleGetManufDates } from './components/editDatePicker/function';
import { isEmpty } from 'lodash';
import './styles.scss';

export const SupplierStockContext = createContext();

const SupplierStock = () => {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [filter, setFilter] = useState({ itemCode: [], trade_name: [] });
    const [suppliers, setSuppliers] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [refetch, setRefetch] = useState(false);
    const [page, setPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filterStatus, setFilterStatus] = useState(initialValueFilter);
    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isDateRefetch, setIsDateRefetch] = useState(false);
    const [disabledLoadingMain, setDisabledLoadingMain] = useState(false);
    const [formData, setFormData] = useState(formDefaultValues);
    const [showEditDate, setShowEditDate] = useState(false);
    const [addDatePicker, setAddDatePicker] = useState([initialValueDates]);
    const [idDate, setIdDate] = useState(initialValueDates.id);
    const [enable, setEnable] = useState(false);
    const [indexDate, setIndexDate] = useState(null);
    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const onChangeModalState = ({ value }) => setShowEditDate(value);
    const onChangeFilter = ({ key, value }) => {
        setFilterStatus((prev) => ({ ...prev, [key]: value }));
    };
    const onSelectedValue = ({ value }) => {
        setSelectedItem(value);
        if (!isEmpty(value?.stockSupplier?.stockManufDates)) {
            const manufDatArray = value?.stockSupplier?.stockManufDates;
            const manufLastId =
                manufDatArray?.length >= 10
                    ? manufDatArray[manufDatArray?.length - 1].id
                    : `${manufDatArray?.length + 1}ids`;
            handleGetManufDates({ manufDates: manufDatArray, setState: setAddDatePicker });
            setIdDate(manufLastId);
            setIndexDate(manufDatArray.length - 1);
        }
    };

    const onCloseEditDate = () => {
        if (isDateRefetch) {
            fetchDataSupplierStock({
                page,
                filter,
                filterStatus,
                setSuppliers,
                setIsLoading,
                setTotalData,
                setTotalPage,
                selectedPerPage,
                searchQuery,
                jenisMaterial,
                supplier,
                setDisabledLoadingMain,
                disabledLoadingMain,
            });
        }
        setIsDateRefetch(false);
        setAddDatePicker([initialValueDates]);
        setIdDate(initialValueDates.id);
        setShowEditDate(false);
    };

    useEffect(() => {
        if (Boolean(supplier?.length !== 0) || jenisMaterial?.id) {
            setPage(1);
        }
    }, [supplier?.length, jenisMaterial?.id]);

    useEffect(() => {
        if (jenisMaterial?.id) {
            fetchDataSupplierStock({
                page,
                filter,
                filterStatus,
                setSuppliers,
                setIsLoading,
                setTotalData,
                setTotalPage,
                selectedPerPage,
                searchQuery,
                jenisMaterial,
                supplier,
                setDisabledLoadingMain,
                disabledLoadingMain,
            });
        }
    }, [page, filter, searchQuery, selectedPerPage, filterStatus, refetch, jenisMaterial, supplier]);

    return (
        <SupplierStockContext.Provider
            value={{
                isEdit,
                setIsEdit,
                setIsOpen,
                formData,
                setFormData,
                onChangeFilter,
                filter,
                setFilter,
                refetch,
                setRefetch,
                onSelectedValue,
                enable,
                setPage,
                filterStatus,
                setEnable,
                setDisabledLoadingMain,
            }}
        >
            <Box className="supplier-stock__container">
                <SectionHead />
                {isLoading ? (
                    <TableSkeleton
                        tableList={tableList}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                ) : (
                    suppliers?.length > 0 && (
                        <SectionTable suppliers={suppliers} onChangeModalState={onChangeModalState} />
                    )
                )}
                {!isLoading && !Boolean(suppliers.length) && (
                    <Box>
                        <EmptyState
                            description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                            text="Tidak ada data yg di tampilkan"
                        />
                    </Box>
                )}
                <Pagination
                    page={page}
                    totalPage={totalPage}
                    totalData={totalData}
                    selectedPerPage={selectedPerPage}
                    setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                    onPageChange={handlePageClick}
                />

                {/* ------Modals------- */}

                {showEditDate && (
                    <EditDatePicker
                        isOpen={showEditDate}
                        onClose={onCloseEditDate}
                        selectedItem={selectedItem}
                        addDatePicker={addDatePicker}
                        setAddDatePicker={setAddDatePicker}
                        idDate={idDate}
                        setIdDate={setIdDate}
                        setIsDateRefetch={setIsDateRefetch}
                        indexDate={indexDate}
                        setIndexDate={setIndexDate}
                        setDisabledLoadingMain={setDisabledLoadingMain}
                    />
                )}
                <ModalConfirm
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onSubmit={() => setIsSubmit(true)}
                    submitLabel="Lanjut"
                    title={`Apa anda yakin Ingin menyimpan data ?`}
                />

                {isSubmit && (
                    <Modalsuccess isOpen={isOpen} onClose={() => setIsOpen(false)} title={`Data berhasil disimpan`} />
                )}
            </Box>
        </SupplierStockContext.Provider>
    );
};

export default SupplierStock;

import { Box, Skeleton, Stack, Flex } from '@chakra-ui/react';

const SkeletonOrderStatus = () => {
    return (
        <Flex flexWrap="nowrap">
            {[1, 2, 3, 4, 5, 6].map((_, idx) => {
                return (
                    <Box key={idx} mr="10px">
                        <Box mb="18px">
                            <Skeleton w="270px" h="37px" />
                        </Box>
                        {[1, 2, 3, 4, 5].map((_, i) => (
                            <Box
                                key={i}
                                h="156px"
                                w="270px"
                                border={'1px'}
                                borderRadius="4px"
                                borderColor={'#e4e4e4'}
                                my={2}
                                overflow="hidden"
                            >
                                <Box bg="#bdc6d3" h="35px" w="100%"></Box>
                                <Stack p={2}>
                                    <Skeleton height="14px" />
                                    <Skeleton height="14px" w="80%" />
                                    <Skeleton height="14px" w="40%" />
                                    <Skeleton height="14px" w="40%" />
                                    <Flex>
                                        <Skeleton height="14px" w="30%" mr="16px" />
                                        <Skeleton height="14px" w="30%" />
                                    </Flex>
                                </Stack>
                            </Box>
                        ))}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default SkeletonOrderStatus;

import React from 'react';

export function IconMinus() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
            <path
                fill="#B7B7B7"
                fillRule="evenodd"
                d="M.7 7a.7.7 0 01.7-.7h11.2a.7.7 0 010 1.4H1.4A.7.7 0 01.7 7z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconMinusDisabled() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
            <path
                fill="#B7B7B7"
                fillRule="evenodd"
                d="M.7 7a.7.7 0 01.7-.7h11.2a.7.7 0 010 1.4H1.4A.7.7 0 01.7 7z"
                clipRule="evenodd"
                opacity="0.1"
            ></path>
        </svg>
    );
}

import CardOrderStatus from '@pages/mainDashboard/components/cardOrderStatus';
import { getBgColorHandler } from '@pages/mainDashboard/functions';
import { fillingEmptyOrderHandler, getStatus } from './functions';
import { Box } from '@chakra-ui/layout';
import EmptyCardOrderStatus from '@pages/mainDashboard/components/emptyCardOrderStatus';
import Cookies from 'universal-cookie';

import './styles.scss';

const ListCardOrderStatus = ({ orders }) => {
    return (
        <Box className="list-order-status__container">
            {fillingEmptyOrderHandler({ orders })?.map((order) => {
                if (order.type) {
                    return <EmptyCardOrderStatus />;
                } else {
                    const cookies = new Cookies();
                    const activeTab = cookies.get('tab');

                    return (
                        <CardOrderStatus
                            activeTab={activeTab}
                            bgHeader={getBgColorHandler({ valueColor: order?.streamline?.name })}
                            status={getStatus({ order })?.statusText}
                            start={order?.kardData?.start}
                            end={order?.kardData?.end}
                            namaItem={order?.nama_item}
                            streamName={order?.streamline?.name}
                            nomo={order?.kardData?.kard_fg?.kards[0]?.nomo}
                            snfg={order?.kardData?.kard_fg.snfg}
                            colorStatus={getStatus({ order })?.statusBackgroundColor}
                            colorText={getStatus({ order })?.statusTextColor}
                            isKetersediaanMaterial={order?.isKetersediaanMaterial}
                            selectedOrderPlanning={order}
                        />
                    );
                }
            })}
        </Box>
    );
};

export default ListCardOrderStatus;

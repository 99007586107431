import { Modal, ModalOverlay, ModalContent, Box, Button, Text, Flex } from '@chakra-ui/react';

import './styles.scss';

const ModalCustom = ({
    icon,
    confirmText,
    cancelText,
    isOpen,
    title,
    onClose,
    descriptionText,
    onSubmit,
    isLoading,
    confirmBg,
    confirmColor,
    cancelBg,
    cancelColor,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <Box className="modal-custom-global__container">
                    {icon}
                    <Text className="custom-title">{title}</Text>
                    <Box className="custom-description">{descriptionText}</Box>
                    <Flex className="button-container">
                        <Button
                            className="custom-button"
                            onClick={onSubmit}
                            isLoading={isLoading}
                            loadingText="Deleting"
                            _hover="none"
                            bg={confirmBg}
                            color={confirmColor}
                            border={`1px solid ${confirmColor}`}
                        >
                            {confirmText}
                        </Button>
                        <Button
                            bg={cancelBg}
                            color={cancelColor}
                            border={`1px solid ${cancelColor}`}
                            className="custom-cancel"
                            onClick={onClose}
                            _hover="none"
                        >
                            {cancelText}
                        </Button>
                    </Flex>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ModalCustom;

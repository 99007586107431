import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const handleCloseModalFilter = ({
    setOpenModalFilter,
    setData,
    selectedData,
    data,
    type = 'close',
    setTradeNameChoosed,
}) => {
    let selectedUserBeforeApply = [...selectedData];
    let cloneAllData = [...data];
    cloneAllData.map((user_item) => {
        user_item.checked = false;
        if (type !== 'reset') {
            selectedUserBeforeApply?.map((before_apply) => {
                if (user_item.trade_name === before_apply?.trade_name) {
                    user_item.checked = true;
                }
                return before_apply;
            });
        }
        return user_item;
    });
    setData(cloneAllData);
    setTradeNameChoosed([]);
    if (type === 'close') {
        setTradeNameChoosed(selectedData);
        setOpenModalFilter(false);
    }
};

export const fetchDataInfinite = async ({
    page,
    setTradeName,
    tradeNameChoosed,
    setLoadingSearch,
    setPage,
    moreData = false,
    setTotalData,
    search,
    supplierRef,
    jenisMaterial,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const ref = supplierRef?.join(';');
    const filterStringify = queryString.stringify(
        {
            attributes: 'trade_name',
            page,
            q: search,
            sort: 'trade_name asc',
            supplier_ref: ref,
            jenis_material_id: jenisMaterial?.id,
        },
        { skipEmptyString: true }
    );
    setLoadingSearch(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/filter?limit=10&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        let { list, totalData } = data.data;
        list?.map((item) => {
            item['checked'] = false;
            tradeNameChoosed?.map((choosedItem) => {
                if (item?.trade_name === choosedItem?.trade_name && choosedItem?.checked) {
                    item['checked'] = true;
                }
            });
            return item;
        });
        if (moreData && !search) {
            setTradeName((prev) => [...prev, ...list]);
            setPage(page + 1);
        } else {
            setTradeName(list);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
    } catch (error) {
        console.log(error);
        setLoadingSearch(false);
    }
};

import { useToast, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { onRemoveStreamline } from './functions';
import { StreamlineManagementContext } from '@pages/streamlineManagement';
import './styles.scss';
import ModalDelete from '@components/modalDelete';

const DeleteModal = ({ isOpen, reFetchStreamlineHandler }) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const { selectedStreamline, onChangeModalState, selectedView } = useContext(StreamlineManagementContext);

    const onClose = () => onChangeModalState({ value: null });
    return (
        <ModalDelete
            isOpen={isOpen}
            onClose={onClose}
            title="Delete Streamline"
            isLoading={isLoading}
            onSubmit={() =>
                onRemoveStreamline({
                    setIsLoading,
                    selectedStreamline,
                    onClose,
                    reFetchStreamlineHandler,
                    toast,
                    selectedView,
                })
            }
            descriptionComponent={
                <Text className="description-text">
                    Apakah anda yakin ingin menghapus Streamline <span>{selectedStreamline?.name}</span>?
                </Text>
            }
        />
    );
};

export default DeleteModal;

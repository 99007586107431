import { DownloadBlackIcon, UploadBlackIcon } from '@asset/icons';
import { isAllowed } from '@helpers/roleAccess';

export const optionMenuDot = () => {
    const listPermissions = [];
    if (
        isAllowed({
            pageName: "Supplier's Stock",
            permissionName: "Upload Supplier's Stock",
        })
    ) {
        listPermissions.push({
            name: 'Upload File',
            icon: <UploadBlackIcon />,
        });
    }
    listPermissions.push({
        name: 'Download Data',
        icon: <DownloadBlackIcon />,
    });

    return listPermissions;
};

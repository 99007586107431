export const handleCloseModalFilter = ({
    setOpenModalFilter,
    setCodeItem,
    selectedData,
    codeItem,
    type = 'close',
    setCodeItemChoosed,
}) => {
    let selectedUserBeforeApply = [...selectedData];
    let cloneAllData = [...codeItem];
    cloneAllData.map((user_item) => {
        user_item.checked = false;
        if (type !== 'reset') {
            selectedUserBeforeApply?.map((before_apply) => {
                if (user_item?.kode_item === before_apply?.kode_item) {
                    user_item.checked = true;
                }
                return before_apply;
            });
        }
        return user_item;
    });
    setCodeItem(cloneAllData);
    setCodeItemChoosed([]);
    if (type === 'close') {
        setCodeItemChoosed(selectedData);
        setOpenModalFilter(false);
    }
};

import { ArrowUpIconBlack } from '@asset/icons';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuList,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react';
import { EmptyField } from '@components/fieldMenu';
import ModalConfirm from '@components/modalConfirm';
import ModalSuccess from '@components/modalSuccess';
import ScreenTemplate from '@components/screenTemplateV2';
import { LeftArrowIcon, PlusBlueIcon, TrashIcon } from '@pagesPhase2/materialConfiguration/icons';
import { debounce, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formNewItem } from './const';
import {
    fetchDetailItemSet,
    fetchSupplierNewItemSet,
    handleFormatSetComponent,
    onResetHandler,
    onSubmitForm,
    onValidationForm,
} from './function';
import MainComponent from './mainComponent';
import SetComponent from './setComponent';
import './styles.scss';

const CreateAddItemSet = ({
    isEditModal,
    isOpen,
    onChangeModalState,
    reFetchMaterialItemSetHandler,
    mainId,
    formData,
    setFormData,
}) => {
    const toast = useToast();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isModalSuccess, setIsModalSuccess] = useState(false);
    const [isModalWarning, setIsModalWarning] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [supplier, setSupplier] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [openInfiniteScroll, setOpenInfiniteScroll] = useState(false);
    const [page, setPage] = useState(1);
    const [debounceVal, setDebounceVal] = useState('');
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [search, setSearch] = useState('');
    const isValidField = onValidationForm({ formData });
    const getMoreData = (hasMore = false) => {
        fetchSupplierNewItemSet({
            page,
            setSupplier,
            supplier,
            setPage,
            setTotalData,
            moreData: hasMore,
            search: debounceVal,
            setLoadingSearch: setLoadingSearch,
        });
    };

    const handleAddField = () => {
        const addListSetComponent = {
            id_dummy: Math.floor(Math.random() * 10000) + 1,
            set_component_internal_name: '',
            set_component_kode_item: '',
            set_component_id: null,
        };

        setFormData((prevState) => ({
            ...prevState,
            field_set_components: [...prevState.field_set_components, addListSetComponent],
        }));
    };

    const onDeleteInput = (id) => {
        const filter = formData.field_set_components.filter((item) => item.id_dummy !== id);
        setFormData((prevState) => ({
            ...prevState,
            field_set_components: filter,
        }));
    };
    const handleChangeDebounce = (text) => {
        setTotalData(1);
        setDebounceVal(text.target.value);
        setPage(() => {
            return 1;
        });
    };

    const debounceSearch = useMemo(() => debounce(handleChangeDebounce, 500), []);
    const handleClickSupplierName = (item) => {
        setSearch(item?.name);
        setFormData((prev) => ({
            ...prev,
            supplier_ref: item?.supplier_ref,
            supplier_name: item?.item?.name,
        }));
        setOpenInfiniteScroll(false);
    };

    const onChangeSetComponent = (item, id, index) => {
        handleFormatSetComponent({
            item,
            id,
            index,
            formData,
            setFormData,
        });
    };

    const onChangeMainComponent = (item) => {
        setFormData({
            ...formData,
            main_component_kode_item: item?.kode_item,
            main_component_internal_name: item?.internal_name,
            main_component_id: item?.id,
        });
    };

    const onClose = () => {
        onResetForm();
        setIsModalSuccess(false);
        onChangeModalState({ value: null });
    };

    const onResetForm = () => {
        onResetHandler({
            setFormData,
            setSearch,
            setDebounceVal,
            setIsSubmitted,
        });
    };

    const onCloseWarn = () => {
        setIsModalWarning(false);
    };

    const handleSubmit = () => {
        onSubmitForm({
            toast,
            formData,
            setIsSubmitted,
            isEditModal,
            reFetchMaterialItemSetHandler,
            onResetForm,
            setIsSubmitLoading,
            setIsModalSuccess,
            setIsModalWarning,
            mainId,
        });
    };

    useEffect(() => {
        if (isEditModal) {
            fetchDetailItemSet({ mainId, setFormData });
        }
    }, [isEditModal, mainId, setFormData]);

    useEffect(() => {
        getMoreData(true);
    }, [debounceVal]);

    return (
        <ScreenTemplate isOpen={isOpen} sx={{ overflowX: 'hidden' }}>
            <Box p="20px">
                <Box
                    className={`section-add-new-item__container section-modal-add-new-item__container ${
                        isOpen && 'modal-actived__section-add-new-item'
                    }`}
                    borderRadius="8px"
                >
                    <Box>
                        <Box className="section-header__add-new-item" onClick={onClose} cursor="pointer">
                            <LeftArrowIcon color={'#1746A2'} />
                            <Text className="text-header">
                                {isEditModal ? 'edit add new item set' : 'add new item set'}
                            </Text>
                        </Box>
                        <Flex className="section-supplier__add-new-item">
                            <Box>
                                <Text className="text-supplier">pilih supplier</Text>
                                <Box w="267px">
                                    <Text className="text-desc">
                                        Pilih supplier dengan tepat, untuk melanjutkan membuat new item set
                                    </Text>
                                </Box>
                            </Box>
                            <Box ml="73px">
                                <Flex flexDirection="column" w="706px">
                                    {formNewItem.map((formList) => {
                                        if (formList.type === 'select') {
                                            return (
                                                <>
                                                    <Box mt="40px">
                                                        <Text className="label">
                                                            {formList?.name}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </Text>
                                                        <Box position="relative">
                                                            <InputGroup>
                                                                <Input
                                                                    className={`fieldmenu-input ${
                                                                        isSubmitted &&
                                                                        isEmpty(search) &&
                                                                        'required-field__input'
                                                                    }`}
                                                                    placeholder={formList?.placeholder}
                                                                    onFocus={() => setOpenInfiniteScroll(true)}
                                                                    value={formData.supplier_name || search}
                                                                    height="48px"
                                                                    onChange={(text) => {
                                                                        setSearch(text.target.value);
                                                                        debounceSearch(text);
                                                                    }}
                                                                />
                                                                <InputRightElement height="48px" right="12px">
                                                                    <Box className="divider_input_infinite_scroll" />
                                                                    {loadingSearch ? (
                                                                        <Spinner size="sm" />
                                                                    ) : (
                                                                        <ArrowUpIconBlack />
                                                                    )}
                                                                </InputRightElement>
                                                            </InputGroup>
                                                            <Menu
                                                                isOpen={openInfiniteScroll}
                                                                onClose={() => setOpenInfiniteScroll(false)}
                                                            >
                                                                <MenuList mt="55px" zIndex={20} w={'706px'}>
                                                                    <div
                                                                        id="scrollableDiv"
                                                                        className="scroll-container"
                                                                    >
                                                                        <InfiniteScroll
                                                                            dataLength={supplier.length}
                                                                            next={() => getMoreData(true)}
                                                                            hasMore={
                                                                                supplier.length === totalData
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            scrollableTarget="scrollableDiv"
                                                                            loader={
                                                                                supplier.length === 0 ? (
                                                                                    <Text className="loading_fetch_next_page">
                                                                                        Loading...
                                                                                    </Text>
                                                                                ) : supplier.length < 9 ? null : (
                                                                                    <Text className="loading_fetch_next_page">
                                                                                        Loading...
                                                                                    </Text>
                                                                                )
                                                                            }
                                                                            endMessage={null}
                                                                        >
                                                                            {supplier.length === 0 && !loadingSearch ? (
                                                                                <Text className="no_data_infinite_scroll">
                                                                                    Tidak Bisa Menemukan "{debounceVal}"
                                                                                </Text>
                                                                            ) : (
                                                                                supplier?.map((item, index) => {
                                                                                    return (
                                                                                        <Box
                                                                                            onClick={() =>
                                                                                                handleClickSupplierName(
                                                                                                    item
                                                                                                )
                                                                                            }
                                                                                            className="list_item_infinite"
                                                                                            key={index}
                                                                                        >
                                                                                            {item?.name}
                                                                                        </Box>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </InfiniteScroll>
                                                                    </div>
                                                                </MenuList>
                                                            </Menu>
                                                            {isSubmitted && isEmpty(formData?.supplier_ref) && (
                                                                <Text className="required-field__text">
                                                                    *Masukan Nama Supplier
                                                                </Text>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </>
                                            );
                                        }
                                        return (
                                            <>
                                                <Box>
                                                    <Text
                                                        mt={formList.type === 'unselect' && '40px'}
                                                        className="title-fieldmenu"
                                                    >
                                                        {formList?.name}
                                                        {formList.isRequired && <span style={{ color: 'red' }}>*</span>}
                                                    </Text>
                                                    <EmptyField
                                                        className="disabled-field-supplier"
                                                        buttonName={formData?.supplier_ref || formList.placeholder}
                                                    />
                                                    {isSubmitted && isEmpty(formData?.supplier_ref) && (
                                                        <Text className="required-field__text">
                                                            *Masukan Kode Supplier
                                                        </Text>
                                                    )}
                                                </Box>
                                            </>
                                        );
                                    })}
                                </Flex>
                            </Box>
                        </Flex>
                        {/* ---Main Component--- */}
                        <Flex className="section-main-component__add-new-item">
                            <Box>
                                <Text className="text-main-component">main component</Text>
                                <Box w="267px">
                                    <Text className="text-desc">
                                        Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                                    </Text>
                                </Box>
                            </Box>
                            <MainComponent
                                kode_item={formData.main_component_kode_item}
                                internal_name={formData.main_component_internal_name}
                                isSubmitted={isSubmitted}
                                onChangeValHandler={onChangeMainComponent}
                                disabled={isEditModal}
                            />
                        </Flex>
                        {/* ---Set Component--- */}
                        <Flex className="section-set-component__add-new-item">
                            <Box>
                                <Text className="text-set-component">Set Component</Text>
                                <Box w="267px">
                                    <Text className="text-desc">
                                        Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                                    </Text>
                                </Box>
                            </Box>
                            <Flex ml="73px">
                                <Box className="wrapper-fields__add-more-item">
                                    {formData.field_set_components.map((item, index) => {
                                        return (
                                            <Flex pt="36px" pb="25px" key={index}>
                                                <Box>
                                                    <SetComponent
                                                        id={item.id_dummy}
                                                        name={item.id_dummy}
                                                        kode_item={item.set_component_kode_item}
                                                        internal_name={item.set_component_internal_name}
                                                        onChangeValHandler={(val, id) =>
                                                            onChangeSetComponent(val, id, index)
                                                        }
                                                        isSubmitted={isSubmitted}
                                                    />
                                                </Box>
                                                {formData.field_set_components?.length >= 1 && index !== 0 && (
                                                    <Box
                                                        className="wrapper-trash-icon"
                                                        onClick={onDeleteInput.bind(null, item.id_dummy)}
                                                    >
                                                        <TrashIcon />
                                                    </Box>
                                                )}
                                            </Flex>
                                        );
                                    })}
                                    <Box className="wrapper-btn-more">
                                        <Button
                                            className="btn-more-item"
                                            borderRadius={'none'}
                                            onClick={handleAddField}
                                        >
                                            <PlusBlueIcon />
                                            add more item
                                        </Button>
                                    </Box>
                                </Box>
                            </Flex>
                        </Flex>
                        <Box className="wrapper-btn-save-cancel">
                            <Box ml="871px" display="flex">
                                <Button className="btn btn-cancel" mr="12px" onClick={onClose}>
                                    cancel
                                </Button>
                                <Button
                                    className="btn btn-save"
                                    onClick={() => {
                                        if (!isValidField) {
                                            setIsSubmitted(true);
                                        } else {
                                            setIsModalWarning(true);
                                        }
                                    }}
                                >
                                    save item
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/*================== Modals ================*/}
            <ModalConfirm
                loading={isSubmitLoading}
                isOpen={isModalWarning}
                onClose={onCloseWarn}
                onSubmit={handleSubmit}
                submitLabel="Lanjut"
                title="Apa anda yakin telah mengisi dengan benar?"
            />
            <ModalSuccess
                isOpen={isModalSuccess}
                onClose={onClose}
                submitLabel="Kembali"
                title="Sukses Membuat Item Set"
                description="Item set berhasil dibuat, kembali ke table untuk melihat item set"
            />
        </ScreenTemplate>
    );
};

export default CreateAddItemSet;

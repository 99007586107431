import { find, remove } from 'lodash';

export const onSelectedItem = ({ value, setItemSelected, itemSelected, toast }) => {
    const isDuplicated = find(itemSelected, { id: value.id });
    if (isDuplicated) {
        toast({
            position: 'top-center',
            description: 'Pilihan sudah ada!',
            status: 'error',
            duration: 500,
            isClosable: true,
        });
    } else {
        setItemSelected([...itemSelected, value]);
    }
};

export const onRemoveItem = ({ value, setItemSelected, itemSelected }) => {
    const arr = remove([...itemSelected], (item) => item.id !== value.id);
    setItemSelected(arr);
};

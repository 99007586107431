import { Flex, Box, Text } from '@chakra-ui/react';
import { tableList } from '@pagesPhase2/demandAnticipation/const';
import './styles.scss';
import { useContext } from 'react';
import { DemandAnticipationContext } from '@pagesPhase2/demandAnticipation';

const TableHead = ({ isEdit }) => {
    const { table } = useContext(DemandAnticipationContext);
    return (
        <Flex className="table-head__container__demand_anticipation">
            {table?.map((head, idx, it) => {
                if (head.children) {
                    return (
                        <Box
                            className="table-head__item"
                            flexDirection="column"
                            width={head.width}
                            key={head.name}
                            year={head.year}
                        >
                            <Flex
                                // sx={
                                //     head?.value?.includes('uom') &&
                                //     isEdit && {
                                //         paddingLeft: '14px !important',
                                //         justifyContent: 'flex-start !important',
                                //     }
                                // }
                                className="table-head__parent"
                                w="fit-content"
                                pl="6px"
                                position="relative"
                            >
                                {head.name}
                                <Text className="text-year__table-head-children">{head.year}</Text>
                            </Flex>
                            <Flex justify="space-between" w="100%">
                                {head.children.map((child, index) => {
                                    return (
                                        <Box key={index} className="table-head__child-container">
                                            <Box className="table-head__child">
                                                <Text>{child}</Text>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Flex>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}
        </Flex>
    );
};

export default TableHead;

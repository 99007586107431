import { Box, Checkbox, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { CapslockIcon, EyeHideIcon, EyeOpenIcon } from './icons';
import { onKeyEnter, checkCapsLock } from '../../functions';
import './styles.scss';

const SectionFieldInput = ({ handleChange, error, onLoginHandler, setIsRememberMe, formData }) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isCapslock, setIsCapsLock] = useState(false);

    return (
        <Box className="section-field-input__container__login-page">
            <Box className="field-input__item" mb="35px">
                <p className="label">Email</p>
                <Input
                    className="input-login"
                    onChange={(e) => handleChange({ value: e.target.value, type: 'email', setIsCapsLock })}
                    autoComplete="off"
                    isRequired
                    placeholder="Insert email"
                    onKeyDown={(e) => onKeyEnter({ key: e.key, onLoginHandler })}
                    value={formData?.email}
                />
                {error && !error.username && <Box className="login-error">Email tidak boleh kosong</Box>}
            </Box>
            <Box className="field-input__item" mb="28px">
                <p className="label">Password</p>
                <Box position="relative">
                    <Input
                        className="input-login"
                        type={isShowPassword ? 'text' : 'password'}
                        onChange={(e) => handleChange({ value: e.target.value, type: 'password', e })}
                        autoComplete="off"
                        isRequired
                        value={formData?.password}
                        placeholder="Insert Password"
                        onKeyDown={(e) => onKeyEnter({ key: e.key, onLoginHandler })}
                        onKeyUp={(e) => checkCapsLock({ e, setIsCapsLock, isCapslock })}
                    />
                    {isCapslock && (
                        <Box className="icon-capslock__container">
                            <CapslockIcon />
                        </Box>
                    )}
                    <Box onClick={() => setIsShowPassword(!isShowPassword)} className="icon-eye__container">
                        {isShowPassword ? <EyeOpenIcon /> : <EyeHideIcon />}
                    </Box>
                </Box>

                {error && !error.password && <Box className="login-error">password tidak boleh kosong</Box>}
            </Box>
            <Box className="box-remember__container">
                <Checkbox onChange={(val) => setIsRememberMe(val.target.checked)}>Remember me</Checkbox>
            </Box>
        </Box>
    );
};

export default SectionFieldInput;

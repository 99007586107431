import { downloadFromS3Handler, isEmptyString } from '@helpers/functions';
import { read, utils } from 'xlsx';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { attributeMaterialConfigTemplate } from './const';

export const onChangeFile = ({
    files,
    setPreviewData,
    setState,
    setFiles,
    setDisabledSaveItem,
    toast,
    jenisMaterial,
}) => {
    if (files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            let isErrorTemplateFile = false;
            const workbook = read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            worksheet['!ref'] = 'A1:AJ1000';
            /// https://stackoverflow.com/a/54852654/18038473
            const json = utils.sheet_to_json(worksheet, { defval: null });
            if (Boolean(json?.length === 0)) {
                setDisabledSaveItem(true);
                toast({
                    title: 'Error !',
                    description: 'File tidak bisa diupload, setidaknya terdapat 1 data',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
                setState('upload');
                return;
            }
            let jsonAfterCompile = [];
            for (let indexItem = 0; indexItem < json.length; indexItem++) {
                const keyAttribute = Object.keys(json[indexItem] || {});
                let newKey = {};
                for (let indexKey = 0; indexKey < keyAttribute?.length; indexKey++) {
                    if (!keyAttribute[indexKey]?.toLowerCase()?.includes('empty')) {
                        /// PENGECEKAN BILA FORMAT TEMPLATE TIDAK SESUAI DENGAN YANG DIHARAPKAN
                        if (!Boolean(attributeMaterialConfigTemplate[0][keyAttribute[indexKey]])) {
                            isErrorTemplateFile = true;
                        }
                        newKey[`${keyAttribute[indexKey]}`] = {
                            value: keyAttribute[indexKey]?.toLowerCase()?.includes('supplier ref')
                                ? String(json[indexItem][keyAttribute[indexKey]])
                                : json[indexItem][keyAttribute[indexKey]],
                            isError: false,
                            messageError: null,
                        };
                    }
                }
                jsonAfterCompile.push(newKey);
            }
            if (isErrorTemplateFile) {
                toast({
                    title: 'Error !',
                    description: `File tidak bisa diupload, sepertinya file "${files?.name}" memiliki format yang salah. Cek kembali!`,
                    status: 'warning',
                    duration: 8000,
                    isClosable: true,
                    position: 'top-center',
                });
                setState('upload');
                isErrorTemplateFile = false;
                return;
            }
            let resultJsonAfterValidation = [];
            setDisabledSaveItem(false);
            for (let index = 0; index < jsonAfterCompile.length; index++) {
                const currentAttribute = Object.keys(jsonAfterCompile[index] || {});
                if (jsonAfterCompile[index]['Supplier Ref']?.value === 'null')
                    jsonAfterCompile[index]['Supplier Ref'].value = null;
                for (let indexAttribute = 0; indexAttribute < currentAttribute.length; indexAttribute++) {
                    /// VALUE SETIAP KOLOM
                    const value = jsonAfterCompile[index][currentAttribute[indexAttribute]]?.value;

                    /// LIST KOLOM WAJIB DIISI
                    const isRequired = attributeMaterialConfigTemplate[0][currentAttribute[indexAttribute]]?.isRequired;

                    /// LIST KOLOM YANG HANYA MENERIMA VALUE TERTENTU
                    const attributeOptions =
                        attributeMaterialConfigTemplate[0][currentAttribute[indexAttribute]]?.option;
                    const isOption = attributeOptions?.find(
                        (item) => item?.toLowerCase() === value?.toString()?.toLowerCase()
                    );

                    /// TYPE VALUE SETIAP KOLOM
                    const type = attributeMaterialConfigTemplate[0][currentAttribute[indexAttribute]]?.type;

                    /// FIELD YANG TIDAK BOLEH FLOAT KETIKA PM
                    const isFloat =
                        attributeMaterialConfigTemplate[0][currentAttribute[indexAttribute]]?.disabledFloatIfRM;

                    /// LOGIC KOLOM HANYA MENERIMA VALUE TERTENTU
                    if (Boolean(attributeOptions) && !Boolean(isOption)) {
                        jsonAfterCompile[index][currentAttribute[indexAttribute]].isError = true;
                        jsonAfterCompile[index][
                            currentAttribute[indexAttribute]
                        ].messageError = `value tidak valid, pilih salah satus (${attributeOptions?.join('/')})`;
                    }
                    /// LOGIC JIKA TIPE VALUE TIDAK SESUAI. CONTOH : KOLOM KUSUS NUMBER DIISI STRING
                    if (typeof value !== type && value !== null && isRequired) {
                        jsonAfterCompile[index][currentAttribute[indexAttribute]].isError = true;
                        jsonAfterCompile[index][
                            currentAttribute[indexAttribute]
                        ].messageError = `Harus bertipe ${type}`;
                        const isValidValue = attributeOptions?.find(
                            (item) => item?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
                        );
                        if (!Boolean(isValidValue) && Boolean(attributeOptions)) {
                            jsonAfterCompile[index][currentAttribute[indexAttribute]].isError = true;
                            jsonAfterCompile[index][
                                currentAttribute[indexAttribute]
                            ].messageError = `value tidak valid, pilih salah satu (${attributeOptions?.join('/')})`;
                        }
                    }

                    /// LOGIC JIKA KOLOM ANGKA MEMILIKI VALUE DIBAWAH 0 (NEGATIVE NUMBER)
                    if (typeof value === 'number' && value < 0) {
                        jsonAfterCompile[index][currentAttribute[indexAttribute]].isError = true;
                        jsonAfterCompile[index][
                            currentAttribute[indexAttribute]
                        ].messageError = `Tidak boleh kurang dari 0`;
                    }
                    /// LOGIC JIKA RM ATAU PM
                    if (
                        isFloat &&
                        jenisMaterial?.name === 'Packaging Material' &&
                        (String(value).includes('.') || String(value).includes(','))
                    ) {
                        jsonAfterCompile[index][currentAttribute[indexAttribute]].isError = true;
                        jsonAfterCompile[index][
                            currentAttribute[indexAttribute]
                        ].messageError = `Jenis material PM tidak boleh float`;
                    }
                    /// LOGIC JIKA KOLOM TIDAK TERISI
                    if (value === null && isRequired) {
                        jsonAfterCompile[index][currentAttribute[indexAttribute]].isError = true;
                        jsonAfterCompile[index][currentAttribute[indexAttribute]].messageError =
                            'Field ini tidak boleh kosong';
                    }

                    /// LOGIC MISAL FIELD RAW MAT ADA YANG DIISI DAN ADA YANG KOSONG
                    /// FIELD RAW MAT HARUS DIISI SEMUA JIKA INGIN MENAMBAH RAW MAT,
                    /// KALAU TIDAK JANGAN DIISI SEMUA (Nama Material, Max Stock Material, ROP)
                    for (let rawMat = 1; rawMat < 6; rawMat++) {
                        const rop = jsonAfterCompile[index][`ROP ${rawMat} (optional)`];
                        const nameMaterial = jsonAfterCompile[index][`Nama Material ${rawMat} (optional)`];
                        const maxStock = jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`];
                        const hasNameMaterial = isEmptyString(rop?.value) && isEmptyString(maxStock?.value);
                        const hasRop = isEmptyString(nameMaterial?.value) && isEmptyString(maxStock?.value);
                        const hasMaxStock = isEmptyString(nameMaterial?.value) && isEmptyString(rop?.value);
                        jsonAfterCompile[index][`Nama Material ${rawMat} (optional)`].isError = false;
                        jsonAfterCompile[index][`Nama Material ${rawMat} (optional)`].messageError = null;
                        /// NAMA MATERIAL
                        if (isEmptyString(nameMaterial?.value)) {
                            jsonAfterCompile[index][`Nama Material ${rawMat} (optional)`].isError = !hasNameMaterial;
                            jsonAfterCompile[index][`Nama Material ${rawMat} (optional)`].messageError =
                                !hasNameMaterial
                                    ? 'Field ini tidak boleh kosong jika ingin menambah material stock'
                                    : null;
                        }
                        /// ROP
                        if (isEmptyString(rop?.value)) {
                            jsonAfterCompile[index][`ROP ${rawMat} (optional)`].isError = !hasRop;
                            jsonAfterCompile[index][`ROP ${rawMat} (optional)`].messageError = !hasRop
                                ? 'Field ini tidak boleh kosong jika ingin menambah material stock'
                                : null;
                        }
                        /// MAX STOCK
                        if (isEmptyString(maxStock?.value)) {
                            jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`].isError = !hasMaxStock;
                            jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`].messageError =
                                !hasMaxStock ? 'Field ini tidak boleh kosong jika ingin menambah material stock' : null;
                        }
                        /// LOGIC JIKA Max Stock Material MEMILIKI VALUE DIBAWAH 0 (NEGATIVE NUMBER)
                        if (!isEmptyString(maxStock?.value) && maxStock?.value < 0) {
                            jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`].isError = true;
                            jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`].messageError =
                                'Tidak boleh kurang dari 0';
                        }
                        /// LOGIC JIKA ROP MEMILIKI VALUE DIBAWAH 0 (NEGATIVE NUMBER)
                        if (!isEmptyString(rop?.value) && rop?.value < 0) {
                            jsonAfterCompile[index][`ROP ${rawMat} (optional)`].isError = true;
                            jsonAfterCompile[index][`ROP ${rawMat} (optional)`].messageError =
                                'Tidak boleh kurang dari 0';
                        }
                        /// LOGIC JIKA Max Stock Material BUKAN NUMBER
                        if (!isEmptyString(maxStock?.value) && typeof maxStock?.value !== 'number') {
                            jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`].isError = true;
                            jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`].messageError =
                                'Harus bertipe number';
                        }
                        /// LOGIC JIKA ROP BUKAN NUMBER
                        if (!isEmptyString(rop?.value) && typeof rop?.value !== 'number') {
                            jsonAfterCompile[index][`ROP ${rawMat} (optional)`].isError = true;
                            jsonAfterCompile[index][`ROP ${rawMat} (optional)`].messageError = 'Harus bertipe number';
                        }
                        /// LOGIC JIKA Max Stock Material MEMILIKI TYPE NUMBER FLOAT DISAAT FILTER JENIS MATERIAL PM
                        if (
                            !isEmptyString(maxStock?.value) &&
                            jenisMaterial?.name === 'Packaging Material' &&
                            (String(maxStock?.value).includes('.') || String(maxStock?.value).includes(','))
                        ) {
                            jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`].isError = true;
                            jsonAfterCompile[index][`Max Stock Material ${rawMat} (optional)`].messageError =
                                'Jenis material PM tidak boleh float';
                        }
                        /// LOGIC JIKA ROP MEMILIKI TYPE NUMBER FLOAT DISAAT FILTER JENIS MATERIAL PM
                        if (
                            !isEmptyString(rop?.value) &&
                            jenisMaterial?.name === 'Packaging Material' &&
                            (String(rop?.value).includes('.') || String(rop?.value).includes(','))
                        ) {
                            jsonAfterCompile[index][`ROP ${rawMat} (optional)`].isError = true;
                            jsonAfterCompile[index][`ROP ${rawMat} (optional)`].messageError =
                                'Jenis material PM tidak boleh float';
                        }
                    }
                }
                resultJsonAfterValidation.push(jsonAfterCompile[index]);
            }
            const disabledSaveButton = resultJsonAfterValidation?.map((item) => {
                const attribute = Object.keys(item || {});
                let result = false;
                for (let index = 0; index < attribute.length; index++) {
                    if (item[attribute[index]]?.isError) {
                        result = true;
                    }
                }
                return result;
            });
            if (Boolean(resultJsonAfterValidation?.length === 0)) {
                setDisabledSaveItem(true);
                toast({
                    title: 'Error !',
                    description: 'File tidak bisa diupload, cek kembali!',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
            }
            if (Boolean(disabledSaveButton?.find((err) => err))) {
                setDisabledSaveItem(true);
                toast({
                    title: 'Error !',
                    description: 'File tidak bisa diupload, cek kembali!',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
            }
            setPreviewData(resultJsonAfterValidation);
            setState('table-view');
            setFiles(files);
        };
        reader.readAsArrayBuffer(files);
    }
};

export const AutomaticWidthTable = ({ previewData }) => {
    const currentData = previewData?.map((data) => {
        return Object.keys(data || {})?.map((item) => {
            const widthCell = item?.length < data?.[item]?.length ? data?.[item]?.length : item?.length;
            return {
                id: item,
                name: item,
                widthCell: widthCell,
            };
        });
    });
    const findlongestWidth = currentData
        .map((item) => ({ ...item, totalCellWidth: item?.map((value) => value.widthCell).reduce((a, b) => a + b) }))
        .sort((a, b) => b?.totalCellWidth - a?.totalCellWidth);
    let dataResult = [];
    const length = Object.keys(previewData?.[0] || {})?.length;
    for (let index = 0; index < length; index++) {
        dataResult.push(findlongestWidth?.[0][index]);
    }
    return dataResult;
};

export const handleSaveSubmit = ({ setOpenModalConfirm }) => {
    setOpenModalConfirm(true);
};
export const handleSubmit = async ({
    setOpenModalFailed,
    previewData,
    setPreviewData,
    files,
    setIsLoadingUpload,
    setOpenModalSuccess,
    toast,
    setOpenModalConfirm,
    setDisabledSaveItem,
}) => {
    setIsLoadingUpload(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const formData = new FormData();
    formData.append('file', files);
    try {
        await Axios({
            method: 'post',
            url: '/material/config/upload',
            data: formData,
            headers: { Authorization: 'Bearer ' + userToken },
        })
            .then((res) => {
                setOpenModalConfirm(false);
                let rowError =
                    res?.data?.data?.errors?.map((item) => {
                        const isErrorSupplierRef = Boolean(item?.msg?.toLowerCase()?.includes('supplier ref'));
                        const isErrorSupplierRefNotFound = Boolean(
                            item?.msg?.toLowerCase()?.includes('supplier with ref')
                        );
                        const isErrorKodeItem = Boolean(item?.msg?.toLowerCase()?.includes('kode item'));
                        return {
                            ...item,
                            columnName:
                                isErrorSupplierRef && isErrorKodeItem
                                    ? ['Supplier Ref', 'Kode Item']
                                    : isErrorSupplierRef || isErrorSupplierRefNotFound
                                    ? ['Supplier Ref']
                                    : isErrorKodeItem
                                    ? ['Kode Item']
                                    : [''],
                        };
                    }) || [];

                let clonePreviewData = [...previewData];
                const errorCompilation = [];
                rowError?.map((row) => {
                    errorCompilation.push(Number(row?.lineError));
                    row?.columnName?.map((col) => {
                        if (Boolean(col)) {
                            clonePreviewData[Number(row?.lineError) - 1][col].isError = true;
                            clonePreviewData[Number(row?.lineError) - 1][col].messageError = row?.msg;
                        }
                    });
                    return row;
                });

                setPreviewData(clonePreviewData);
                if (clonePreviewData?.length === errorCompilation?.length) {
                    setDisabledSaveItem(true);
                    setOpenModalFailed({
                        isOpen: true,
                        type: 'then',
                        message: rowError,
                    });
                } else if (
                    errorCompilation?.length < clonePreviewData?.length &&
                    Boolean(errorCompilation?.length !== 0)
                ) {
                    setDisabledSaveItem(true);
                    setOpenModalSuccess((prev) => ({
                        ...prev,
                        isOpen: true,
                        message: 'Ada baris item yang gagal dibuat. Tutup modal ini untuk melihat detail.',
                    }));
                } else {
                    setDisabledSaveItem(true);
                    setOpenModalSuccess((prev) => ({
                        ...prev,
                        isOpen: true,
                        message: '',
                    }));
                }
            })
            .catch((err) => {
                console.log(err);
                setDisabledSaveItem(true);
                toast({
                    title: 'Failed',
                    description:
                        err?.response?.data?.errors?.map((item) => item?.message)?.join() || 'Internal server error',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
            })
            .finally(() => {
                setIsLoadingUpload(false);
            });
    } catch (error) {
        console.log(error);
    }
};
export const handleSave = ({ setOpenModalConfirm }) => {
    setOpenModalConfirm(true);
};
export const onDownloadTemplateUpload = async ({ setIsLoading, toast }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: '/material/config/template',
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'template_upload_material_config';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        await toast({
            position: 'center-top',
            description: 'Berhasil Download Template',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    } catch (error) {
        const message = error?.response?.data?.errors?.length ? error?.response?.data?.errors[0]?.message : null;
        console.log(error);
        toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setIsLoading(false);
    }
};

import { Flex, Box } from '@chakra-ui/react';
import './styles.scss';

const TableHead = ({ tableList }) => {
    return (
        <Flex className="table-head__container__main-dashboard">
            {tableList.map((head) => (
                <Box
                    left={0}
                    position={head.isSticky ? 'sticky' : 'static'}
                    flex={head.flex}
                    className="table-head__item"
                    width={head.width}
                    key={head.name}
                    display={head.hiddenHead ? 'none !important' : 'flex'}
                >
                    {head.name}
                </Box>
            ))}
        </Flex>
    );
};

export default TableHead;

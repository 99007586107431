import * as ExcelJS from 'exceljs';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { onDownloadGlobalHandler } from '@store/list/downloadManagement';

export const fetchDataSupplierStock = async ({
    page,
    filter,
    filterStatus,
    setSuppliers,
    setIsLoading,
    setTotalData,
    setTotalPage,
    selectedPerPage,
    searchQuery,
    jenisMaterial,
    supplier,
    disabledLoadingMain,
    setDisabledLoadingMain,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    if (!disabledLoadingMain) {
        setIsLoading(true);
    }
    const { status } = filterStatus;
    const { itemCode, trade_name } = filter;
    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            q: searchQuery,
            page,
            limit: selectedPerPage,
            '$stockSupplier.status$': status,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            '$supplier.jenisMaterial.name$': jenisMaterial?.name,
            '$supplier.name$': supplierFilter,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/stock-supplier?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { totalData, totalPage } = data.data;
        const formatData = data?.data?.data?.map((item) => ({
            ...item,
            stockSupplier: !isEmpty(item.stockSupplier)
                ? {
                      ...item.stockSupplier,

                      stockManufDates:
                          !isEmpty(item?.stockSupplier?.stockManufDates) && !isEmpty(item?.stockSupplier)
                              ? sortManufDates(item?.stockSupplier?.stockManufDates)
                              : item?.stockSupplier?.stockManufDates,
                  }
                : item?.stockSupplier,
        }));
        setTotalData(totalData);
        setTotalPage(totalPage);
        setSuppliers(formatData);
    } catch (error) {
        setTotalData(0);
        setTotalPage(0);
        setSuppliers([]);
    } finally {
        setIsLoading(false);
        setDisabledLoadingMain(false);
    }
};

export const onFormatUpdateDate = (value) => (!isEmpty(value) ? moment(value).format('DD/MM/YYYY') : '-');

export const onChangeFormData = ({ type, setFormData, value, formData }) => {
    const obj = { ...formData };
    obj[type] = value;
    setFormData({ ...obj });
};

export const onDownloadDataSupplierStock = async ({ dispatch, filter, jenisMaterial, supplier, filterStatus }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const downloadId = Date.now() * Math.floor(Math.random() * 100000);
    const { itemCode, trade_name } = filter;
    const { status } = filterStatus;
    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            '$stockSupplier.status$': status,
            '$supplier.jenis_material_id$': jenisMaterial?.id,
            '$supplier.name$': supplierFilter,
        },
        { skipEmptyString: true }
    );
    dispatch(
        onDownloadGlobalHandler({
            name: 'supplier stock',
            type: 'loading',
            id: downloadId,
            title: 'Menyiapkan dowload data Supplier Stock table',
        })
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/stock-supplier/download?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'download_data_supplier_stock';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        dispatch(
            onDownloadGlobalHandler({
                name: 'supplier stock',
                type: 'done',
                id: downloadId,
            })
        );
    } catch (error) {
        console.log(error);
        dispatch(
            onDownloadGlobalHandler({
                name: 'supplier stock',
                type: 'error',
                id: downloadId,
                title: 'Dowload data Supplier Stock table gagal',
            })
        );
    }
};

export const getColorHandler = ({ status }) => {
    switch (status) {
        case 'OK':
            return '#D9F2E3';
        case 'PRODUCTION':
            return '#CCE2FF';
        case 'OVERSTOCK':
            return '#FDBDC3';
        default:
            return '#0000';
    }
};

export const getColorTextHandler = ({ status }) => {
    switch (status) {
        case 'OK':
            return '#267346';
        case 'PRODUCTION':
            return '#004199';
        case 'OVERSTOCK':
            return '#EA4F4F';
        default:
            return '#0000';
    }
};

export const getCurrMap = ({ value }) => {
    const currMap = [];

    if (value?.length < 3) {
        for (let i = 0; i < value?.length; i++) {
            currMap.push(i);
        }
    } else {
        currMap.push(0, 1, 2);
    }
    return currMap;
};

export const sortManufDates = (value) =>
    value?.sort((a, b) => new Date(a.manufacturing_date) - new Date(b.manufacturing_date));

export const getCurrtLengthDates = ({ value }) => {
    const currLength = !isEmpty(value) && value?.length > 3 ? value?.length - 3 : 0;
    return currLength;
};

export const getValueTooltip = ({ value }) =>
    !isEmpty(value) && value?.length > 3
        ? value
              ?.slice(3, value?.length)
              ?.map((item) => item?.manufacturing_date)
              ?.join(', ')
              ?.toString()
        : '-';

const onDataFormattedExcel = ({ data = [] }) => {
    const newData = [];
    const headers = [];
    data.forEach((v) => {
        newData.push({
            STATUS: v.status || '-',
            'KODE ITEM': v.kode_item || '-',
            'NAMA ITEM': v.nama_item || '-',
            UOM: v.uom || '-',
            'ROP SUPPLIER': v.rop_supplier || '-',
            'MAX STOCK SUPPLIER': v.max_stock_supplier || '-',
            'MPQ SUPPLIER': v.mpq_supplier || '-',
            'PRODUCTION TARGET': v.production_target || '-',
            'SOH SUPPLIER': v.soh_supplier || '-',
            'ON PROCESS': v.on_process || '-',
            'MANUFACTURING DATE': v.manufacturing_date || '-',
            NOTES: v.notes || '-',
            'LAST UPDATE': v.last_update || '-',
        });
    });

    return { newData, headers };
};

export const onExportExcelST = ({ setIsLoading }) => {
    setIsLoading(true);
    const dummyDatas = [
        {
            status: 'Produksi',
            kode_item: 12345678,
            nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
            uom: 'Pcs',
            rop_supplier: '200,000',
            max_stock_supplier: '200,000',
            mpq_supplier: '200,000',
            production_target: '999,999,999',
            soh_supplier: '500.000',
            on_process: '500.000',
            manufacturing_date: '12/09/2922',
            notes: 'Sedang Produksi',
            last_update: '12/09/2023',
        },
        {
            status: 'Produksi',
            kode_item: 12345678,
            nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
            uom: 'Pcs',
            rop_supplier: '200,000',
            max_stock_supplier: '200,000',
            mpq_supplier: '200,000',
            production_target: '999,999,999',
            soh_supplier: '500.000',
            on_process: '500.000',
            manufacturing_date: '12/09/2922',
            notes: 'Sedang Produksi',
            last_update: '12/09/2023',
        },
    ];

    const { newData, headers } = onDataFormattedExcel({ data: dummyDatas });
    Object.keys(newData[0]).forEach((v) => {
        headers.push(v);
    });
    // INITILIZE EXCELJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.addRow(headers);

    // looping for value excel
    newData.forEach((item, _idx) => {
        worksheet.addRow(Object.values(item));
    });
    // set width for each column
    for (let i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 52;
    }

    // set bold for header
    const selectedRows = [];
    worksheet.eachRow((row, rowNumber) => {
        if ([1].indexOf(rowNumber) !== -1) {
            selectedRows.push(row);
        }
    });

    selectedRows.forEach((row) => {
        row.eachCell((cell) => {
            cell.font = {
                bold: true,
            };
            cell.alignment = { horizontal: 'center' };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `supplier_stock_${new Date().toDateString()}.xlsx`;
        link.click();
        setIsLoading(false);
    });
};

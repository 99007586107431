import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onDownloadDataRACI = async ({ setLoadingState, toast }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setLoadingState(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/raci/file/download`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'material_config_raci';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        setLoadingState(false);
    } catch (error) {
        console.log(error);
        toast({
            position: 'top-center',
            description: 'Gagal Download Data RACI',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        setLoadingState(false);
    }
};

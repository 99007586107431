export const formDefaultValue = {
    streamlineName: '',
    lineKemas: [],
    lineOlah1: [],
    lineOlah2: [],
    lineTimbang: [],
    zone: '',
};

export const lineList = [
    { label: 'Line Timbang', arrName: 'timbangList', stateName: 'lineTimbang', errorName: 'Field timbang requiered' },
];

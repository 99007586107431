import { Text, Box, Flex } from '@chakra-ui/react';
import { EditIcon, RemoveIcon } from './icons';

export const tableList = [
    {
        name: 'ID',
        value: 'id',
        width: 50,
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'STREAMLINE',
        width: 200,
        value: 'name',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'LINE TIMBANG',
        width: 172,
        flex: 1,
        value: 'timbang',
        component: ({ value }) => (
            <Box>
                {value?.map((item) => (
                    <Text className="column-text">{item.name}</Text>
                ))}
            </Box>
        ),
    },
    {
        name: 'Line PREMIX & olah 1',
        width: 172,
        flex: 1,
        value: 'olah1',
        component: ({ value }) => (
            <Box>
                {value?.map((item) => (
                    <Text className="column-text">{item.name}</Text>
                ))}
            </Box>
        ),
    },
    {
        name: 'LINE OLAH 2',
        width: 172,
        flex: 1,
        value: 'olah2',
        component: ({ value }) => (
            <Box>
                {value?.map((item) => (
                    <Text className="column-text">{item.name}</Text>
                ))}
            </Box>
        ),
    },
    {
        name: 'LINE KEMAS',
        width: 172,
        flex: 1,
        value: 'kemas',
        component: ({ value }) => (
            <Box>
                {value?.map((item) => (
                    <Text className="column-text">{item.name}</Text>
                ))}
            </Box>
        ),
    },
    {
        name: 'ACTIONS',
        value: null,
        width: 100,
        isCenter: true,
        component: ({ onSelectedStreamline, streamline, onChangeModalState, accessPermission }) => {
            return (
                <Flex w="100%" justify={'center'}>
                    <Box
                        // className="icon-edit"
                        cursor={accessPermission?.streamLine?.isCanEditStreamline ? 'pointer' : 'not-allowed'}
                        opacity={accessPermission?.streamLine?.isCanEditStreamline ? 1 : 0.2}
                        style={{ border: '1px solid #1746A2', borderRadius: 4, padding: 3 }}
                        h="max-content"
                        mr="12px"
                        onClick={() => {
                            if (accessPermission?.streamLine?.isCanEditStreamline) {
                                onSelectedStreamline({ value: streamline });
                                onChangeModalState({ value: 'modal-edit' });
                            }
                        }}
                    >
                        <EditIcon />
                    </Box>
                    <Box
                        style={{ border: '1px solid #EF5350', borderRadius: 4, padding: 3 }}
                        h="max-content"
                        cursor={accessPermission?.streamLine?.isCanDeleteStreamline ? 'pointer' : 'not-allowed'}
                        opacity={accessPermission?.streamLine?.isCanDeleteStreamline ? 1 : 0.2}
                        rounded={'4px'}
                        border={'1px solid'}
                        borderColor={'#EF5350'}
                        onClick={() => {
                            if (accessPermission?.streamLine?.isCanDeleteStreamline) {
                                onSelectedStreamline({ value: streamline });
                                onChangeModalState({ value: 'modal-delete' });
                            }
                        }}
                    >
                        <RemoveIcon />
                    </Box>
                </Flex>
            );
        },
    },
];

import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import * as ExcelJS from 'exceljs';
import queryString from 'query-string';

const onSkuDataFormatting = ({ listSku }) => {
    return listSku?.map((item) => {
        return {
            ...item,
            maximum: item?.critical_point?.maximum,
            rop: item?.critical_point?.rop,
            safety: item?.critical_point?.safety,
            stock_nasional: {
                standar_stock_nasional: item?.standar_stock_nasional,
                stock_nasional: item?.stock_nasional,
            },
            stock_ndc: {
                pcs: item?.stock_ndc,
                pallet: item?.stock_ndc_palet,
            },
        };
    });
};
export const fetchSkuHandler = async ({
    setSku,
    setTotalData,
    setTotalPage,
    page,
    sortName,
    setIsLoading,
    searchQuery,
    selectedMaterial,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const Authorization = { Authorization: 'Bearer ' + userToken };

    const filterStringify = queryString.stringify(
        {
            sort: `${sortName.sort},${sortName.typeBol ? 'asc' : 'desc'}`,
            includeStreamline: true,
            page,
            q: searchQuery,
            sediaan_id: selectedMaterial?.id,
            limit: 10,
        },
        { skipEmptyString: true }
    );
    try {
        setIsLoading(true);
        const { data } = await Axios({
            method: 'GET',
            url: `/management/sku/list?${filterStringify}`,
            headers: Authorization,
        });
        const { totalData, totalPage, listSku } = data.data;
        setTotalData(totalData);
        setTotalPage(totalPage);
        const onSkuDataFormatted = onSkuDataFormatting({ listSku });
        setSku(onSkuDataFormatted);
    } catch (error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
};

const onCheckKeysOfCells = (v) => {
    const colsNdcStock = 'F,G';
    const colsCoMingguan = 'H,I,J';
    const colsLeadTime = 'K,L,M,N,O,P,Q';
    const colsSafety = 'R,S';
    const colsRop = 'T,U';
    const colsMaximum = 'V,W';
    const colsNasionalStock = 'X,Y';

    switch (v) {
        case 'NDC STOCK':
            return colsNdcStock;
        case 'STOK NASIONAL':
            return colsNasionalStock;
        case 'CO MINGGUAN':
            return colsCoMingguan;
        case 'LEAD TIME (JAM)':
            return colsLeadTime;
        case 'SAFETY':
            return colsSafety;
        case 'ROP':
            return colsRop;
        case 'MAXIMUM':
            return colsMaximum;
        default:
            return '';
    }
};

const onDataFormattedExcel = ({ data = [] }) => {
    const newData = [];
    const headers = [];

    data.forEach((v) => {
        newData.push({
            'ODOO CODE': v.odoo_code || '-',
            'KODE SL': v.kode_sl || '-',
            'KODE ITEM': v.kode_item || '-',
            'NAMA ITEM': v.nama_item || '-',
            'DEDICATED STREAMLINE': v.jenis_storage_ndc || '-',

            'NDC STOCK': {
                PCS: `${v?.stock_ndc?.pcs || '-'}`,
                STORAGE: `${v.stock_ndc.pallet || '-'}`,
            },
            'CO MINGGUAN': {
                PCS: `${v?.customer_order?.pcs || '-'}`,
                'STORAGE NDC': `${v?.customer_order?.storage || '-'}`,
                KOLI: `${v?.customer_order?.koli || '-'}`,
            },
            'LEAD TIME (JAM)': {
                TIMBANG: v?.leadtime?.timbang || '-',
                PREMIX: v?.leadtime?.premix || '-',
                'OLAH 1': v?.leadtime?.olah_1 || '-',
                'OLAH 2': v?.leadtime?.olah_2 || '-',
                KEMAS: v?.leadtime?.kemas || '-',
                'LAIN-LAIN': v?.leadtime?.others || '-',
                TOTAL: v?.leadtime?.total || '-',
            },
            SAFETY: {
                PCS: v?.critical_point?.safety?.pcs || '-',
                'STRG NDC': v?.critical_point?.safety?.strgNdc || '-',
            },
            ROP: {
                PCS: v?.critical_point?.rop?.pcs || '-',
                'STRG NDC': v?.critical_point?.rop?.strgNdc || '-',
            },
            MAXIMUM: {
                PCS: v?.critical_point?.maximum?.pcs || '-',
                'STRG NDC': v?.critical_point?.maximum?.strgNdc || '-',
            },
            'STOK NASIONAL': {
                STANDAR: v?.stock_nasional?.standar_stock_nasional || '-',
                TOTAL: v?.stock_nasional?.stock_nasional || '-',
            },
        });
    });

    return { newData, headers };
};

export const onExportToExcel = ({ selectedData = [] }) => {
    const { newData, headers } = onDataFormattedExcel({ data: selectedData });

    Object.keys(newData[0]).forEach((v) => {
        headers.push(v);
    });

    //  DEFINE SOME HEADERS
    headers.splice(headers.indexOf('NDC STOCK') + 1, 0, '');
    headers.splice(headers.indexOf('CO MINGGUAN') + 1, 0, '', '');
    headers.splice(headers.indexOf('SAFETY') + 1, 0, '');
    headers.splice(headers.indexOf('ROP') + 1, 0, '');
    headers.splice(headers.indexOf('MAXIMUM') + 1, 0, '');
    headers.splice(headers.indexOf('STOK NASIONAL') + 1, 0, '');

    headers.splice(headers.indexOf('LEAD TIME (JAM)') + 1, 0, '', '', '', '', '', '');

    // INITILIZE EXCELJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.addRow(headers);

    // RENAME HEADERS

    // DEFINE CELL MERGE
    const cellsMerge = [
        'A1:A3',
        'B1:B3',
        'C1:C3',
        'D1:D3',
        'E1:E3',
        'F1:G1',
        'H1:J1',
        'K1:Q1',
        'F2:F3',
        'G2:G3',
        'H2:H3',
        'I2:I3',
        'J2:J3',
        'K2:K3',
        'L2:L3',
        'M2:M3',
        'N2:N3',
        'O2:O3',
        'P2:P3',
        'Q2:Q3',
        'R1:S1',
        'T1:U1',
        'V1:W1',
        'X1:Y1',
        'R2:R3',
        'S2:S3',
        'T2:T3',
        'U2:U3',
        'V2:V3',
        'W2:W3',
        'X2:X3',
        'Y2:Y3',
    ];

    const subHeader = [];
    // NDC STOCK
    subHeader[6] = 'PCS';
    subHeader[7] = 'STORAGE';
    // CO MINGGUAN
    subHeader[8] = 'PCS';
    subHeader[9] = 'STORAGE NDC';
    subHeader[10] = 'KOLI';
    // LEAD TIME
    subHeader[11] = 'TIMBANG';
    subHeader[12] = 'PREMIX';
    subHeader[13] = 'OLAH 1';
    subHeader[14] = 'OLAH 2';
    subHeader[15] = 'KEMAS';
    subHeader[16] = 'LAIN LAIN';
    subHeader[17] = 'TOTAL';
    // CRITICAL POINT
    subHeader[18] = 'PCS';
    subHeader[19] = 'STRG NDC';
    subHeader[20] = 'PCS';
    subHeader[21] = 'STRG NDC';
    subHeader[22] = 'PCS';
    subHeader[23] = 'STRG NDC';
    subHeader[24] = 'STANDAR';
    subHeader[25] = 'TOTAL';

    //NASIONAL

    worksheet.addRow(subHeader);

    const subsHeader2 = [];

    worksheet.addRow(subsHeader2);
    cellsMerge.forEach((v) => worksheet.mergeCells(v));

    newData.forEach((item, _idx) => {
        const arrKeys = 4 + _idx;
        worksheet.addRow(Object.values(item));

        Object.entries(item).forEach(([key, v]) => {
            const setKey = onCheckKeysOfCells(key);
            console.log(v);
            if (typeof v === 'object') {
                Object.entries(v).forEach(([key, vt], idx) => {
                    const setKeys = onCheckKeysOfCells(key);
                    if (typeof vt === 'object') {
                        Object.entries(v).forEach(([_, v]) => {
                            Object.entries(v).forEach(([_, y], idx) => {
                                const cells = setKeys.split(',')[idx];
                                worksheet.getCell(`${cells}${arrKeys}`).value = y;
                            });
                        });
                    } else {
                        const cells = setKey.split(',')[idx];
                        worksheet.getCell(`${cells}${arrKeys}`).value = vt;
                    }
                });
            }
        });
    });
    // set width for each column
    for (let i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 20;
    }

    // set bold for header
    const selectedRows = [];
    worksheet.eachRow((row, rowNumber) => {
        if ([1, 2, 3].indexOf(rowNumber) !== -1) {
            selectedRows.push(row);
        }
    });

    selectedRows.forEach((row) => {
        row.eachCell((cell) => {
            cell.font = {
                bold: true,
            };
            cell.alignment = { horizontal: 'center' };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `sku_config_${new Date().toDateString()}.xlsx`;
        link.click();
    });
};

export const numberToFixed = (num) => {
    if (num || num === 0) {
        return Number(num.toFixed(3)) % num ? num.toFixed(3) : num;
    }
    return '-';
};

export const sortSwitch = ({ previousState, currentState }) => {
    if (previousState.sort !== currentState.sortValue) {
        return { sort: currentState.sortValue, typeBol: previousState.typeBol };
    } else {
        return { sort: currentState.sortValue, typeBol: !previousState.typeBol };
    }
};

export const numberToFixedLeadtime = (num) => {
    if (num || num === 0) {
        return Number(num.toFixed(2)) % num ? num.toFixed(2) : num;
    }

    return '-';
};

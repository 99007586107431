import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toggleExtendSideMenu } from '@store/list/sideMenu';
import Header from '@components/header';
import SectionHeadGlobal from '@components/sectionHeadGlobal';
import SideMenu from '@components/sideMenu';
import './styles.scss';
import HideSidebar from '@components/hideSidebar';
import { isForbiddenPage } from '@helpers/functions';
import { fetchUserProfile } from '@store/list/accessPermission';
import ForbiddenPage from '@components/forbiddenPage';
import { jenisMaterialCookieSet, protectedUserManagementRoute } from './function';
import Cookies from 'universal-cookie';
import { onChangeJenisMaterialType } from '@store/list/globalFilter';

const DashboardContainer = ({ children }) => {
    const cookies = new Cookies();
    const jenisMaterialCookies = cookies.get('jenisMaterial');
    const dispatch = useDispatch();
    const { isHide } = useSelector((state) => state.sideMenuSlice);
    const { routeList } = useSelector((reducer) => reducer.accessPermissionSlice);
    const [isForbidden, setIsForbidden] = useState(false);
    const { selectedMaterial } = useSelector((reducer) => reducer.materialSlice);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isLogin = useSelector((state) => state.loginSlice.isLogin);
    const togglerHideLeftSide = () => dispatch(toggleExtendSideMenu(!isHide));

    useEffect(() => {
        if (isLogin && pathname !== '/login' && !isEmpty(selectedMaterial)) {
            dispatch(fetchUserProfile({ selectedMaterial }));
        }
    }, [dispatch, pathname, isLogin, selectedMaterial]);

    useEffect(() => {
        if (!isLogin && pathname !== '/login') {
            navigate('/login');
        }
        if (pathname !== '/lobby') {
            protectedUserManagementRoute({ currentAppType: cookies.get('appType'), navigate, pathname });
        }

        if (isLogin && pathname === '/login') {
            navigate('/');
        }
    }, [pathname, isLogin, navigate]);

    useEffect(() => {
        if (routeList?.length && pathname && pathname !== '/lobby' && pathname !== '/user-management') {
            setIsForbidden(isForbiddenPage({ routeList, type: pathname }));
        }
    }, [routeList, pathname]);
    useEffect(() => {
        dispatch(onChangeJenisMaterialType(jenisMaterialCookieSet({ jenisMaterialCookies })));
    }, [jenisMaterialCookies]);

    const isUserManagement = pathname.includes('/user-management');
    if (isLogin && pathname !== '/login') {
        return (
            <Box className={`page-container`}>
                {/** -------------- LEFT SECTION ---------------- */}

                {!isUserManagement && (
                    <Box className={`dashboard-section-left__container`}>
                        {isHide && pathname !== '/lobby' && <HideSidebar togglerHideLeftSide={togglerHideLeftSide} />}
                        {!isHide && pathname !== '/lobby' && <SideMenu togglerHideLeftSide={togglerHideLeftSide} />}
                    </Box>
                )}

                {/** -------------- RIGHT SECTION ---------------- */}
                <Box
                    className={`dashboard-section-right__container  ${isHide && 'dashboard-body__container'} ${
                        pathname === '/lobby' && 'lobbypage-img-bg'
                    }`}
                >
                    {!isUserManagement && <Header navigate={navigate} />}
                    <Box
                        className={`dashboard-content__container`}
                        sx={{
                            bgColor: pathname === '/lobby' ? 'none' : '#f8f8f8 !important',
                            padding: isUserManagement ? '0px !important' : '26px 16px !important',
                        }}
                    >
                        {pathname !== '/' && <SectionHeadGlobal />}

                        {isForbidden ? <ForbiddenPage /> : children}
                    </Box>
                </Box>
            </Box>
        );
    } else if (pathname === '/login') {
        return children;
    } else {
        return <></>;
    }
};
export default DashboardContainer;

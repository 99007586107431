import dateFormat from 'dateformat';

export const handleClickMenu = ({ selected, setFilterSelected }) => {
    setFilterSelected(selected);
};

export const onFormatDate = (value) => {
    const date = new Date(value);
    return dateFormat(date, 'yyyy-mm-dd');
};

export const handleCheckboxChange = ({ checked, selectedData, data, setData, dataChoosed, setDataChoosed }) => {
    let clone = [...data];
    let cloneCodeItemChoosed = [...dataChoosed];

    if (checked) {
        cloneCodeItemChoosed.push({ ...selectedData, checked });
    }
    if (!checked) {
        const unChecked = cloneCodeItemChoosed?.filter((item) => item?.name !== selectedData?.name);
        cloneCodeItemChoosed = unChecked;
    }
    clone.map((userItem) => {
        if (userItem?.name === selectedData?.name) {
            userItem.checked = checked;
        }
        return userItem;
    });
    setDataChoosed(cloneCodeItemChoosed);
    setData(clone);
};

export const handleCloseModalFilter = ({
    setOpenModalFilter,
    setData,
    selectedDataItem,
    data,
    type = 'close',
    setDataChoosed,
}) => {
    let selectedUserBeforeApply = [...selectedDataItem];
    let cloneAllData = [...data];
    cloneAllData.map((user_item) => {
        user_item.checked = false;
        if (type !== 'reset') {
            selectedUserBeforeApply?.map((before_apply) => {
                if (user_item?.name === before_apply?.name) {
                    user_item.checked = true;
                }
                return before_apply;
            });
        }
        return user_item;
    });
    setData(cloneAllData);
    setDataChoosed([]);
    if (type === 'close') {
        setDataChoosed(selectedDataItem);
        setOpenModalFilter(false);
    }
};



export const initialValue = {
    dataLengkap: {
        supplier_name: '',
        supplier_code: '',
        trade_name: '',
        code_item: '',
        internal_name: '',
        jenis_material: '',
        supplier_category: '',
        mto_mts: '',
        close_po_by: '',
    },
    konfigurasiSatuan: {
        satuan_order: '',
        jenis_kemasan: '',
        jenis_kemasan_pcs_koli: '',
        jenis_kemasan_koli_pallet: '',
        jenis_kemasan_pcs_pallet: '',
    },
    baselinePerWeek: {
        pcs: '',
        koli: '',
        pallet: '',
    },
    avgPerDay: {
        pcs: '',
        koli: '',
        pallet: '',
    },
    critical_point: {
        stdev: '',
        safety_by_demand_pcs: '',
        safety_by_demand_hari: '',
        frekuensi_delivery: '',
        lead_time_produksi: '',
        lead_time_delivery: '',
        mpq_pti: '',
        mpq_supplier: '',
        mdq_supplier: '',
        safety_vulnerability: '',
        safety_by_lead_time: '',
        rop_ospo: '',
    },
    stock_pti: {
        rop: '',
        max_stock_pcs: '',
        max_stock_koli: '',
        max_stock_pallet: '',
    },
    stock_supplier: {
        rop: '',
        max_stock_pcs: '',
        max_stock_koli: '',
        max_stock_pallet: '',
    },
    stock_raw_material: [
        {
            id: 1,
            material_name: '',
            rop: '',
            max_stock: '',
            delete: false,
            is_new: true,
        },
    ],
};
export const supplierCategoryOption = [
    { name: 'Local', value: 'local' },
    { name: 'Import', value: 'import' },
];
export const formListDataLengkap = [
    {
        name: 'supplier_name',
        api: '/supplier/list',
        title: 'Nama Supplier',
        class: 'dataLengkap',
        placeholder: 'masukan nama supplier',
        isDisabled: false,
        isRequired: true,
        isAutoComplete: true,
        option: [{ name: 'A' }, { name: 'B' }, { name: 'C' }, { name: 'D' }],
    },
    {
        name: 'supplier_code',
        title: 'Kode Supplier',
        class: 'dataLengkap',
        placeholder: 'Terisi otomatis',
        isDisabled: true,
        isRequired: false,
    },
    {
        name: 'trade_name',
        api: '/material/list',
        title: 'Trade Name',
        isAutoComplete: true,
        class: 'dataLengkap',
        placeholder: 'pilih trade name',
        isDisabled: false,
        isRequired: true,
        option: [{ name: 'A' }, { name: 'B' }, { name: 'C' }, { name: 'D' }],
    },
    {
        name: 'code_item',
        title: 'Kode Item',
        class: 'dataLengkap',
        placeholder: 'Terisi otomatis',
        isDisabled: true,
        isRequired: false,
    },
    {
        name: 'internal_name',
        title: 'Internal Name',
        class: 'dataLengkap',
        placeholder: 'Terisi otomatis',
        isDisabled: true,
        isRequired: false,
    },
    {
        name: 'jenis_material',
        title: 'Jenis Material',
        class: 'dataLengkap',
        placeholder: 'Terisi otomatis',
        isDisabled: true,
        isRequired: false,
    },
    {
        name: 'supplier_category',
        title: 'Kategori Supplier',
        type: 'select',
        class: 'dataLengkap',
        placeholder: 'cari item',
        isDisabled: false,
        isRequired: true,
        option: supplierCategoryOption,
    },
    {
        name: 'mto_mts',
        title: 'MTO/MTS',
        type: 'select',
        class: 'dataLengkap',
        placeholder: 'cari item',
        isDisabled: false,
        isRequired: true,
        option: [
            { name: 'MTO', value: 'mto' },
            { name: 'MTS', value: 'mts' },
        ],
    },
    {
        name: 'close_po_by',
        title: 'Close PO By',
        type: 'select',
        class: 'dataLengkap',
        placeholder: 'cari item',
        isDisabled: false,
        isRequired: true,
        option: [
            { name: 'Due Date', value: 'due_date' },
            { name: 'Quantity', value: 'quantity' },
        ],
    },
];

export const formListKonfigurasiSatuan = [
    {
        name: 'satuan_order',
        class: 'konfigurasiSatuan',
        title: 'Satuan Order',
        type: 'select',
        placeholder: 'pilih satuan order',
        isDisabled: false,
        isRequired: true,
        apiOption: true,
        option: [],
    },
    {
        name: 'jenis_kemasan',
        title: 'Jenis Kemasan',
        class: 'konfigurasiSatuan',
        type: 'normal',
        placeholder: 'cari trade name',
        isRequired: false,
        children: [
            {
                name: 'jenis_kemasan',
                class: 'konfigurasiSatuan',
                placeholder: 'Terisi otomatis',
                id: '1',
            },
            {
                name: 'jenis_kemasan_pcs_koli',
                placeholder: 'Terisi otomatis',
                id: '2',
                class: 'konfigurasiSatuan',
                rightLabel: 'Pcs/Koli',
            },
            {
                name: 'jenis_kemasan_koli_pallet',
                placeholder: 'Terisi otomatis',
                id: '3',
                class: 'konfigurasiSatuan',
                rightLabel: 'Koli/Pallet',
            },
            {
                name: 'jenis_kemasan_pcs_pallet',
                placeholder: 'Terisi otomatis',
                id: '4',
                class: 'konfigurasiSatuan',
                rightLabel: 'Pcs/Pallet',
            },
        ],
    },
];
export const formListPerWeek = [
    {
        name: 'jenis_kemasan',
        title: 'Jenis Kemasan',
        class: 'baselinePerWeek',
        type: 'normal',
        placeholder: 'cari trade name',
        isRequired: false,
        children: [
            {
                name: 'pcs',
                rightLabel: 'Pcs',
                class: 'baselinePerWeek',
                isDisabled: false,
                placeholder: 'Masukan nominal',
                mustIntegerIfPm: true,
            },
            {
                name: 'koli',
                rightLabel: 'Koli',
                isDisabled: true,
                class: 'baselinePerWeek',
                placeholder: 'Terisi otomatis',
            },
            {
                name: 'pallet',
                rightLabel: 'Pallet',
                class: 'baselinePerWeek',
                isDisabled: true,
                placeholder: 'Terisi otomatis',
            },
        ],
    },
];
export const formListAVGBaseline = [
    {
        name: 'jenis_kemasan',
        title: 'Jenis Kemasan',
        type: 'normal',
        class: 'avgPerDay',
        placeholder: 'cari trade name',
        isRequired: false,
        children: [
            {
                name: 'pcs',
                class: 'avgPerDay',
                rightLabel: 'Pcs',
                isDisabled: true,
                placeholder: 'Terisi otomatis',
            },
            {
                name: 'koli',
                class: 'avgPerDay',
                rightLabel: 'Koli',
                isDisabled: true,
                placeholder: 'Terisi otomatis',
            },
            {
                name: 'pallet',
                class: 'avgPerDay',
                rightLabel: 'Pallet',
                isDisabled: true,
                placeholder: 'Terisi otomatis',
            },
        ],
    },
];

export const formListCriticalPoint = [
    {
        name: 'stdev',
        children: [
            {
                name: 'stdev',
                class: 'critical_point_controller',
                placeholder: 'Masukan nominal',
                title: 'Stdev',
                rightLabel: 'Pcs',
                isRequired: true,
                mustIntegerIfPm: true,
            },
        ],
    },
    {
        name: '',
        children: [
            {
                name: 'frekuensi_delivery',
                class: 'critical_point',
                title: 'Frekuensi Delivery',
                placeholder: 'Masukan nominal',
                rightLabel: 'Hari',
                isRequired: true,
            },
            {
                name: 'lead_time_produksi',
                class: 'critical_point',
                title: 'Lead Time Produksi',
                placeholder: 'Masukan nominal',
                rightLabel: 'Hari',
                isRequired: true,
            },
            {
                name: 'lead_time_delivery',
                class: 'critical_point',
                title: 'Lead Time Delivery',
                placeholder: 'Masukan nominal',
                rightLabel: 'Hari',
                isRequired: true,
            },
        ],
    },
    {
        name: 'safety_by_demand',
        children: [
            {
                name: 'safety_by_demand_pcs',
                class: 'critical_point',
                title: 'Safety by demand',
                placeholder: 'Terisi otomatis',
                rightLabel: 'Pcs',
                isDisabled: true,
                isRequired: false,
            },
            {
                name: 'safety_by_demand_hari',
                class: 'critical_point_controller',
                placeholder: 'Terisi otomatis',
                rightLabel: 'Hari',
                isDisabled: true,
                isRequired: false,
            },
        ],
    },
    {
        name: '',
        children: [
            {
                name: 'mpq_pti',
                class: 'critical_point',
                title: 'MPQ PTI',
                placeholder: 'Masukan nominal',
                rightLabel: 'Pcs',
                isRequired: true,
                mustIntegerIfPm: true,
            },
            {
                name: 'mpq_supplier',
                class: 'critical_point',
                title: 'MPQ Supplier',
                placeholder: 'Masukan nominal',
                rightLabel: 'Pcs',
                isRequired: true,
                mustIntegerIfPm: true,
            },
            {
                name: 'mdq_supplier',
                class: 'critical_point',
                title: 'MDQ Supplier',
                placeholder: 'Masukan nominal',
                rightLabel: 'Kanban',
                isRequired: true,
                mustIntegerIfPm: true,
            },
        ],
    },
    {
        name: '',
        children: [
            {
                name: 'safety_vulnerability',
                class: 'critical_point',
                title: 'Safety Vulnerability (Optional)',
                placeholder: 'Masukan nominal',
                rightLabel: 'Hari',
                isRequired: false,
            },
            {
                name: 'safety_by_lead_time',
                class: 'critical_point',
                title: 'Safety By Lead Time (Optional)',
                placeholder: 'Masukan nominal',
                rightLabel: 'Hari',
                isRequired: false,
            },
            {
                name: 'rop_ospo',
                class: 'critical_point',
                title: 'ROP OSPO (Optional)',
                placeholder: 'Masukan nominal',
                rightLabel: 'Pcs',
                isRequired: false,
            },
        ],
    },
];

export const formListStockPTI = [
    {
        name: 'rop',
        title: 'ROP',
        type: 'normal',
        placeholder: 'Masukan nominal',
        isDisabled: false,
        isRequired: true,
        rightLabel: 'Pcs',
        class: 'stock_pti',
    },
    {
        name: 'max_stock',
        title: 'MAX Stock',
        type: 'normal',
        isRequired: true,
        children: [
            {
                name: 'max_stock_pcs',
                rightLabel: 'Pcs',
                isDisabled: false,
                class: 'stock_pti_controller',
                placeholder: 'Masukan nominal',
                isRequired: true,
            },
            {
                name: 'max_stock_koli',
                rightLabel: 'Koli',
                isDisabled: true,
                class: 'stock_pti_controller',
                placeholder: 'Terisi otomatis',
                isRequired: false,
            },
            {
                name: 'max_stock_pallet',
                rightLabel: 'Pallet',
                isDisabled: true,
                class: 'stock_pti_controller',
                placeholder: 'Terisi otomatis',
                isRequired: false,
            },
        ],
    },
];
export const formListStockSupplier = [
    {
        name: 'rop',
        class: 'stock_supplier',
        title: 'ROP',
        type: 'normal',
        placeholder: 'Masukan nominal',
        isDisabled: false,
        isRequired: true,
        rightLabel: 'Pcs',
    },
    {
        name: 'max_stock',
        title: 'MAX Stock',
        type: 'normal',
        isRequired: true,
        children: [
            {
                name: 'max_stock_pcs',
                class: 'stock_supplier_controller',
                rightLabel: 'Pcs',
                isDisabled: false,
                placeholder: 'Masukan nominal',
                isRequired: true,
            },
            {
                name: 'max_stock_koli',
                class: 'stock_supplier_controller',
                rightLabel: 'Koli',
                isDisabled: true,
                placeholder: 'Terisi otomatis',
                isRequired: false,
            },
            {
                name: 'max_stock_pallet',
                class: 'stock_supplier_controller',
                rightLabel: 'Pallet',
                isDisabled: true,
                placeholder: 'Terisi otomatis',
                isRequired: false,
            },
        ],
    },
];

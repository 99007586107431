export const attributeMaterialConfigTemplate = [
    {
        ['Baseline PCS']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['Close PO By']: {
            type: 'string',
            isRequired: true,
            option: ['Quantity', 'Due Date'],
        },
        ['Delivery Freq']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['Delivery Leadtime']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['Kode Item']: {
            type: 'string',
            isRequired: true,
        },
        ['Supplier Category']: {
            type: 'string',
            isRequired: true,
            option: ['local', 'import'],
        },
        ['MDQ Supplier']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['MPQ PTI']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['MPQ Supplier']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['MTO']: {
            type: 'string',
            isRequired: true,
            option: ['mto', 'mts'],
        },
        ['Max Stock Material 1 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['Max Stock Material 2 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['Max Stock Material 3 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['Max Stock Material 4 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['Max Stock Material 5 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['Max Stock PTI (pallet)']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['Max Stock Supplier (pcs atau kg)']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['Nama Material 1 (optional)']: {
            type: 'string',
            isRequired: false,
        },
        ['Nama Material 2 (optional)']: {
            type: 'string',
            isRequired: false,
        },
        ['Nama Material 3 (optional)']: {
            type: 'string',
            isRequired: false,
        },
        ['Nama Material 4 (optional)']: {
            type: 'string',
            isRequired: false,
        },
        ['Nama Material 5 (optional)']: {
            type: 'string',
            isRequired: false,
        },
        ['Production Leadtime']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['ROP 1 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['ROP 2 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['ROP 3 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['ROP 4 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['ROP 5 (optional)']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['ROP OSPO']: {
            type: 'number',
            isRequired: false,
            disabledFloatIfRM: true,
        },
        ['STDEV']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['Safety Leadtime']: {
            type: 'number',
            isRequired: false,
        },
        ['Safety Vulnerability']: {
            type: 'number',
            isRequired: false,
        },
        ['Satuan Order']: {
            type: 'string',
            isRequired: true,
            option: ['Koli', 'Pallet', 'Kemasan'],
        },
        ['Stock PTI ROP']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['Stock Supplier ROP']: {
            type: 'number',
            isRequired: true,
            disabledFloatIfRM: true,
        },
        ['Supplier Ref']: {
            type: 'string',
            isRequired: true,
        },
    },
];

import { Box, Flex } from '@chakra-ui/react';
import TableHead from '../../components/tableHead';
import TableColumn from '../../components/tableColumn';
import './styles.scss';

const SectionTable = ({ orderDelivery }) => {
    return (
        <>
            <Flex
                className={`section-table-order_delivery section-table__container__table-order-delivery ${
                    orderDelivery.length <= 0 && 'hide-scrollbar-section-table__container__table-order-delivery'
                }`}
            >
                <Box className="section-table">
                    <TableHead />
                    <Box className="columns__container" bg="white">
                        {orderDelivery?.map((item, index) => {
                            return (
                                <TableColumn key={index} orderData={item} index={index} orderDelivery={orderDelivery} />
                            );
                        })}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;

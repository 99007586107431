import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onChangeStatus = async ({ status, dataItem, toast, setLoading, setStateValue }) => {
    setLoading(true);
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        await Axios({
            method: 'PATCH',
            url: `/demand-anticipation/${dataItem?.demand_anticipation_id}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: { status_id: status?.value, material_config_id: dataItem?.id },
        })
            .then(() => {
                toast({
                    position: 'center-top',
                    description: 'Berhasil Meng-Update Data',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setStateValue(status);
            })
            .finally(() => {
                setLoading(false);
            });
    } catch (error) {
        setLoading(false);
        console.log(error);
        toast({
            position: 'top-center',
            description: 'Something wrong!',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }
};

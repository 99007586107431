export const initialValue = {
    status: '',
    transition: '',
    lt_Produksi: '',
    index_mpq_vs_baseline: '',
    index_mpq_s_vs_mpq_pti: '',
    index_maks_vs_baseline: '',
};
export const multipleFilterKey = [
    {
        name: 'Between',
        query: 'between:',
    },
    {
        name: 'Equal',
        query: 'eq:',
    },
    {
        name: 'More Than',
        query: 'gt:',
    },
    {
        name: 'Less Than',
        query: 'lt:',
    },
    {
        name: 'More Than Equal',
        query: 'gte:',
    },
    {
        name: 'Less Than Equal',
        query: 'lte:',
    },
];

import { Flex, Box, Text } from '@chakra-ui/react';
import { EditIcon, TrashIcon } from '@pagesPhase2/materialConfiguration/icons';

export const tableListAddItem = [
    {
        name: 'main component',
        value: 'trade_name',
        width: 300,
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value || '-'}</Text>,
    },
    {
        name: 'nama set item',
        value: '',
        flex: 1,
        children: ['-'],
        component: ({ dataItem }) => (
            <Box>
                {[dataItem?.set_component[0]?.trade_name].map((tradeItem, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {tradeItem || '-'}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Action',
        width: 129,
        value: null,
        children: ['-'],
        component: ({
            onEditHandler,
            onRemoveHandler,
        }) => (
            <Flex>
                <Box
                    display="flex"
                    alignItems="center"
                    cursor="pointer"
                    rounded={'4px'}
                    border={'1px solid'}
                    borderColor={'#1746A2'}
                    p="6px"
                    mr="12px"
                    onClick={() => {
                        onEditHandler();
                    }}
                >
                    <EditIcon />
                </Box>
                <Box
                    h="max-content"
                    cursor="pointer"
                    rounded={'4px'}
                    border={'1px solid'}
                    borderColor={'#EF5350'}
                    onClick={onRemoveHandler}
                    p="6px"
                >
                    <TrashIcon />
                </Box>
            </Flex>
        ),
    },
];

export const formDefaultId = {
    main_component_id: {},
    set_component_id: {},
};

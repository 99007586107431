import { Box, Button, Text, useToast, Flex } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { ArrowDownIcon, ArrowRightIcon } from '@asset/icons';

import ScreenTemplate from '@components/screenTemplateV2';
import { StreamlineManagementContext } from '@pages/streamlineManagement';
import ModalSelectLine from '@components/modalSelected';

import { onChangeFormData, onSubmitForm, mappedSelectedStreamline } from './functions';
import { formDefaultValue } from './const';

import { ArrowLeftIcon } from '@asset/icons';
import Modalsuccess from '@components/modalSuccess';
import { onValidation } from './functions';
import FieldMenu from '@components/fieldMenu';
import './styles.scss';

const CreateAndEditModal = ({ modalAttribute, isOpen, isEditModal, reFetchStreamlineHandler, selectedStreamline }) => {
    const { onChangeModalState } = useContext(StreamlineManagementContext);

    const toast = useToast();
    const { zoneList, timbangList, olah1List, olah2List, kemasList } = modalAttribute;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState(formDefaultValue);
    const [isOpenModalSelect, setIsOpenModalSelect] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);

    const [lineSelected, setLineSelected] = useState({});

    const onClose = () => {
        onChangeModalState({ value: null });
        if (!isEditModal) onResetForm();
    };

    const onModalToggling = () => setIsOpenModalSelect(!isOpenModalSelect);

    const onSelectedLine = (value) => {
        setLineSelected(value);
        onModalToggling();
    };

    const onCloseModalSelectLine = ({ type }) => {
        onModalToggling();
        setFormData((i) => {
            return {
                ...i,
                [type]: [],
            };
        });
        setLineSelected([])
    };

    const onCloseSuccessSubmit = () => {
        setIsSuccessSubmit(false);
        onClose();
    };

    const onResetForm = () => {
        setFormData(formDefaultValue);
        setIsSubmitted(false);
    };

    useEffect(() => {
        if (isEditModal && !isEmpty(selectedStreamline) && !isEmpty(zoneList)) {
            mappedSelectedStreamline({ setFormData, selectedStreamline, zoneList });
        }
    }, [isEditModal, selectedStreamline, zoneList]);

    return (
        <ScreenTemplate isOpen={isOpen} onClose={onModalToggling}>
            <ModalSelectLine
                isOpen={isOpenModalSelect}
                onClose={({ type }) => {
                    onCloseModalSelectLine({ type });
                }}
                items={lineSelected}
                onSubmitSelected={({ type, arr }) =>
                    onChangeFormData({
                        type,
                        value: arr,
                        setFormData,
                        formData,
                    })
                }
            />
            {modalAttribute.kemasList && (
                <Box className="create-and-edit-modal__wrapper__streamlinemanagement">
                    <Box className="create-and-edit-modal__container__streamlinemanagement">
                        <Flex className="section-head__container">
                            <Flex experimental_spaceX={2} onClick={onClose} cursor={'pointer'}>
                                <Box cursor="pointer">
                                    <ArrowLeftIcon />
                                </Box>
                                <Text>Kembali</Text>
                            </Flex>
                            <Text className="head-title__container">{isEditModal ? 'Edit' : 'Add New'} Streamline</Text>
                        </Flex>

                        <Box mb={'28px'}>
                            <Text fontSize={'16px'} fontWeight={600}>
                                Isi Data Streamline Baru
                            </Text>
                            <Text fontSize={'12px'}>Lengkapi data streamline beserta line penyusunnya</Text>
                        </Box>

                        <Box className="section-body__container">
                            <Box className="modal-item__container">
                                <Text className="label">
                                    Zona Streamline<Text color="red">*</Text>
                                </Text>
                                <Box flex={1}>
                                    <FieldMenu
                                        onClick={(zone) =>
                                            onChangeFormData({
                                                type: 'zone',
                                                value: zone,
                                                setFormData,
                                                formData,
                                            })
                                        }
                                        menuClassName="menu-container__asset-management"
                                        buttonName={formData?.zone?.name || 'Pilih Zona Streamline'}
                                        menuList={zoneList}
                                        keyName="name"
                                        menuButtonClassName={`menu-button ${isSubmitted && isEmpty(formData.zone) && 'error-empty__field'
                                            }`}
                                        icon={<ArrowDownIcon />}
                                    />
                                    {isSubmitted && isEmpty(formData.zone) && (
                                        <Text className="required-field__text">*Pilih zona streamline</Text>
                                    )}
                                </Box>
                            </Box>

                            <Box className="modal-item__container">
                                <Text className="label">
                                    Streamline<Text color="red">*</Text>
                                </Text>
                                <Box flex={1}>
                                    <input
                                        className={`input-container ${isSubmitted && !formData.streamlineName && 'error-empty__field'
                                            }`}
                                        onChange={(e) =>
                                            onChangeFormData({
                                                type: 'streamlineName',
                                                value: e.target.value,
                                                setFormData,
                                                formData,
                                            })
                                        }
                                        value={formData?.streamlineName}
                                        placeholder="Masukan nama streamline"
                                    />
                                    {isSubmitted && !formData?.streamlineName && (
                                        <Text className="required-field__text">*Masukkan nama streamline</Text>
                                    )}
                                </Box>
                            </Box>

                            <Box className="modal-item__container">
                                <Text className="label">
                                    Line Timbang<Text color="red">*</Text>
                                </Text>
                                <Box flex={1}>
                                    <Flex className="menu-input__multiple-container">
                                        <Flex
                                            className={`selected-multiple-input__container ${isSubmitted && isEmpty(formData.lineTimbang) && 'error-empty__field'
                                                }`}
                                        >
                                            {formData?.lineTimbang?.slice(0, 3)?.map((line) => (
                                                <Flex className="selected-multiple-input__item">{line.name}</Flex>
                                            ))}
                                            {formData?.lineTimbang.length > 3 && (
                                                <Box className="text-show-more__container">
                                                    {formData?.lineTimbang?.length - 3} More +
                                                </Box>
                                            )}
                                        </Flex>
                                        <Flex
                                            className="button-choosed__container"
                                            onClick={() => {
                                                onSelectedLine({
                                                    type: 'lineTimbang',
                                                    arr: timbangList,
                                                    title: 'Pilih Line Timbang',
                                                    currentArr: formData?.lineTimbang,
                                                });
                                            }}
                                        >
                                            <Text>Pilih Line</Text>
                                            <ArrowRightIcon color="#1746A2" />
                                        </Flex>
                                    </Flex>
                                    {isSubmitted && isEmpty(formData?.lineTimbang) && (
                                        <Text className="required-field__text">*Pilih line timbang</Text>
                                    )}
                                </Box>
                            </Box>

                            <Box className="modal-item__container">
                                <Text className="label">
                                    Line Premix & Olah 1<Text color="red">*</Text>
                                </Text>
                                <Box flex={1}>
                                    <Flex className="menu-input__multiple-container">
                                        <Flex
                                            className={`selected-multiple-input__container ${isSubmitted &&
                                                isEmpty(formData?.lineOlah2) &&
                                                isEmpty(formData?.lineOlah1) &&
                                                'error-empty__field'
                                                }`}
                                        >
                                            {formData?.lineOlah1?.slice(0, 3)?.map((line) => (
                                                <Flex className="selected-multiple-input__item">{line.name}</Flex>
                                            ))}
                                            {formData?.lineOlah1.length > 3 && (
                                                <Box className="text-show-more__container">
                                                    {formData?.lineOlah1?.length - 3} More +
                                                </Box>
                                            )}
                                        </Flex>
                                        <Flex
                                            className="button-choosed__container"
                                            onClick={() => {
                                                onSelectedLine({
                                                    type: 'lineOlah1',
                                                    arr: olah1List,
                                                    title: 'Pilih Line Olah 1',
                                                    currentArr: formData?.lineOlah1,
                                                });
                                            }}
                                        >
                                            <Text>Pilih Line</Text>
                                            <ArrowRightIcon color="#1746A2" />
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Box>
                            <Box className="modal-item__container">
                                <Text className="label">
                                    Line Olah 2<Text color="red">*</Text>
                                </Text>
                                <Box flex={1}>
                                    <Flex className="menu-input__multiple-container">
                                        <Flex
                                            className={`selected-multiple-input__container ${isSubmitted &&
                                                isSubmitted &&
                                                isEmpty(formData?.lineOlah2) &&
                                                isEmpty(formData?.lineOlah1) &&
                                                'error-empty__field'
                                                }`}
                                        >
                                            {formData?.lineOlah2?.slice(0, 3)?.map((line) => (
                                                <Flex className="selected-multiple-input__item">{line.name}</Flex>
                                            ))}
                                            {formData?.lineOlah2.length > 3 && (
                                                <Box className="text-show-more__container">
                                                    {formData?.lineOlah2?.length - 3} More +
                                                </Box>
                                            )}
                                        </Flex>
                                        <Flex
                                            className="button-choosed__container"
                                            onClick={() => {
                                                onSelectedLine({
                                                    type: 'lineOlah2',
                                                    arr: olah2List,
                                                    title: 'Pilih Line Olah 2',
                                                    currentArr: formData?.lineOlah2,
                                                });
                                            }}
                                        >
                                            <Text>Pilih Line</Text>
                                            <ArrowRightIcon color="#1746A2" />
                                        </Flex>
                                    </Flex>
                                    {isSubmitted && isEmpty(formData?.lineOlah2) && isEmpty(formData?.lineOlah1) && (
                                        <Text className="required-field__text">*Pilih line olah 1 atau 2</Text>
                                    )}
                                </Box>
                            </Box>
                            <Box className="modal-item__container">
                                <Text className="label">
                                    Line Kemas<Text color="red">*</Text>
                                </Text>
                                <Box flex={1}>
                                    <Flex className="menu-input__multiple-container">
                                        <Flex
                                            className={`selected-multiple-input__container ${isSubmitted && isEmpty(formData.lineKemas) && 'error-empty__field'
                                                }`}
                                        >
                                            {formData?.lineKemas?.slice(0, 3)?.map((line) => (
                                                <Flex className="selected-multiple-input__item">{line.name}</Flex>
                                            ))}
                                            {formData?.lineKemas.length > 3 && (
                                                <Box className="text-show-more__container">
                                                    {formData?.lineKemas?.length - 3} More +
                                                </Box>
                                            )}
                                        </Flex>
                                        <Flex
                                            className="button-choosed__container"
                                            onClick={() => {
                                                onSelectedLine({
                                                    type: 'lineKemas',
                                                    arr: kemasList,
                                                    title: 'Pilih Line Kemas',
                                                    currentArr: formData?.lineKemas,
                                                });
                                            }}
                                        >
                                            <Text>Pilih Line</Text>
                                            <ArrowRightIcon color="#1746A2" />
                                        </Flex>
                                    </Flex>
                                    {isSubmitted && isEmpty(formData?.lineKemas) && (
                                        <Text className="required-field__text">*Pilih line kemas</Text>
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        <Flex className="section-footer__container">
                            <Button
                                border={isSubmitLoading ? '1px solid grey' : 'none'}
                                isLoading={isSubmitLoading}
                                className={`button-add__container ${!onValidation({ formData }) && 'disable-button-add__container'
                                    }`}
                                onClick={() =>
                                    onSubmitForm({
                                        toast,
                                        formData,
                                        setIsSubmitLoading,
                                        reFetchStreamlineHandler,
                                        isEditModal,
                                        selectedStreamline,
                                        setIsSubmitted,
                                        onResetForm,
                                        setIsSuccessSubmit,
                                    })
                                }
                            >
                                Save Changes
                            </Button>
                            <Button className="button-cancel__container" onClick={onClose}>
                                Cancel
                            </Button>
                        </Flex>
                    </Box>

                    <Modalsuccess
                        isOpen={isSuccessSubmit}
                        onClose={onCloseSuccessSubmit}
                        title={`Sukses ${isEditModal ? 'mengubah' : 'membuat'} streamline`}
                        description={`Streamline ${isEditModal ? 'berhasil diupdate' : 'baru berhasil dibuat'
                            } , kembali ke table untuk melihat dan mengatur streamline`}
                    />
                </Box>
            )}
        </ScreenTemplate>
    );
};

export default CreateAndEditModal;

import { getFilterOrderStatus } from '@pages/mainDashboard/section/sectionTab/components/filter/function';
import { fill } from 'lodash';
import Cookies from 'universal-cookie';

export const fillingEmptyOrderHandler = ({ orders }) => {
    const cookies = new Cookies();
    const tabActive = cookies.get('tab');
    const isKetersediaanMaterial = tabActive === 'INBOUND' || tabActive === 'PENDING';
    let temp = [...orders];
    const orderLength = orders?.length || 0;
    if (orderLength < 5) {
        temp = [...temp, ...fill(Array(5 - orderLength), { type: 'empty' })];
    }

    return temp?.map((item) => ({ ...item, isKetersediaanMaterial }));
};

/// Fungsi untuk get status dipage main dashboard
/// fungsi ini memiliki koneksi dengan tabMenuOrderStatus
/// src\@pages\mainDashboard\section\sectionTab\const.js
export const getStatus = ({ order }) => {
    const cookies = new Cookies();
    const cookieFilter = cookies.get('filterListOrderStatus');
    const activeTab = cookies.get('tab');
    const hasFilter = getFilterOrderStatus({ cookieFilter: cookieFilter || [] });

    switch (activeTab) {
        case 'QUEUE': /// TAB MENU ANTRIAN ORDER
            const findStatusQueue = cookieFilter?.find((item) => item?.name === hasFilter?.selected);
            if (findStatusQueue)
                return {
                    statusText: [order?.kardData?.kard_fg?.kards[0].br_ready ? 'BR Ready' : 'BR Not Ready'],
                    statusBackgroundColor: order?.kardData?.kard_fg?.kards[0].br_ready ? '#D9F2E3' : '#FFCCCC',
                    statusTextColor: order?.kardData?.kard_fg?.kards[0].br_ready ? '#267346' : '#CC0019',
                };
            return [];
        case 'DONE': /// TAB MENU KANBAN SELESAI
            const findStatusDone = cookieFilter?.find((item) => item?.name === hasFilter?.selected);
            if (findStatusDone)
                return {
                    statusText: [order?.kardOrders[0]?.kard_fg?.is_ontime ? 'Ontime' : 'Overdue'],
                    statusBackgroundColor: order?.kardOrders[0]?.kard_fg?.is_ontime ? '#D9F2E3' : '#FFCCCC',
                    statusTextColor: order?.kardOrders[0]?.kard_fg?.is_ontime ? '#267346' : '#CC0019',
                };
            return [];
        case 'INBOUND': /// TAB MENU ANTRIAN KONFIRMASI
            return {
                statusText: ['Antrian Konfirmasi'],
                statusBackgroundColor: '#FDDDEC',
                statusTextColor: '#F22B89',
            };
        case 'PENDING': /// TAB MENU PENDING
            if (hasFilter.selected === 'Pilih Jenis Pending' || hasFilter.selected === 'All') {
                return {
                    statusText: [
                        !order?.kardOrders[0]?.ketersediaanStatus?.status_bb && 'Pending BB',
                        !order?.kardOrders[0]?.ketersediaanStatus?.status_packaging && 'Pending Packaging',
                    ],
                    statusBackgroundColor: '#FFCCCC',
                    statusTextColor: '#CC0019',
                };
            }
            if (hasFilter?.selected === 'Pending BB')
                return {
                    statusText: [!order?.kardOrders?.ketersediaanStatus?.status_bb && 'Pending BB'],
                    statusBackgroundColor: '#FFCCCC',
                    statusTextColor: '#CC0019',
                };
            if (hasFilter?.selected === 'Pending Packaging')
                return {
                    statusText: [!order?.kardOrders?.ketersediaanStatus?.status_packaging && 'Pending Packaging'],
                    statusBackgroundColor: '#FFCCCC',
                    statusTextColor: '#CC0019',
                };
    }
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';

import { Box, useToast } from '@chakra-ui/react';

import { toggleLoginHandler } from '@store/list/login';
import { handleChange, loginHandler } from './functions';

import SectionFieldInput from './section/sectionFieldInput';
import SectionBottom from './section/sectionBottom';
import SectionTop from './section/sectionTop';
import SectionTitle from './section/sectionTitle';

import './styles.scss';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

    const [formData, setFormData] = useState({ email: '', password: '' });
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRememberMe, setIsRememberMe] = useState(false);

    const fetchCurrentAccount = async ({ email }) => {
        setFormData({
            email,
        });
    };

    useEffect(() => {
        const cookies = new Cookies();
        const email = cookies.get('email');

        if (email) {
            fetchCurrentAccount({ email });
        }
    }, []);

    return (
        <Box className="login-page__container">
            <Box className="box-login__container">
                <Box className="box-login__body">
                    <SectionTitle />
                    <SectionTop isError={isError} />
                    <SectionFieldInput
                        handleChange={({ value, type }) => handleChange({ value, type, setFormData, formData })}
                        onLoginHandler={() =>
                            loginHandler({
                                toast,
                                setIsLoading,
                                navigate,
                                formData,
                                dispatch,
                                toggleLoginHandler,
                                setIsError,
                                isRememberMe,
                            })
                        }
                        formData={formData}
                        setIsRememberMe={setIsRememberMe}
                    />
                    <SectionBottom
                        onLoginHandler={() =>
                            loginHandler({
                                setIsError,
                                toast,
                                setIsLoading,
                                navigate,
                                formData,
                                dispatch,
                                toggleLoginHandler,
                                isRememberMe,
                            })
                        }
                        isLoading={isLoading}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Login;

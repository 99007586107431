import { Box } from '@chakra-ui/react';
import TableHead from '../../components/tableHead';
import TableColumn from '../../components/tableColumn';
import './styles.scss';

const SectionTable = ({ onChangeModalState, onOpenModal, materialItem, onSelectedItem }) => {
    return (
        <Box className="section-table__add-new-item__configuration section-table__container__table-add-item">
            <TableHead />
            <Box className="section-table">
                <Box className="columns-container" bg="white">
                    {materialItem?.map((dataItem, idx ) => {
                        return (
                            <TableColumn
                                key={idx}
                                dataItem={dataItem}
                                onOpenModal={onOpenModal}
                                onSelectedItem={onSelectedItem}
                                onChangeModalState={onChangeModalState}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default SectionTable;

import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { PyramidHamburgerIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';
import { handleCloseModalFilter } from './function';
import ModalFilterKodeItem from './filterCodeItem/index';
import { MaterialConfigurationContext } from '@pagesPhase2/materialConfiguration';
import './styles.scss';

function Index() {
    const { filter, setFilter, setPage: setMainPage, section } = React.useContext(MaterialConfigurationContext);
    const [selectedData, setSelectedData] = React.useState([]);
    const [openModalFilter, setOpenModalFilter] = React.useState(false);
    const [codeItem, setCodeItem] = React.useState([]);
    const [codeItemChoosed, setCodeItemChoosed] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const dataSelected = codeItemChoosed?.filter((item) => item.checked);

    const handleApplyFilter = () => {
        setSelectedData(dataSelected);
        setFilter({ ...filter, itemCode: codeItemChoosed });
        setOpenModalFilter(false);
        setSelectedData(dataSelected);
        setPage(1);
        setMainPage(1);
        setSearch('');
        setRefetch(!refetch);
    };

    const handleResetFilter = () => {
        handleCloseModalFilter({
            selectedData,
            setCodeItem,
            codeItem,
            type: 'reset',
            setCodeItemChoosed,
        });
        setPage(1);
        setSearch('');
        setRefetch(!refetch);
    };
    React.useEffect(() => {
        if (filter?.itemCode?.length > 0) {
            setCodeItemChoosed(filter?.itemCode);
            setSelectedData(filter?.itemCode);
            setRefetch(!refetch);
        }
    }, [filter?.itemCode?.length]);
    return (
        <Box className="code-item-material-config">
            <ModalFilterKodeItem
                search={search}
                page={page}
                setPage={setPage}
                setSearch={setSearch}
                refetch={refetch}
                outlineLabel="Reset Filter"
                modalTitle="Filter Kode Item"
                labelSearch="Kode yang dipilih"
                labelSelectedData="Pilih Kode Item"
                codeItem={codeItem}
                setCodeItem={setCodeItem}
                isOpen={openModalFilter}
                codeItemChoosed={codeItemChoosed}
                setCodeItemChoosed={setCodeItemChoosed}
                loadingSearch={loadingSearch}
                setLoadingSearch={setLoadingSearch}
                onClose={() => {
                    handleCloseModalFilter({
                        setOpenModalFilter,
                        selectedData,
                        codeItem,
                        setCodeItem,
                        setCodeItemChoosed,
                    });
                    setPage(1);
                    setSearch('');
                    setRefetch(!refetch);
                }}
                selectedData={selectedData}
                handleApplyFilter={handleApplyFilter}
                handleResetFilter={handleResetFilter}
            />
            <Box
                onClick={() => {
                    setOpenModalFilter(true);
                }}
                className={`btn-filter-code-item-container ${selectedData?.length > 0 && 'has-item-selected'}`}
            >
                <Text className={`btn-filter-text`}>Cari kode item</Text>
                <Box className={`btn-filter-code-item-icon`}>
                    <PyramidHamburgerIcon fill={selectedData?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                </Box>
                {selectedData?.length > 0 && <Text className="btn-filter-badge">{selectedData?.length}</Text>}
            </Box>
        </Box>
    );
}

export default Index;

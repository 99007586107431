import { DownloadBlackIcon, UploadBlackIcon } from '@asset/icons';
import { isAllowed } from '@helpers/roleAccess';

export const optionMenuDot = () => {
    const list = [];

    if (isAllowed({ pageName: 'Demand Anticipation', permissionName: 'Download Demand Anticipation' })) {
        list.push({
            name: 'Download Data',
            icon: <DownloadBlackIcon />,
        });
    }

    if (isAllowed({ pageName: 'Demand Anticipation', permissionName: 'Upload Demand Anticipation' })) {
        list.push({
            name: 'Upload File',
            icon: <UploadBlackIcon />,
        });
    }

    return list;
};

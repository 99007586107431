import { AnaliticIcon, DottedIcon, EditIcon } from '@asset/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { DemandAnticipationContext } from '@pagesPhase2/demandAnticipation';
import DropDown from '@pagesPhase2/demandAnticipation/components/dropDownMenu';
import { onDownloadDemandAnticipation } from '@pagesPhase2/demandAnticipation/function';
import useFetch from 'hooks/useFetch';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CodeItemSearch from './codeItem';
import { optionMenuDot } from './const';
import MultipleFilter from './multipleFilter/index';
import './styles.scss';
import TradeNameSearch from './tradeName';
import { isAllowed } from '@helpers/roleAccess';
import { useNavigate } from 'react-router-dom';

function Index() {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const { setIsEdit, isEdit, filter } = React.useContext(DemandAnticipationContext);
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onOpenHandler = ({ value }) => setOpen(value);
    const { data } = useFetch({
        api: 'demand-anticipation/last-update',
    });

    const handleMenuDropDown = (name) => {
        switch (name) {
            case 'Download Data':
                onDownloadDemandAnticipation({ dispatch, filter, jenisMaterial, supplier });
                break;
            case 'Upload File':
                navigate('/demand-anticipation/upload-item');
                break;
            default:
                break;
        }
    };

    return (
        <Box>
            <Flex className="demand-anticipation-box-header">
                <Flex gap="10px" alignItems="flex-start">
                    <AnaliticIcon />
                    <Box>
                        <Text className="text-demand-anticipation">Demand Anticipation</Text>
                        <Text className="last-update">
                            Last update : Week {data?.lastUpdate?.week} - {data?.lastUpdate?.year}
                        </Text>
                    </Box>
                </Flex>
                {/* <Flex className="btn-edit">
                    <CustomButton variant="outline" btnClassName="cancel">
                        Cancel
                    </CustomButton>
                    <CustomButton btnClassName="save">Save</CustomButton>
                </Flex> */}
                <Box>
                    <Flex className="action-button-container" alignItems="center">
                        {!isEdit && <CodeItemSearch />}
                        {!isEdit && (
                            <Box mx="16px">
                                <TradeNameSearch />
                            </Box>
                        )}
                        {!isEdit && <MultipleFilter />}
                        {!isEdit && <Box className="divider" />}

                        {/* <DatePicker
                                value={date}
                                onChange={setDate}
                                className="date-picker-content"
                                clearIcon={null}
                                calendarIcon={<CalendarIcon />}
                                calendarClassName="calendar-container"
                                format="dd/MM/yyyy"
                                dayPlaceholder="1"
                                monthPlaceholder="1"
                                yearPlaceholder="2023"
                            /> */}
                        {isEdit ? (
                            <Button onClick={() => setIsEdit(false)} className="button-edit">
                                cancel
                            </Button>
                        ) : (
                            <>
                                {isAllowed({
                                    pageName: 'Demand Anticipation',
                                    permissionName: 'Edit Demand Anticipation',
                                }) && (
                                    <Button onClick={() => setIsEdit(true)} className="button-edit">
                                        <Box mr="5px">
                                            <EditIcon />
                                        </Box>
                                        Edit
                                    </Button>
                                )}
                            </>
                        )}
                        {!isEdit && (
                            <DropDown
                                customBodyContentClassName="box"
                                fontWeightListMenu={600}
                                width="160px"
                                placement="left-start"
                                option={optionMenuDot()}
                                open={open}
                                onClickMenu={(item) => {
                                    handleMenuDropDown(item?.name);
                                }}
                                onClose={() => setOpen(false)}
                            >
                                <Button
                                    onClick={() => onOpenHandler({ value: true })}
                                    sx={{
                                        width: '32px',
                                        padding: 1,
                                        height: '32px',
                                    }}
                                    variant="ghost"
                                >
                                    <DottedIcon />
                                </Button>
                            </DropDown>
                        )}
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
}

export default Index;

import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchMaterialsHandler = createAsyncThunk('material/fetchMaterialHandler', async () => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            url: '/sediaan/list',
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        return data.data.sediaan;
    } catch (error) {
        console.log(error);
        return [];
    }
});

export const materialSlice = createSlice({
    name: 'material',
    initialState: {
        materials: [],
        isLoadingFetchMaterial: true,
        selectedMaterial: {},
    },
    reducers: {
        resetMaterials: (state) => {
            state.material = [];
        },
        onToggleLoadingMaterial: (state, action) => {
            state.isLoadingFetchMaterial = action.payload;
        },
        onChangeMaterialType: (state, action) => {
            state.selectedMaterial = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMaterialsHandler.fulfilled, (state, action) => {
            state.materials = action.payload;
            state.isLoadingFetchMaterial = false;

            const cookies = new Cookies();

            const selectedMaterial = cookies.get('selectedMaterial');

            state.selectedMaterial = selectedMaterial ? queryString.parse(selectedMaterial) : action.payload[0];
        });
    },
});

export const { resetMaterials, onToggleLoadingMaterial, onChangeMaterialType } = materialSlice.actions;

export default materialSlice.reducer;

import React, { Fragment, useState } from 'react';
import { Box, VStack, Text, Button, HStack } from '@chakra-ui/react';
import Modalsuccess from '@components/modalSuccess';
import ModalTemplate from '@components/modalTemplate';
import { IconQuestionMark } from '../icon';
import { onSolveProblem } from '../detailProductStatus/function';

import './styles.scss';

const ModallStopProblem = ({ isOpen, onClose, selectedStopProblem, refetchProductStatusHandler }) => {
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onCloseModal = () => {
        onClose();
        setIsSubmitted(false);
    };

    return (
        <ModalTemplate width={543} isOpen={isOpen} onClose={onCloseModal}>
            {!isSubmitted ? (
                <VStack py="50px" experimental_spaceY={5} className="modal-stop-problem__container">
                    <Fragment>
                        <Box
                            display={'flex'}
                            bg="#d9d9d9"
                            w={'150px'}
                            h={'150px'}
                            rounded={'full'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <IconQuestionMark />
                        </Box>
                        <Box textAlign={'center'}>
                            <Text className="title">Report Solved Problem</Text>
                            <Text className="desc">Apakah masalah sudah selesai dan Anda ingin</Text>
                            <Text className="desc">menghentikan Laporan masalah?</Text>
                        </Box>
                        <HStack mt="35px !important">
                            <Button
                                fontSize={'12px'}
                                bg="white"
                                border="1px solid #2D2D2D"
                                w={'220px'}
                                borderRadius={0}
                                onClick={() => {
                                    onSolveProblem({
                                        id: selectedStopProblem.id,
                                        refetchProductStatusHandler,
                                        setIsSubmitLoading,
                                        setIsSubmitted,
                                    });
                                }}
                                isLoading={isSubmitLoading}
                            >
                                Solve Problem
                            </Button>
                        </HStack>
                    </Fragment>
                </VStack>
            ) : (
                <Modalsuccess
                    isOpen
                    onClose={onCloseModal}
                    title="Laporan Berhasil Dihentikan"
                    description={`menghentikan laporan masalah berhasil dibuat`}
                />
            )}
        </ModalTemplate>
    );
};

export default ModallStopProblem;

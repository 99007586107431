import { sortBy, reverse } from 'lodash';
import Cookies from 'universal-cookie';
import Axios from '@services/axios';

export const getActualDurationHandler = ({ kard }) => {
    return new Date() - new Date(kard?.start);
};

export const formattedDateString = (val) => {
    const splitValue = val?.split(':');
    // const removeSecond = splitValue.pop();

    if (splitValue) {
        let str = '';

        for (let i = 0; i < splitValue.length; i++) {
            const el = splitValue[i];

            str += `${el} ${i < splitValue.length - 1 ? ` : ` : ''} `;
        }

        return str;
    }
};

export const getLastActualDuration = ({ kardHistory, type }) => {
    if (kardHistory?.length) {
        let getLastArr = [];
        if (type === 'ndc') {
            getLastArr = reverse(sortBy(kardHistory, (item) => item.nourut));
        } else {
            getLastArr = reverse(sortBy(kardHistory, (item) => item.createdAt));
        }

        return getLastArr[0];
    }

    return;
};

export const formatNameActual = ({ type, kardHistory }) => {
    if (type === 'kemas') {
        return `${getLastActualDuration({ kardHistory, type })?.status?.name || ''}  | KFG Palet ${
            getLastActualDuration({ kardHistory, type })?.nourut_akhir || ''
        }`;
    }
    if (type === 'ndc') {
        return `${getLastActualDuration({ kardHistory, type })?.status?.name || ''} ${
            getLastActualDuration({ kardHistory, type })?.nourut || '-'
        } / ${getLastActualDuration({ kardHistory, type })?.nourut_akhir || '-'}`;
    } else {
        return `${getLastActualDuration({ kardHistory, type })?.status?.name || ''} `;
    }
};

export const isShowDurationHandler = ({ type }) => {
    const arr = ['timbang', 'olah1', 'olah2', 'kemas', 'qc'];

    return arr.includes(type);
};

export const fetchDetailCard = async ({ id, type, setData, setIsLoading }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/kanban/kard?kardId=${id}&posisi=${type}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        setData(data?.data);
    } catch (error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
};
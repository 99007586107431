import { Box, Flex } from '@chakra-ui/react';
import TableColumn from '@pages/orderPlanning/components/tableColumn';
import TableHead from '@pages/orderPlanning/components/tableHead';
import './styles.scss';

const SectionTable = ({ orderPlanning, setSortName }) => {
    return (
        <Flex
            className={`section-table__container__order-planning ${
                orderPlanning.length <= 0 && 'hide-scrollbar-section-table__container'
            }`}
        >
            <Box className="section-table">
                <TableHead setSortName={setSortName} />
                <Box className="columns__container" bg="white">
                    {orderPlanning?.map((item, idx) => (
                        <TableColumn orderPlanning={item} key={idx} />
                    ))}
                </Box>
            </Box>
        </Flex>
    );
};

export default SectionTable;

import { useState } from 'react';
import { useToast, Text } from '@chakra-ui/react';
import ModalDelete from '@components/modalDelete';

const DeleteModal = ({
    isOpen,
    onClose,
    selectedItem,
    onRemoveMaterial,
    togglerDeleteMaterialModal,
    reFetchMaterialHandler,
}) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <ModalDelete
            isOpen={isOpen}
            onClose={onClose}
            title="Delete Material"
            isLoading={isLoading}
            onSubmit={() => {
                onRemoveMaterial({
                    setIsLoading,
                    selectedItem,
                    togglerDeleteMaterialModal,
                    reFetchMaterialHandler,
                    toast,
                });
            }}
            descriptionComponent={
                <Text className="description-text">
                    Apakah anda yakin ingin menghapus item <span>{selectedItem?.trade_name}</span> ?
                </Text>
            }
        />
    );
};

export default DeleteModal;

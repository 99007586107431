export const formDefaultValue = {
    kode_oddo: '',
    default_code: '',
    kode_sl: '',
    kode_item: '',
    stockNdc: '',
    name: '',
    streamline: {},
    streamline_label: '',
    jenis_storage_ndc: '',
    mpq_kemas: '',
    pcs_per_koli: '',
    koli: '',
    koli_per_storage: '',
    storage_ndc: '',
    pcs_co: '',
    storage_co: '',
    ndc_co: '',
    cp_safety_pcs: '',
    cp_safety_koli: '',
    cp_safety_strg: '',
    cp_rop_pcs: '',
    cp_rop_koli: '',
    cp_rop_strg: '',
    cp_max_pcs: '',
    cp_max_koli: '',
    cp_max_strg: '',
    timbang_leadtime: '',
    premix_leadtime: '',
    olah_1_leadtime: '',
    olah_2_leadtime: '',
    kemas_leadtime: '',
    lain_lain_leadtime: '',
    total_leadtime: '',
};

export const jenisList = [
    {
        id: 1,
        name: 'Pallet',
    },
    {
        id: 2,
        name: 'Trolly',
    },
    {
        id: 3,
        name: 'Koli',
    },
];

export const listItemCriticalPoint = [
    {
        title: 'Safety',
        listKey: ['cp_safety_pcs', 'cp_safety_koli', 'cp_safety_strg'],
    },

    {
        title: 'Reorder Point',
        listKey: ['cp_rop_pcs', 'cp_rop_koli', 'cp_rop_strg'],
    },
    {
        title: 'Maximum',
        listKey: ['cp_max_pcs', 'cp_max_koli', 'cp_max_strg'],
    },
];

import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

export const downloadManagementSlice = createSlice({
    name: 'downloadMagement',
    initialState: {
        downloadList: [],
    },
    reducers: {
        onDownloadGlobalHandler: (state, action) => {
            let downloadList = state?.downloadList?.filter((item) => item.id !== action?.payload?.id);

            if (action?.payload?.type !== 'done') {
                downloadList.push(action.payload);
            }

            state.downloadList = downloadList;
        },
    },
});

export const { onDownloadGlobalHandler } = downloadManagementSlice.actions;

export default downloadManagementSlice.reducer;

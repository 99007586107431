import { Box, Flex } from '@chakra-ui/react';
import TableHead from '@pagesPhase2/supplierStock/components/tableHead';
import TableColumn from '@pagesPhase2/supplierStock/components/tableColumn';
import './styles.scss';

const SectionTable = ({ suppliers, onChangeModalState }) => {
    return (
        <>
            <Flex
                className={`section-table-supplier_stock ${
                    suppliers.length <= 0 &&
                    'hide-scrollbar-section-table__container__table-section-table-supplier_stock'
                }`}
            >
                <Box className="section-table">
                    <TableHead />
                    <Box className="columns__container" bg="white">
                        {suppliers.map((item, index) => {
                            return <TableColumn key={index} dataItem={item} onChangeModalState={onChangeModalState} />;
                        })}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;

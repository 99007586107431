import React, { createContext } from 'react';
import { Box, Flex, Skeleton, Stack, Text, useToast } from '@chakra-ui/react';
import { ArrowUpIconBlack, ChevronLeftIcon } from '@asset/icons';
import FieldMenu, { EmptyField } from '@components/fieldMenu';
import { formListDataLengkap, initialValue } from './const';
import KonfiguratiSatuan from './konfigurasiSatuan';
import BaseLinePerWeek from './baselinePerWeek';
import AVGBaseline from './AVGBaseline';
import CriticalPoint from './criticalPoint';
import StockPTI from './stockPTI';
import StockSupplier from './stockSuppliers';
import StockRAWMaterial from './stockRAWMaterial';
import ModalConfirm from '@components/modalConfirm';
import Button from '@components/button';
import {
    handleClickMenu,
    handleSubmit,
    handleConfirmOut,
    afterFetchRaci,
    handleSetValueForm,
    handleSaveItem,
} from './function';
import useFetch from 'hooks/useFetch';
import SupplierName from './infiniteScroll/SupplierName';
import TradeName from './infiniteScroll/TradeName';
import { isEmptyString } from '@helpers/functions';
import Modalsuccess from '@components/modalSuccess';
import { onChangeManualShowGLobalFilter } from '@store/list/globalFilter';
import { useNavigate, useParams } from 'react-router-dom';

import './styles.scss';
import { useDispatch } from 'react-redux';
export const FormCreateNewItemContext = createContext({});

function Index() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [formData, setFormData] = React.useState(initialValue);
    const [searchSupplierName, setSearchSupplierName] = React.useState('');
    const [searchTradeName, setSearchTradeName] = React.useState('');
    const [isEmptyRaci, setIsEmptyRaci] = React.useState(false);
    const [confirmOut, setConfirmOut] = React.useState(false);
    const [isDraftPost, setIsDraftPost] = React.useState(false);
    const [loadingTradeName, setLoadingTradeName] = React.useState(false);
    const [openModal, setOpenModal] = React.useState({ type: '', title: '', tag: '' });
    const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);
    const toast = useToast();

    const [openInfiniteScroll, setOpenInfiniteScroll] = React.useState({
        trade_name: false,
        supplier_name: false,
        supplierRef: '',
        api: '',
    });
    const { data: dataMaterialRaci, isLoading: isLoadingMaterialRaci } = useFetch({
        api: `material/raci?supplierRef=${formData?.dataLengkap?.supplier_code}&kodeItem=${formData?.dataLengkap?.code_item}`,
        enabled: Boolean(formData?.dataLengkap?.code_item),
        then: (res) => afterFetchRaci({ res, setIsEmptyRaci, toast }),
    });
    const { data: dataMaterialItemById, isLoading: loadingGetById } = useFetch({
        api: `material/config/${id}`,
        enabled: Boolean(id),
    });
    const enabledSaveAsDraft =
        !dataMaterialItemById?.materialConfig?.is_draft && dataMaterialItemById?.materialConfig?.is_warning;
    const isDataMaterialIdNotFound = !loadingGetById && !Boolean(dataMaterialItemById?.materialConfig);
    const handleSubmitModalSuccess = () => {
        setOpenModal((prev) => ({ ...prev, type: '' }));
        setIsSubmitLoading(false);
        setFormData(() => {
            return {
                ...initialValue,
                stock_raw_material: [
                    {
                        id: 1,
                        material_name: '',
                        rop: '',
                        max_stock: '',
                        delete: false,
                        is_new: true,
                    },
                ],
            };
        });
        setSearchSupplierName('');
        setSearchTradeName('');
        navigate('/material-configuration');
    };
    React.useEffect(() => {
        if (isDataMaterialIdNotFound) {
            toast({
                description: `Material config dengan id ${id} tidak tersedia!`,
                status: 'error',
                duration: 7000,
                isClosable: true,
                position: 'top-center',
            });
        }
        if (Boolean(id)) {
            dispatch(onChangeManualShowGLobalFilter(id));
            handleSetValueForm({
                setFormData,
                data: dataMaterialItemById?.materialConfig,
                setSearchSupplierName,
                setSearchTradeName,
            });
        }
    }, [Boolean(dataMaterialItemById?.materialConfig?.id), loadingGetById]);
    return (
        <FormCreateNewItemContext.Provider
            value={{
                dataMaterialRaci,
                isLoadingMaterialRaci,
                isSubmitted,
                setSearchSupplierName,
                setSearchTradeName,
                searchSupplierName,
                searchTradeName,
            }}
        >
            <Box className="root-container-add-new-item">
                <Box className="header-text-create-new-item">
                    <Flex
                        onClick={() =>
                            handleConfirmOut({
                                formData,
                                navigate,
                                setConfirmOut,
                                setFormData,
                                setSearchSupplierName,
                                setSearchTradeName,
                            })
                        }
                        className="btn-back"
                    >
                        <ChevronLeftIcon />
                        <Text className="text-add-item-material-conf">
                            {Boolean(id) && !enabledSaveAsDraft ? 'Edit Item' : 'Add New Item'}
                        </Text>
                    </Flex>
                </Box>
                <Flex className="complete-data">
                    <Box width="267px">
                        <Text className="title-complete-data">Isi data lengkap</Text>
                        <Text className="tag-complete-data">
                            Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                        </Text>
                    </Box>
                    <Box className="form-add-new-item-container">
                        {formListDataLengkap.map((itemForm, index) => {
                            if (loadingGetById && Boolean(id)) {
                                return (
                                    <Box key={index}>
                                        <Text className="label">
                                            {itemForm.title}
                                            {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                                        </Text>
                                        <Stack mt="6px">
                                            <Skeleton height="48px" />
                                        </Stack>
                                    </Box>
                                );
                            }
                            if (itemForm.isDisabled) {
                                return (
                                    <Box key={index}>
                                        <Text className="label">
                                            {itemForm.title}
                                            {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                                        </Text>
                                        <EmptyField
                                            disabledWidthBox="706px"
                                            className="disabled-field-input"
                                            disabledHeightBox="48px"
                                            buttonName={
                                                formData?.dataLengkap[itemForm?.name] ? (
                                                    <span className="label-selected-menuList">
                                                        {formData?.dataLengkap[itemForm?.name]}
                                                    </span>
                                                ) : (
                                                    'Terisi otomatis'
                                                )
                                            }
                                        />
                                    </Box>
                                );
                            }
                            if (itemForm?.name === 'supplier_name') {
                                return (
                                    <Box key={index}>
                                        <SupplierName
                                            formData={formData}
                                            isSubmitted={isSubmitted}
                                            setFormData={setFormData}
                                            itemForm={itemForm}
                                            setLoadingTradeName={setLoadingTradeName}
                                            openInfiniteScroll={openInfiniteScroll}
                                            setOpenInfiniteScroll={setOpenInfiniteScroll}
                                        />
                                    </Box>
                                );
                            }
                            if (itemForm?.name === 'trade_name') {
                                return (
                                    <Box key={index}>
                                        <TradeName
                                            formData={formData}
                                            isSubmitted={isSubmitted}
                                            setFormData={setFormData}
                                            itemForm={itemForm}
                                            loadingSearch={loadingTradeName}
                                            setLoadingSearch={setLoadingTradeName}
                                            openInfiniteScroll={openInfiniteScroll}
                                            setOpenInfiniteScroll={setOpenInfiniteScroll}
                                        />
                                    </Box>
                                );
                            }

                            return (
                                <Box key={index}>
                                    <Text className="label">
                                        {itemForm.title}
                                        {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                                    </Text>
                                    <Box mt="6px">
                                        <FieldMenu
                                            buttonName={
                                                <span>
                                                    {formData?.dataLengkap[itemForm.name] ? (
                                                        <span className="selected-role-text">
                                                            {formData?.dataLengkap[itemForm.name]}
                                                        </span>
                                                    ) : (
                                                        <span className="fieldMenu-placeholder">
                                                            {itemForm.placeholder}
                                                        </span>
                                                    )}
                                                </span>
                                            }
                                            onClick={(selected) => handleClickMenu({ itemForm, selected, setFormData })}
                                            menuList={itemForm.option}
                                            keyName="name"
                                            disabledWidthBox="706px"
                                            disabledHeightBox="48px"
                                            menuClassName="menu-container__user-management"
                                            menuButtonClassName={`menu-button`}
                                            menuListClassName="menuList-container"
                                            icon={<ArrowUpIconBlack />}
                                        />
                                        {isSubmitted && !formData?.dataLengkap[itemForm.name] && (
                                            <Text className="required-field__text">Field required!</Text>
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Flex>
                <Box display={!isEmptyString(formData?.dataLengkap?.trade_name) ? 'block' : 'none'}>
                    <KonfiguratiSatuan formData={formData} setFormData={setFormData} />
                    <BaseLinePerWeek formData={formData} setFormData={setFormData} />
                    <AVGBaseline formData={formData} setFormData={setFormData} />
                    <CriticalPoint formData={formData} setFormData={setFormData} />
                    <StockPTI formData={formData} setFormData={setFormData} />
                    <StockSupplier formData={formData} setFormData={setFormData} />
                    <StockRAWMaterial formData={formData} setFormData={setFormData} />
                    <Flex className="btn-submit-add-new-item">
                        {(enabledSaveAsDraft || !Boolean(id)) && (
                            <Button
                                height="32px"
                                btnClassName="btn-save-draft"
                                isLoading={isSubmitLoading}
                                onClick={() =>
                                    handleSubmit({
                                        formData,
                                        toast,
                                        setOpenModal,
                                        isSubmitLoading,
                                        setIsSubmitLoading,
                                        isDraft: true,
                                        setIsDraftPost,
                                    })
                                }
                                variant="outline"
                            >
                                Save Draft
                            </Button>
                        )}
                        <Button
                            height="32px"
                            btnClassName="btn-save-item"
                            disabled={isEmptyRaci || isDataMaterialIdNotFound}
                            onClick={() =>
                                handleSaveItem({
                                    isDraf: false,
                                    setIsSubmitted,
                                    setOpenModal,
                                    formData,
                                    isEmptyRaci,
                                    toast,
                                    isDataMaterialIdNotFound,
                                })
                            }
                        >
                            Save Item
                        </Button>
                    </Flex>
                </Box>
            </Box>
            <ModalConfirm
                loading={isSubmitLoading}
                isOpen={openModal.type?.includes('confirm')}
                onClose={() => {
                    setOpenModal((prev) => ({ ...prev, type: '' }));
                    setIsSubmitLoading(false);
                }}
                onSubmit={() =>
                    handleSubmit({
                        formData,
                        toast,
                        setOpenModal,
                        isSubmitLoading,
                        setIsSubmitLoading,
                        isDraft: enabledSaveAsDraft ? true : false,
                        section: Boolean(id) ? 'item-update' : 'item-create',
                        materialConfigId: id,
                        setIsDraftPost,
                        isEditTransition: enabledSaveAsDraft,
                    })
                }
                submitLabel="Lanjut"
                title="Apa anda yakin telah mengisi
                dengan benar?"
            />
            <ModalConfirm
                isOpen={confirmOut}
                onClose={() => {
                    setConfirmOut(false);
                }}
                onSubmit={() => {
                    setSearchSupplierName('');
                    setSearchTradeName('');
                    setFormData(initialValue);
                    navigate('/material-configuration');
                }}
                submitLabel="Ya"
                title="Apakah anda yakin ingin keluar?"
            />
            <Modalsuccess
                isOpen={openModal.type?.includes('success')}
                onClose={handleSubmitModalSuccess}
                title={isDraftPost ? 'Save draft berhasil' : `Sukses ${Boolean(id) ? 'Mengubah' : 'Membuat'} Item`}
                description={
                    isDraftPost
                        ? 'kembali ke table untuk melihat item'
                        : `Item berhasil ${Boolean(id) ? 'diubah' : 'dibuat'}, kembali ke table untuk melihat item`
                }
            />
        </FormCreateNewItemContext.Provider>
    );
}

export default Index;

import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onRemove = async ({ selectedSku, onClose, reFecthSkuHandler, toast, setIsLoading }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        setIsLoading(true);

        const { id } = selectedSku;

        //----------------------- DELETE STREAMLINE ----------------------------

        await Axios({
            method: 'DELETE',
            url: `/management/sku/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await reFecthSkuHandler();

        await toast({
            position: 'center-top',
            description: 'Berhasil menghapus SKU',
            status: 'success',
            duration: 1500,
        });
    } catch (error) {
        const err = error?.response?.data?.errors[0]?.message || 'err';
        toast({
            position: 'top-center',
            description: err,
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        onClose();
        setIsLoading(false);
    }
};

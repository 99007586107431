import { Flex, Box, Text } from '@chakra-ui/react';
import { useContext, useRef, useState } from 'react';
import { StreamlineManagementContext } from '@pages/streamlineManagement';
import { ArrowDownIcon } from '@asset/icons';

import { isShowButtonExpandHandler } from './functions';
import { tableList } from '../../const';
import './styles.scss';
import { useSelector } from 'react-redux';

const TableColumn = ({ streamline }) => {
    const ref = useRef();
    const { accessPermission } = useSelector((reducer) => reducer.accessPermissionSlice);

    const { onSelectedStreamline, onChangeModalState } = useContext(StreamlineManagementContext);
    const [isExpand, setIsExpand] = useState(false);

    const onToggleExpand = () => setIsExpand(!isExpand);

    return (
        <Box className="table-column-sticky table-column__wrapper__streamline-management">
            <Flex
                className={`table-column-sticky table-column__container__streamline-management ${
                    isExpand && 'unset-max-height'
                }`}
            >
                {tableList.map((item) => {
                    return (
                        <Box
                            flex={item.flex}
                            key={item.name}
                            w={item.width}
                            ref={ref}
                            className={`column-sticky__item `}
                            id={`column-sticky__item__${streamline?.id}`}
                        >
                            {item.component({
                                onChangeModalState,
                                onSelectedStreamline,
                                streamline,
                                value: streamline[item.value] || '-',
                                accessPermission,
                            })}
                        </Box>
                    );
                })}
            </Flex>

            {isShowButtonExpandHandler({ streamline }) && (
                <Box className="expand-button__container">
                    <Flex justify="center" alignItems="center" cursor="pointer" onClick={onToggleExpand}>
                        <Text className="expand-text">{isExpand ? 'Tutup' : 'Lihat Lebih Banyak'}</Text>
                        <Box className={`${isExpand && 'rotate-arrow'}`}>
                            <ArrowDownIcon color={'#4163A4'} />
                        </Box>
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export default TableColumn;

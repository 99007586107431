import { Box, Image, Text } from '@chakra-ui/react';
import logo from './forbidden-icon.svg';
import './styles.scss';

const ForbiddenPage = () => {
    return (
        <Box className="forbidden-page__container">
            <Box className="icon">
                <Image src={logo} />
            </Box>
            <Text className="title">Ooppss...!!</Text>
            <Text className="desc">Page ini tidak tersedia untuk mu</Text>
        </Box>
    );
};

export default ForbiddenPage;

import { Flex, Box } from '@chakra-ui/react';
import { tableListRekapDelivery, objDummy } from '../../const';
import { TableRekapDeliveryContext } from '../..';
import './styles.scss';
import { useContext } from 'react';

const TableColumn = ({ datasRekap }) => {
    const { setIsOpen, openModalNotes } = useContext(TableRekapDeliveryContext);
    return (
        <Flex className="table-column table-column__container__rekap-delivery">
            {tableListRekapDelivery.map((item, idx) => {
                return (
                    <Box
                        background={'white'}
                        key={item.name}
                        w={item.width}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: datasRekap[item.value] || '-',
                            setIsOpen,
                            openModalNotes,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

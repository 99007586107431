export function RestoreIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path
                stroke="#1746A2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M17.25 7.334v4.667M6.75 12v4.667"
            ></path>
            <path
                stroke="#1746A2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17.25 12a5.25 5.25 0 00-9.028-3.646M6.75 12a5.25 5.25 0 008.896 3.778"
            ></path>
            <rect width="23" height="23" x="0.5" y="0.5" stroke="#1746A2" rx="3.5"></rect>
        </svg>
    );
}

export function NoteIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path
                stroke="#2D2D2D"
                strokeLinejoin="round"
                d="M7.333 6.75c0-.323.262-.584.584-.584h5.833l2.917 2.917v8.166a.583.583 0 01-.584.584H7.918a.583.583 0 01-.583-.584V6.75z"
            ></path>
            <path
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.666 10.834h4.667M9.666 13.166h4.667"
            ></path>
            <path
                stroke="#2D2D2D"
                d="M.5 4A3.5 3.5 0 014 .5h16A3.5 3.5 0 0123.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4z"
            ></path>
        </svg>
    );
}

import { Text, Flex, Box } from '@chakra-ui/react';
import { NotesIcon } from './icons';

export const tableListRekapDelivery = [
    {
        name: 'No Order',
        value: 'id',
        width: 20,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'No PO',
        value: 'no_po',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Kode Item',
        value: 'orderManagement.materialConfig.kode_item',
        width: 150,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Nama Item',
        value: 'orderManagement.materialConfig.trade_name',
        width: 360,
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value || '-'}</Text>,
    },
    {
        name: 'Konfirmasi ETA',
        value: 'eta_konfirmasi',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Qty To Delivery',
        value: 'on_delivery',
        width: 110,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Update ETA',
        value: 'eta_update',
        width: 130,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Notes',
        value: 'notes',
        width: 280,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
];

export const objDummy = {
    no_order: 'O00005',
    no_po: 'PO000123',
    kode_item: 'GODE-TWC5,4B-SM-P',
    nama_item: 'Plug Crystallure SR Rich Cream 50 g Wardah',
    konfirmasi_eta: '07/02/2023',
    qty_delivery: '20000',
    update_eta: '08/02/2023',
    notes: 'Tes panjang notes aja....',
};

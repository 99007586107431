export const IconCheck = () => (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="75" cy="75" r="75" fill="#92F296" />
        <path
            d="M98.3332 57.5L66.2498 89.5833L51.6665 75"
            stroke="#2D2D2D"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export const IconQuestionMark = () => (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="75" cy="75" r="75" fill="#7086B0" />
        <path
            d="M70.9165 86.6667C70.9165 82.7292 71.2694 79.8981 71.9753 78.1733C72.6811 76.4486 74.1754 74.5644 76.4582 72.5208C78.4512 70.7708 79.9708 69.2513 81.0169 67.9621C82.063 66.6729 82.5851 65.2029 82.5832 63.5521C82.5832 61.559 81.9153 59.9062 80.5794 58.5937C79.2436 57.2812 77.3837 56.625 74.9998 56.625C72.5207 56.625 70.6365 57.3785 69.3473 58.8854C68.0582 60.3924 67.1472 61.9236 66.6144 63.4792L59.104 60.2708C60.1248 57.1597 61.9964 54.4618 64.7186 52.1771C67.4408 49.8924 70.8679 48.75 74.9998 48.75C80.104 48.75 84.0298 50.1724 86.7773 53.0171C89.5248 55.8618 90.8976 59.2762 90.8957 63.2604C90.8957 65.691 90.3736 67.7696 89.3294 69.4962C88.2853 71.2229 86.6441 73.179 84.4061 75.3646C82.0241 77.6493 80.5784 79.3876 80.069 80.5796C79.5596 81.7715 79.3039 83.8006 79.3019 86.6667H70.9165ZM74.9998 104.167C73.3957 104.167 72.0229 103.596 70.8815 102.455C69.7401 101.313 69.1684 99.9394 69.1665 98.3333C69.1665 96.7292 69.7382 95.3564 70.8815 94.215C72.0248 93.0736 73.3976 92.5019 74.9998 92.5C76.604 92.5 77.9778 93.0717 79.1211 94.215C80.2644 95.3583 80.8351 96.7311 80.8332 98.3333C80.8332 99.9375 80.2615 101.311 79.1182 102.455C77.9748 103.598 76.6021 104.169 74.9998 104.167Z"
            fill="black"
        />
    </svg>
);

export const RedWaningIcon = () => (
    <svg
        style={{ marginLeft: '4px' }}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.1 3.45964L16.1258 11.8304C16.848 13.0326 16.4933 14.6144 15.333 15.3629C14.9436 15.615 14.4897 15.7494 14.0258 15.7499H3.9735C2.60775 15.7499 1.5 14.6024 1.5 13.1856C1.5 12.7064 1.62975 12.2376 1.8735 11.8304L6.9 3.45964C7.6215 2.25739 9.147 1.88914 10.3073 2.63764C10.6283 2.84464 10.8998 3.12589 11.1 3.45964ZM9.435 4.08889C9.34318 4.02984 9.24035 3.99 9.13271 3.9718C9.02506 3.9536 8.91485 3.95741 8.80872 3.98299C8.70259 4.00858 8.60275 4.05541 8.51523 4.12067C8.42771 4.18592 8.35432 4.26823 8.2995 4.36264L3.27375 12.7349C3.19211 12.8713 3.14908 13.0274 3.14925 13.1864C3.14925 13.6589 3.51825 14.0414 3.97425 14.0414H14.025C14.1795 14.0414 14.3303 13.9964 14.4608 13.9124C14.648 13.788 14.7801 13.5961 14.8294 13.3768C14.8787 13.1574 14.8415 12.9275 14.7255 12.7349L9.69975 4.36264C9.63384 4.25241 9.54352 4.15876 9.43575 4.08889H9.435ZM9 12.7499C8.80109 12.7499 8.61032 12.6709 8.46967 12.5302C8.32902 12.3896 8.25 12.1988 8.25 11.9999C8.25 11.801 8.32902 11.6102 8.46967 11.4696C8.61032 11.3289 8.80109 11.2499 9 11.2499C9.19891 11.2499 9.38968 11.3289 9.53033 11.4696C9.67098 11.6102 9.75 11.801 9.75 11.9999C9.75 12.1988 9.67098 12.3896 9.53033 12.5302C9.38968 12.6709 9.19891 12.7499 9 12.7499ZM9 5.99989C9.19891 5.99989 9.38968 6.07891 9.53033 6.21956C9.67098 6.36022 9.75 6.55098 9.75 6.74989V9.74989C9.75 9.94881 9.67098 10.1396 9.53033 10.2802C9.38968 10.4209 9.19891 10.4999 9 10.4999C8.80109 10.4999 8.61032 10.4209 8.46967 10.2802C8.32902 10.1396 8.25 9.94881 8.25 9.74989V6.74989C8.25 6.55098 8.32902 6.36022 8.46967 6.21956C8.61032 6.07891 8.80109 5.99989 9 5.99989Z"
            fill="white"
        />
    </svg>
);

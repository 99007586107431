import React from 'react';
import { Table as TableChakra, Thead, Tbody, Tr, Th, Td, Box, Input, Tooltip } from '@chakra-ui/react';

import './styles.scss';

function Table({ head = [], body = [] }) {
    return (
        <Box className="table-additional-upload">
            <TableChakra className="table-container-archived">
                <Thead>
                    <Tr className="container-head">
                        {head?.map((tHead, index) => {
                            let cellWidth = tHead?.widthCell > 44 ? tHead?.widthCell - 15 : tHead?.widthCell;
                            return (
                                <Th
                                    key={index}
                                    className="title-head"
                                    sx={{
                                        minWidth:
                                            tHead?.id === 'Kode Item'
                                                ? `${cellWidth * 10 + 90}px`
                                                : `${cellWidth * 10 + 30}px`,
                                        paddingLeft: index === 0 ? '10px !important' : '6px !important',
                                    }}
                                >
                                    {tHead.name}
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody className="table-body-container">
                    {body.map((tBody, bodyIndex) => (
                        <Tr className="table-tr" key={bodyIndex}>
                            {head.map((tHead, headIndex) => {
                                const messageError = tBody[tHead.id]?.messageError || '';
                                const isErrorHighLight = Boolean(tBody[tHead.id]?.isError);
                                return (
                                    <Td
                                        className="table-td"
                                        sx={{
                                            paddingLeft: headIndex === 0 ? '4px !important' : '0px !important',
                                        }}
                                        key={headIndex}
                                    >
                                        <Tooltip
                                            hasArrow
                                            bg="red.500"
                                            label={Boolean(messageError) ? messageError : ''}
                                        >
                                            <Input
                                                readOnly
                                                className={`input-value ${isErrorHighLight && 'empty-input'}`}
                                                defaultValue={tBody[tHead.id]?.value?.toString() || ''}
                                            />
                                        </Tooltip>
                                    </Td>
                                );
                            })}
                        </Tr>
                    ))}
                </Tbody>
            </TableChakra>
        </Box>
    );
}

export default Table;

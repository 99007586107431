import React from 'react';
import { Menu, MenuButton, Button, MenuList, MenuItem, Text, Box, FormHelperText } from '@chakra-ui/react';
import { ArrowRightIcon } from '@asset/icons';

import './styles.scss';

function Index({
    width = '481px',
    menuList = [],
    onClickMenuItem,
    rightIcon,
    titleMenuButton = 'pilih status',
    keyMap,
    error,
}) {
    return (
        <Menu>
            <Box className="drop-down-menu-container">
                <MenuButton
                    sx={{
                        width,
                        border: `0.5px solid ${error ? 'red' : '#D7D7D7'} !important`,
                    }}
                    className="menu-button"
                    as={Button}
                    rightIcon={rightIcon || <ArrowRightIcon />}
                >
                    <Text
                        className="menu-button-name"
                        sx={{
                            color: titleMenuButton === 'pilih status' ? '#939393' : '#000',
                        }}
                    >
                        {titleMenuButton}
                    </Text>
                </MenuButton>
            </Box>
            <MenuList className="drop-down-menu-list-container">
                {menuList.map((item, index) => (
                    <MenuItem className="menu-item-list" onClick={() => onClickMenuItem(item)} key={index}>
                        {item[keyMap]}
                    </MenuItem>
                ))}
            </MenuList>
            {error && (
                <FormHelperText className="error-schema-drop-down">* field ini tidak boleh kosong</FormHelperText>
            )}
        </Menu>
    );
}

export default Index;

import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { get, find } from 'lodash';

export const onChangeFormData = ({ setFormData, value, keyValue }) => {
    setFormData((v) => ({
        ...v,
        [keyValue]: value,
    }));
};

export const onSubmitReport = async ({
    refetchProductStatusHandler,
    formData,
    setIsSubmitLoading,
    setIsSubmitted,
    selectedDataProductStatus,
    listJenisProblem,
    setIsSuccess,
    toast,
}) => {
    setIsSubmitLoading(true);
    setIsSubmitted(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const kard_id = selectedDataProductStatus.kard.id;
    try {
        await Axios({
            method: 'POST',
            url: '/kendala',
            data: {
                jenis_kendala_id: get(find(listJenisProblem, { name: formData.jenis_problem }), 'id'),
                kard_id,
                detail: formData.detail_problem,
            },
            headers: { Authorization: 'Bearer ' + userToken },
        });
        await refetchProductStatusHandler();
        setIsSuccess(true);
    } catch (error) {
        console.error(error.message);
        toast({
            position: 'top-center',
            description: error.message || 'Something wrong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        setIsSubmitLoading(false);
    }
};

export const fetchListJenisProblem = async ({ setListJenisProblem }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    try {
        const { data } = await Axios({
            method: 'GET',
            url: '/jenis-kendala/list',
            headers: { Authorization: 'Bearer ' + userToken },
        });
        setListJenisProblem(data?.data?.jenisKendala);
    } catch (error) {
        console.error(error.message);
    }
};
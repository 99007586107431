import React, { useState, useContext, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { ArrowRightIcon } from '@asset/icons';
import { Flex, Box, Text, Popover, PopoverTrigger, PopoverContent, PopoverBody, Button } from '@chakra-ui/react';
import { getCurrentFilterSelected, getDefaultFilterAfterRefresh, getFilterOrderStatus } from './function';
import { MainDashboardContext } from '@pages/mainDashboard';

import './styles.scss';

function Index() {
    const cookies = new Cookies();
    const { filterOrderStatus, setFilterOrderStatus } = useContext(MainDashboardContext);
    const hasFilter = getFilterOrderStatus({ cookieFilter: cookies.get('filterListOrderStatus') || [] });
    const [openOptions, setOpenOptions] = useState(false);
    const handleClickFilterOptions = ({ name }) => {
        getCurrentFilterSelected({ currentFilter: name, filterOrderStatus, setFilterOrderStatus });
        setOpenOptions(false);
    };
    useEffect(() => {
        getDefaultFilterAfterRefresh({
            currentFilter: cookies.get('filterListOrderStatus') || 'false',
            filterOrderStatus,
            setFilterOrderStatus,
        });
    }, []);
    return (
        <>
            {hasFilter.showFilter && (
                <Popover isOpen={openOptions} isLazy onClose={() => setOpenOptions(false)}>
                    <PopoverTrigger>
                        <Flex className="section-select-filter" onClick={() => setOpenOptions(true)} cursor="pointer">
                            <Text className="text-select-streamline">{hasFilter.selected}</Text>
                            <Box>
                                <ArrowRightIcon />
                            </Box>
                        </Flex>
                    </PopoverTrigger>
                    <PopoverContent className="filterList" w="170px">
                        <PopoverBody h="full" display="grid" padding={0}>
                            {hasFilter.option.map((itemFilter, index) => {
                                if (itemFilter.isHidden) return;
                                return (
                                    <Button
                                        onClick={() => handleClickFilterOptions({ name: itemFilter })}
                                        variant="ghost"
                                        className="button-filter-options"
                                    >
                                        <Text className="text-select-filter" key={index}>
                                            {itemFilter.name}
                                        </Text>
                                    </Button>
                                );
                            })}
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            )}
        </>
    );
}

export default Index;

import { Flex, Box } from '@chakra-ui/react';
import { tableList } from '../../const';
import './styles.scss';

const TableHead = () => {
    return (
        <Flex className="table-head__container__streamline-management">
            {tableList.map((head) => (
                <Box flex={head.flex} className="table-head__item" width={head.width} key={head.name}>
                    {head.name}
                </Box>
            ))}
        </Flex>
    );
};

export default TableHead;

import { Flex, Box, Text, IconButton } from '@chakra-ui/react';
import { tableList } from '../../const';
import { IconSortHeaderTable } from '@pages/orderPlanning/icons';
import { sortSwitch } from '@pages/skuConfiguration/function';
import './styles.scss';

const TableHead = ({ setSortName }) => {
    return (
        <Flex className="table-head__container__sku-configuration">
            {tableList.map((head, idx, it) => {
                if (head.children) {
                    return (
                        <Box className="table-head__item" flexDirection="column" width={head.width} key={head.name}>
                            <Flex className="table-head__parent" pl="10px" w="fit-content">
                                {head.name}
                                {head.sortValue && (
                                    <IconButton
                                        zIndex={1}
                                        onClick={() =>
                                            setSortName((previousState) =>
                                                sortSwitch({ previousState, currentState: head })
                                            )
                                        }
                                        minW={0}
                                        ml={7.75}
                                        h={0}
                                        p={0}
                                        icon={<IconSortHeaderTable />}
                                    />
                                )}
                            </Flex>
                            <Flex justify="space-between" w="100%">
                                {head.children.map((child, index) => {
                                    return (
                                        <Box key={index} className="table-head__child-container">
                                            <Box
                                                style={{
                                                    paddingLeft: child === 'strg NDC' && '5px',
                                                }}
                                                className="table-head__child"
                                                borderBottom={head.grandChild ? '1px solid grey' : 'none'}
                                            >
                                                <Text fontWeight={head.grandChild ? 'bold' : 500}>{child}</Text>
                                            </Box>
                                            <Flex w="100%">
                                                {head.grandChild &&
                                                    head.grandChild.map((grandChild) => (
                                                        <Box className="table-head__grand-child">{grandChild}</Box>
                                                    ))}
                                            </Flex>
                                        </Box>
                                    );
                                })}
                            </Flex>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}
        </Flex>
    );
};

export default TableHead;

import { useState, useContext } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { PyramidHamburgerIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';
import { handleCloseModalFilter } from './function';
import ModalFilterKodeItem from './filterCodeItem/index';
import { tableOrderDeliveryContext } from '../../..';
import './styles.scss';

function Index() {
    const { filter, setFilter } = useContext(tableOrderDeliveryContext);
    const [selectedData, setSelectedData] = useState([]);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [kodeItem, setKodeItem] = useState([]);
    const [kodeItemChoosed, setKodeItemChoosed] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [reFetch, setReFetch] = useState(false);
    const [debounceVal, setDebounceVal] = useState('');
    const [search, setSearch] = useState('');
    const [totalData, setTotalData] = useState(0);
    const [page, setPage] = useState(1);
    const dataSelected = kodeItem?.filter((item) => item.checked);

    const handleApplyFilter = () => {
        setFilter({ ...filter, itemCode: kodeItemChoosed });
        setOpenModalFilter(false);
        setSelectedData(dataSelected);
        setPage(1);
        setSearch('');
        setReFetch(!reFetch);
    };

    const handleResetFilter = () => {
        handleCloseModalFilter({
            selectedData,
            kodeItem,
            setKodeItem,
            type: 'reset',
            setKodeItemChoosed,
        });
        setPage(1);
        setSearch('');
        setReFetch(!reFetch);
    };
    return (
        <Box className="code-item__container-order-delivery">
            <ModalFilterKodeItem
                outlineLabel="Reset Filter"
                modalTitle="Filter Kode Item"
                labelSearch="Kode yang dipilih"
                labelSelectedData="Pilih Kode Item"
                search={search}
                page={page}
                setPage={setPage}
                setSearch={setSearch}
                reFetch={reFetch}
                kodeItem={kodeItem}
                setKodeItem={setKodeItem}
                isOpen={openModalFilter}
                kodeItemChoosed={kodeItemChoosed}
                setKodeItemChoosed={setKodeItemChoosed}
                loadingSearch={loadingSearch}
                setLoadingSearch={setLoadingSearch}
                debounceVal={debounceVal}
                setDebounceVal={setDebounceVal}
                totalData={totalData}
                setTotalData={setTotalData}
                onClose={() => {
                    handleCloseModalFilter({
                        setOpenModalFilter,
                        selectedData,
                        kodeItem,
                        setKodeItem,
                        setKodeItemChoosed,
                    });
                    setPage(1);
                    setSearch('');
                    setReFetch(!reFetch);
                }}
                selectedData={selectedData}
                handleApplyFilter={handleApplyFilter}
                handleResetFilter={handleResetFilter}
            />
            <Box
                onClick={() => {
                    setOpenModalFilter(true);
                }}
                className={`btn-filter-code-item-container ${selectedData?.length > 0 && 'has-item-selected'}`}
            >
                <Text className={`btn-filter-text`}>Cari kode item</Text>
                <Box className={`btn-filter-code-item-icon`}>
                    <PyramidHamburgerIcon fill={selectedData?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                </Box>
                {selectedData?.length > 0 && <Text className="btn-filter-badge">{selectedData?.length}</Text>}
            </Box>
        </Box>
    );
}

export default Index;

import { Flex, Box } from '@chakra-ui/react';

import './styles.scss';

function Index({ onChangeTab, selectedTab, filterOrderStatus }) {
    return (
        <Flex>
            <Box
                className={`tab-item ${selectedTab === 'production-status' && 'active-tab'}`}
                onClick={() => onChangeTab({ value: 'production-status' })}
            >
                Progress Production
            </Box>
            {filterOrderStatus
                .sort((a, b) => a.index - b.index)
                .map((tabItem, index) => (
                    <Box
                        key={index}
                        className={`tab-item ${selectedTab === tabItem.value && 'active-tab'}`}
                        onClick={() => onChangeTab({ value: tabItem.value, filter: tabItem.filter })}
                    >
                        {tabItem.name}
                    </Box>
                ))}
        </Flex>
    );
}

export default Index;

import { Checkbox, Box, Text, Flex } from '@chakra-ui/react';
import { EditIcon, RemoveIcon } from '@pages/skuConfiguration/icons';

import { numberToFixed, numberToFixedLeadtime } from './function';
import { isNumber } from 'lodash';

export const tableList = [
    {
        name: 'NAMA ITEM',
        value: 'nama_item',
        width: 325,
        sortValue: 'nama_item',
        children: ['-'],
        component: ({ value, sku, onSelectedSkuExport }) => (
            <Box>
                <Flex justifyContent={'space-between'} alignItems={'center'} gap={'15px'}>
                    <Box>
                        <Checkbox
                            className="checkbox-input"
                            onChange={(v) => {
                                onSelectedSkuExport({ value: sku, isRemove: v.target.checked ? false : true });
                            }}
                        />
                    </Box>
                    <Box>
                        <Text textAlign="left !important" className="column-text" w="300px" paddingRight={'23px'}>
                            {value}
                        </Text>
                    </Box>
                </Flex>
            </Box>
        ),
    },
    {
        name: 'KODE ITEM',
        width: 200,
        value: 'kode_item',
        sortValue: 'kode_item',
        children: ['-'],

        component: ({ value }) => (
            <Text textAlign="left !important" fontWeight={'bold'} className="column-text">
                {value}
            </Text>
        ),
    },
    {
        name: 'KODE ODOO',
        width: 134,
        value: 'odoo_code',
        sortValue: 'odoo_code',
        children: ['-'],

        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'KODE SL',
        width: 134,
        value: 'kode_sl',
        sortValue: 'kode_sl',
        children: ['-'],

        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },

    {
        name: 'STREAMLINE',
        value: 'streamline',
        sortValue: 'streamline,name',
        width: 200,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value.name}</Text>,
    },
    {
        name: 'STOK NASIONAL',
        value: 'stock_nasional',
        width: 306,
        children: ['Standar', 'Total'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {['standar_stock_nasional', 'stock_nasional'].map((item, idx) => {
                    return (
                        <Text pl={idx !== 0 && '10px'} className="column-text" key={item}>
                            {isNumber(value[item]) ? value[item] : '-'}
                        </Text>
                    );
                })}
            </Flex>
        ),
    },
    {
        name: 'STOCK NDC',
        value: 'stock_ndc',
        width: 306,
        children: ['pcs', 'storage'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {['pcs', 'pallet'].map((item, idx) => {
                    return (
                        <Text pl={idx !== 0 && '10px'} className="column-text" key={item}>
                            {value[item]}
                        </Text>
                    );
                })}
            </Flex>
        ),
    },
    {
        name: 'CO MINGGUAN',
        value: 'customer_order',
        width: 306,
        children: ['pcs', 'storage ndc', 'koli'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {['pcs', 'storage', 'koli'].map((item, idx) => {
                    return (
                        <Text pl={idx !== 0 && '10px'} className="column-text" key={item}>
                            {numberToFixed(value[item])}
                        </Text>
                    );
                })}
            </Flex>
        ),
    },

    {
        name: 'lead time (jam)',
        value: 'leadtime',
        width: 800,
        children: ['timbang', 'premix', 'olah 1', 'olah 2', 'kemas', 'lain-lain ', 'total'], // 'total'
        component: ({ value }) => (
            <Flex className="column__item-child">
                {['timbang', 'premix', 'olah_1', 'olah_2', 'kemas', 'others', 'total'].map((item, idx) => {
                    // 'total'
                    return (
                        <Text pl={idx !== 0 && '12px'} className="column-text" key={item}>
                            {numberToFixedLeadtime(value[item])}
                        </Text>
                    );
                })}
            </Flex>
        ),
    },
    {
        name: 'JENIS STORAGE NDC',
        value: 'jenis_storage_ndc',
        sortValue: 'jenis_storage_ndc',
        children: ['-'],
        width: 200,
        component: ({ value }) => (
            <Text className="column-text" pt={'10px'} textAlign={'center'}>
                {value}
            </Text>
        ),
    },
    {
        name: 'Safety',
        value: 'safety',
        width: 210,
        children: ['pcs', 'strg NDC'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                <Box className="column__item-grand-child">
                    {['pcs', 'strgNdc'].map((item) => (
                        <Text className="column-text" key={item}>
                            {numberToFixed(value[item])}
                        </Text>
                    ))}
                </Box>
            </Flex>
        ),
    },
    {
        name: 'ROP',
        value: 'rop',
        width: 210,
        children: ['pcs', 'strg NDC'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                <Box className="column__item-grand-child">
                    {['pcs', 'strgNdc'].map((item) => (
                        <Text className="column-text" key={item}>
                            {numberToFixed(value[item])}
                        </Text>
                    ))}
                </Box>
            </Flex>
        ),
    },
    {
        name: 'Maximum',
        value: 'maximum',
        width: 210,
        children: ['pcs', 'strg NDC'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                <Box className="column__item-grand-child">
                    {['pcs', 'strgNdc'].map((item) => (
                        <Text className="column-text" key={item}>
                            {numberToFixed(value[item])}
                        </Text>
                    ))}
                </Box>
            </Flex>
        ),
    },

    {
        name: 'Action',
        width: 117,
        value: null,
        children: ['-'],
        component: ({ onEditHandler, onSelectedSku, sku, onRemoveHandler }) => (
            <Flex>
                <Box
                    h="max-content"
                    cursor="pointer"
                    rounded={'4px'}
                    border={'1px solid'}
                    borderColor={'#1746A2'}
                    onClick={() => {
                        onEditHandler();
                        onSelectedSku({ value: sku });
                    }}
                    p="6px"
                    mr="12px"
                >
                    <EditIcon />
                </Box>
                <Box
                    h="max-content"
                    cursor="pointer"
                    rounded={'4px'}
                    border={'1px solid'}
                    borderColor={'#EF5350'}
                    onClick={() => {
                        onRemoveHandler();
                        onSelectedSku({ value: sku });
                    }}
                    p="6px"
                >
                    <RemoveIcon />
                </Box>
            </Flex>
        ),
    },
];

export const obj = {
    stock_ndc_koli: 0.008771929824561403,
    stock_ndc_palet: 0,
    id: 1,
    odoo_code: '01911',
    kode_sl: 'SA1',
    kode_item: 'EB-IPB3M01-W5.5G',
    nama_item: 'Wardah Instaperfect BROWFESSIONAL 3D Brow Mascara 01. BROWN 5.5 g',
    stock_ndc: 2,
    jenis_storage_ndc: 'Trolly',
    streamline_id: 1,
    createdAt: '2023-01-12T07:08:14.906Z',
    updatedAt: '2023-01-12T07:08:14.906Z',
    deletedAt: null,
    mpq_kemas: {
        id: 1,
        pcs: 2456,
        pcs_per_troli: 228,
        troli: 10.771929824561404,
        troli_per_storage: 0,
        storage_ndc: 0,
        sku_id: 1,
        createdAt: '2023-01-12T07:08:14.912Z',
        updatedAt: '2023-01-12T07:08:14.912Z',
        deletedAt: null,
    },
    customer_order: {
        id: 1,
        pcs: 6,
        koli: 0.02631578947368421,
        palet: 0,
        sku_id: 1,
        createdAt: '2023-01-12T07:08:14.914Z',
        updatedAt: '2023-01-12T07:08:14.914Z',
        deletedAt: null,
    },
    leadtime: {
        id: 1,
        timbang: 1,
        premix: 2,
        olah_1: 2,
        olah_2: 2,
        kemas: 2,
        others: 2,
        sku_id: 1,
        createdAt: '2023-01-12T07:08:14.916Z',
        updatedAt: '2023-01-12T07:08:14.916Z',
        deletedAt: null,
    },
    critical_point: {
        id: 1,
        s_pcs: 1,
        s_koli: 0.0043859649122807015,
        s_ndc: null,
        rop_pcs: 2,
        rop_koli: 0.008771929824561403,
        rop_ndc: null,
        max_pcs: 2,
        max_koli: 0.008771929824561403,
        max_ndc: null,
        sku_id: 1,
        createdAt: '2023-01-12T07:08:14.918Z',
        updatedAt: '2023-01-12T07:08:14.918Z',
        deletedAt: null,
    },
};

import { Box, Text, Flex, CloseButton } from '@chakra-ui/react';
import { NotesIcon } from './icons';
import { onDownloadGlobalHandler } from '@store/list/downloadManagement';
import './styles.scss';
import { useDispatch } from 'react-redux';

const ModalFailedDownload = ({ description, downloadId }) => {
    const dispatch = useDispatch();
    const closeModal = () => {
        dispatch(
            onDownloadGlobalHandler({
                name: 'demand anticipation',
                type: 'done',
                id: downloadId,
            })
        );
    };
    return (
        <Box className="modal-failed-download-global__container">
            <Flex alignItems="center">
                <Box className="box-icon">
                    <NotesIcon />
                </Box>
                <Text className="text-desc-modal">{description}</Text>
            </Flex>
            <Box className="box-close-btn" _hover="none" onClick={closeModal}>
                <CloseButton />
            </Box>
        </Box>
    );
};

export default ModalFailedDownload;

import { Box, Skeleton, Flex } from '@chakra-ui/react';

const ProductionStatusSkeleton = () => {
    return (
        <Box className="production-status-skeleton__container">
            <Flex className="section-head" gap="20px" width="max-content" flexWrap="nowrap" mb="10px">
                <Skeleton w="77px" h="48px" />
                <Skeleton h="50px" w="270px" />
                <Skeleton h="50px" w="270px" />
                <Skeleton h="50px" w="270px" />
                <Skeleton h="50px" w="270px" />
                <Skeleton h="50px" w="270px" />
            </Flex>
            {[1, 2, 3, 4, 5].map((_, idx) => (
                <Flex key={idx} className="section-cards" gap="20px" width="max-content" flexWrap="nowrap" mb="10px">
                    <Skeleton h="152" w="77px" />

                    {[1, 2, 3, 4, 5].map((_, idx) => (
                        <Flex
                            key={idx}
                            flexDirection="column"
                            className="card"
                            border="0.5px solid #e4e4e4"
                            borderRadius="4px"
                            w="270px"
                            h="140px"
                        >
                            <Box bg="#bdc6d3" h="32px" w="100%"></Box>
                            <Box p="12px 8px">
                                <Flex></Flex>
                            </Box>
                        </Flex>
                    ))}
                </Flex>
            ))}
        </Box>
    );
};

export default ProductionStatusSkeleton;

import Axios from '@services/axios';
import { read, utils } from 'xlsx';
import Cookies from 'universal-cookie';
import { getWeekHandler } from '@components/header/functions';
import moment from 'moment/moment';
import 'moment/locale/id';

export const handleNext = ({ disabled, setOpenModalInputWeek, onOpenModal }) => {
    if (disabled) return;
    setOpenModalInputWeek((prev) => ({ ...prev, isOpen: false }));
    onOpenModal({ value: true });
};
export const optionInputWeek = () => {
    let availableWeek = [];
    for (let index = Number(getWeekHandler()); index < Number(moment().weeksInYear()); index++) {
        availableWeek.push({
            name: new Date().getFullYear(),
            title: `Week ${index}`,
            week: index,
        });
    }
    const nextYearTotalWeek = moment(new Date()).add(1, 'year').weeksInYear();
    const nextYear = moment(new Date()).add(1, 'year').year();
    for (let futureIndex = 1; futureIndex < nextYearTotalWeek; futureIndex++) {
        availableWeek.push({
            name: nextYear,
            title: `Week ${futureIndex}`,
            week: futureIndex,
        });
    }
    return availableWeek;
};

export const onChangeFile = ({ files, setPreviewData, setState, setFile, setDisabledSaveItem, toast }) => {
    if (files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            let isErrorFile = false;
            const workbook = read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            worksheet['!ref'] = 'A1:BU1000';
            /// https://stackoverflow.com/a/54852654/18038473
            const json = utils.sheet_to_json(worksheet, { defval: null });
            if (Boolean(json?.length === 0)) {
                setDisabledSaveItem(true);
                toast({
                    title: 'Error !',
                    description: 'File tidak bisa diupload, setidaknya terdapat 1 data',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
                setState('upload');
                return;
            }
            let jsonAfterCompile = [];
            for (let indexItem = 0; indexItem < json.length; indexItem++) {
                const keyAttribute = Object.keys(json[indexItem] || {});
                let newKey = {};
                for (let indexKey = 0; indexKey < keyAttribute?.length; indexKey++) {
                    if (!keyAttribute[indexKey]?.toLowerCase()?.includes('empty')) {
                        newKey[`${keyAttribute[indexKey]}`] = {
                            value: json[indexItem][keyAttribute[indexKey]],
                            isError: false,
                            messageError: null,
                        };
                    }
                }
                jsonAfterCompile.push(newKey);
            }
            for (let indexItem = 0; indexItem < jsonAfterCompile.length; indexItem++) {
                const currentAttribute = Object.keys(jsonAfterCompile[indexItem] || {});
                for (let indexAttribute = 0; indexAttribute < currentAttribute.length; indexAttribute++) {
                    const isKodeItem = jsonAfterCompile[indexItem]['Kode Item']?.value;
                    const isTradeName = jsonAfterCompile[indexItem]['Trade Name']?.value;
                    const isSupplierRef = jsonAfterCompile[indexItem]['Supplier Ref']?.value;
                    if (isKodeItem === null) {
                        isErrorFile = true;
                        jsonAfterCompile[indexItem]['Kode Item'].isError = true;
                        jsonAfterCompile[indexItem]['Kode Item'].messageError = 'Field ini tidak boleh kosong';
                    }
                    if (isTradeName === null) {
                        isErrorFile = true;
                        jsonAfterCompile[indexItem]['Trade Name'].isError = true;
                        jsonAfterCompile[indexItem]['Trade Name'].messageError = 'Field ini tidak boleh kosong';
                    }
                    if (isSupplierRef === null) {
                        isErrorFile = true;
                        jsonAfterCompile[indexItem]['Supplier Ref'].isError = true;
                        jsonAfterCompile[indexItem]['Supplier Ref'].messageError = 'Field ini tidak boleh kosong';
                    }
                }
            }
            setDisabledSaveItem(isErrorFile);
            setPreviewData(jsonAfterCompile);
            setState('table-view');
            if (isErrorFile) {
                toast({
                    title: 'Error !',
                    description: 'File tidak bisa diupload, cek kembali!',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
            }
            setFile(files);
        };
        reader.readAsArrayBuffer(files);
    }
};
export const handleDownloadTemplate = async ({ setLoading, toast }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setLoading((prev) => ({ ...prev, download: true }));
    try {
        const { data } = await Axios({
            method: 'GET',
            url: 'demand-anticipation/template',
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'template-demand_anticipation';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        await toast({
            position: 'center-top',
            description: 'Berhasil Download Template',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    } catch (error) {
        const message = error?.response?.data?.errors?.length ? error?.response?.data?.errors[0]?.message : null;
        console.log(error);
        toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setLoading((prev) => ({ ...prev, download: false }));
    }
};

export const handleUploadFile = async ({
    file,
    setLoading,
    startWeek,
    setOpenModalInputWeek,
    setSubmitError,
    setIsOpenConfirm,
    previewData,
    setPreviewData,
    setDisabledSaveItem,
    setOpenModalAfterSubmit,
}) => {
    setLoading((prev) => ({ ...prev, upload: true }));
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const formData = new FormData();
    formData.append('file', file);
    await Axios({
        method: 'POST',
        url: `demand-anticipation/upload?startWeek=${startWeek}`,
        data: formData,
        headers: { Authorization: 'Bearer ' + userToken },
    })
        .then((res) => {
            setOpenModalAfterSubmit({ isOpen: true, message: '' });
        })
        .catch((error) => {
            console.log(error);
            setDisabledSaveItem(true);
            const errors = error?.response?.data?.errors?.[0]?.errors || [];
            let cloneCurrentTable = [...previewData];
            const attributeHeader = Object.keys(previewData?.[0] || {});
            for (let index = 0; index < errors.length; index++) {
                const isSupplierColumn = errors[index]?.msg?.toLowerCase()?.includes('supplier ref');
                if (isSupplierColumn) {
                    cloneCurrentTable[errors[index].row - 1][attributeHeader[2]].isError = true;
                    cloneCurrentTable[errors[index].row - 1][attributeHeader[2]].messageError = errors[index]?.msg;
                }
                cloneCurrentTable[errors[index].row - 1][attributeHeader[errors[index].col - 1]].isError = true;
                cloneCurrentTable[errors[index].row - 1][attributeHeader[errors[index].col - 1]].messageError =
                    errors[index]?.msg;
            }
            const errorDetail = error?.response?.data?.errors?.[0]?.errors || [];
            if (errorDetail?.length === cloneCurrentTable?.length) {
                setSubmitError((prev) => {
                    return {
                        ...prev,
                        open: true,
                        type: 'catch',
                        message: { errorDetail, error },
                    };
                });
            } else {
                setOpenModalAfterSubmit({
                    isOpen: true,
                    message: 'Ada baris item yang gagal dibuat. Tutup modal ini untuk melihat detail.',
                });
            }
            setIsOpenConfirm(false);
            setPreviewData(cloneCurrentTable);
        })
        .finally(() => {
            setLoading((prev) => ({ ...prev, upload: false }));
            setOpenModalInputWeek({ isOpen: false, selected: {} });
        });
};
export const AutomaticWidthTable = ({ previewData }) => {
    const currentData = previewData?.map((data) => {
        return Object.keys(data || {})?.map((item) => {
            const widthCell = item?.length < data?.[item]?.length ? data?.[item]?.length : item?.length;
            return {
                id: item,
                name: item,
                widthCell: widthCell,
                isCenter: '',
            };
        });
    });
    const findlongestWidth = currentData
        .map((item) => ({ ...item, totalCellWidth: item?.map((value) => value.widthCell).reduce((a, b) => a + b) }))
        .sort((a, b) => b?.totalCellWidth - a?.totalCellWidth);
    let dataResult = [];
    const length = Object.keys(previewData?.[0] || {})?.length;
    for (let index = 0; index < length; index++) {
        dataResult.push(findlongestWidth?.[0][index]);
    }
    return dataResult;
};

export const viewViewerObj = {
    mainDashboard: {
        isViewMainDashboard: true,
        isCanGantiPlant: true,
        isCanFilterZona: true,
        isCanFilterProccess: true,
        isCanViewDetailMainDashboard: true,
        isCanInputKendala: false,
        isCanViewDetailKendala: true,
        isCanDeleteKendala: false,
        isCanFilterInboundPending: true,
        isCanFilterZonaInbound: true,
        isCanFilterStreamlineInboundPending: true,
        isCanFilterJenisPending: true,
        isCanViewDetailPending: true,
        isCanFilterZonaQueue: true,
        isCanFilterStreamlineQueue: true,
        isCanFilterKesiapanBRQueue: true,
        isCanFilterZonaDone: true,
        isCanFilterKetepatanWaktuDone: true,
        isCanViewDetailDone: true,
        isCanViewDetailKendalaDone: true,
    },

    dashboardKondisiSku: {
        isCanViewDashboardKondisiSku: true,
        isCanGantiPlant: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
        isCanEditPlanningNextWeek: false,
        isCanAction: false,
        isCanPendingBB: false,
        isCanPendingPackaging: false,
        isCanConfirmMaterial: false,
        isCanChecklistBrReady: false,
    },
};

export const viewNdcObj = {
    mainDashboard: {
        isViewMainDashboard: true,
        isCanGantiPlant: true,
        isCanFilterZona: true,
        isCanFilterProccess: true,
        isCanViewDetailMainDashboard: true,
        isCanInputKendala: true,
        isCanViewDetailKendala: true,
        isCanDeleteKendala: true,
        isCanFilterInboundPending: true,
        isCanFilterZonaInbound: true,
        isCanFilterStreamlineInboundPending: true,
        isCanFilterJenisPending: true,
        isCanViewDetailPending: true,
        isCanFilterZonaQueue: true,
        isCanFilterStreamlineQueue: true,
        isCanFilterKesiapanBRQueue: true,
        isCanFilterZonaDone: true,
        isCanFilterKetepatanWaktuDone: true,
        isCanViewDetailDone: true,
        isCanViewDetailKendalaDone: true,
    },

    dashboardKondisiSku: {
        isCanViewDashboardKondisiSku: true,
        isCanGantiPlant: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
        isCanEditPlanningNextWeek: false,
        isCanAction: false,
        isCanPendingBB: false,
        isCanPendingPackaging: false,
        isCanConfirmMaterial: true,
        isCanChecklistBrReady: false,
    },
};

export const viewProduksiObj = { ...viewNdcObj };
export const viewLogisticObj = { ...viewNdcObj };

export const viewDistplanObj = {
    mainDashboard: {
        isViewMainDashboard: true,
        isCanGantiPlant: true,
        isCanFilterZona: true,
        isCanFilterProccess: true,
        isCanViewDetailMainDashboard: true,
        isCanInputKendala: true,
        isCanViewDetailKendala: true,
        isCanDeleteKendala: true,
        isCanFilterInboundPending: true,
        isCanFilterZonaInbound: true,
        isCanFilterStreamlineInboundPending: true,
        isCanFilterJenisPending: true,
        isCanViewDetailPending: true,
        isCanFilterZonaQueue: true,
        isCanFilterStreamlineQueue: true,
        isCanFilterKesiapanBRQueue: true,
        isCanFilterZonaDone: true,
        isCanFilterKetepatanWaktuDone: true,
        isCanViewDetailDone: true,
        isCanViewDetailKendalaDone: true,
    },
    sku: {
        isCanViewSkuManagement: true,
        isCanGantiPlant: true,
        isCanAddManualItem: true,
        isCanImportDataFromExcel: true,
        isCanDownloadFormatExcel: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
    },
    streamLine: {
        isCanViewStreamlineManagement: true,
        isCanGantiPlant: false,
        isCanAddNewStreamline: false,
        isCanEditStreamline: false,
        isCanDeleteStreamline: false,
    },
    userManagement: {
        isCanViewUserManagement: false,
        isCanAddNewAccount: false,
        isCanFilteringRole: false,
        isCanEditAccount: false,
        isCanDeleteAccount: false,
    },
    dashboardKondisiSku: {
        isCanViewDashboardKondisiSku: true,
        isCanGantiPlant: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
        isCanEditPlanningNextWeek: true,
        isCanAction: true,
        isCanPendingBB: true,
        isCanPendingPackaging: true,
        isCanConfirmMaterial: true,
        isCanChecklistBrReady: true,
    },
    daftarZonaLineProduksi: {
        isCanViewDaftarZonaDanLineProduksi: true,
        isCanAddZona: false,
        isCanTimbangLine: false,
    },
};

export const viewSuperAdminObj = {
    mainDashboard: {
        isViewMainDashboard: true,
        isCanGantiPlant: true,
        isCanFilterZona: true,
        isCanFilterProccess: true,
        isCanViewDetailMainDashboard: true,
        isCanInputKendala: true,
        isCanViewDetailKendala: true,
        isCanDeleteKendala: true,
        isCanFilterInboundPending: true,
        isCanFilterZonaInbound: true,
        isCanFilterStreamlineInboundPending: true,
        isCanFilterJenisPending: true,
        isCanViewDetailPending: true,
        isCanFilterZonaQueue: true,
        isCanFilterStreamlineQueue: true,
        isCanFilterKesiapanBRQueue: true,
        isCanFilterZonaDone: true,
        isCanFilterKetepatanWaktuDone: true,
        isCanViewDetailDone: true,
        isCanViewDetailKendalaDone: true,
    },
    sku: {
        isCanViewSkuManagement: true,
        isCanGantiPlant: true,
        isCanAddManualItem: true,
        isCanImportDataFromExcel: true,
        isCanDownloadFormatExcel: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
    },
    streamLine: {
        isCanViewStreamlineManagement: true,
        isCanGantiPlant: true,
        isCanAddNewStreamline: true,
        isCanEditStreamline: true,
        isCanDeleteStreamline: true,
    },
    userManagement: {
        isCanViewUserManagement: true,
        isCanAddNewAccount: true,
        isCanFilteringRole: true,
        isCanEditAccount: true,
        isCanDeleteAccount: true,
    },
    dashboardKondisiSku: {
        isCanViewDashboardKondisiSku: true,
        isCanGantiPlant: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
        isCanEditPlanningNextWeek: true,
        isCanAction: true,
        isCanPendingBB: true,
        isCanPendingPackaging: true,
        isCanConfirmMaterial: true,
        isCanChecklistBrReady: true,
    },
    daftarZonaLineProduksi: {
        isCanViewDaftarZonaDanLineProduksi: true,
        isCanAddZona: true,
        isCanTimbangLine: true,
    },
};

export const viewPpcObj = {
    mainDashboard: {
        isViewMainDashboard: true,
        isCanGantiPlant: true,
        isCanFilterZona: true,
        isCanFilterProccess: true,
        isCanViewDetailMainDashboard: true,
        isCanInputKendala: true,
        isCanViewDetailKendala: true,
        isCanDeleteKendala: true,
        isCanFilterInboundPending: true,
        isCanFilterZonaInbound: true,
        isCanFilterStreamlineInboundPending: true,
        isCanFilterJenisPending: true,
        isCanViewDetailPending: true,
        isCanFilterZonaQueue: true,
        isCanFilterStreamlineQueue: true,
        isCanFilterKesiapanBRQueue: true,
        isCanFilterZonaDone: true,
        isCanFilterKetepatanWaktuDone: true,
        isCanViewDetailDone: true,
        isCanViewDetailKendalaDone: true,
    },
    sku: {
        isCanViewSkuManagement: true,
        isCanGantiPlant: true,
        isCanAddManualItem: true,
        isCanImportDataFromExcel: true,
        isCanDownloadFormatExcel: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
    },
    streamLine: {
        isCanViewStreamlineManagement: true,
        isCanGantiPlant: true,
        isCanAddNewStreamline: true,
        isCanEditStreamline: true,
        isCanDeleteStreamline: true,
    },
    userManagement: {
        isCanViewUserManagement: false,
        isCanAddNewAccount: false,
        isCanFilteringRole: false,
        isCanEditAccount: false,
        isCanDeleteAccount: false,
    },
    dashboardKondisiSku: {
        isCanViewDashboardKondisiSku: true,
        isCanGantiPlant: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
        isCanEditPlanningNextWeek: true,
        isCanAction: true,
        isCanPendingBB: true,
        isCanPendingPackaging: true,
        isCanConfirmMaterial: true,
        isCanChecklistBrReady: true,
    },
    daftarZonaLineProduksi: {
        isCanViewDaftarZonaDanLineProduksi: true,
        isCanAddZona: false,
        isCanTimbangLine: false,
    },
};

export const viewBedaPpcObj = {
    mainDashboard: {
        isViewMainDashboard: true,
        isCanGantiPlant: true,
        isCanFilterZona: true,
        isCanFilterProccess: true,
        isCanViewDetailMainDashboard: true,
        isCanInputKendala: false,
        isCanViewDetailKendala: true,
        isCanDeleteKendala: false,
        isCanFilterInboundPending: true,
        isCanFilterZonaInbound: true,
        isCanFilterStreamlineInboundPending: true,
        isCanFilterJenisPending: true,
        isCanViewDetailPending: true,
        isCanFilterZonaQueue: true,
        isCanFilterStreamlineQueue: true,
        isCanFilterKesiapanBRQueue: true,
        isCanFilterZonaDone: true,
        isCanFilterKetepatanWaktuDone: true,
        isCanViewDetailDone: true,
        isCanViewDetailKendalaDone: true,
    },

    dashboardKondisiSku: {
        isCanViewDashboardKondisiSku: true,
        isCanGantiPlant: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
        isCanEditPlanningNextWeek: false,
        isCanAction: false,
        isCanPendingBB: false,
        isCanPendingPackaging: false,
        isCanConfirmMaterial: false,
        isCanChecklistBrReady: false,
    },
    streamLine: {
        isCanViewStreamlineManagement: true,
    },
};

export const viewBedaProduksiObj = {
    mainDashboard: {
        isViewMainDashboard: true,
        isCanGantiPlant: true,
        isCanFilterZona: true,
        isCanFilterProccess: true,
        isCanViewDetailMainDashboard: true,
        isCanInputKendala: false,
        isCanViewDetailKendala: true,
        isCanDeleteKendala: false,
        isCanFilterInboundPending: true,
        isCanFilterZonaInbound: true,
        isCanFilterStreamlineInboundPending: true,
        isCanFilterJenisPending: true,
        isCanViewDetailPending: true,
        isCanFilterZonaQueue: true,
        isCanFilterStreamlineQueue: true,
        isCanFilterKesiapanBRQueue: true,
        isCanFilterZonaDone: true,
        isCanFilterKetepatanWaktuDone: true,
        isCanViewDetailDone: true,
        isCanViewDetailKendalaDone: true,
    },

    dashboardKondisiSku: {
        isCanViewDashboardKondisiSku: true,
        isCanGantiPlant: true,
        isCanFilteringZona: true,
        isCanFilteringStreamline: true,
        isCanEditPlanningNextWeek: false,
        isCanAction: false,
        isCanPendingBB: false,
        isCanPendingPackaging: false,
        isCanConfirmMaterial: false,
        isCanChecklistBrReady: false,
    },
};

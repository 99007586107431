import { useState, createContext } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import SectionHead from '../sectionOrderConfirmation/sections/sectionHead';
import SectionTable from '../sectionOrderConfirmation/sections/sectionTable';
import SectionWrapperButton from '../sectionOrderConfirmation/sections/sectionButton';
import TableSkeleton from '@components/tableSkeleton';
import EmptyState from '@components/emptyState';
import Modalsuccess from '@components/modalSuccess';
import ModalConfirm from '@components/modalConfirm';
import { tableListOrderConfirmation } from './const';
import { numToArr } from '@helpers/functions';
import './styles.scss';

export const TableOrderConfirmationContext = createContext();

const SectionOrderConfirmation = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({ trade_name: '', itemCode: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate();

    // console.log('innerWidth :', window.innerWidth);
    return (
        <TableOrderConfirmationContext.Provider value={{ filter, setFilter }}>
            <Box className="section-table-order-confirmation__configuration">
                <SectionHead />
                {!isLoading && (
                    <TableSkeleton
                        tableList={tableListOrderConfirmation}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                )}
                {isLoading && <SectionTable items={items} />}
                {!isLoading && !items.length && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}
                <SectionWrapperButton setIsOpen={setIsOpen} navigate={navigate} />
                {/*---------MODAL-----------*/}
                <ModalConfirm
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onSubmit={() => setIsSubmit(true)}
                    title="Apakah anda yakin?"
                    submitLabel="Lanjut"
                />
                {isSubmit && <Modalsuccess isOpen={isOpen} onClose={() => setIsOpen(false)} title="Submit success" />}
            </Box>
        </TableOrderConfirmationContext.Provider>
    );
};

export default SectionOrderConfirmation;

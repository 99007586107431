import React from 'react';
import { Box } from '@chakra-ui/react';
import './styles.scss';

function index({ data }) {
    return (
        <Box className="container-error-detail-upload-supplier-stock">
            <Box>Periksa kembali file mu, dan jaringan internet, untuk melanjutkan upload file</Box>
            <Box style={{ textAlign: 'left', width: '100%' }}>
                <strong>Detail : </strong>
            </Box>
            {data?.type === 'catch' && (
                <Box role="ul" className="error-detail">
                    {data.message?.code === '500' ? (
                        <li style={{ textAlign: 'left' }}>{data.message?.message}</li>
                    ) : (
                        data.message?.errors?.map((item, index) => (
                            <li key={index} style={{ textAlign: 'left' }}>
                                {item}
                            </li>
                        ))
                    )}
                </Box>
            )}
            {data?.type === 'then' && (
                <Box role="ul" className="error-detail">
                    {data?.message?.map((item, index) => (
                        <li key={index} style={{ textAlign: 'left' }}>
                            {item?.message}
                        </li>
                    ))}
                </Box>
            )}
        </Box>
    );
}

export default index;

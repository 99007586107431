import React from 'react';
import { Table as TableChakra, Thead, Tbody, Tr, Th, Td, Box, Input, InputGroup, Tooltip } from '@chakra-ui/react';
import { ArrowRightIcon } from '@asset/icons';
import { EditNoteIcon } from '../../icons';

import './styles.scss';

function Table({ head = [], body = [] }) {
    return (
        <Box className="table-demand-anticipation-additional-upload">
            <TableChakra className="table-container-archived">
                <Thead>
                    <Tr className="container-head">
                        {head?.map((tHead, index) => {
                            const isLetterW = tHead?.name;
                            const isCenter =
                                Boolean(tHead?.name?.toLowerCase()?.includes('status')) || isLetterW?.[0] === 'W';
                            const isNoteColumn = Boolean(tHead?.name?.toLowerCase()?.includes('notes'));
                            const cellWidth = tHead?.widthCell > 44 ? tHead?.widthCell - 15 : tHead?.widthCell;
                            return (
                                <Th
                                    key={index}
                                    className="title-head"
                                    sx={{
                                        textAlign: isCenter ? 'center' : 'left',
                                        minWidth: isNoteColumn ? '250px' : `${cellWidth * 10}px`,
                                        paddingLeft: index === 0 ? '10px !important' : '6px !important',
                                    }}
                                >
                                    {tHead.name}
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody className="table-body-container">
                    {body.map((tBody, bodyIndex) => (
                        <Tr className="table-tr" key={bodyIndex}>
                            {head.map((tHead, headIndex) => {
                                const isDropDown = Boolean(tHead?.name?.toLowerCase()?.includes('status'));
                                const isWeek = Boolean(tHead?.name?.toLowerCase()?.includes('week'));
                                const isNoteColumn = Boolean(tHead?.name?.toLowerCase()?.includes('notes'));
                                const isErrorHighLight = Boolean(tBody[tHead.id]?.isError);
                                const messageError = tBody[tHead.id]?.messageError || '';
                                if (isDropDown) {
                                    return (
                                        <Td
                                            key={headIndex}
                                            className="container-drop-down"
                                            sx={{
                                                textAlign: isDropDown ? 'center' : 'left',
                                            }}
                                        >
                                            <Tooltip
                                                hasArrow
                                                bg="red.500"
                                                label={Boolean(messageError) ? messageError : ''}
                                            >
                                                <Box
                                                    sx={{ border: isErrorHighLight ? 'red solid 1px !important' : '' }}
                                                    className={`disabled-select`}
                                                >
                                                    {/* /// https://stackoverflow.com/a/52428922 */}
                                                    {tBody[tHead.id]?.value || '-'}
                                                    <span style={{ transform: 'rotate(-90deg)' }}>
                                                        <ArrowRightIcon />
                                                    </span>
                                                </Box>
                                            </Tooltip>
                                        </Td>
                                    );
                                }

                                return (
                                    <Td
                                        className="table-td"
                                        sx={{
                                            marginRight: '20px !important',
                                            paddingLeft: headIndex === 0 ? '4px !important' : '0px !important',
                                        }}
                                        key={headIndex}
                                    >
                                        <InputGroup>
                                            <Tooltip
                                                hasArrow
                                                bg="red.500"
                                                label={Boolean(messageError) ? messageError : ''}
                                            >
                                                <Input
                                                    readOnly
                                                    sx={{
                                                        padding: `10px ${isNoteColumn ? '56px' : '6px'} 10px 6px`,
                                                        // width: `${cellWidth * 10 + 40}px`,
                                                        mx: '1px !important',
                                                        textAlign: isWeek ? 'center' : 'left',
                                                        width:
                                                            tHead.id?.toString()?.toLowerCase() === 'trade name'
                                                                ? '300px'
                                                                : '',
                                                    }}
                                                    className={`input-value ${
                                                        isErrorHighLight && !isNoteColumn && 'empty-input'
                                                    }`}
                                                    /// https://stackoverflow.com/a/52428922
                                                    defaultValue={tBody[tHead.id]?.value}
                                                    placeholder={tHead?.id === 'Notes' && 'Isi notes......'}
                                                />
                                            </Tooltip>
                                            {isNoteColumn && (
                                                <Box className="icon">
                                                    <EditNoteIcon />
                                                </Box>
                                            )}
                                        </InputGroup>
                                    </Td>
                                );
                            })}
                        </Tr>
                    ))}
                </Tbody>
            </TableChakra>
        </Box>
    );
}

export default Table;

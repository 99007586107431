import Cookies from 'universal-cookie';
import Axios from '@services/axios';
import { Box } from '@chakra-ui/react';
import { formDefaultValue } from './const';
import { CloseIcon } from '@pages/mainDashboard/components/detailProductStatus/icons';

export const initialFormState = {
    raw_material_status: false,
    raw_material_note: false,
    raw_packaging_status: false,
    raw_packaging_note: false,
};

export function reducer(state, action) {
    switch (action.type) {
        case 'raw_material_status': {
            action.setIsError((prev) => ({ ...prev, raw_material_status: false }));
            return {
                label_value: { ...state.label_value, status_bahan_baku: action.label },
                data_send_to_be: { ...state.data_send_to_be, raw_material_status: action.value },
            };
        }
        case 'raw_packaging_status': {
            action.setIsError((prev) => ({ ...prev, raw_packaging_status: false }));
            return {
                label_value: { ...state.label_value, status_packaging: action.label },
                data_send_to_be: { ...state.data_send_to_be, raw_packaging_status: action.value },
            };
        }
        case 'raw_material_note': {
            action.setIsError((prev) => ({ ...prev, raw_material_note: false }));
            return {
                label_value: { ...state.label_value },
                data_send_to_be: { ...state.data_send_to_be, raw_material_note: action.value },
            };
        }
        case 'raw_packaging_note': {
            action.setIsError((prev) => ({ ...prev, raw_packaging_note: false }));
            return {
                label_value: { ...state.label_value },
                data_send_to_be: { ...state.data_send_to_be, raw_packaging_note: action.value },
            };
        }
        case 'reset': {
            action?.setIsError(initialFormState);
            return {
                ...formDefaultValue,
            };
        }
    }
}

export const onSubmitForm = async ({ data, selectedOrderPlanning, handleCloseModal, setLoading, toast, fetchData }) => {
    setLoading(true);
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        const { raw_material_status, raw_packaging_status, raw_material_note, raw_packaging_note } = data;
        await Axios({
            method: 'PATCH',
            url: `/order-planning/${selectedOrderPlanning?.kardOrders[0]?.id}?order_planning_id=${selectedOrderPlanning?.kardOrders[0]?.order_planning_id}&updateKetersediaan=true&`,
            headers: { Authorization: 'Bearer' + userToken },
            data: {
                status_bb: raw_material_status === 'Ready',
                status_packaging: raw_packaging_status === 'Ready',
                notes_status_bb: raw_material_note,
                notes_status_packaging: raw_packaging_note,
            },
        }).then((res) => {
            toast({
                render: (event) => (
                    <Box
                        color="white"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                        bg="green.500"
                    >
                        {/* {res?.data?.data?.mesage} */}
                        Berhasil disimpan
                        <Box onClick={() => event.onClose()} className="btn-close-toast">
                            <CloseIcon size="23" fill="white" />
                        </Box>
                    </Box>
                ),

                duration: 5000,
                position: 'top-center',
            });
        });

        handleCloseModal();
        setLoading(false);
        fetchData();
    } catch (err) {
        toast({
            title: err?.name,
            description: err?.response?.data?.errors?.[0]?.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-center',
        });
        setLoading(false);
        console.log(err);
    }
};

export const isFormHasEmptyValue = ({
    setIsError,
    initialValue,
    data,
    selectedOrderPlanning,
    handleCloseModal,
    setLoading,
    toast,
    fetchData,
}) => {
    const pattern = /[^ ]/g;
    if (initialValue.data_send_to_be.raw_material_status === '') {
        setIsError((prev) => ({ ...prev, raw_material_status: true }));
    }
    if (!Boolean(initialValue.data_send_to_be.raw_material_note.match(pattern))) {
        setIsError((prev) => ({ ...prev, raw_material_note: true }));
    }
    if (initialValue.data_send_to_be.raw_packaging_status === '') {
        setIsError((prev) => ({ ...prev, raw_packaging_status: true }));
    }
    if (!Boolean(initialValue.data_send_to_be.raw_packaging_note.match(pattern))) {
        setIsError((prev) => ({ ...prev, raw_packaging_note: true }));
    } else if (
        Boolean(initialValue.data_send_to_be.raw_material_note.match(pattern)) &&
        initialValue.data_send_to_be.raw_packaging_status != '' &&
        Boolean(initialValue.data_send_to_be.raw_packaging_note.match(pattern)) &&
        initialValue.data_send_to_be.raw_material_status != ''
    ) {
        onSubmitForm({ data, fetchData, selectedOrderPlanning, handleCloseModal, setLoading, toast });
    }
};

import { Box, Text, Flex, Button, Skeleton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ArrowLeftIconBlack, AvatarIcon } from '@asset/icons';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from './function';
import Cookies from 'universal-cookie';
import { height } from './const';

import './styles.scss';

function Index() {
    const cookie = new Cookies();
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(true);
    const [data, setData] = useState({});
    useEffect(() => {
        getUserProfile({ setData, setIsloading });
    }, []);
    return (
        <Box height={height} className="container-user-management">
            <Flex justifyContent="space-between" alignItems="center">
                <Flex gap="14px">
                    <Button onClick={() => navigate('/lobby')} variant="ghost" className="btn-back-to-lobby">
                        <ArrowLeftIconBlack />
                    </Button>
                    <Text className="btn-text-back">User Management</Text>
                </Flex>
                <Flex gap="8px" alignItems="center">
                    <AvatarIcon />
                    {isLoading ? (
                        <Skeleton className="loading-profile-skeleton" />
                    ) : (
                        <Text className="avatar-title-name">{data?.name || '-'}</Text>
                    )}

                    {isLoading ? (
                        <Skeleton className="loading-profile-skeleton" />
                    ) : (
                        <Text className="status-role-badge">{cookie.get('role') || '-'}</Text>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
}

export default Index;

import { useToast, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { onRemove } from './functions';
import { skuConfigurationContext } from '@pages/skuConfiguration';
import ModalDelete from '@components/modalDelete';

const DeleteModal = ({ isOpen, reFecthSkuHandler }) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const { selectedSku, setIsShowDelete } = useContext(skuConfigurationContext);

    const onClose = () => setIsShowDelete(false);

    return (
        <ModalDelete
            isOpen={isOpen}
            onClose={onClose}
            title="Delete Streamline"
            isLoading={isLoading}
            onSubmit={() =>
                onRemove({
                    setIsLoading,
                    selectedSku,
                    onClose,
                    reFecthSkuHandler,
                    toast,
                })
            }
            descriptionComponent={
                <Text className="description-text">
                    Apakah anda yakin ingin menghapus SKU <span>{selectedSku?.nama_item}</span>?
                </Text>
            }
        />
    );
};

export default DeleteModal;

import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { getActualDurationHandler, formattedDateString, getLastActualDuration, formatNameActual } from './functions';
import Timecode from 'react-timecode';
import { Skeleton } from '@chakra-ui/react';
import Timer from 'react-timer-wrapper';
import { RedWaningIcon } from '../icon';
import { timeConverter } from '../detailProductStatus/function';
import { getBgColorHandler } from '@pages/mainDashboard/functions';
import { fetchDetailCard } from './functions';
import './styles.scss';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

const ProductStatusCard = ({ value, onClick, type }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { kendala, kardHistory, kardFg } = data;
    const isErr = kendala?.length > 0;
    const streamlineName = kardFg?.sku?.streamline?.name;

    useEffect(() => {
        if (value?.kard?.id) {
            fetchDetailCard({ id: value?.kard?.id, type, setData, setIsLoading });
        }
    }, [value?.id]);

    return (
        <>
            <Box className="weighing-card__wrapper__main-dashboard" onClick={onClick}>
                <Box className="weighing-card__container__main-dashboard" onClick={onClick}>
                    {/** -----------------------------HEAD ------------------------------------- */}
                    <Flex
                        className="weighing-card__head-container"
                        bg={isErr ? `rgba(239, 83, 80, 0.9)` : `${getBgColorHandler({ valueColor: streamlineName })}`}
                    >
                        {isLoading ? (
                            <>
                                <Skeleton h="16px" w="30%" />
                                <Skeleton h="16px" w="20%" />
                            </>
                        ) : (
                            <>
                                <Text className="head-title__container" color={isErr ? 'white' : '#2D2D2D'}>
                                    {`${formatNameActual({ type, kardHistory })}`}
                                    {isErr && <RedWaningIcon />}
                                </Text>
                                <Text className="production-status__line-name" color={isErr ? 'white' : '#2D2D2D'}>
                                    {value?.name}
                                </Text>
                            </>
                        )}
                    </Flex>
                    {/** ------------------------------ BODY --------------------------------- */}
                    <Box className="weighing-card__body-container">
                        <Box p="6px">
                            {isLoading ? (
                                <>
                                    <Skeleton h="14px" w="100%" mb="2px" />
                                    <Skeleton h="14px" w="60%" mb="4px" />
                                    <Flex justify="space-between" alignItems="center">
                                        <Skeleton h="10px" w="30%" />
                                        <Skeleton h="10px" w="20%" />
                                    </Flex>
                                </>
                            ) : (
                                <>
                                    <Text className="product-name__text">{kardFg?.sku?.nama_item}</Text>
                                    <Flex justifyContent={'space-between'}>
                                        <Text className="code-name__text" mb="0 !important">
                                            SNFG: {kardFg?.snfg}
                                        </Text>
                                        <Text className="code-name__text">{kardFg?.sku?.odoo_code}</Text>
                                    </Flex>
                                </>
                            )}
                        </Box>

                        <Divider mb="0" />
                        <Flex p="4px 8px 6px" justify="space-between" h="24px">
                            {isLoading ? (
                                <Flex justifyContent="space-between" alignItems="center" w="100%" h="32px">
                                    <Skeleton h="12px" w="30%" />
                                    <Skeleton h="12px" w="30%" />
                                </Flex>
                            ) : (
                                <>
                                    {type !== 'ndc' && (
                                        <Flex>
                                            <Text className="expectation-label">Exp. Duration</Text>
                                            {getLastActualDuration({ kardHistory, type })?.expected_duration ? (
                                                <Text className="expectation-text" color="#4163A4">
                                                    {timeConverter(
                                                        getLastActualDuration({ kardHistory, type })
                                                            ?.expected_duration * 3600
                                                    )}
                                                </Text>
                                            ) : (
                                                <Text className="expectation-text" color="#4163A4">
                                                    -
                                                </Text>
                                            )}
                                        </Flex>
                                    )}
                                    <Flex>
                                        <Text className="expectation-label">Act. Duration</Text>

                                        {kardHistory?.length > 0 &&
                                        kardHistory[0] &&
                                        (!getLastActualDuration({ kardHistory, type }).actual_duration ||
                                            getLastActualDuration({ kardHistory, type }).actual_duration ===
                                                '00:00:00') ? (
                                            <Timer
                                                active
                                                duration={null}
                                                time={getActualDurationHandler({
                                                    kard: getLastActualDuration({ kardHistory, type }),
                                                })}
                                            >
                                                <Timecode
                                                    format="HH:mm:ss"
                                                    component={({ children }) => (
                                                        <Text className="expectation-text" color="#80B44D">
                                                            {formattedDateString(children[0])}
                                                        </Text>
                                                    )}
                                                />
                                            </Timer>
                                        ) : (
                                            <Text className="expectation-text">-</Text>
                                        )}
                                    </Flex>
                                </>
                            )}
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ProductStatusCard;

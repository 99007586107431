import { Box, Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import { getColorHandler, numberToFixedMC } from './function';
import { Attention, EditIcon, RemoveIcon, TrashIcon } from './icons';
import { isAllowed } from '@helpers/roleAccess';

// cek function formatTableMC
export const tableList = [
    {
        name: '!',
        value: 'is_warning',
        width: 10,
        children: ['-'],
        component: ({ value, onClickWarning, material }) => (
            <Flex
                onClick={() => {
                    if (!Boolean(material?.is_draft) && !Boolean(material?.is_warning)) return;
                    onClickWarning({ value: material });
                }}
                cursor={`${material?.is_warning ? 'pointer' : 'default'}`}
                flexDirection="column"
            >
                {(isAllowed({ pageName: 'Material Config', permissionName: 'Edit Material Config' }) ||
                    material?.is_draft) && (
                    <Attention
                        color={getColorHandler({
                            isDraft: material?.is_draft,
                            isWarn: material?.is_warning,
                        })}
                    />
                )}
            </Flex>
        ),
    },
    {
        name: 'kode item',
        value: 'kode_item',
        sortValue: 'kode_item',
        width: 141,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'trade name',
        width: 288,
        value: 'trade_name',
        sortValue: 'trade_name',
        children: ['-'],
        component: ({ value }) => (
            <Box width={'270px'}>
                <Tooltip
                    label={value}
                    fontStyle={'normal'}
                    fontWeight={'400'}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    textAlign={'left'}
                    color="white"
                >
                    <Text className="column-text-ellipsis">{value ? value : '-'}</Text>
                </Tooltip>
            </Box>
        ),
    },
    {
        name: 'internal name',
        value: 'internal_name',
        width: 247,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'jenis material',
        width: 150,
        value: 'supplier',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value?.jenisMaterial?.name}</Text>,
    },
    {
        name: 'UOM',
        width: 70,
        value: 'uom',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value ? value : '-'}</Text>,
    },
    {
        name: 'supplier',
        width: 180,
        value: 'supplier',
        sortValue: 'supplier',
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['name'].map((suppName) => (
                    <Text className="column-text" key={suppName.id}>
                        {value[suppName] ? value[suppName] : '-'}
                    </Text>
                ))}
            </Box>
        ),
    },
    {
        name: 'kode supplier',
        width: 113,
        value: 'supplier_ref',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'kategori supplier',
        value: 'supplier',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value?.supplierCategory?.category}</Text>,
    },
    {
        name: 'transition',
        value: 'transition_code',
        sortValue: 'transition_code',
        width: 172,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'status',
        value: 'transition_status',
        sortValue: 'status',
        width: 105,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value ? value : '-'}</Text>,
    },
    {
        name: 'main component',
        value: 'main_component',
        width: 133,
        children: ['-'],
        component: ({ value }) => (
            <Flex flexDirection="column">
                <Checkbox className="checkbox-disable" />
            </Flex>
        ),
    },
    {
        name: 'set item',
        value: 'set_item',
        width: 125,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value ? value : '-'}</Text>,
    },
    {
        name: 'MTO/MTS',
        value: 'is_mto',
        width: 90,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value ? 'MTO' : 'MTS'}</Text>,
    },
    {
        name: 'close PO by',
        value: 'close_po_by',
        width: 106,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'satuan order',
        value: 'satuan_order',
        width: 103,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'jenis kemasan',
        value: 'materialRaci',
        width: 111,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['jenis_kemasan'].map((jenisItem) => {
                    return (
                        <Text className="column-text" key={jenisItem.id}>
                            {value[jenisItem]}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Pcs/Koli atau Kg/Kemasan',
        value: 'materialRaci',
        width: 178,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['qty_per_kemasan'].map((itemPcs) => {
                    return (
                        <Text className="column-text" key={itemPcs.id}>
                            {numberToFixedMC(value[itemPcs])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Koli/Pallet atau Kemasan/Pallet',
        value: 'materialRaci',
        width: 206,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['kemasan_per_storage'].map((itemKoli) => {
                    return (
                        <Text className="column-text" key={itemKoli.id}>
                            {numberToFixedMC(value[itemKoli])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Pcs/Pallet atau Kg/Pallet',
        value: 'materialRaci',
        width: 178,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['qty_per_storage'].map((itemPallet) => {
                    return (
                        <Text className="column-text" key={itemPallet.id}>
                            {numberToFixedMC(value[itemPallet])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'kanban',
        value: 'kanban',
        width: 67,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'Baseline/Week (Pcs atau Kg)',
        value: 'baseline_pcs',
        width: 190,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'Baseline/Week (koli atau kemasan)',
        value: 'baseline_koli',
        width: 240,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'Baseline/Week (Pallet)',
        value: 'baseline_pallet',
        width: 190,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'avg baseline/day',
        value: 'avg_baseline_pcs',
        width: 131,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'Stdev',
        value: 'stdev',
        width: 71,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'safety by demand',
        value: 'materialCriticalPoint',
        width: 142,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['safety_demand_pcs'].map((dataItem) => {
                    return (
                        <Text className="column-text" key={dataItem.id}>
                            {numberToFixedMC(value[dataItem])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Safety By Demand (hari)',
        value: 'materialCriticalPoint',
        width: 170,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['safety_demand_hari'].map((itemData) => {
                    return (
                        <Text className="column-text" key={itemData.id}>
                            {numberToFixedMC(value[itemData])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'safety by vulnerability',
        value: 'safety_vulnerability',
        width: 160,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'safety by deviasi LT',
        value: 'safety_leadtime',
        width: 160,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'frekuensi delivery(hari)',
        value: 'delivery_freq',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'lead time produksi(hari)',
        value: 'production_leadtime',
        sortValue: 'production_leadtime',
        width: 140,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'lead time delivery (hari)',
        value: 'delivery_leadtime',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MPQ PTI',
        value: 'mpq_pti',
        width: 82,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MPQ PTI roundup',
        value: 'materialCriticalPoint',
        width: 85,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['mpq_pti_roundup'].map((mpqItem) => {
                    return (
                        <Text className="column-text" key={mpqItem.id}>
                            {numberToFixedMC(value[mpqItem])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'MPQ supplier',
        value: 'mpq_supplier',
        width: 100,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MPQ supplier roundup',
        value: 'materialCriticalPoint',
        width: 108,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['mpq_supplier_roundup'].map((suppItem) => {
                    return (
                        <Text className="column-text" key={suppItem.id}>
                            {numberToFixedMC(value[suppItem])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'MDQ supplier',
        value: 'mdq_supplier',
        width: 100,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'indeks MPQ vs baseline',
        value: 'materialCriticalPoint',
        sortValue: 'indeks_mpq',
        width: 120,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['mpq_v_baseline'].map((itemMpq) => {
                    return (
                        <Text className="column-text" key={itemMpq.id}>
                            {numberToFixedMC(value[itemMpq])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'indeks MPQ vs MPQ PTI',
        value: 'materialCriticalPoint',
        sortValue: 'indeks_mpq_pti',
        width: 120,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['mpq_supp_v_mpq_pti'].map((itemSupp) => {
                    return (
                        <Text className="column-text" key={itemSupp.id}>
                            {numberToFixedMC(value[itemSupp])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'ROP OSPO',
        value: 'rop_ospo',
        width: 89,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'ROP PTI (Pcs)',
        value: 'stock_pti_rop',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MAX Stock PTI (Pcs atau Kg)',
        value: 'stock_pti_max',
        width: 190,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MAX Stock PTI (Koli atau Kemasan)',
        value: 'stock_pti_max_koli',
        width: 240,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MAX stock PTI (pallet)',
        value: 'stock_pti_max_storage',
        width: 160,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'indeks max vs baseline',
        value: 'max_stock_v_baseline',
        sortValue: 'max_stock_v_baseline',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'ROP supplier',
        value: 'stock_supp_rop',
        width: 142,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MAX Stock Supplier (Pcs atau Kg)',
        value: 'stock_supp_max',
        width: 230,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MAX Stock Supplier (Koli atau Kemasan)',
        value: 'stock_supp_max_koli',
        width: 270,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    {
        name: 'MAX stock supplier (Pallet)',
        value: 'stock_supp_max_storage',
        width: 200,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{numberToFixedMC(value)}</Text>,
    },
    // {
    //     name: 'Action',
    //     width: 87,
    //     value: null,
    //     children: ['-'],
    //     component: ({ material, onEditHandler, onOpenModal, onModalDelete, onSelectedValue, onDeleteMaterial }) => (
    //         <>
    //             {isAllowed({ pageName: 'Material Config', permissionName: 'Edit Material Config' }) ? (
    //                 <Flex>
    //                     <Box
    //                         h="max-content"
    //                         cursor="pointer"
    //                         rounded={'4px'}
    //                         border={'1px solid'}
    //                         borderColor={'#1746A2'}
    //                         onClick={() => {
    //                             onEditHandler();
    //                         }}
    //                         p="6px"
    //                         mr="12px"
    //                     >
    //                         <EditIcon />
    //                     </Box>

    //                     <Box
    //                         h="max-content"
    //                         cursor="pointer"
    //                         rounded={'4px'}
    //                         border={'1px solid'}
    //                         borderColor={'#EF5350'}
    //                         onClick={() => {
    //                             if (dummyData.is_delete) {
    //                                 onDeleteMaterial();
    //                                 // onModalDelete({ value: true });
    //                                 // onSelectedValue({ value: material.trade_name });
    //                             } else {
    //                                 onOpenModal({ value: true });
    //                             }
    //                         }}
    //                         p="6px"
    //                     >
    //                         {dummyData.is_delete ? <TrashIcon /> : <RemoveIcon />}
    //                     </Box>
    //                 </Flex>
    //             ) : (
    //                 <Text className="column-text">-</Text>
    //             )}
    //         </>
    //     ),
    // },
];

const setRandomStatusTable = () => {
    const names = ['Flow Through', 'Immediately', 'Interchangeable'];
    const randomIndex = Math.floor(Math.random() * names.length);
    return names[randomIndex];
};

export const initialValueFormTransition = {
    old_item_code: '',
    old_trade_name: '',
    new_item_code: '',
    new_trade_name: '',
    status: '',
};

export const dummyData = {
    is_warning: 'Warning',
    kode_item: '22134432e',
    trade_name: 'Plug Crystallure SR Rich Cream 50 g Wardah...',
    internal_name: 'Godet Two Way Cake 4,4 Cm',
    jenis_material: 'Packaging Material',
    catergory_supplier: 'Local',
    uom: 'Pcs',
    supplier: 'Metalipark Kreasindo, PT',
    kode_supplier: 2200054,
    transition: 'XXXGODE-TWC5,4B-SM-P',
    status: setRandomStatusTable(),
    set_item: 'Set item A',
    mto_mts: 'MTO',
    close_po: 'Quantity',
    satuan_order: 'kemasan',
    jenis_kemasan: 'RB1',
    pcs_koli: 999.999,
    koli_pallet: 999.999,
    pcs_pallet: 999.999,
    kanban: 999.999,
    baseline_week_pcs: 999.999,
    baseline_week_koli: 999.999,
    baseline_week_pallet: 999.999,
    avg_day: 999.999,
    stdev: 999.999,
    safety_demand: 999.999,
    safety_demand_hari: 10,
    safety_vulnerability: 999.999,
    safety_deviasi: 999.999,
    frekuensi_delivery: 13,
    leadtime_produksi: 4,
    leadtime_delivery: 2,
    mpq_pti: 999.999,
    mpq_roundup: 999.999,
    mpq_supplier: 999.999,
    mpq_supplier_roundup: 999.999,
    mdq_supplier: 999.999,
    indeks_mpq: 999.999,
    indeks_mpq_pti: 999.999,
    rop_ospo: 999.999,
    rop_pti_pcs: 999.999,
    mx_stock_pti_pcs: 999.999,
    mx_stock_pti_koli: 999.999,
    mx_stock_pti_pallet: 999.999,
    index_max: 999.999,
    rop_supplier: 999.999,
    mx_stock_pcs: 999.999,
    mx_stock_koli: 999.999,
    mx_stock_pallet: 999.999,
    material_satu: '2,60',
    rop_rm1: 999.999,
    max_stock_rm1: 999.999,
    material_dua: '2,60',
    rop_rm2: 999.999,
    max_stock_rm2: 999.999,
    is_delete: Math.random() < 0.5,
};

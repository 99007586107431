import { Flex, Text, Button, Box, CircularProgress, useToast } from '@chakra-ui/react';
import { useContext, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { IconBack, UploadIconSm, IconFile } from './icons';
import { AutomaticWidthTable, handleDownloadTemplate, handleNext, handleUploadFile, onChangeFile } from './functions';
import { DownloadBlackIcon } from '@asset/icons';
import { useSelector } from 'react-redux';
import Table from './component/table/Table';
import CustomButton from '@components/button';
import ModalConfirm from '@components/modalConfirm';
import Modalsuccess from '@components/modalSuccess';
import ModalFormat from '@components/modalFormat';
import ModalInputWeek from './component/modalInputWeek';
import ModalFailedUpload from '@components/modalFailed';
import ErrorDetails from './component/errorDetails';

import './styles.scss';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const [previewData, setPreviewData] = useState([]);
    const [file, setFile] = useState({});
    const navigate = useNavigate();
    const [state, setState] = useState('upload');
    const [submitError, setSubmitError] = useState({ open: false, message: '', type: '' });
    const [disabledSaveItem, setDisabledSaveItem] = useState(false);
    const [loading, setLoading] = useState({ download: false, upload: false });
    const [onRange, setOnrange] = useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [openModalAfterSubmit, setOpenModalAfterSubmit] = useState({ isOpen: false, message: '' });
    const [openModalInputWeek, setOpenModalInputWeek] = useState({ isOpen: false, selected: {} });
    const [error, setError] = useState(false);
    const toast = useToast();
    const head = AutomaticWidthTable({ previewData });
    const onOpenModal = ({ value }) => setIsOpenConfirm(value);
    const onSubmitModal = () =>
        handleUploadFile({
            setIsOpenConfirm,
            file,
            previewData,
            setPreviewData,
            setLoading,
            setSubmitError,
            setOpenModalInputWeek,
            startWeek: `${openModalInputWeek?.selected?.week}-${openModalInputWeek?.selected?.name}`,
            setDisabledSaveItem,
            setOpenModalAfterSubmit,
        });
    const onCloseModal = ({ value }) => setError(value);
    const isData = Boolean(previewData?.length > 0);
    const { isHide } = useSelector((state) => state.sideMenuSlice);
    const handleCancel = () => {
        setPreviewData([]);
        setState('upload');
    };
    /// https://stackoverflow.com/a/53090848
    const timer = useRef(null);
    const handleUpload = ({ files }) => {
        setFile(files);
        setState('loading');
        timer.current = setTimeout(() => {
            onChangeFile({ setPreviewData, files, setState, setFile, setDisabledSaveItem, toast });
        }, 2000);
    };
    const handleCancelLoading = () => {
        clearTimeout(timer.current);
        setState('upload');
    };
    const handleNextAction = () => {
        navigate('/demand-anticipation');
        setState('upload');
        setOpenModalAfterSubmit({ isOpen: false, message: '' });
        setOpenModalInputWeek({ isOpen: false, selected: {} });
    };

    return (
        <Box className="section-upload__container__demand-anticipation">
            <Flex className="head">
                <Flex onClick={() => navigate('/demand-anticipation')} className="left-section">
                    <IconBack />
                    <Text className="title-label">Upload File</Text>
                </Flex>
                {!isData && !state?.includes('loading') && (
                    <Button
                        className="right-section"
                        isLoading={loading.download}
                        onClick={() => handleDownloadTemplate({ setLoading, toast })}
                    >
                        <Box>
                            <DownloadBlackIcon color="#1746A2" />
                        </Box>
                        <Text className="title-download">Download Template</Text>
                    </Button>
                )}
            </Flex>
            <Box>
                {state?.includes('loading') && (
                    <Box className="parent">
                        <Box className="child">
                            <CircularProgress size="70px" isIndeterminate color="#1746a2" />
                            <Text className="text-loading">Uploading File.....</Text>
                            <CustomButton onClick={handleCancelLoading} btnClassName="cancel-button" variant="outline">
                                Cancel
                            </CustomButton>
                        </Box>
                    </Box>
                )}
                {state?.includes('table-view') && (
                    <Box className="container-table-view">
                        <ModalInputWeek
                            setOpenModalInputWeek={setOpenModalInputWeek}
                            openModalInputWeek={openModalInputWeek}
                            handleNext={() =>
                                handleNext({
                                    disabled: !Boolean(openModalInputWeek?.selected?.week),
                                    setOpenModalInputWeek,
                                    onOpenModal,
                                })
                            }
                        />
                        <Text className="label-table">Priview File</Text>
                        <Box
                            className="container-table"
                            sx={{
                                maxWidth: isHide ? 'calc(100vw - 166px)' : 'calc(100vw - 356px)',
                            }}
                        >
                            <Table head={head} setPreviewData={setPreviewData} body={previewData} />
                        </Box>
                        <Flex className="btn-action-group">
                            <CustomButton onClick={handleCancel} btnClassName="cancel-button" variant="outline">
                                Cancel
                            </CustomButton>
                            <Button
                                isDisabled={disabledSaveItem}
                                className="save-button"
                                onClick={() => setOpenModalInputWeek((prev) => ({ ...prev, isOpen: true }))}
                            >
                                Save Item
                            </Button>
                        </Flex>
                    </Box>
                )}
                {state?.includes('upload') && (
                    <Box className="body">
                        <Flex className="section-body">
                            <Flex className="upload-box">
                                <Box className="parent-line-vertical">
                                    {Array(11)
                                        .fill(1)
                                        .map((item, index) => (
                                            <Box key={index} className="child-line-vertical" />
                                        ))}
                                </Box>
                                <Box className="parent-line-horizon">
                                    {Array(8)
                                        .fill(1)
                                        .map((item, index) => (
                                            <Box key={index} className="child-line-horizon" />
                                        ))}
                                </Box>
                                <FileUploader
                                    name="file"
                                    onDraggingStateChange={(dragging) => setOnrange(dragging)}
                                    onDrop={(files) => handleUpload({ files })}
                                    handleChange={(files) => handleUpload({ files })}
                                    classes="file-uploader__drag-and-drop-only"
                                    types={['xlsx', 'csv']}
                                    onTypeError={(err) => setError(true)}
                                    disabled
                                />
                                <Box opacity={!onRange ? 0 : 10} className="after-drop">
                                    <Text className="after-drop-title">" Letakan Disini "</Text>
                                </Box>
                                <Box opacity={onRange ? 0 : 10} className="before-drop">
                                    <IconFile />
                                    <Text className="upload-text">
                                        Drag and Drop atau pilih file Exel untuk di upload
                                    </Text>
                                    <Button className="upload-button">
                                        <FileUploader
                                            onDrop={(files) => handleUpload({ files })}
                                            handleChange={(files) => handleUpload({ files })}
                                            name="file"
                                            classes="file-uploader"
                                            types={['xlsx', 'csv']}
                                        />
                                        <UploadIconSm />
                                        Upload File
                                    </Button>
                                </Box>
                            </Flex>
                        </Flex>
                    </Box>
                )}
            </Box>

            {/* ========= MODALS ========= */}
            {error && (
                <ModalFormat
                    isOpen={error}
                    onClose={() => onCloseModal({ value: false })}
                    title="Format File salah"
                    description="Maaf, format file yang Anda masukkan tidak sesuai. 
                    Mohon pastikan menggunakan format yang benar."
                />
            )}

            <ModalConfirm
                isOpen={isOpenConfirm}
                onClose={() => onOpenModal({ value: false })}
                onSubmit={onSubmitModal}
                title="Apa anda yakin ingin mengupload File?"
                submitLabel="Lanjut"
                loading={loading.upload}
            />
            <ModalFailedUpload
                isOpen={submitError.open}
                onClose={() => {
                    setSubmitError((prev) => ({ ...prev, open: false, message: '', type: '' }));
                }}
                onRightBtnClick={() => setSubmitError((prev) => ({ ...prev, open: false, message: '', type: '' }))}
                onLeftBtnClick={() => {
                    setSubmitError({ open: false, message: '', type: '' });
                    setFile({});
                    setState('upload');
                }}
                title="Uplod file gagal"
                classNameDescription="description"
                description={<ErrorDetails data={submitError} />}
                leftLabelButton="Upload Ulang"
                rightLabelButton="Kembali"
            />
            <Modalsuccess
                isOpen={openModalAfterSubmit.isOpen}
                onClose={handleNextAction}
                nextAction={handleNextAction}
                title="File Berhasil Diupload"
                labelButton="Kembali"
                description={
                    Boolean(openModalAfterSubmit.message) ? (
                        <>
                            <Box sx={{ color: 'red', mb: '8px' }}>{openModalAfterSubmit.message}</Box>
                            <Box>
                                <CustomButton
                                    height="36px"
                                    onClick={() => {
                                        setOpenModalAfterSubmit({ isOpen: false, message: '' });
                                    }}
                                >
                                    Tutup
                                </CustomButton>
                            </Box>
                        </>
                    ) : null
                }
            />
        </Box>
    );
};

export default Index;

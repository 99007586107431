// const { Button } = require('@components/buttons');
import { Box, Button } from '@chakra-ui/react';
import baseColor from '@asset/baseColor';
import './styles.scss';

const SectionBottom = ({ onLoginHandler, isLoading }) => {
    return (
        <Box className="section-bottom__container__login-page">
            <Button
                className="button-login__login-page"
                isLoading={isLoading}
                onClick={onLoginHandler}
                colorScheme={baseColor.mainColor}
                variant="solid"
            >
                Login
            </Button>
        </Box>
    );
};

export default SectionBottom;

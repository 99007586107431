import { Box, Text, Flex } from '@chakra-ui/react';
import { downloadFromS3Handler } from '@helpers/functions';
import Axios from '@services/axios';
import * as ExcelJS from 'exceljs';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { tableList } from './const';
import { isAllowed } from '@helpers/roleAccess';
import { EditIcon, TrashIcon } from './icons';

export const fetchMaterialHandler = async ({
    filter,
    page,
    setListMaterial,
    setTotalData,
    setTotalPage,
    setIsLoading,
    searchQuery,
    selectedPerPage,
    multipleFilter,
    jenisMaterial,
    supplier,
    setTableListMC,
    sort,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    const { itemCode, trade_name } = filter;

    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            q: searchQuery,
            page,
            limit: selectedPerPage,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            ...multipleFilter,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
            sort: sort?.name && `${sort?.name},${sort?.type}`,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/config/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { listMaterial, totalData, totalPage } = data.data;
        const formatTable = formatTableMC({ listMaterial });
        setTableListMC(formatTable);
        setTotalPage(totalPage);
        setTotalData(totalData);
        setListMaterial(listMaterial);
        setIsLoading(false);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setListMaterial([]);
    } finally {
        setIsLoading(false);
    }
};

export const onRemoveMaterial = async ({
    setIsLoading,
    selectedItem,
    toast,
    reFetchMaterialHandler,
    togglerDeleteMaterialModal,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        setIsLoading(true);
        await Axios({
            method: 'DELETE',
            url: `/material/config/${selectedItem.id}?force=false`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        await reFetchMaterialHandler();
        await toast({
            position: 'center-top',
            description: 'Deleted material berhasil',
            status: 'success',
            duration: 1500,
            isClosable: true,
        });
    } catch (error) {
        const message = error?.response?.data?.errors?.length ? error?.response?.data?.errors[0]?.message : null;

        console.log(error);
        await toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        togglerDeleteMaterialModal();
        setIsLoading(false);
    }
};

export const sortSwitch = ({ previousState, currentState }) => {
    if (previousState.sort !== currentState.sortValue) {
        return { sort: currentState.sortValue, typeBol: previousState.typeBol };
    } else {
        return { sort: currentState.sortValue, typeBol: !previousState.typeBol };
    }
};

export const onEditTransition = ({ selectedData, setOpenModalTransition, setFormTransition }) => {
    if (Boolean(selectedData?.is_draft)) return;
    const isWarning = !Boolean(selectedData?.is_draft) && Boolean(selectedData?.is_warning);
    if (isWarning) {
        setFormTransition({
            old_item_code: selectedData?.kode_item || '-',
            old_trade_name: selectedData?.trade_name || '-',
            new_item_code: selectedData?.transition_code || '-',
            new_trade_name: selectedData?.transition_trade_name || '-',
            status: selectedData?.transition_status || '-',
            id_material: selectedData?.id,
        });
        setOpenModalTransition(true);
    }
};

export const onDownloadDataMaterialConfig = async ({ setLoadingState, toast, filter, jenisMaterial, supplier }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setLoadingState(true);
    const { itemCode, trade_name } = filter;
    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
        },
        { skipEmptyString: true }
    );
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/config/download?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'material_configuration';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        setLoadingState(false);
    } catch (error) {
        console.log(error);
        toast({
            position: 'top-center',
            description: 'Gagal Download Material Configuration',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        setLoadingState(false);
    }
};

export const getColorHandler = ({ isDraft, isWarn }) => {
    if ((Boolean(isDraft) && !Boolean(isWarn)) || (Boolean(isDraft) && Boolean(isWarn))) {
        return '#6AC1D4';
    }
    if (!Boolean(isDraft) && Boolean(isWarn)) {
        return '#FF3E3E';
    } else {
        return '#FFF';
    }
};

export const getColorStatusHandler = ({ name }) => {
    switch (name) {
        case 'Flow Through':
            return '#CCE2FF';
        case 'Immediately':
            return '#D9F2E3';
        case 'Interchangeable':
            return '#FDDDEC';
        default:
            return '#FFFF';
    }
};

export const getColorTextHandler = ({ name }) => {
    switch (name) {
        case 'Flow Through':
            return '#004199';
        case 'Immediately':
            return '#267346';
        case 'Interchangeable':
            return '#F22B89';
        default:
            return '#0000';
    }
};

const onDataFormattedExcel = ({ data = [] }) => {
    const newData = [];
    const headers = [];
    data.forEach((v) => {
        newData.push({
            'KODE ITEM': v.kode_item || '-',
            'TRADE NAME': v.trade_name || '-',
            'INTERNAL NAME': v.internal_name || '-',
            'JENIS MATERIAL': v.jenis_material || '-',
            UOM: v.uom || '-',
            SUPPLIER: v.supplier || '-',
            'KODE SUPPLIER': v.kode_supplier || '-',
            TRANSITION: v.transition || '-',
            STATUS: v.status || '-',
            'SET ITEM': v.set_item || '-',
            'MTO/MTS': v.mto_mts || '-',
            'CLOSE PO BY': v.close_po || '-',
            'SATUAN ORDER': v.satuan_order || '-',
            'JENIS KEMASAN': v.jenis_kemasan || '-',
            'PCS/KOLI ATAU KG/KEMASAN': v.pcs_koli || '-',
            'PCS/PALLETE ATAU KEMASAN/PALLETE': v.koli_pallet || '-',
            'PCS/PALLETE ATAU KG/PALLETE': v.pcs_pallet || '-',
            KANBAN: v.kanban || '-',
            'BASELINE/WEEK (PCS ATAU KG)': v.baseline_week_pcs || '-',
            'BASELINE/WEEK (KOLI ATAU KEMASAN)': v.baseline_week_koli || '-',
            'BASELINE/WEEK (PALLET)': v.baseline_week_pallet || '-',
            'AVG BASELINE/DAY': v.avg_day || '-',
            STDEV: v.stdev || '-',
            'SAFETY BY DEMAND': v.safety_demand || '-',
            'SAFETY BY DEMAND(HARI)': v.safety_demand_hari || '-',
            'SAFETY BY VULNERALBILITY': v.safety_vulnerability || '-',
            'SAFETY BY DEVIASI LT': v.safety_deviasi || '-',
            'FREKUENSI DELIVERY(HARI)': v.frekuensi_delivery || '-',
            'LEADTIME PRODUKSI(HARI)': v.leadtime_produksi || '-',
            'LEADTIME DELIVERY(HARI)': v.leadtime_delivery || '-',
            'MPQ PTI': v.mpq_pti || '-',
            'MPQ PTI ROUNDUP': v.mpq_roundup || '-',
            'MPQ SUPPLIER': v.mpq_supplier || '-',
            'MPQ SUPPLIER ROUNDUP': v.mpq_supplier_roundup || '-',
            'MDQ SUPPLIER': v.mdq_supplier || '-',
            'INDEKS MPQ VS BASELINE': v.indeks_mpq || '-',
            'INDEKS MPQ VS MPQ PTI': v.indeks_mpq_pti || '-',
            'ROP OSPO': v.rop_ospo || '-',
            'ROP PTI(PCS)': v.rop_pti_pcs || '-',
            'MAX STOCK PTI(PCS ATAU KG)': v.mx_stock_pti_pcs || '-',
            'MAX STOCK PTI(KOLI ATAU KEMASAN)': v.mx_stock_pti_koli || '-',
            'MAX STOCK PTI(PALLET)': v.mx_stock_pti_pallet || '-',
            'INDEKS MAX VS BASELINE': v.index_max || '-',
            'ROP SUPPLIER': v.rop_supplier || '-',
            'MAX STOCK SUPPLIER(PCS ATAU KG)': v.mx_stock_pcs || '-',
            'MAX STOCK SUPPLIER(KOLI ATAU KEMASAN)': v.mx_stock_koli || '-',
            'MAX STOCK SUPPLIER(PALLET)': v.mx_stock_pallet || '-',
            'NAMA MATERIAL 1': v.material_satu || '-',
            'ROP RM1': v.rop_rm1 || '-',
            'MAX STOCK RM1': v.max_stock_rm1 || '-',
            'NAMA MATERIAL 2': v.material_dua || '-',
            'ROP RM2': v.rop_rm2 || '-',
            'MAX STOCK RM2': v.max_stock_rm2 || '-',
        });
    });

    return { newData, headers };
};

export const onExportToExcelMcp = (json) => {
    const dummyData = [
        {
            kode_item: '22134432e',
            trade_name: 'Plug Crystallure SR Rich Cream 50 g Wardah...',
            internal_name: 'Godet Two Way Cake 4,4 Cm',
            jenis_material: 'Packaging Material',
            uom: 'Pcs',
            supplier: 'Metalipark Kreasindo, PT',
            kode_supplier: 2200054,
            transition: 'XXXGODE-TWC5,4B-SM-P',
            status: 'Interchangeable',
            set_item: 'Set item A',
            mto_mts: 'MTO',
            close_po: 'Quantity',
            satuan_order: 'kemasan',
            jenis_kemasan: 'RB1',
            pcs_koli: 999.999,
            koli_pallet: 999.999,
            pcs_pallet: 999.999,
            kanban: 999.999,
            baseline_week_pcs: 999.999,
            baseline_week_koli: 999.999,
            baseline_week_pallet: 999.999,
            avg_day: 999.999,
            stdev: 999.999,
            safety_demand: 999.999,
            safety_demand_hari: 10,
            safety_vulnerability: 999.999,
            safety_deviasi: 999.999,
            frekuensi_delivery: 13,
            leadtime_produksi: 4,
            leadtime_delivery: 2,
            mpq_pti: 999.999,
            mpq_roundup: 999.999,
            mpq_supplier: 999.999,
            mpq_supplier_roundup: 999.999,
            mdq_supplier: 999.999,
            indeks_mpq: 999.999,
            indeks_mpq_pti: 999.999,
            rop_ospo: 999.999,
            rop_pti_pcs: 999.999,
            mx_stock_pti_pcs: 999.999,
            mx_stock_pti_koli: 999.999,
            mx_stock_pti_pallet: 999.999,
            index_max: 999.999,
            rop_supplier: 999.999,
            mx_stock_pcs: 999.999,
            mx_stock_koli: 999.999,
            mx_stock_pallet: 999.999,
            material_satu: '2,60',
            rop_rm1: 999.999,
            max_stock_rm1: 999.999,
            material_dua: '2,60',
            rop_rm2: 999.999,
            max_stock_rm2: 999.999,
        },
        {
            kode_item: '22134432e',
            trade_name: 'Plug Crystallure SR Rich Cream 50 g Wardah...',
            internal_name: 'Godet Two Way Cake 4,4 Cm',
            jenis_material: 'Packaging Material',
            uom: 'Pcs',
            supplier: 'Metalipark Kreasindo, PT',
            kode_supplier: 2200054,
            transition: 'XXXGODE-TWC5,4B-SM-P',
            status: 'Interchangeable',
            set_item: 'Set item A',
            mto_mts: 'MTO',
            close_po: 'Quantity',
            satuan_order: 'kemasan',
            jenis_kemasan: 'RB1',
            pcs_koli: 999.999,
            koli_pallet: 999.999,
            pcs_pallet: 999.999,
            kanban: 999.999,
            baseline_week_pcs: 999.999,
            baseline_week_koli: 999.999,
            baseline_week_pallet: 999.999,
            avg_day: 999.999,
            stdev: 999.999,
            safety_demand: 999.999,
            safety_demand_hari: 10,
            safety_vulnerability: 999.999,
            safety_deviasi: 999.999,
            frekuensi_delivery: 13,
            leadtime_produksi: 4,
            leadtime_delivery: 2,
            mpq_pti: 999.999,
            mpq_roundup: 999.999,
            mpq_supplier: 999.999,
            mpq_supplier_roundup: 999.999,
            mdq_supplier: 999.999,
            indeks_mpq: 999.999,
            indeks_mpq_pti: 999.999,
            rop_ospo: 999.999,
            rop_pti_pcs: 999.999,
            mx_stock_pti_pcs: 999.999,
            mx_stock_pti_koli: 999.999,
            mx_stock_pti_pallet: 999.999,
            index_max: 999.999,
            rop_supplier: 999.999,
            mx_stock_pcs: 999.999,
            mx_stock_koli: 999.999,
            mx_stock_pallet: 999.999,
            material_satu: '2,60',
            rop_rm1: 999.999,
            max_stock_rm1: 999.999,
            material_dua: '2,60',
            rop_rm2: 999.999,
            max_stock_rm2: 999.999,
        },
    ];
    const { newData, headers } = onDataFormattedExcel({ data: json || dummyData });
    Object.keys(newData[0]).forEach((v) => {
        headers.push(v);
    });
    // INITILIZE EXCELJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.addRow(headers);

    // looping for value excel
    newData.forEach((item, _idx) => {
        worksheet.addRow(Object.values(item));
    });
    // set width for each column
    for (let i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 52;
    }

    // set bold for header
    const selectedRows = [];
    worksheet.eachRow((row, rowNumber) => {
        if ([1].indexOf(rowNumber) !== -1) {
            selectedRows.push(row);
        }
    });

    selectedRows.forEach((row) => {
        row.eachCell((cell) => {
            cell.font = {
                bold: true,
            };
            cell.alignment = { horizontal: 'center' };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `material_config_${new Date().toDateString()}.xlsx`;
        link.click();
    });
};

export const numberToFixedMC = (num) => {
    if (typeof num === 'number' || num === 0) {
        return Number(num.toFixed(2)) % num ? num.toFixed(2) : num;
    } else {
        return '-';
    }
};

const formatTableMC = ({ listMaterial }) => {
    const formatTable = [...tableList];

    let isRawMatLength = 1;

    for (let i = 0; i < listMaterial?.length; i++) {
        if (listMaterial[i]?.materialRawmats?.length > isRawMatLength) {
            isRawMatLength = listMaterial[i]?.materialRawmats?.length;
        }
    }

    for (let i = 0; i < isRawMatLength; i++) {
        formatTable.push(
            {
                name: `Nama Material ${i + 1}`,
                value: 'materialRawmats',
                width: 160,
                children: ['-'],
                component: ({ value }) => (
                    <Box>
                        {value?.length >= 1
                            ? [{ item: i, index: 1 }].map((res) => (
                                  <Text className="column-text" key={res.index}>
                                      {value[res.item]?.material_name}
                                  </Text>
                              ))
                            : '-'}
                    </Box>
                ),
            },
            {
                name: `ROP RM${i + 1}`,
                value: 'materialRawmats',
                width: 71,
                children: ['-'],
                component: ({ value }) => (
                    <Box>
                        {value?.length >= 1
                            ? [{ item: i, index: 1 }].map((res) => (
                                  <Text className="column-text" key={res.index}>
                                      {numberToFixedMC(value[res.item]?.rop)}
                                  </Text>
                              ))
                            : '-'}
                    </Box>
                ),
            },
            {
                name: `MAX stock RM${i + 1}`,
                value: 'materialRawmats',
                width: 100,
                children: ['-'],
                component: ({ value }) => (
                    <Box>
                        {value?.length >= 1
                            ? [{ item: i, index: 1 }].map((res) => (
                                  <Text className="column-text" key={res.index}>
                                      {numberToFixedMC(value[res.item]?.max_stock)}
                                  </Text>
                              ))
                            : '-'}
                    </Box>
                ),
            }
        );
    }

    formatTable.push({
        name: 'Action',
        width: 87,
        value: null,
        children: ['-'],
        component: ({ onEditHandler, onDeleteMaterial }) => (
            <>
                {isAllowed({ pageName: 'Material Config', permissionName: 'Edit Material Config' }) ? (
                    <Flex>
                        <Box
                            h="max-content"
                            cursor="pointer"
                            rounded={'4px'}
                            border={'1px solid'}
                            borderColor={'#1746A2'}
                            onClick={() => {
                                onEditHandler();
                            }}
                            p="6px"
                            mr="12px"
                        >
                            <EditIcon />
                        </Box>

                        <Box
                            h="max-content"
                            cursor="pointer"
                            rounded={'4px'}
                            border={'1px solid'}
                            borderColor={'#EF5350'}
                            onClick={() => {
                                onDeleteMaterial();
                            }}
                            p="6px"
                        >
                            <TrashIcon />
                        </Box>
                    </Flex>
                ) : (
                    <Text className="column-text">-</Text>
                )}
            </>
        ),
    });

    return formatTable;
};

import Axios from '@services/axios';
import { find, isArray, isEmpty, uniqBy, forEach } from 'lodash';
import Cookies from 'universal-cookie';

export const onChangeFormData = ({ type, setFormData, value, formData }) => {
    const obj = { ...formData };
    obj[type] = value;

    if (isArray(obj[type])) {
        obj[type] = uniqBy(value, 'id');
    }

    setFormData({ ...obj });
};

const getIdHandler = (arr) => {
    return arr.map((item) => item.id);
};
export const removeEmptyValue = (obj, isEditModal) => {
    const temp = {};

    forEach(obj, (value, key) => {
        if (!isEmpty(value) || isEditModal) {
            temp[key] = value;
        }
    });

    return temp;
};

const onDataFormatted = ({ selectedStreamline, formData, isEditModal }) => {
    const { streamlineName, zone, lineOlah1, lineOlah2, lineTimbang, lineKemas } = formData;

    const obj = {
        streamline: {
            name: streamlineName?.toUpperCase()?.trim(),
            zona_id: zone.id,
        },
        timbang: getIdHandler(lineTimbang),
        kemas: getIdHandler(lineKemas),
        ...removeEmptyValue({ olah1: getIdHandler(lineOlah1), olah2: getIdHandler(lineOlah2) }, isEditModal),
    };

    if (isEditModal && obj.streamline.name === selectedStreamline.name) {
        delete obj.streamline.name;
    }
    return {
        ...obj,
    };
};

export const onValidation = ({ formData }) => {
    const { streamlineName, zone, lineOlah1, lineOlah2, lineKemas } = formData;
    return streamlineName && !isEmpty(zone) && (!isEmpty(lineOlah1) || !isEmpty(lineOlah2)) && !isEmpty(lineKemas);
};

export const onSubmitForm = async ({
    selectedStreamline,
    isEditModal,
    formData,
    toast,
    setIsSubmitLoading,
    reFetchStreamlineHandler,
    setIsSubmitted,
    onResetForm,
    setIsSuccessSubmit,
}) => {
    setIsSubmitted(true);
    const isValid = onValidation({ formData });

    if (!isValid) {
        toast({
            position: 'top-center',
            description: 'Field tidak boleh kosong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
        return;
    }

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    setIsSubmitLoading(true);

    const url = isEditModal ? `/streamline/${selectedStreamline.id}` : `/streamline`;
    const method = isEditModal ? 'PATCH' : 'POST';

    const dataFormatted = onDataFormatted({ selectedStreamline, formData, isEditModal });
    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: dataFormatted,
        });
        await reFetchStreamlineHandler();
        onResetForm();
        setIsSuccessSubmit(true);
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0]?.message;

        toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        setIsSubmitLoading(false);
    }
};

export const mappedSelectedStreamline = ({ setFormData, selectedStreamline, zoneList }) => {
    const { name, zona, olah1, olah2, kemas, timbang } = selectedStreamline;

    setFormData({
        streamlineName: name,
        zone: find(zoneList, { id: zona.id }),
        lineOlah1: olah1,
        lineOlah2: olah2,
        lineKemas: kemas,
        lineTimbang: timbang,
    });
};

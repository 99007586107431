import { Flex, Text, Button, Box, CircularProgress, useToast } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { IconBack, UploadIconSm, IconFile } from './icons';
import { AutomaticWidthTable, handleSubmitUpload, onChangeFile, onDownloadTemplateUpload } from './functions';
import { DownloadBlackIcon } from '@asset/icons';
import { useSelector } from 'react-redux';
import ModalConfirm from '@components/modalConfirm';
import Table from './component/table/Table';
import ErrorDetails from './component/errorDetails';
import Pagination from '@components/pagination';
import CustomButton from '@components/button';
import ModalFormat from '@components/modalFormat';
import ModalSuccess from '@components/modalSuccess';
import ModalFailedUpload from '@components/modalFailed';

import './styles.scss';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [previewData, setPreviewData] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [state, setState] = useState('upload');
    const [file, setFile] = useState({});
    const [isLoadingUpload, setIsLoadingUpload] = useState(false);
    const [onRange, setOnrange] = useState(false);
    const [error, setError] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [disabledSaveItem, setDisabledSaveItem] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState({ message: '', isOpen: false });
    const [submitError, setSubmitError] = useState({ open: false, message: '', type: '' });

    const head = AutomaticWidthTable({ previewData });
    const isData = Boolean(previewData?.length > 0);
    const { isHide } = useSelector((state) => state.sideMenuSlice);
    const handleCancel = () => {
        setPreviewData([]);
        setState('upload');
    };

    /// https://stackoverflow.com/a/53090848
    const timer = useRef(null);
    const handleUpload = ({ files }) => {
        setState('loading');
        timer.current = setTimeout(() => {
            onChangeFile({ setPreviewData, files, setFile, setState, setDisabledSaveItem, toast });
        }, 2000);
    };
    const handleCancelLoading = () => {
        clearTimeout(timer.current);
        setState('upload');
    };
    return (
        <Box className="section-upload__container__supplier-stock">
            <Flex className="head">
                <Flex onClick={() => navigate('/supplier-stock')} className="left-section">
                    <IconBack />
                    <Text className="title-label">Upload File</Text>
                </Flex>
                {!isData && !state?.includes('loading') && (
                    <Button
                        className="right-section"
                        isLoading={loadingState}
                        onClick={() =>
                            onDownloadTemplateUpload({
                                setIsLoading: setLoadingState,
                                toast,
                            })
                        }
                    >
                        <Box>
                            <DownloadBlackIcon color="#1746A2" />
                        </Box>
                        <Text className="title-download">Download Template</Text>
                    </Button>
                )}
            </Flex>
            <Box>
                {state?.includes('loading') && (
                    <Box className="parent">
                        <Box className="child">
                            <CircularProgress size="70px" isIndeterminate color="#1746a2" />
                            <Text className="text-loading">Uploading File.....</Text>
                            <CustomButton onClick={handleCancelLoading} btnClassName="cancel-button" variant="outline">
                                Cancel
                            </CustomButton>
                        </Box>
                    </Box>
                )}
                {state?.includes('table-view') && (
                    <Box className="container-table-view">
                        <Text className="label-table">Priview File</Text>
                        <Box
                            className="container-table"
                            sx={{
                                maxWidth: isHide ? 'calc(100vw - 166px)' : 'calc(100vw - 356px)',
                            }}
                        >
                            <Table head={head} body={previewData} />
                        </Box>
                        {/* <Pagination
                            totalData={200}
                            selectedPerPage={selectedPerPage}
                            setSelectedPerpage={setSelectedPerpage}
                        /> */}
                        <Flex className="btn-action-group">
                            <CustomButton onClick={handleCancel} btnClassName="cancel-button" variant="outline">
                                Cancel
                            </CustomButton>
                            <Button
                                isDisabled={disabledSaveItem}
                                className="save-button"
                                onClick={() => setConfirm(true)}
                            >
                                Save Item
                            </Button>
                        </Flex>
                    </Box>
                )}
                {state?.includes('upload') && (
                    <Box className="body">
                        <Flex className="section-body">
                            <Flex className="upload-box">
                                <Box className="parent-line-vertical">
                                    {Array(11)
                                        .fill(1)
                                        .map((item, index) => (
                                            <Box key={index} className="child-line-vertical" />
                                        ))}
                                </Box>
                                <Box className="parent-line-horizon">
                                    {Array(8)
                                        .fill(1)
                                        .map((item, index) => (
                                            <Box key={index} className="child-line-horizon" />
                                        ))}
                                </Box>
                                <FileUploader
                                    name="file"
                                    onDraggingStateChange={(dragging) => setOnrange(dragging)}
                                    onDrop={(files) => handleUpload({ files })}
                                    handleChange={(files) => handleUpload({ files })}
                                    classes="file-uploader__drag-and-drop-only"
                                    types={['xlsx']}
                                    onTypeError={(err) => setError(true)}
                                    disabled
                                />
                                <Box opacity={!onRange ? 0 : 10} className="after-drop">
                                    <Text className="after-drop-title">" Letakan Disini "</Text>
                                </Box>
                                <Box opacity={onRange ? 0 : 10} className="before-drop">
                                    <IconFile />
                                    <Text className="upload-text">
                                        Drag and Drop atau pilih file Exel untuk di upload
                                    </Text>
                                    <Button className="upload-button">
                                        <FileUploader
                                            onDrop={(files) => handleUpload({ files })}
                                            handleChange={(files) => handleUpload({ files })}
                                            name="file"
                                            classes="file-uploader"
                                            types={['xlsx']}
                                        />
                                        <UploadIconSm />
                                        Upload File
                                    </Button>
                                </Box>
                            </Flex>
                        </Flex>
                    </Box>
                )}
            </Box>
            {error && (
                <ModalFormat
                    isOpen={error}
                    onClose={() => setError(false)}
                    title="Format File salah"
                    description="Maaf, format file yang Anda masukkan tidak sesuai. 
                    Mohon pastikan menggunakan format yang benar."
                />
            )}
            <ModalConfirm
                loading={isLoadingUpload}
                isOpen={confirm}
                onClose={() => setConfirm(false)}
                onSubmit={() =>
                    handleSubmitUpload({
                        setSubmitSuccess,
                        setConfirm,
                        setIsLoadingUpload,
                        file,
                        setSubmitError,
                        previewData,
                        setPreviewData,
                        setDisabledSaveItem,
                    })
                }
                title="Apa anda yakin ingin mengupload File?"
                submitLabel="Lanjut"
            />
            <ModalSuccess
                isOpen={submitSuccess.isOpen}
                onClose={() => {
                    setSubmitSuccess({ isOpen: false, message: '' });
                    navigate('/supplier-stock');
                }}
                labelButton="Kembali"
                description={
                    Boolean(submitSuccess.message) ? (
                        <>
                            <Box sx={{ color: 'red', mb: '8px' }}>{submitSuccess.message}</Box>
                            <Box>
                                <CustomButton
                                    height="36px"
                                    onClick={() => {
                                        setSubmitSuccess({ isOpen: false, message: '' });
                                    }}
                                >
                                    Tutup
                                </CustomButton>
                            </Box>
                        </>
                    ) : null
                }
                title="File Berhasil Diupload"
            />
            <ModalFailedUpload
                isOpen={submitError.open}
                onClose={() => {
                    setSubmitError((prev) => ({ ...prev, open: false }));
                }}
                onRightBtnClick={() => setSubmitError((prev) => ({ ...prev, open: false }))}
                onLeftBtnClick={() => {
                    setSubmitError({ open: false, message: '', type: '' });
                    setFile({});
                    setState('upload');
                }}
                title="Uplod file gagal"
                description={<ErrorDetails data={submitError} />}
                leftLabelButton="Upload Ulang"
                rightLabelButton="Kembali"
            />
        </Box>
    );
};

export default Index;

import Axios from "@services/axios";
import Cookies from "universal-cookie";

export const fetchJenisSupplier = async ({
    setListJenisMaterial
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    try {
        const { data } = await Axios({
            method: 'GET',
            url: 'jenis-material/list',
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { jenisMaterials } = data?.data;
        const formatJenisMaterial = jenisMaterials?.filter((item) => item?.name !== 'General')
        setListJenisMaterial(formatJenisMaterial)
    } catch (err) {
        console.log(err);
    }
};
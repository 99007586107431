import { Modal, ModalOverlay, ModalContent, Box, Button, Text, Flex } from '@chakra-ui/react';
import { CloseIcon } from './icons';
import './styles.scss';

const ModalFormat = ({ isOpen, title, onClose, description }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalContent w="350px" position="absolute" top="55%" left="21px">
                <Box className="modal-format-global__container">
                    <Flex className="wrapper-title">
                        <Text className="format-title">{title}</Text>
                        <Button className="back-button" onClick={onClose}>
                            <CloseIcon />
                        </Button>
                    </Flex>
                    <Box className="format-description">{description}</Box>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ModalFormat;

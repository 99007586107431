export function ConfirmIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="132" height="133" fill="none" viewBox="0 0 132 133">
            <circle cx="65.999" cy="66.66" r="65.901" fill="#F5F070"></circle>
            <path
                fill="#fff"
                stroke="#fff"
                strokeWidth="7.029"
                d="M67.616 75.535l-.002.018-.003.017c-.098.8-.712 1.249-1.278 1.249-.924 0-1.519-.791-1.442-1.481.171-1.45.487-2.711 1.109-3.82l.004-.008.004-.007c.984-1.777 2.476-3.287 4.387-5.06l.62-.573c1.67-1.536 3.651-3.36 5.161-5.517 2.23-3.167 2.681-7.555 1.07-11.215-1.732-3.934-5.647-6.653-11.213-6.653-5.367 0-8.904 2.893-10.955 6.079l-.02.032-.02.032c-.082.133-.216.248-.447.313a1.344 1.344 0 01-.837-.051 1.327 1.327 0 01-.716-.797c-.11-.337-.06-.621.083-.839 2.64-3.913 6.988-6.74 12.845-6.74 6.674 0 10.872 2.984 12.934 6.35.857 1.41 1.618 3.603 1.767 5.992.147 2.367-.316 4.67-1.578 6.541-1.787 2.635-3.527 4.353-5.21 5.982l-.262.254c-1.56 1.508-3.331 3.219-4.597 5.54-.699 1.26-1.144 2.412-1.404 4.362zm1.536 18.132A3.172 3.172 0 0166 96.819a3.172 3.172 0 01-3.152-3.152A3.172 3.172 0 0166 90.515a3.172 3.172 0 013.152 3.152z"
            ></path>
        </svg>
    );
}

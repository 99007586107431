import { Text, Box, Flex, Input, Tooltip } from '@chakra-ui/react';
import {
    getColorHandler,
    getColorTextHandler,
    getCurrMap,
    getCurrtLengthDates,
    getValueTooltip,
    onFormatUpdateDate,
} from './function';
import { NoteIcon, EditDateIcon } from './icons';
import EditableColumn from './components/editableColumn';
import { isAllowed } from '@helpers/roleAccess';
import EditableNotes from './components/editableNotes';

export const tableList = [
    {
        name: 'Status',
        value: 'stockSupplier',
        sortValue: 'status',
        label: 'sort by status',
        width: 120,
        children: ['-'],
        component: ({ value }) => (
            <Box className="box-column-status" background={getColorHandler({ status: value?.status })}>
                <Text className="text-colum-status" color={getColorTextHandler({ status: value?.status })}>
                    {value?.status}
                </Text>
            </Box>
        ),
    },
    {
        name: 'Kode Item',
        value: 'kode_item',
        sortValue: 'kode_item',
        label: 'sort by kode item',
        width: 200,
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value || '-'}</Text>,
    },
    {
        name: 'Nama Item',
        value: 'trade_name',
        sortValue: 'nama_item',
        label: 'sort by nama item',
        width: 300,
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value || '-'}</Text>,
    },
    {
        name: 'Supplier',
        value: 'supplier',
        sortValue: 'supplier_name',
        label: 'sort by supplier',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value?.name || '-'}</Text>,
    },
    {
        name: 'UOM',
        value: 'uom',
        width: 50,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'ROP Supplier',
        value: 'stock_supp_rop',
        width: 90,
        // width: 100,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'MAX Stock Supplier',
        value: 'stock_supp_max',
        width: 90,
        // width: 130,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'MPQ Supplier',
        value: 'materialCriticalPoint',
        width: 100,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value?.mpq_supplier || '-'}</Text>,
    },
    {
        name: 'Production Target',
        value: 'stockSupplier',
        width: 130,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value?.production_target}</Text>,
    },
    {
        name: 'SOH Supplier',
        value: 'stockSupplier',
        width: 100,
        children: ['pcs'],
        component: ({ value }) => (
            <EditableColumn keyAttribute="soh_supplier" currentValue={value?.soh_supplier || '-'} id={value?.id} />
        ),
    },
    {
        name: 'On Process',
        value: 'stockSupplier',
        width: 40,
        // width: 80,
        // flex: 1,
        children: ['pcs'],
        component: ({ value }) => (
            <EditableColumn keyAttribute="on_process" currentValue={value?.on_process || '-'} id={value?.id} />
        ),
    },
    {
        name: 'Manufacturing Date',
        value: 'stockSupplier',
        // width: 300,
        width: 80,
        flex: 1,
        children: ['-'],
        component: ({ value, dataItem, onChangeModalState, onSelectedValue }) => (
            <Flex w="300px" justifyContent="space-between" alignItems="center" position="relative">
                <Box display="flex">
                    {value?.stockManufDates?.length >= 1 ? (
                        getCurrMap({ value: value?.stockManufDates }).map((_, index) => (
                            <Tooltip
                                className="text-tooltip-label"
                                label={value?.stockManufDates[index]?.manufacturing_date}
                            >
                                <Text className="column-text" mr={'5px'} key={index}>
                                    {value?.stockManufDates[index]?.manufacturing_date}
                                </Text>
                            </Tooltip>
                        ))
                    ) : (
                        <Text>{'-'}</Text>
                    )}
                </Box>
                <Tooltip className="text-tooltip-label" label={getValueTooltip({ value: value?.stockManufDates })}>
                    <Box
                        bg={'#EBEBEB'}
                        position="absolute"
                        top="2px"
                        right="38px"
                        borderRadius={'50%'}
                        w={'16px'}
                        h={'16px'}
                        fontSize={'8px'}
                        lineHeight={'12px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        color={'#B1B1B1'}
                        mr={'12px'}
                    >
                        {`+${getCurrtLengthDates({ value: value?.stockManufDates })}`}
                    </Box>
                </Tooltip>
                {Boolean(value?.id) && (
                    <>
                        {isAllowed({ pageName: "Supplier's Stock", permissionName: "Edit Supplier's Stock" }) && (
                            <Box
                                cursor="pointer"
                                onClick={() => {
                                    onSelectedValue({ value: dataItem });
                                    onChangeModalState({ value: true });
                                }}
                            >
                                <EditDateIcon />
                            </Box>
                        )}
                    </>
                )}
            </Flex>
        ),
    },
    {
        name: 'Notes',
        value: 'stockSupplier',
        width: 210,
        // width: 80,
        // flex: 1,
        children: ['-'],
        component: ({ value }) => <EditableNotes dataItem={value} />,
    },
    {
        name: 'Last Update',
        value: 'stockSupplier',
        width: 120,
        // width: 140,
        children: ['-'],
        component: ({ value, dataItem }) => <Text className="column-text">{onFormatUpdateDate(value?.updatedAt)}</Text>,
    },
];

const setRandomStatus = () => {
    const arr = ['OK', 'PRODUCTION', 'OVERSTOCK'];
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
};

export const formDefaultValues = {
    note: 'Sedang Produksi',
    process: '500.000',
    supplier: '500.000',
};

export const initialValueFilter = {
    status: '',
};

export const dummyDatas = {
    status: setRandomStatus(),
    kode_item: 12345678,
    nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
    supplier_name: 'Metalipak Kreasindo, PT',
    uom: 'Pcs',
    rop_supplier: '200,000',
    max_stock_supplier: '200,000',
    mpq_supplier: '200,000',
    production_target: '999,999,999',
    soh_supplier: '500.000',
    on_process: '500.000',
    manufacturing_date: `12/09/2022 | 12/09/2022 | 12/09/2022`,
    notes: 'Sedang Produksi',
    last_update: '12/09/2023',
};

export const SearchIcon = ({ size = '24' }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
            stroke="#2D2D2D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21 20.9999L16.65 16.6499"
            stroke="#2D2D2D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const LeftArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4141 11.9998L14.0001 15.5858C14.3911 15.9768 14.3911 16.6098 14.0001 16.9998C13.6091 17.3908 12.9761 17.3908 12.5861 16.9998L9.00006 13.4138C8.21906 12.6328 8.21906 11.3668 9.00006 10.5858L12.5861 6.99979C12.9761 6.60879 13.6091 6.60879 14.0001 6.99979C14.3911 7.39079 14.3911 8.02379 14.0001 8.41379L10.4141 11.9998Z"
            fill="#6B6B6B"
        />
    </svg>
);

export const RightArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5859 11.9998L9.99991 15.5858C9.60991 15.9758 9.60991 16.6088 9.99991 16.9998C10.3899 17.3908 11.0239 17.3908 11.4139 16.9998L14.9999 13.4138C15.7809 12.6328 15.7809 11.3668 14.9999 10.5858L11.4149 6.99979C11.0239 6.60879 10.3909 6.60879 9.99991 6.99979C9.60891 7.39079 9.60991 8.02379 9.99991 8.41379L13.5859 11.9998Z"
            fill="#686868"
        />
    </svg>
);

export const DownloadIcon = ({ color = '#1746A2' }) => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.8027 7.95707L7.65625 8.81062V7.60352V1.76758C7.65625 1.67641 7.69247 1.58898 7.75693 1.52451C7.8214 1.46004 7.90883 1.42383 8 1.42383C8.09117 1.42383 8.1786 1.46004 8.24307 1.52451C8.30753 1.58898 8.34375 1.67641 8.34375 1.76758V7.60352V8.81062L9.1973 7.95707L10.709 6.44535C10.7738 6.38061 10.8616 6.34424 10.9531 6.34424C11.0447 6.34424 11.1325 6.38061 11.1972 6.44535C11.262 6.51009 11.2983 6.5979 11.2983 6.68945C11.2983 6.78101 11.262 6.86882 11.1972 6.93356L8.24642 9.88438C8.24611 9.88468 8.2458 9.88499 8.2455 9.88529C8.17994 9.94952 8.09181 9.98551 8 9.98551C7.90819 9.98551 7.82006 9.94952 7.7545 9.88529C7.7542 9.88499 7.75389 9.88468 7.75358 9.88438L4.80277 6.93356C4.73803 6.86882 4.70166 6.78101 4.70166 6.68945C4.70166 6.5979 4.73803 6.51009 4.80277 6.44535C4.86751 6.38061 4.95532 6.34424 5.04688 6.34424C5.13843 6.34424 5.22624 6.38061 5.29098 6.44535L6.8027 7.95707ZM13.8438 13.7988V13.2988V9.64258C13.8438 9.55141 13.88 9.46398 13.9444 9.39951L13.5909 9.04596L13.9444 9.39951C14.0089 9.33504 14.0963 9.29883 14.1875 9.29883C14.2787 9.29883 14.3661 9.33504 14.4306 9.39951L14.7841 9.04596L14.4306 9.39951C14.495 9.46398 14.5312 9.55141 14.5312 9.64258V13.5786C14.5298 13.8189 14.4337 14.0489 14.2638 14.2189C14.0939 14.3888 13.8638 14.4849 13.6236 14.4863H2.37645C2.13618 14.4849 1.90615 14.3888 1.73623 14.2189L1.38349 14.5716L1.73623 14.2189C1.56632 14.049 1.47023 13.8189 1.46875 13.5787V9.64258C1.46875 9.55141 1.50497 9.46398 1.56943 9.39951L1.21588 9.04596L1.56943 9.39951C1.6339 9.33504 1.72133 9.29883 1.8125 9.29883C1.90367 9.29883 1.9911 9.33504 2.05557 9.39951L2.40912 9.04596L2.05557 9.39951C2.12003 9.46398 2.15625 9.55141 2.15625 9.64258V13.2988V13.7988H2.65625H13.3438H13.8438Z"
            fill={color}
            stroke="#1746A2"
        />
    </svg>
);

export const EditIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.625 15.75H16.125"
            stroke="#1746A2"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M4.125 10.02V12.75H6.86895L14.625 4.99054L11.8857 2.25L4.125 10.02Z"
            stroke="#1746A2"
            stroke-width="2"
            stroke-linejoin="round"
        />
    </svg>
);

export const RemoveIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.375 3.75V16.5H14.625V3.75H3.375Z" stroke="#EF5350" stroke-width="2" stroke-linejoin="round" />
        <path d="M7.5 7.5V12.375" stroke="#EF5350" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.5 7.5V12.375" stroke="#EF5350" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.5 3.75H16.5" stroke="#EF5350" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 3.75L7.23337 1.5H10.7914L12 3.75H6Z" stroke="#EF5350" stroke-width="2" stroke-linejoin="round" />
    </svg>
);

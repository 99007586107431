import { useEffect, useState, createContext } from 'react';
import { Box } from '@chakra-ui/react';
import TableSkeleton from '@components/tableSkeleton';
import { numToArr } from '@helpers/functions';
import EmptyState from '@components/emptyState';

import SectionHead from './sections/sectionHead';
import SectionTable from './sections/sectionTable';
import SectionFooter from './sections/sectionFooter';
import CreateAndEditModal from './component/createAndEditModal';
import DeleteModal from './component/deleteModal';
import { tableList, userManagementList } from './const';
import Header from './component/header';
import { fetchUserHandler, onRemoveAccount, initialValueUpdate } from './functions';
import Cookies from 'universal-cookie';
import ForbiddenPage from '@components/forbiddenPage';
import { formDefaultValue } from './component/createAndEditModal/const';
import './styles.scss';
import useFetch from 'hooks/useFetch';

export const UserManagementContext = createContext();

const UserManagement = () => {
    const { data } = useFetch({ api: 'role/list' });
    const { data: dataMaterial } = useFetch({ api: 'sediaan/list' });
    const { data: listJenisMaterial } = useFetch({ api: 'jenis-material/list' });
    const materials = dataMaterial?.sediaan;
    const jenisMaterial = listJenisMaterial?.jenisMaterials || [];
    const roles = data?.roles?.map((item) => ({ ...item, name: item.apps?.join() }));
    const cookies = new Cookies();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({ role: {}, name: [], id: '' });

    const [isShowCreateUserModal, setIsShowCreateUserModal] = useState(false);
    const [isShowEditUserModal, setIsShowEditUserModal] = useState(false);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [roleSelected, setRoleSelected] = useState({});
    const [formData, setFormData] = useState(formDefaultValue);

    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const togglerCreateUserModal = () => setIsShowCreateUserModal(!isShowCreateUserModal);
    const togglerEditUserModal = () => setIsShowEditUserModal(!isShowEditUserModal);
    const togglerDeleteUserModal = () => {
        setRoleSelected({});
        setFormData(formDefaultValue);
        setIsShowDeleteModal(!isShowDeleteModal);
    };

    const onSelectedUser = ({ value }) => {
        initialValueUpdate({ value, setFormData, setRoleSelected, materials });
        setSelectedUser(value);
    };

    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const onSearchHandler = ({ value }) => setSearchQuery(value);

    const onResetFilter = () => {
        setPage(1);
        setSearchQuery('');
    };

    const reFetchUserHandler = () => {
        page === 1 && searchQuery === ''
            ? fetchUserHandler({ filter, searchQuery, setUsers, setTotalPage, setTotalData, setIsLoading, page })
            : onResetFilter();
    };
    const isForbidden = userManagementList.find((role) => role?.role === cookies.get('role'));

    useEffect(() => {
        fetchUserHandler({
            filter,
            searchQuery,
            setUsers,
            setTotalPage,
            setTotalData,
            setIsLoading,
            page,
        });
    }, [page, searchQuery, filter]);
    if (!isForbidden || cookies.get('appType') !== 'userManagement') {
        return <ForbiddenPage />;
    } else {
        return (
            <UserManagementContext.Provider
                value={{
                    onSelectedUser,
                    togglerEditUserModal,
                    togglerDeleteUserModal,
                    jenisMaterial,
                    materials,
                    setPage,
                }}
            >
                <Header />
                <Box className="user-management__container">
                    {/** ----------------------MAIN COMPONENT --------- */}

                    <SectionHead
                        onSearchHandler={onSearchHandler}
                        roles={roles}
                        togglerCreateUserModal={togglerCreateUserModal}
                        filter={filter}
                        setFilter={setFilter}
                    />

                    {!isLoading && !isShowCreateUserModal && !isShowEditUserModal && (
                        <SectionTable users={users} roleList={roles} />
                    )}
                    {!isLoading && users?.length === 0 && (
                        <EmptyState
                            description="Maaf, tidak ada data yang bisa ditamplikan, silakan memilih role / keyword yang lain."
                            text="Tidak ada data yg di tampilkan"
                        />
                    )}

                    {isLoading && (
                        <TableSkeleton
                            tableList={tableList}
                            count={numToArr(10)}
                            tableHeadHeight="50px"
                            tablColumnHeight="46px"
                        />
                    )}

                    <SectionFooter
                        page={page}
                        totalData={totalData}
                        totalPage={totalPage}
                        handlePageClick={handlePageClick}
                        count={users?.length}
                    />

                    {/** ----------------------MODAL CREATE ---------------------------- */}
                    <CreateAndEditModal
                        roles={roles}
                        isOpen={isShowCreateUserModal}
                        onClose={togglerCreateUserModal}
                        reFetchUserHandler={reFetchUserHandler}
                        roleSelected={roleSelected}
                        setRoleSelected={setRoleSelected}
                        formData={formData}
                        setFormData={setFormData}
                    />

                    {/** ----------------------MODAL EDIT ---------------------------- */}
                    <CreateAndEditModal
                        isEditModal
                        roles={roles}
                        isOpen={isShowEditUserModal}
                        onClose={togglerEditUserModal}
                        reFetchUserHandler={reFetchUserHandler}
                        selectedUser={selectedUser}
                        roleSelected={roleSelected}
                        setRoleSelected={setRoleSelected}
                        formData={formData}
                        setFormData={setFormData}
                    />

                    {/** ----------------------MODAL DELETE ---------------------------- */}
                    <DeleteModal
                        selectedUser={selectedUser}
                        isOpen={isShowDeleteModal}
                        onClose={togglerDeleteUserModal}
                        reFetchUserHandler={reFetchUserHandler}
                        onRemoveAccount={onRemoveAccount}
                        togglerDeleteUserModal={togglerDeleteUserModal}
                    />
                </Box>
            </UserManagementContext.Provider>
        );
    }
};

export default UserManagement;

import React from 'react';
import { Box, Flex, Text, Skeleton, Stack } from '@chakra-ui/react';
import { formListKonfigurasiSatuan } from '../const';
import FieldMenu from '@components/fieldMenu';
import { ArrowUpIconBlack } from '@asset/icons';
import { handleClickMenu, rightLabelHelper } from '../function';

import './styles.scss';
import { FormCreateNewItemContext } from '..';
import useFetch from 'hooks/useFetch';
import { useSelector } from 'react-redux';
import { isEmptyString } from '@helpers/functions';

function Index({ formData, setFormData }) {
    const { jenisMaterial } = useSelector((reducer) => reducer.globalFilterSlice);
    const { isSubmitted } = React.useContext(FormCreateNewItemContext);
    const { data, isLoading } = useFetch({
        api: `satuan-order/list?jenis_material_id=${jenisMaterial?.id}`,
        enabled: !isEmptyString(formData?.dataLengkap?.jenis_material),
    });

    const dataSatuanOrder = () => {
        return (
            data?.satuanOrders?.map((item) => ({
                name: item?.name?.toUpperCase(),
                value: item?.idiom,
            })) || []
        );
    };
    return (
        <Flex className="unit-configuration">
            <Box width="267px">
                <Text className="title-unit-configuration">Konfigurasi Satuan</Text>
                <Text className="tag-unit-configuration">
                    Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                </Text>
            </Box>
            <Box className="unit-configuration-container">
                {formListKonfigurasiSatuan.map((itemForm, index) => {
                    if (isLoading && itemForm.apiOption) {
                        return (
                            <Box key={index}>
                                <Text className="label">
                                    {itemForm.title}
                                    {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                                <Stack mt="6px">
                                    <Skeleton height="48px" />
                                </Stack>
                            </Box>
                        );
                    }
                    if (itemForm.apiOption) {
                        return (
                            <Box key={index}>
                                <Text className="label">
                                    {itemForm.title}
                                    {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                                <Box mt="6px" flex={1}>
                                    <FieldMenu
                                        buttonName={
                                            <span>
                                                {formData?.konfigurasiSatuan[itemForm.name] ? (
                                                    <span className="selected-role-text">
                                                        {formData?.konfigurasiSatuan[itemForm.name]}
                                                    </span>
                                                ) : (
                                                    <span className="FieldMenu-placeholder">
                                                        {itemForm.placeholder}
                                                    </span>
                                                )}
                                            </span>
                                        }
                                        onClick={(selected) => handleClickMenu({ itemForm, selected, setFormData })}
                                        menuList={dataSatuanOrder()}
                                        isLoading={isLoading}
                                        keyName="name"
                                        disabledWidthBox="706px"
                                        disabledHeightBox="48px"
                                        menuClassName="menu-container__user-management"
                                        menuButtonClassName={`menu-button`}
                                        menuListClassName="menuList-container"
                                        icon={<ArrowUpIconBlack />}
                                    />
                                    {isSubmitted && !formData?.konfigurasiSatuan?.satuan_order && (
                                        <Text className="required-field__text">Field required!</Text>
                                    )}
                                </Box>
                            </Box>
                        );
                    }
                    if (itemForm.type === 'select') {
                        return (
                            <Box key={index}>
                                <Text className="label">
                                    {itemForm.title}
                                    {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                                <Box mt="6px" flex={1}>
                                    <FieldMenu
                                        buttonName={
                                            <span>
                                                {formData?.konfigurasiSatuan[itemForm.name] ? (
                                                    <span className="selected-role-text">
                                                        {formData?.konfigurasiSatuan[itemForm.name]}
                                                    </span>
                                                ) : (
                                                    <span className="FieldMenu-placeholder">
                                                        {itemForm.placeholder}
                                                    </span>
                                                )}
                                            </span>
                                        }
                                        onClick={(selected) => handleClickMenu({ itemForm, selected, setFormData })}
                                        menuList={itemForm.option}
                                        keyName="name"
                                        disabledWidthBox="706px"
                                        disabledHeightBox="48px"
                                        menuClassName="menu-container__user-management"
                                        menuButtonClassName={`menu-button`}
                                        menuListClassName="menuList-container"
                                        icon={<ArrowUpIconBlack />}
                                    />
                                    {isSubmitted && !formData?.konfigurasiSatuan?.satuan_order && (
                                        <Text className="required-field__text">Field required!</Text>
                                    )}
                                </Box>
                            </Box>
                        );
                    }
                    if (itemForm.children) {
                        return (
                            <Box key={index}>
                                <Text className="label">
                                    {itemForm.title}
                                    {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                                </Text>
                                <Box className="child-container">
                                    {itemForm.children?.map((child, childIndex) => (
                                        <Box key={childIndex} position="relative">
                                            {isLoading ? (
                                                <Box width="213px" height="48px">
                                                    <Skeleton height="48px" />
                                                </Box>
                                            ) : (
                                                <input
                                                    placeholder={child.placeholder}
                                                    disabled
                                                    value={formData?.konfigurasiSatuan[child?.name]}
                                                    style={{
                                                        padding: child.rightLabel ? '12px 80px 12px 16px' : '12px 16px',
                                                    }}
                                                    className="input-style-add-new-item-MC"
                                                />
                                            )}

                                            {child.rightLabel && (
                                                <Text className="right-label-input">
                                                    {rightLabelHelper({
                                                        name: child.rightLabel,
                                                        jenis_material: formData?.dataLengkap?.jenis_material,
                                                    })}
                                                </Text>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        );
                    }
                })}
            </Box>
        </Flex>
    );
}

export default Index;

export const optionFilterMenu = [
    {
        label: 'Sort By',
        name: 'sort_by',
        option: [
            {
                name: 'weekly',
                value: '',
            },
            {
                name: 'monthly',
                value: 'monthly',
            },
        ],
    },
    {
        label: 'Status',
        name: 'status',
        option: [
            {
                name: 'All',
                value: '',
            },
            {
                name: 'Activity',
                value: '1',
            },
            {
                name: 'NPD',
                value: '2',
            },
            {
                name: 'Activity & NPD',
                value: '3',
            },
        ],
    },
];

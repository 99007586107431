import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { listSupplierForm } from './component/createAndEditModal/const';

export const fetchUserHandler = async ({
    filter,
    page,
    setUsers,
    setTotalData,
    setTotalPage,
    setIsLoading,
    searchQuery,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    setIsLoading(true);

    const { role, name, id } = filter;

    const filterStringify = queryString.stringify(
        { role_id: role?.id, q: searchQuery, limit: 10, page, jenis_material_id: id, name: name?.map((item) => item) },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            // url: `/management/user/list?limit=10 &${filterStringify}`,
            url: `/management/user/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { users, totalData, totalPage } = data.data;
        setTotalPage(totalPage);
        setTotalData(totalData);
        setUsers(users);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setUsers([]);
    } finally {
        setIsLoading(false);
    }
};

export const fetchRoleHandler = async ({ setRoles }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/role/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { roles } = data.data;
        setRoles(roles);
    } catch (error) {
        console.log(error);
    }
};

export const onRemoveAccount = async ({
    setIsLoading,
    selectedUser,
    toast,
    reFetchUserHandler,
    togglerDeleteUserModal,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        setIsLoading(true);
        await Axios({
            method: 'DELETE',
            url: `/management/user/${selectedUser.id}?includeSediaanConnection=true&force=true`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await reFetchUserHandler();
        await toast({
            position: 'center-top',
            description: 'Deleted account berhasil',
            status: 'success',
            duration: 1500,
            isClosable: true,
        });
    } catch (error) {
        console.log(error);
        toast({
            position: 'top-center',
            description: 'Deleted account gagal!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        togglerDeleteUserModal();
        setIsLoading(false);
    }
};

export const initialValueUpdate = ({ value, setFormData, setRoleSelected, materials }) => {
    setRoleSelected({ id: value?.role_id, role: value?.role });
    const isSupplier = listSupplierForm.find((item) => item === value?.role);
    setFormData((prev) => ({
        ...prev,
        id: value?.id,
        role: { id: value?.role_id, role: value?.role },
        name: value?.name,
        supplier_name: isSupplier ? { name: value?.name } : {},
        supplier_ref: isSupplier ? value?.supplier_ref : '',
        email: value?.email,
        password: `PTI${value?.supplier_ref}`,
        jenis_material: { id: value?.jenis_material_id, name: value?.jenis_material },
        materials: value?.sediaans?.map((initial) => {
            materials?.map((item) => {
                if (item?.name === initial?.name) {
                    initial['id'] = item?.id;
                }
            });
            return initial;
        }),
    }));
};

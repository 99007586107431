import Cookies from 'universal-cookie';

/// fungsi get pilihan filter order status disetiap tab menu main dashboard
export const getFilterOrderStatus = ({ cookieFilter }) => {
    const cookies = new Cookies();
    if (cookieFilter === 'false' || cookieFilter === 'undefined') return false;
    const findSelectedFilter = cookieFilter.find((item) => item.selected === true);
    const ifUndefined = cookies.get('filterListOrderStatus') || []; /// jika opsi filter order status di cookie undefined
    return {
        showFilter: true,
        selected: findSelectedFilter?.name || ifUndefined?.[0]?.name,
        status: findSelectedFilter?.status,
        selectedEndpoint: findSelectedFilter?.endpoint || '',
        selectedValueEndpoint: findSelectedFilter?.value,
        option: [...cookieFilter].filter((title) => title.value !== 'not_option'),
    };
};

/// memilih jenis filter order status yang terakhir dipilih oleh user ketika pindah tab menu main dashboard (DATA FETCHING)
export const getCurrentFilterSelected = ({ currentFilter, filterOrderStatus, setFilterOrderStatus }) => {
    const cookies = new Cookies();
    const cloneOrderStatusTabMenu = [...filterOrderStatus];
    const findFilterOrderStatusSelected = cloneOrderStatusTabMenu.filter((i) => i.value === cookies.get('tab'));
    const changePreviousSelectedToFalse = {
        ...findFilterOrderStatusSelected[0],
        filter: findFilterOrderStatusSelected[0]?.filter?.map((item) => {
            if (item?.selected) {
                item.selected = false;
            }
            if (item?.name === currentFilter.name) {
                item.selected = true;
            }

            return item;
        }),
    };
    const result = cloneOrderStatusTabMenu.filter((selected) => selected.value !== cookies.get('tab'));
    result.push(changePreviousSelectedToFalse);
    setFilterOrderStatus(() => {
        return result;
    });

    cookies.set('filterListOrderStatus', changePreviousSelectedToFalse.filter);
};

/// fungsi untuk merefresh pilihan filter order status ketika user reload page atupun pindah side menu tab
export const getDefaultFilterAfterRefresh = ({ currentFilter, filterOrderStatus, setFilterOrderStatus }) => {
    const cookies = new Cookies();
    let currentValue = currentFilter;
    if (currentValue !== 'false' && currentValue !== 'undefined') {
        currentValue?.map((item) => {
            if (item.selected) {
                item.selected = false;
            }

            return item;
        });
        currentValue[0].selected = true;
        cookies.set('filterListOrderStatus', currentValue);
    }
    const defaultFilterValue = filterOrderStatus.map((tabMenu) => {
        if (tabMenu.filter) {
            tabMenu.filter.map((filterItem, index) => {
                if (index === 0) {
                    filterItem.selected = true;
                } else {
                    filterItem.selected = false;
                }

                return filterItem;
            });
        }

        return tabMenu;
    });
    setFilterOrderStatus(defaultFilterValue);
};

import { Flex, Box, Text } from '@chakra-ui/react';
import { tableListAddItem } from '../../const';
import './styles.scss';

const TableHead = () => {
    return (
        <Flex className="table-head__container__table-add-item__item-set">
            {tableListAddItem.map((head) => (
                <Box
                    flex={head.flex}
                    className="table-head__item"
                    id={`table-head__item-${head.value}`}
                    width={head.width}
                    key={head.name}
                >
                    <Text className={`table-head__item-${head.value}`}>{head.name}</Text>
                </Box>
            ))}
        </Flex>
    );
};

export default TableHead;

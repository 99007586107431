import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const isAllowed = ({ pageName, permissionName }) => {
    try {
        const cookies = new Cookies();
        const { permissions } = queryString.parse(cookies.get(pageName));
        return permissions?.filter((item) => item?.includes(permissionName))?.length > 0;
    } catch (error) {
        console.log(error);
        return false;
    }
};

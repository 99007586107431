import { Flex, Box } from '@chakra-ui/react';
import { tableArchivedList } from '../../const';
import './styles.scss';

const TableHead = () => {
    return (
        <Flex className="table-head__container__section-archived-list">
            {tableArchivedList?.map((head, idx, it) => {
                if (head.children) {
                    return (
                        <Box
                            className="table-head__item"
                            flexDirection="column"
                            width={head.width}
                            flex={head.flex}
                            key={head.name}
                        >
                            <Flex className="table-head__parent" w="fit-content" pl="6px">
                                {head.name}
                            </Flex>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}
        </Flex>
    );
};

export default TableHead;

import React from 'react';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';

function useFetch({
    api,
    enabled = true,
    then = () => {
        return;
    },
}) {
    const [item, setItem] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    /// https://www.w3schools.com/react/react_customhooks.asp
    React.useEffect(() => {
        async function fetch() {
            setIsLoading(true);
            await Axios({
                method: 'GET',
                url: `/${api}`,
                headers: { Authorization: 'Bearer ' + userToken },
            })
                .then((res) => {
                    setItem(res.data?.data);
                    then(res);
                })
                .catch((error) => {
                    console.log(error);
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        if (enabled) {
            fetch();
        }
    }, [api, enabled]);
    const data = item || [];
    return { data, isLoading, isError };
}

export default useFetch;

import { isEmpty, reverse, sortBy, sum } from 'lodash';
import dateFormat from 'dateformat';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';
// import data from './data.json';

export const convertDate = ({ time = new Date().toISOString() }) => {
    const date = new Date(time);
    const formattedDate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    return formattedDate;
};

export const convertDateToTime = ({ time = new Date().toISOString() }) => {
    const date = new Date(time);
    const formattedDate = date.toLocaleTimeString();
    return formattedDate;
};

export const getLastStatusHandler = ({ histories }) => {
    if (histories) {
        const sorted = reverse(sortBy([...histories], (item) => item.createdAt));
        return sorted[0]?.status?.name || '-';
    }
};

export const timeConverter = (num) => {
    const hours = Math.floor(num / 3600);
    const minutes = Math.floor((num % 3600) / 60);
    const seconds = Math.floor((num % 3600) % 60);
    return `${hours < 10 ? `0${hours}` : hours} : ${minutes < 10 ? `0${minutes}` : minutes} :  ${
        seconds < 10 ? `0${seconds}` : seconds
    } `;
};

export const getTotalLeadTimeHandler = ({ total }) => {
    if (total || total === 0) {
        return timeConverter(total * 3600);
    }
    return '-';
};

const getActualDuration = ({ histories }) => {
    if (histories) {
        const historiesFiltered = histories?.filter(
            (status) => status.status.posisi !== 'inbound' && status.status.posisi !== 'ndc'
        );
        const actualDuration = historiesFiltered.map((actual_duration) => actual_duration.actual_duration);

        return actualDuration;
    }
};

export const getTotalActualTimeHandler = ({ histories }) => {
    try {
        const arr = getActualDuration({ histories });
        const sumAll = sum(arr);
        return timeConverter(sumAll);
    } catch (error) {
        return '-';
    }
};

export const getAllHistoriesHandler = ({ histories, detailKards }) => {
    if (histories) {
        return [...histories]?.map(({ status, actual_duration, start, stop, expected_duration, kard }) => {
            return {
                position: status?.name,
                nomo: kard?.nomo,
                snfg: detailKards?.snfg,
                startTime: start ? dateFormat(start, 'yy/mm/dd HH:MM:ss') : '-',
                endTime: stop ? dateFormat(stop, 'yy/mm/dd HH:MM:ss') : '-',
                actualDuration: timeConverter(actual_duration),
                expectedTime: timeConverter(expected_duration * 3600),
            };
        });
    }
};

export const getProblemReporting = ({ kendalas }) => {
    if (kendalas) {
        return [...kendalas]?.map(({ id, detail, createdAt, solvedAt, duration, jenisKendala }) => {
            return {
                jenis: jenisKendala?.name,
                detail,
                createdAt: createdAt ? dateFormat(createdAt, 'yy/mm/dd HH:MM:ss') : '-',
                solvedAt: solvedAt ? dateFormat(solvedAt, 'yy/mm/dd HH:MM:ss') : '-',
                duration: duration ? timeConverter(duration) : '-',
                status: isEmpty(solvedAt) ? 'On going' : 'Solved',
                id,
            };
        });
    }
};

export const fetchDetailProductStatusHandler = async ({ kard, setDetailKards, setIsLoading }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/kanban/${kard.kard_fg_id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        setDetailKards(data.data.detailKards);
    } catch (error) {
        console.log(error);
        setDetailKards({});
    } finally {
        setIsLoading(false);
    }
};

export const onSolveProblem = async ({ id, refetchProductStatusHandler, setIsSubmitted, setIsSubmitLoading }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsSubmitLoading(true);

    try {
        await Axios({
            method: 'PATCH',
            url: `/kendala/${id}`,
            data: {
                solvedAt: new Date(),
            },
            headers: { Authorization: 'Bearer ' + userToken },
        });
        await refetchProductStatusHandler();
    } catch (error) {
        console.log(error);
    } finally {
        setIsSubmitLoading(false);
        setIsSubmitted(true);
    }
};

export const filteringListNomoAndSnfgComponent = ({ kards }) => {
    return kards?.filter(({ snfg_komponen }) => snfg_komponen !== '-');
};

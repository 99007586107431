import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const handleCheckboxChange = ({ checked, selectedUser, user, setUser, type }) => {
    let clone = [...user];
    clone.map((userItem) => {
        if (userItem.id === selectedUser?.id) {
            userItem.checked = checked;
        }
        return userItem;
    });
    setUser(clone);
};

export const fetchUserInfinite = async ({ page, setUser, setPage, moreData = false, setTotalData, search }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const filterStringify = queryString.stringify({ q: search, page }, { skipEmptyString: true });
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/management/user/list?limit=20&sort=id,asc&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { users, totalData } = data.data;
        if (moreData && !search) {
            setUser((prev) => [...prev, ...users]);
            setPage(page + 1);
        } else {
            setUser(users);
        }
        setTotalData(totalData);
        setPage(page + 1);
    } catch (error) {
        console.log(error);
    }
};

import { Table, Thead, Tbody, Box, Td, Tr } from '@chakra-ui/react';
import SubHeader from './components/subHeader';
import Body from './components/body';
import Head from './components/head';
import EmptyState from '@components/emptyState';
import { useSelector } from 'react-redux';

import './styles.scss';

function Index({ tableHead, tableBody }) {
    const isEmptyState = tableBody?.length === 0;
    const { isHide } = useSelector((state) => state.sideMenuSlice);
    return (
        <Box
            className="table-container-recap-order"
            sx={{
                width: isHide ? 'calc(100vw - 130px)' : 'calc(100vw - 337px)',
            }}
        >
            <Table className="table-recap-order" variant="unstyled">
                <Thead>
                    <Head tableHead={tableHead} />
                </Thead>
                {isEmptyState ? (
                    <Tbody>
                        <Tr>
                            <Td colSpan={24} className="empty-state-container">
                                <Box className="empty-state-box">
                                    <EmptyState
                                        text="Tidak ada data yg di tampilkan"
                                        description="Maaf, tidak ada data yang bisa ditamplikan, silakan memilih Role yang lain."
                                    />
                                </Box>
                            </Td>
                        </Tr>
                    </Tbody>
                ) : (
                    <Tbody>
                        <SubHeader tableHead={tableHead} />
                        <Body tableBody={tableBody} tableHead={tableHead} />
                    </Tbody>
                )}
            </Table>
        </Box>
    );
}

export default Index;

import React from 'react';
import { Box, Flex, Text, Skeleton } from '@chakra-ui/react';
import { formListAVGBaseline } from '../const';
import { handleClickMenu, rightLabelHelper } from '../function';

import './styles.scss';
import { FormCreateNewItemContext } from '..';

function Index({ formData, setFormData }) {
    const { isLoadingMaterialRaci } = React.useContext(FormCreateNewItemContext);
    const handleChange = ({ event, itemForm }) => {
        handleClickMenu({ setFormData, valueOnChange: event?.target?.value, itemForm });
    };
    return (
        <Flex className="avg-baseline">
            <Box width="267px">
                <Text className="title-avg-base-line">AVG Baseline Per Day</Text>
                <Text className="tag-avg-base-line">
                    Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                </Text>
            </Box>
            <Box className="baseline-per-week-container">
                {formListAVGBaseline.map((itemForm, index) => (
                    <Box key={index} className="child-container-per-week">
                        {itemForm.children?.map((child, childIndex) => (
                            <Box key={childIndex} position="relative">
                                {isLoadingMaterialRaci && child.isDisabled ? (
                                    <Box width="213px" height="48px">
                                        <Skeleton height="48px" />
                                    </Box>
                                ) : (
                                    <input
                                        onChange={(event) => handleChange({ event, itemForm })}
                                        placeholder={child.placeholder}
                                        disabled={child.isDisabled}
                                        value={formData?.avgPerDay[child?.name] || ''}
                                        style={{
                                            padding: child.rightLabel ? '12px 80px 12px 16px' : '12px 16px',
                                        }}
                                        className={`input-style-add-new-item-MC ${
                                            child?.isDisabled && 'input-style-add-new-item-MC-disabled'
                                        }`}
                                    />
                                )}

                                {child.rightLabel && (
                                    <Text className="right-label-input">
                                        {rightLabelHelper({
                                            name: child.rightLabel,
                                            jenis_material: formData?.dataLengkap?.jenis_material,
                                        })}
                                    </Text>
                                )}
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        </Flex>
    );
}

export default Index;

import Cookies from 'universal-cookie';
import Axios from '@services/axios';
import { fill, forEach, isArray, isEmpty, find, reverse, sortBy } from 'lodash';
import queryString from 'query-string';
import { getBgColorHandler } from '../../functions';

const onGetMaxKard = ({ data }) => {
    let counter = 0;

    forEach(data, (value) => {
        if (isArray(value)) {
            counter = value?.length >= counter ? value.length : counter;
        }
    });

    return counter;
};

const getLineValueHandler = ({ lineValue, max }) => {
    if (max > lineValue?.length) {
        return [...lineValue, ...fill(Array(max - lineValue?.length), { type: 'empty' })];
    }

    return lineValue;
};

const fillingEmptyKardHandler = ({ kards }) => {
    forEach(kards, (streamlineValue, streamlineKey) => {
        const getMaxKard = onGetMaxKard({ data: streamlineValue });
        kards[streamlineKey].lines = {};
        forEach(streamlineValue, (lineValue, lineKey) => {
            if (isArray(lineValue)) {
                kards[streamlineKey][lineKey] = getLineValueHandler({ lineValue, max: getMaxKard });
            }
        });
    });

    return kards;
};

const findKardsHandler = ({ kard, streamline_id }) => {
    if (kard?.kard_fg?.sku?.streamline_id === streamline_id) {
        return kard;
    }
};

const removeEmptyKards = ({ kards }) => {
    return kards?.filter((item) => {
        const timbang = item?.timbang?.filter(({ kards }) => !isEmpty(kards));
        const olah1 = item?.olah1?.filter(({ kards }) => !isEmpty(kards));
        const olah2 = item?.olah2?.filter(({ kards }) => !isEmpty(kards));
        const qcbulks = item?.qcbulks?.filter(({ kards }) => !isEmpty(kards));
        const kemas = item?.kemas?.filter(({ kards }) => !isEmpty(kards));
        const ndcs = item?.ndcs?.filter(({ kards }) => !isEmpty(kards));

        return (
            timbang?.length > 0 ||
            olah1?.length > 0 ||
            olah2?.length > 0 ||
            qcbulks?.length > 0 ||
            kemas?.length > 0 ||
            ndcs?.length > 0
        );
    });
};

const mappingLineByKards = ({ lines, streamline_id, isFilteredByStreamline }) => {
    let temp = [];
    lines?.forEach(({ name, kards }) => {
        if (kards?.length) {
            kards.forEach((kard) => {
                const obj = {
                    name,
                    kard: isFilteredByStreamline ? findKardsHandler({ kard, streamline_id }) : kard,
                };

                temp.push(obj);
            });
        }
    });

    return removeEmptyKards({ kards: temp });
};

const sortingLine = (lines) => {
    const getLastHistories = [...lines].map((item) => {
        return {
            ...item,
            kard: {
                ...item.kard,
                kard_histories: reverse(sortBy(item?.kard?.kard_histories, (item) => item.createdAt)),
            },
        };
    });

    const sorted = reverse(sortBy(getLastHistories, (item) => item?.kard?.kard_histories[0]?.createdAt));

    return sorted;
};

export const mappingByKardsHandler = ({ streamlines }) => {
    return streamlines?.map((item) => {
        return {
            name: item.name,
            timbang: sortingLine(
                mappingLineByKards({ lines: item.timbang, streamline_id: item.id, isFilteredByStreamline: true })
            ),
            olah1: sortingLine(mappingLineByKards({ lines: item.olah1, streamline_id: item.id })),
            olah2: sortingLine(mappingLineByKards({ lines: item.olah2, streamline_id: item.id })),
            qcbulks: sortingLine(
                mappingLineByKards({ lines: item.qcbulks, streamline_id: item.id, isFilteredByStreamline: true })
            ),
            kemas: sortingLine(mappingLineByKards({ lines: item.kemas, streamline_id: item.id })),
            ndcs: sortingLine(
                mappingLineByKards({ lines: item.ndcs, streamline_id: item.id, isFilteredByStreamline: true })
            ),
        };
    });
};

export const isEmptyLine = (val) => {
    if (val?.length) {
        const temp = val.filter((item) => {
            return !isEmpty(item.kard);
        });

        return isEmpty(temp);
    }

    return true;
};

export const removeEmptyLineHandler = ({ streamlines }) => {
    return streamlines?.filter(({ timbang, olah1, olah2, qcbulks, kemas, ndcs }) => {
        return (
            !isEmptyLine(timbang) ||
            !isEmptyLine(olah1) ||
            !isEmptyLine(olah2) ||
            !isEmptyLine(qcbulks) ||
            !isEmpty(kemas) ||
            !isEmpty(ndcs)
        );
    });
};

const getAllKardHandler = ({ arr }) => {
    const result = [];
    arr?.forEach((item) => item?.kards?.forEach((kard) => result.push({ ...item, kard })));
    return result;
};
const mappingStreamlineWithavailableLineHandler = ({ streamlines, availableLines }) => {
    return streamlines?.map((streamline) => {
        // const getavailableLine = find(availableLines, { name: streamline?.name });
        // if (getavailableLine) {
        //     return {
        //         ...streamline,
        //         // olah1: { data: [getAllKardHandler({ arr: streamline.olah1 })], available: getavailableLine?.olah1 },
        //         // olah2: { data: [...streamline.olah2], available: getavailableLine?.olah2 },
        //         timbang: {
        //             data: [getAllKardHandler({ arr: streamline.timbang })],
        //             available: getavailableLine?.timbang,
        //         },
        //         kemas: { data: [...streamline.kemas], available: getavailableLine?.kemas },
        //         ndcs: { data: [...streamline.ndcs], available: [] },
        //         qcbulks: { data: [...streamline.qcbulks], available: [] },
        //     };
        // }

        return {
            ...streamline,
            timbang: getAllKardHandler({ arr: streamline.timbang }),
            olah1: getAllKardHandler({ arr: streamline.olah1 }),
            olah2: getAllKardHandler({ arr: streamline.olah2 }),
            kemas: getAllKardHandler({ arr: streamline.kemas }),
            ndcs: getAllKardHandler({ arr: streamline.ndcs }),
            qcbulks: getAllKardHandler({ arr: streamline.qcbulks }),
        };
    });
};

export const sortingStreamlineByNameHandler = ({ arr }) => {
    //----------- SORTING ALPANUMERICAL --------------
    const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
    });

    const sorted = arr.sort((curr, prev) => {
        return collator.compare(curr.name, prev.name);
    });

    return sorted;
};

export const fetchProductStatus = async ({ setProductionStatus, setIsLoading, filter }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const { sediaan_id, id, q } = filter;

    const filterStringify = queryString.stringify({ sediaan_id, id, q }, { skipEmptyString: true, skipNull: true });

    setIsLoading(true);

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/kanban?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        // const getavailableLine = await Axios({
        //     method: 'GET',
        //     url: '/kanban/available/line',
        //     headers: { Authorization: 'Bearer ' + userToken },
        // });

        // const availableLines = getavailableLine?.data?.data?.availableLines;
        const streamlines = data?.data?.streamlines;

        if (streamlines.length) {
            let kards = [];
            kards = mappingStreamlineWithavailableLineHandler({ streamlines });
            kards = removeEmptyKards({ kards });
            kards = fillingEmptyKardHandler({ kards });
            setProductionStatus(kards);
        } else {
            setProductionStatus([]);
        }
    } catch (error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
};

export const getStreamlineColor = ({ name }) => {
    return getBgColorHandler({ valueColor: name.name });
};

export const getListNomo = ({ detai }) => {};

import { Modal, ModalOverlay, ModalContent, Box, Button, Text } from '@chakra-ui/react';
import { SuccessIcon } from './icons';

import './styles.scss';

const Modalsuccess = ({ isOpen, title, onClose, description, labelButton = 'Kembali', nextAction = false }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <Box className="modal-success-global__container">
                    <SuccessIcon />
                    <Text className="success-title">{title}</Text>
                    <Box className="success-description">{description}</Box>
                    <Button className="back-button" onClick={nextAction || onClose} _hover="none">
                        {labelButton}
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default Modalsuccess;

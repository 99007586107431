export const initialForm = [
    {
        title: 'Kode Item Lama',
        name: 'old_item_code',
        disabled: true,
    },
    {
        title: 'Trade Name Lama',
        name: 'old_trade_name',
        disabled: true,
    },
    {
        title: 'Kode Item Baru',
        name: 'new_item_code',
        disabled: true,
    },
    {
        title: 'Trade Name Baru',
        name: 'new_trade_name',
        disabled: true,
    },
    {
        title: 'Status Perubahan',
        name: 'status',
        disabled: true,
    },
];

export const tempData = {
    new_item_code: '',
    new_trade_name: '',
};

export const initialValueTransition = {
    old_item_code: 'GODE-TWC5,4B-SM-P',
    old_trade_name: 'Godet TWC SM',
    new_item_code: '',
    new_trade_name: '',
    status: 'Immediately',
};

import { Flex, Box } from '@chakra-ui/react';
import './styles.scss';

const SectionWrapperButton = ({ setIsOpen, navigate }) => {
    return (
        <Flex justifyContent="flex-end" w="100%" mt='31px'>
            <Box className="btn btn-cancel" onClick={() => navigate('/order-management')}>
                Cancel
            </Box>
            <Box className="btn btn-save" onClick={() => setIsOpen(true)}>
                Confirm
            </Box>
        </Flex>
    );
};

export default SectionWrapperButton;

import { Box } from '@chakra-ui/react';
import { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableSkeleton from '@components/tableSkeleton';
import { numToArr } from '@helpers/functions';
import { onChangeSearchInput } from '@store/list/assetManagement';
import EmptyState from '@components/emptyState';

import SectionHead from './sections/sectionHead';
import SectionTable from './sections/sectionTable';
import SectionFooter from './sections/sectionFooter';
import SectionTabView from './sections/sectionTabView';

import CreateAndEditModal from './component/createAndEditModal';
import DeleteModal from './component/deleteModal';

import { tableList } from './const';
import { fetchAssetsHandler, fetchZoneListHandler } from './functions';
import './styles.scss';

export const AssetManagementContext = createContext();

const AssetManagement = () => {
    const dispatch = useDispatch();

    const { selectedMaterial } = useSelector((reducer) => reducer.materialSlice);
    const { accessPermission } = useSelector((reducer) => reducer.accessPermissionSlice);

    const [selectedView, setSelectedView] = useState('zona');
    const [assets, setAssets] = useState([]);
    const [zoneList, setZoneList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAsset, setSelectedAsset] = useState({});
    const [selectName, setSelectName] = useState('Zona Streamline');

    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const [modalToggler, setModalToggler] = useState(null);

    const onChangeModalState = ({ value }) => setModalToggler(value);
    const onSearchHandler = ({ value }) => setSearchQuery(value);
    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const onSelectedAsset = ({ value }) => setSelectedAsset(value);

    const onChangeTabView = ({ value, name }) => {
        setSelectedView(value);
        setSelectName(name);
        onReset();
        dispatch(onChangeSearchInput(''));
    };

    const onReset = () => {
        setPage(1);
        setSearchQuery('');
    };

    const reFetchAssetHandler = () => {
        fetchAssetsHandler({
            setZoneList,
            selectedView,
            selectName,
            searchQuery,
            setTotalData,
            setTotalPage,
            setAssets,
            setIsLoading,
            page,
            selectedMaterial,
        });
    };

    useEffect(() => {
        if (selectedMaterial?.id) {
            fetchAssetsHandler({
                setZoneList,
                selectedView,
                selectName,
                searchQuery,
                setTotalData,
                setTotalPage,
                setAssets,
                setIsLoading,
                page,
                selectedMaterial,
            });
        }
    }, [searchQuery, page, selectedView, selectName, selectedMaterial]);

    useEffect(() => {
        if (selectedMaterial?.id) {
            fetchZoneListHandler({ setZoneList, selectedMaterial });
        }
    }, [selectedMaterial]);

    return (
        <AssetManagementContext.Provider
            value={{
                onChangeTabView,
                selectedView,
                selectName,
                assets,
                onChangeModalState,
                onSelectedAsset,
                selectedAsset,
                zoneList,
                selectedMaterial,
            }}
        >
            <Box className="asset-management__container">
                <SectionTabView />
                <SectionHead onSearchHandler={onSearchHandler} onChangeModalState={onChangeModalState} />
                {!isLoading && <SectionTable />}
                {isLoading && (
                    <TableSkeleton
                        tableList={tableList}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="46px"
                    />
                )}
                {!isLoading && totalData === 0 && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan, silakan memilih kategori yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}
                <SectionFooter
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                    handlePageClick={handlePageClick}
                    count={assets.length}
                />
                {/** ----------------------MODALS ---------------------------- */}
                {accessPermission?.daftarZonaLineProduksi?.isCanAddZona && (
                    <CreateAndEditModal
                        isEditModal={false}
                        isOpen={modalToggler === 'modal-create'}
                        reFetchAssetHandler={reFetchAssetHandler}
                    />
                )}
                <CreateAndEditModal
                    isEditModal
                    isOpen={modalToggler === 'modal-edit'}
                    reFetchAssetHandler={reFetchAssetHandler}
                    selectedAsset={selectedAsset}
                />
                {modalToggler === 'modal-delete' && (
                    <DeleteModal isOpen={modalToggler === 'modal-delete'} reFetchAssetHandler={reFetchAssetHandler} />
                )}
            </Box>
        </AssetManagementContext.Provider>
    );
};

export default AssetManagement;

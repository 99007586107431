import { Box, Text, Flex, Image, Skeleton, CircularProgress, Stack } from '@chakra-ui/react';
import warehouseImg from './images/warehouse.png';
import manufactureImg from './images/manufacture.png';
import ndcImg from './images/ndc.png';
import './styles.scss';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { hideMenu } from './functions';
import { useState } from 'react';
import { SettingIcon } from '@asset/icons';
import useFetch from 'hooks/useFetch';

const LobbyPage = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const { data, isLoading } = useFetch({ api: 'user/app-list' });
    const isUserManagement = Boolean(data?.user?.find((item) => item?.name === 'User Management'));
    // handle set cookies lobbyPage
    const goToMainPage = (cookie) => {
        cookies.set('appType', cookie);
        if (cookie === 'userManagement') {
            navigate('/user-management');
            return;
        }

        if (cookie === 'warehouseToSupplier') {
            navigate('/order-management');
            return;
        }
        navigate('/');
    };

    const onLoadImg = (e) => {
        if (e.target.src.length) {
            setIsLoaded(true);
        }
    };
    const handleClickUserManagement = () => {
        navigate('/user-management');
        cookies.set('appType', 'userManagement');
    };

    return (
        <>
            <Box className="main-container">
                <Box className="lobby-page__container">
                    {isLoading ? (
                        <Flex className="loading-btn-user-management">
                            <Skeleton h={'30px'} w="172px" borderRadius={'4px'} />
                        </Flex>
                    ) : (
                        isUserManagement && (
                            <Flex onClick={handleClickUserManagement} className="btn-user-management">
                                <SettingIcon />
                                <Text className="title">User Management</Text>
                            </Flex>
                        )
                    )}

                    <Text className="main-text">Welcome to DPPS</Text>

                    <Text className="digital-text">Pilih aplikasi yang ingin anda gunakan</Text>
                    <Flex className="wrapper-content__icons">
                        {isLoading ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '35px',
                                }}
                            >
                                {Array(3)
                                    .fill(1)
                                    .map((_, indexSke) => (
                                        <Box key={indexSke}>
                                            <Skeleton h={'130px'} w="130px" borderRadius={'16px'} />
                                            <Skeleton h={'15px'} w="130px" mt={4} borderRadius={'10px'} />
                                        </Box>
                                    ))}
                            </Box>
                        ) : (
                            hideMenu({
                                warehouseImg: warehouseImg,
                                manufactureImg: manufactureImg,
                                ndcImg: ndcImg,
                                profileApps: data?.user || [],
                            })
                                .filter((permissionApp) => permissionApp.isShow)
                                .map((res, index) => (
                                    <Box key={index}>
                                        <Box onClick={goToMainPage.bind(null, res.cookies)}>
                                            <Box className="icons" mx={'35px'}>
                                                {!isLoaded && <Skeleton h={'130px'} borderRadius={'16px'} />}
                                                <Image src={res.img} onLoad={onLoadImg} />
                                                <Flex className="wrapper-text-icons">
                                                    <Text className="text-icons">{res.title}</Text>
                                                </Flex>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))
                        )}
                    </Flex>
                </Box>
            </Box>
        </>
    );
};

export default LobbyPage;

import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { FilterIcon } from './icons';
import ModalMultiple from './modal';
import { dummyNoPO } from './const';
import './styles.scss';
import { handleCloseModalFilter } from './function';

function ModalFilterMultiple() {
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [filter, setFilter] = useState({ inputDate: '' });
    const [formData, setFormData] = useState({ no_po: '' });
    const [data, setData] = useState(dummyNoPO);
    const [selectedDataItem, setSelectedDataItem] = useState([]);
    const [search, setSearch] = useState('');
    const [dataChoosed, setDataChoosed] = useState([]);
    const [selectDate, setSelectDate] = useState(null);
    const dataSelected = dataChoosed.filter((item) => item.checked);

    const handleResetFilter = () => {
        handleCloseModalFilter({
            setOpenModalFilter,
            selectedDataItem,
            setData,
            data,
            type: 'reset',
            setDataChoosed,
        });
    };

    const handleApplyFilter = () => {
        setSelectedDataItem(dataSelected);
        setOpenModalFilter(false);
        setSearch('');
    };

    return (
        <Box className="multiple-filter-rekap-delivery">
            <ModalMultiple
                outlineLabel="Reset Filter"
                value={formData.no_po}
                search={search}
                setSearch={setSearch}
                data={data}
                setData={setData}
                isOpen={openModalFilter}
                dataChoosed={dataChoosed}
                setDataChoosed={setDataChoosed}
                filter={filter}
                setFilter={setFilter}
                selectDate={selectDate}
                setSelectDate={setSelectDate}
                setFormData={setFormData}
                selectedDataItem={selectedDataItem}
                setSelectedDataItem={setSelectedDataItem}
                handleApplyFilter={handleApplyFilter}
                handleResetFilter={handleResetFilter}
                onClose={() => {
                    handleCloseModalFilter({
                        setOpenModalFilter,
                        selectedDataItem,
                        data,
                        setData,
                        setDataChoosed,
                    });
                }}
            />
            <Box
                onClick={() => {
                    setOpenModalFilter(true);
                }}
                className={`multiple-icon-filter ${
                    selectedDataItem?.length > 0 ? 'has-filter-multiple-selected' : 'default-border-icon-filter'
                }`}
            >
                <Box className={`multiple-icon-filter`}>
                    <FilterIcon color={selectedDataItem?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                </Box>
                {selectedDataItem?.length > 0 && (
                    <Text className="btn-filter-multiple-badge">{selectedDataItem?.length}</Text>
                )}
            </Box>
        </Box>
    );
}

export default ModalFilterMultiple;

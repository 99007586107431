import { Box, Flex, Text } from '@chakra-ui/react';
import { getColorHandler, getColorTextHandler, getColorProgresStatus, getColorTextProgresStatus } from './function';
import { EditIcon } from './icons';

export const tableList = [
    {
        name: 'No Order',
        value: 'id',
        width: 20,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'No PO',
        value: 'no_po',
        width: 20,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Status Order',
        value: 'status_order',
        width: 90,
        children: ['-'],
        component: ({ value }) => (
            <Box className="box-column-status" background={getColorHandler({ status: value })}>
                <Text className="text-colum-status" color={getColorTextHandler({ status: value })}>
                    {value}
                </Text>
            </Box>
        ),
    },
    {
        name: 'Kode Item',
        value: 'orderManagement.materialConfig.kode_item',
        width: 145,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Nama Item',
        value: 'orderManagement.materialConfig.trade_name',
        width: 287,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Create Date',
        value: 'created_date',
        width: 100,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Konfirmasi ETA',
        value: 'eta_konfirmasi',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Progres Status',
        value: 'progress',
        width: 120,
        children: ['-'],
        component: ({ value }) => (
            <Box className="box-column-status" background={getColorProgresStatus({ status: value })}>
                <Text className="text-colum-status" color={getColorTextProgresStatus({ status: value })}>
                    {value}
                </Text>
            </Box>
        ),
    },
    {
        name: 'Konfirmasi',
        value: 'qty_konfirmasi',
        width: 100,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'In Preparation',
        value: 'in_preparation',
        width: 110,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'On Delivery',
        value: 'on_delivery',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Received',
        value: 'recieved',
        width: 40,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Arrival Date',
        value: 'arrived',
        width: 90,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Qty Karantina',
        value: 'qty_karantina',
        width: 110,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Status QLT Pending',
        value: 'status_qlt_pending',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Status QLT Reject',
        value: 'status_qlt_reject',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Status QLT Released',
        value: 'status_qlt_released',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: '% Diterima',
        value: 'persentase_diterima',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Action',
        width: 87,
        value: null,
        children: ['-'],
        component: ({}) => (
            <Flex>
                <Box cursor="pointer">
                    <EditIcon />
                </Box>
            </Flex>
        ),
    },
];

const setProgresStatus = () => {
    const arr = ['On Delivery', 'Done', 'In Preparation', 'Received'];
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
};

const setStatusOrder = () => {
    const names = ['ON TRACK', 'OVER DUE'];
    const randomIndex = Math.floor(Math.random() * names.length);
    return names[randomIndex];
};

export const initialValueFormTransition = {
    old_item_code: '',
    old_trade_name: '',
    new_item_code: '',
    new_trade_name: '',
    status: '',
};

export const dummyData = {
    no_order: 'O000006',
    no_po: 'PO000123',
    status_order: setStatusOrder(),
    kode_item: 'GODE-TWC5,4B-SM-P',
    nama_item: 'Plug Crystallure SR Rich Cream 50 g Wardah',
    create_date: '07/02/2023',
    konfirmasi_eta: '07/02/2023',
    Progres_status: setProgresStatus(),
    konfirmasi: '10,000',
    in_preparation: '10,000',
    on_delivery: '10,000',
    received: '10,000',
    arrival_date: '07/02/2023',
    qty_karantina: 999.999,
    status_qlt_pending: 999.999,
    status_qlt_reject: 999.999,
    status_qlt_released: 999.999,
    diterima: 999.999,
};

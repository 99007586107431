import Axios from '@services/axios';
import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie';

export const onChangeFormData = ({ type, setFormData, value, formData }) => {
    const obj = { ...formData };
    obj[type] = value;

    const totals =
        (Number(obj['timbang_leadtime']) || 0) +
        (Number(obj['premix_leadtime']) || 0) +
        (Number(obj['olah_1_leadtime']) || 0) +
        (Number(obj['olah_2_leadtime']) || 0) +
        (Number(obj['kemas_leadtime']) || 0) +
        (Number(obj['lain_lain_leadtime']) || 0);

    setFormData({ ...obj, total_leadtime: totals });
};

export const countTotal = ({
    timbang_leadtime,
    premix_leadtime,
    olah_1_leadtime,
    olah_2_leadtime,
    kemas_leadtime,
    lain_lain_leadtime,
    setFormData,
}) => {
    let total =
        +timbang_leadtime +
        +premix_leadtime +
        +olah_1_leadtime +
        +olah_2_leadtime +
        +kemas_leadtime +
        +lain_lain_leadtime;

    setFormData((prevState) => ({
        ...prevState,
        total_leadtime: total,
    }));
};

export const getMpq = async ({
    toast,
    id,
    jenisStorage,
    setFormData,
    includeCO,
    setIdxAccordion,
    onSetShowAccordion,
    setSuccessSelectStorage,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'POST',
            url: `/sku/product/mpq/${id}?jenisStorage=${jenisStorage}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: {},
        });

        const entries = data?.data?.productMpq;

        if (entries?.warning?.length) {
            setSuccessSelectStorage(false);
            setIdxAccordion(null);
            toast({
                position: 'top-center',
                description: entries?.warning[0],
                status: 'error',
                duration: 1500,
            });
            return;
        }
        setFormData((prevState) => ({
            ...prevState,
            mpq_kemas: entries.mpq_kemas,
            pcs_per_koli: entries.pcs_per_koli,
            koli: entries.koli,
            koli_per_storage: entries.pcs_per_storage,
            storage_ndc: entries.storage_ndc,
        }));

        if (includeCO) {
            const datas = data?.data?.productMpq.customerOrder;
            setFormData((prevState) => ({
                ...prevState,
                storage_co: datas.storage,
                ndc_co: datas.koli.toFixed(3),
            }));
        }
        setSuccessSelectStorage(true);
        onSetShowAccordion();
    } catch (err) {
        console.log(err);
        setIdxAccordion(null);
        setSuccessSelectStorage(false);
        toast({
            position: 'top-center',
            description: err?.response?.data?.errors[0].message,
            status: 'error',
            duration: 1500,
        });
    }
};

export const getOddoCode = async ({
    codeItem,
    toast,
    setFormData,
    // setButtonIsShow,
    setAccordionIsShow,
    setIsGenerateLoading,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        if (!codeItem.length) {
            return toast({
                position: 'top-center',
                description: 'Oddo Code cannot be empty',
                status: 'error',
                duration: 1500,
            });
        }
        setIsGenerateLoading(true);
        // getOddoCode
        const { data } = await Axios({
            method: 'GET',
            url: `/sku/product/template/${codeItem}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        // getStockNdc
        const ress = await Axios({
            method: 'GET',
            url: `/sku/product/stock/ndc/${codeItem}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        data.data?.product.forEach((item) => {
            Object.keys(item).forEach((key) => {
                setFormData((prevState) => ({
                    ...prevState,
                    [key]: item[key],
                    kode_oddo: item.default_code,
                }));
            });
        });

        getLeadTime({ oddoCode: data?.data?.product[0].default_code, setFormData });

        let entries = ress?.data?.data?.product;

        for (let i in entries) {
            setFormData((prevState) => ({
                ...prevState,
                [i]: entries[i],
            }));
        }

        // setButtonIsShow(true);
        setAccordionIsShow(false);
    } catch (err) {
        // setButtonIsShow(false);
        setAccordionIsShow(true);

        console.log(err);
        if (err?.response?.data.errorStatus) {
            toast({
                position: 'top-center',
                description: err?.response?.data?.errors[0].message,
                status: 'error',
                duration: 5000,
            });
        }
    } finally {
        setIsGenerateLoading(false);
    }
};

export const getLeadTime = async ({ setFormData, oddoCode }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const { data } = await Axios({
            method: 'GET',
            url: `/sku/product/leadtime/${oddoCode}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        setFormData((prevState) => ({
            ...prevState,
            timbang_leadtime: data?.data?.leadTime?.WEIGHING,
            premix_leadtime: data?.data?.leadTime?.MIXING,
        }));
    } catch (err) {
        console.log(err);
        return;
    }
};

export const getStreamline = async ({ setStreamline }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: '/streamline/list?sort=name,asc',
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const list = data.data?.streamline.map((item) => {
            return {
                name: item.name,
                id: item.id,
            };
        });
        const sortedList = list.sort((a, b) => a.name.toLowerCase().trim().localeCompare(b.name.toLowerCase().trim()));
        setStreamline(sortedList);
    } catch (err) {
        console.log(err);
        return;
    }
};

export const getProductMpqCO = async ({ data, toast, setIsSumPCSCoLoading, setFormData }) => {
    const { odooCode, includeCO = true, jenisStorage, pcs } = data;
    const numberCheck = /^\d+$/;

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    if (!numberCheck.test(pcs)) {
        toast({
            position: 'top-center',
            description: 'PCS CO must be number',
            status: 'error',
            duration: 1500,
        });
        return;
    }

    setIsSumPCSCoLoading(true);
    try {
        const { data } = await Axios({
            method: 'POST',
            url: `/sku/product/mpq/${odooCode}?includeCO=${includeCO}&jenisStorage=${jenisStorage}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: { pcs: Number(pcs) },
        });

        await toast({
            position: 'center-top',
            description: `Update PCS CO Success`,
            status: 'success',
            duration: 1500,
        });

        setFormData((prevState) => ({
            ...prevState,
            storage_co: data.data.productMpq?.customerOrder?.storage,
            koli_co: data.data.productMpq?.customerOrder?.koli,
            pcs_co: data.data.productMpq?.customerOrder?.pcs,
        }));
    } catch (e) {
        console.log(e);
        if (e?.response?.data.errorStatus) {
            toast({
                position: 'top-center',

                description: e?.response?.data?.errors[0].message,
                status: 'error',
                duration: 5000,
            });
        }
    } finally {
        setIsSumPCSCoLoading(false);
    }
};

export const getProductMpqCP = async ({ data, toast, setIsSumPCSCPLoading, setFormData, idx, keyName }) => {
    const { odooCode, jenisStorage, safetyPcs, ropPcs, maxPcs, payload } = data;
    const numberCheck = /^\d+$/;

    if (!numberCheck.test(safetyPcs) && !numberCheck.test(ropPcs) && !numberCheck.test(maxPcs)) {
        toast({
            position: 'top-center',

            description: 'PCS must be number',
            status: 'error',
            duration: 1500,
        });
        return;
    }

    setIsSumPCSCPLoading({
        idx,
        isLoading: true,
    });
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const { data } = await Axios({
            method: 'POST',
            url: `/sku/product/mpq/${odooCode}?includeCP=true&includeLeadtime=true&jenisStorage=${jenisStorage}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payload,
        });

        await toast({
            position: 'center-top',
            description: `Update critical point Success`,
            status: 'success',
            duration: 1500,
        });

        const nData = {};

        if (keyName === 'Safety') {
            nData.cp_safety_pcs = data.data.productMpq.criticalPoint.safety.pcs;
            nData.cp_safety_koli = data.data.productMpq.criticalPoint.safety.koli;
            nData.cp_safety_strg = data.data.productMpq.criticalPoint.safety.strgNdc;
        }

        if (keyName === 'Reorder Point') {
            nData.cp_rop_pcs = data.data.productMpq.criticalPoint.rop.pcs;
            nData.cp_rop_koli = data.data.productMpq.criticalPoint.rop.koli;
            nData.cp_rop_strg = data.data.productMpq.criticalPoint.rop.strgNdc;
        }

        if (keyName === 'Maximum') {
            nData.cp_max_pcs = data.data.productMpq.criticalPoint.maximum.pcs;
            nData.cp_max_koli = data.data.productMpq.criticalPoint.maximum.koli;
            nData.cp_max_strg = data.data.productMpq.criticalPoint.maximum.strgNdc;
        }
        setFormData((prevState) => ({
            ...prevState,
            ...nData,
            mpq_kemasan: data.data.productMpq.mpq_kemas,
            koli: data.data.productMpq.koli,
            koli_per_storage: data.data.productMpq.koli_per_storage,
            storage_ndc: data.data.productMpq.storage_ndc,
        }));
    } catch (e) {
        console.log(e);
        if (e?.response?.data.errorStatus) {
            toast({
                position: 'top-center',

                description: e?.response?.data?.errors[0].message,
                status: 'error',
                duration: 5000,
            });
        }
    } finally {
        setIsSumPCSCPLoading({
            idx,
            isLoading: false,
        });
    }
};

export const getStreamlineOne = async ({ id }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/streamline/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        return data.data.streamline;
    } catch (err) {
        console.log(err);
    }
};

const formatInt = (value) => {
    return parseInt(value);
};

const onValidation = ({ formData }) => {
    const {
        timbang_leadtime,
        premix_leadtime,
        olah_1_leadtime,
        olah_2_leadtime,
        kemas_leadtime,
        kode_sl,
        cp_safety_pcs,
        cp_rop_pcs,
        cp_max_pcs,
        kode_item,
    } = formData;

    return (
        !isEmpty(kode_item) &&
        !isEmpty(timbang_leadtime) &&
        !isEmpty(premix_leadtime) &&
        !isEmpty(olah_1_leadtime) &&
        !isEmpty(olah_2_leadtime) &&
        !isEmpty(kemas_leadtime) &&
        !isEmpty(kode_sl) &&
        !isEmpty(String(cp_safety_pcs)) &&
        !isEmpty(String(cp_rop_pcs)) &&
        !isEmpty(String(cp_max_pcs))
    );
};

const onDataFormatted = ({ formData, isEditModal }) => {
    const {
        kode_item,
        kode_sl,
        stockNdc,
        jenis_storage_ndc,
        streamline,
        pcs_co,
        timbang_leadtime,
        premix_leadtime,
        olah_1_leadtime,
        olah_2_leadtime,
        kemas_leadtime,
        lain_lain_leadtime,
        cp_safety_pcs,
        cp_rop_pcs,
        cp_max_pcs,
        stok_nasional,
    } = formData;

    const obj = {
        sku: {
            kode_item: kode_item,
            kode_sl: kode_sl?.split('-')[0],
            stock_ndc: formatInt(stockNdc),
            jenis_storage_ndc: jenis_storage_ndc,
            streamline_id: streamline.id,
            standar_stock_nasional: formatInt(stok_nasional) || 0,
        },
        customerOrder: {
            pcs: formatInt(pcs_co),
        },
        leadtime: {
            timbang: Number(timbang_leadtime),
            premix: Number(premix_leadtime),
            olah_1: Number(olah_1_leadtime),
            olah_2: Number(olah_2_leadtime),
            kemas: Number(kemas_leadtime),
            others: Number(lain_lain_leadtime),
        },
        criticalPoint: {
            s_pcs: formatInt(cp_safety_pcs),
            rop_pcs: formatInt(cp_rop_pcs),
            max_pcs: formatInt(cp_max_pcs),
        },
    };

    if (!obj.leadtime.others) {
        delete obj.leadtime.others;
    }

    if (isEditModal) {
        delete obj.sku.kode_item;
        delete obj.sku.kode_sl;
        delete obj.sku.stock_ndc;
        // delete obj.sku.standar_stock_nasional;
    }

    return {
        ...obj,
    };
};

export const onSubmitForm = async ({
    selectedSku,
    isEditModal,
    formData,
    toast,
    setIsSubmitLoading,
    reFecthSkuHandler,
    setIsSubmitted,
    onResetForm,
    setIsSuccessSubmit,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    setIsSubmitted(true);
    const isValid = onValidation({ formData });
    if (!isValid) {
        toast({
            position: 'top-center',
            description: 'Field tidak boleh kosong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
        return;
    }

    setIsSubmitLoading(true);

    const url = isEditModal ? `/management/sku/${selectedSku.id}` : `/management/sku`;
    const method = isEditModal ? 'PATCH' : 'POST';

    const dataFormatted = onDataFormatted({ selectedStreamline: selectedSku, formData, isEditModal });
    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: dataFormatted,
        });

        await reFecthSkuHandler();

        onResetForm();
        setIsSuccessSubmit(true);
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0];
        const messageError = error?.response?.data?.errors[0]?.message;
        const err = messageError || message || 'Something wrong!';
        toast({
            position: 'top-center',
            description: err,
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setIsSubmitLoading(false);
    }
};

export const mappedSelectedSku = async ({ setFormData, selectedSku }) => {
    const total_leadtime = Object.values({
        timbang: selectedSku.leadtime?.timbang,
        premix: selectedSku.leadtime?.premix,
        olah_1: selectedSku.leadtime?.olah_1,
        olah_2: selectedSku.leadtime?.olah_2,
        kemas: selectedSku.leadtime?.kemas,
        others: selectedSku.leadtime?.others,
    }).reduce((acc, curr) => {
        return acc + curr;
    }, 0);


    setFormData({
        id: selectedSku.id,
        kode_oddo: selectedSku.odoo_code,
        kode_sl: selectedSku.kode_sl,
        kode_item: selectedSku.kode_item,
        name: selectedSku.nama_item,
        stockNdc: String(selectedSku?.stock_ndc),
        jenis_storage_ndc: String(selectedSku?.jenis_storage_ndc),
        mpq_kemas: String(selectedSku.mpq_kemas?.pcs),
        pcs_per_koli: String(selectedSku.mpq_kemas?.pcs_per_koli),
        stok_nasional: selectedSku?.stock_nasional?.standar_stock_nasional,
        koli: String(selectedSku.mpq_kemas?.koli),
        koli_per_storage: String(selectedSku.mpq_kemas?.pcs_per_storage),
        storage_ndc: String(selectedSku.mpq_kemas?.storage_ndc),
        pcs_co: String(selectedSku.customer_order?.pcs),
        storage_co: String(selectedSku.customer_order?.storage),
        koli_co: String(selectedSku.customer_order?.koli),
        ndc_co: String(selectedSku.customer_order?.koli),
        timbang_leadtime: String(selectedSku.leadtime?.timbang),
        premix_leadtime: String(selectedSku.leadtime?.premix),
        olah_1_leadtime: String(selectedSku.leadtime?.olah_1),
        olah_2_leadtime: String(selectedSku.leadtime?.olah_2),
        kemas_leadtime: String(selectedSku.leadtime?.kemas),
        lain_lain_leadtime: String(selectedSku.leadtime?.others || ''),
        total_leadtime: String(total_leadtime),
        cp_safety_pcs: String(selectedSku.critical_point.safety?.pcs),
        cp_safety_koli: String(selectedSku.critical_point.safety?.koli),
        cp_safety_strg: String(selectedSku.critical_point.safety?.strgNdc),
        cp_rop_pcs: String(selectedSku.critical_point.rop?.pcs),
        cp_rop_koli: String(selectedSku.critical_point.rop?.koli),
        cp_rop_strg: String(selectedSku.critical_point.rop?.strgNdc),
        cp_max_pcs: String(selectedSku.critical_point.maximum?.pcs),
        cp_max_koli: String(selectedSku.critical_point.maximum?.koli),
        cp_max_strg: String(selectedSku.critical_point.maximum?.strgNdc),
        streamline: {
            id: selectedSku.streamline.id,
            name: selectedSku.streamline.name,
            zonaId: selectedSku.streamline.zonaId,
        },
    });
};

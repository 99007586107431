import Axios from '@services/axios';
import { uniqBy, isArray, forEach, remove, isEmpty, find } from 'lodash';
import Cookies from 'universal-cookie';

export const onChangeFormData = ({ type, setFormData, value, formData, isChoosingMaterial }) => {
    const obj = { ...formData };
    obj[type] = value;

    if (isArray(obj[type])) {
        obj[type] = uniqBy(value, 'id');
    }
    if (type === 'role' && !isChoosingMaterial()) {
        obj.materials = [];
    }

    setFormData({ ...obj });
};

export const removeSelectedMaterial = ({ setFormData, formData, id }) => {
    let arrTemp = [...formData.materials];
    arrTemp = remove(arrTemp, (material) => material.id !== id);
    setFormData({ ...formData, materials: arrTemp });
};

const onMappingObject = ({ selectedUser, data }) => {
    let obj = {};
    forEach(data, (value, key) => {
        const isNotEqual = value !== selectedUser[key];
        if (isNotEqual) {
            obj = { ...obj, [key]: value };
        }
    });

    return obj;
};

export const onValidation = ({ data, typeform }) => {
    if (typeform?.includes('normal')) {
        const { email, role, name, jenis_material, materials } = data;
        return name && email && !isEmpty(role) && !isEmpty(jenis_material) && !isEmpty(materials);
    } else {
        const { email, role, name, jenis_material, supplier_name, materials } = data;
        return (
            name &&
            email &&
            !isEmpty(role) &&
            !isEmpty(jenis_material) &&
            !isEmpty(supplier_name) &&
            !isEmpty(materials)
        );
    }
};

const getMaterialId = ({ materials }) => {
    return materials?.map((item) => item.id);
};

const onDataFormatted = ({ data, isEditModal, selectedUser }) => {
    let obj = { ...data, is_ldap: true };

    if (isEditModal) {
        obj = onMappingObject({ selectedUser, data });
    }

    obj.sediaan_id = getMaterialId({ materials: data?.materials });

    obj = { ...obj, role_id: Number(data?.role?.id) };

    delete obj.materials;
    delete obj.role;

    return obj;
};

const checkEmailDomain = (formData, typeform) => {
    const blockEmail = ['pti-cosmetics.com', 'paramaglobalinspira.com'];
    if (typeform === 'normal') {
        const parts = formData?.email.split('@');
        return Boolean(blockEmail.find((item) => item === parts?.[1]));
    } else {
        return true;
    }
};

const onEmailValidation = ({ formData }) => {
    const regexValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email);
    return regexValidation && formData?.email !== 'pti-cosmetics.com' && formData?.email !== 'paramaglobalinspira';
};

export const validationForm = ({
    formData,
    toast,
    setIsSubmitted,
    isChoosingMaterial,
    materials,
    typeform,
    setModalConfirm,
}) => {
    const cookies = new Cookies();
    setIsSubmitted(true);
    let data = { ...formData };

    if (!isChoosingMaterial()) {
        data.materials = materials;
    }

    const isValid = onValidation({ data, typeform });
    const isEmailValid = onEmailValidation({ formData });
    const onCheckBlockEmail = checkEmailDomain(formData, typeform);
    if (!isValid) {
        toast({
            position: 'top-center',
            description: 'Field tidak boleh kosong!',
            status: 'error',
            duration: 2500,
            isClosable: true,
        });
        return;
    }

    if (!isEmailValid) {
        toast({
            position: 'top-center',
            description: 'Email yang anda masukkan, tidak valid',
            status: 'error',
            duration: 2500,
            isClosable: true,
        });
        return;
    }

    if (!onCheckBlockEmail) {
        toast({
            position: 'top-center',
            description: 'Email yang anda masukkan, tidak valid',
            status: 'error',
            duration: 2500,
            isClosable: true,
        });
        return;
    }
    setModalConfirm({ open: true, typeform });
};

export const onSubmitForm = async ({
    isEditModal,
    formData,
    setIsSubmitLoading,
    reFetchUserHandler,
    onResetForm,
    isChoosingMaterial,
    setIsSuccessSubmit,
    toast,
    setModalConfirm,
    typeform,
}) => {
    setIsSubmitLoading(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const body = {
        name: formData?.name?.toUpperCase(),
        role_id: formData?.role?.id,
        email: formData?.email,
        jenis_material_id: formData?.jenis_material?.id,
        password: formData?.password,
        sediaan_id: [1, 2, 3, 4],
        is_ldap: false,
    };
    if (!Boolean(formData?.password)) {
        body['is_ldap'] = true;
    }
    if (isChoosingMaterial()) {
        body['sediaan_id'] = formData?.materials?.map((item) => item.id);
    }
    if (typeform?.includes('supplier')) {
        body['supplier_ref'] = formData?.supplier_ref;
    }

    await Axios({
        method: isEditModal ? 'PATCH' : 'POST',
        url: isEditModal ? `management/user/${formData?.id}` : 'management/user',
        headers: { Authorization: 'Bearer ' + userToken },
        data: body,
    })
        .then(async () => {
            setIsSuccessSubmit(true);
            setModalConfirm((prev) => ({ ...prev, open: false }));
            onResetForm();
            await reFetchUserHandler();
        })
        .catch((error) => {
            console.log(error);
            setModalConfirm((prev) => ({ ...prev, open: false }));
            toast({
                position: 'top-center',
                description:
                    error?.response?.data?.errors?.[0]?.message || error?.response?.data?.errors?.[0] || 'Failed',
                status: 'error',
                duration: 4500,
                isClosable: true,
            });
            setIsSubmitLoading(false);
        })
        .finally(() => {
            setModalConfirm((prev) => ({ ...prev, open: false }));
            setIsSubmitLoading(false);
        });
};

export const mappedSelectedUser = ({ setFormData, selectedUser, roles }) => {
    const { name, email, role_id, sediaans } = selectedUser;
    setFormData({
        name,
        email,
        role: find(roles, { id: role_id }),
        materials: sediaans,
    });
};

export const handleSelectedRole = ({ setRoleSelected, value, setFormData, onResetForm, formData }) => {
    onResetForm();
    setRoleSelected(value);

    setFormData((prev) => {
        if (value?.role === 'Admin' || value?.role === 'Superior' || value?.role === 'Super Admin') {
            return { ...prev, role: value, jenis_material: { id: 3, name: 'General' }, id: formData?.id };
        }
        return { ...prev, role: value, id: formData?.id };
    });
};

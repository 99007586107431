import { Flex, Box, Text, IconButton, Tooltip } from '@chakra-ui/react';
import { tableList } from '@pagesPhase2/supplierStock/const';
import { SortHeaderIcon } from '@pagesPhase2/supplierStock/icons';
import './styles.scss';

const TableHead = () => {
    return (
        <Flex className="table-head__container__supplier_stock">
            {tableList.map((head, idx, it) => {
                if (head.children) {
                    return (
                        <Box className="table-head__item" flexDirection="column" width={head.width} key={head.name}>
                            <Flex className="table-head__parent" w="fit-content" pl="6px">
                                {head.name}
                                {head.sortValue && (
                                    <Tooltip hasArrow className="text-tooltip-label" label={head.label}>
                                        <IconButton zIndex={1} minW={3} h={4} pr={0} icon={<SortHeaderIcon />} />
                                    </Tooltip>
                                )}
                            </Flex>
                            <Flex justify="space-between" w="100%">
                                {head.children.map((child, index) => {
                                    return (
                                        <Box key={index} className="table-head__child-container" bg={'red'}>
                                            <Box className="table-head__child">
                                                <Text>{child}</Text>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Flex>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}
        </Flex>
    );
};

export default TableHead;

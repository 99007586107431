import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { wordFlick } from './functions';
import { IconInfo } from './icons';

import './styles.scss';

const SectionTop = ({ isError }) => {
    const [text, setText] = useState('');
    useEffect(() => {
        wordFlick({ setText });
    }, []);
    return (
        <section className="section-top__container__login-page">
            <Text className="title" id="text">
                {text}
            </Text>
            <Text className="text">Please enter your login information</Text>
            {isError && (
                <Box className="error-warn__container">
                    <IconInfo />
                    <Text className="error-text">Incorrect email or password</Text>
                </Box>
            )}
        </section>
    );
};

export default SectionTop;

import { Flex } from '@chakra-ui/layout';
import { getStreamlineColor } from '@pages/mainDashboard/section/sectionProductionStatus/functions';
import './styles.scss';

const StreamlineHead = ({ streamline }) => (
    <Flex className="streamline-head__order-container" bg={getStreamlineColor({ name: streamline })}>
        {streamline.name}
    </Flex>
)

export default StreamlineHead;

import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { FilterIcon } from './icons';
import ModalMultiple from './modal';
import {
    handleClickMenu,
    handleCloseModal,
    findBadgeSelectedFilter,
    handleApplyFilter,
    handleClearFilter,
} from './function';
import { initialValue } from './const';
import { MaterialConfigurationContext } from '@pagesPhase2/materialConfiguration';

import './styles.scss';
import { isEmpty } from 'lodash';

function Index() {
    const {
        setMultipleFilter,
        setPage: setMainPage,
        setStoreMultipleFilterTemp,
        storeMultipleFilterTemp,
    } = React.useContext(MaterialConfigurationContext);
    const [openModal, setOpenModal] = React.useState(false);
    const [formData, setFormData] = React.useState(initialValue);
    const [filterSelected, setFilterSelected] = React.useState([]);
    const handleResetFilter = () => {
        setFormData(initialValue);
    };
    const handleChangeExtraInput = ({ extra, input, form, field }) => {
        handleClickMenu({ setFormData, item: { extra, input }, selected: { form, field } });
    };
    const badgeFilterSelected = findBadgeSelectedFilter(filterSelected);

    React.useEffect(() => {
        if (!isEmpty(storeMultipleFilterTemp)) {
            setFormData(storeMultipleFilterTemp?.formData);
            setFilterSelected(storeMultipleFilterTemp?.filterSelected);
        }
    }, []);
    return (
        <Box className="multiple-filter-material-config">
            <ModalMultiple
                onClick={({ item, selected }) => handleClickMenu({ setFormData, item, selected })}
                outlineLabel="Reset Filter"
                onClose={() => handleCloseModal({ formData, filterSelected, setFormData, initialValue, setOpenModal })}
                isOpen={openModal}
                handleApplyFilter={() =>
                    handleApplyFilter({
                        setOpenModal,
                        formData,
                        setFilterSelected,
                        setMultipleFilter,
                        setMainPage,
                        setStoreMultipleFilterTemp,
                    })
                }
                handleClearFilter={({ form }) => handleClearFilter({ setFormData, form, setFormData })}
                value={formData}
                handleResetFilter={handleResetFilter}
                handleChangeExtraInput={handleChangeExtraInput}
            />
            <Box
                onClick={() => setOpenModal(true)}
                className={`multiple-icon-filter ${badgeFilterSelected?.length > 0 && 'has-filter-multiple-selected'}`}
            >
                <FilterIcon color={badgeFilterSelected?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                {badgeFilterSelected?.length > 0 && (
                    <Text className="btn-filter-multiple-badge">{badgeFilterSelected?.length}</Text>
                )}
            </Box>
        </Box>
    );
}

export default Index;

export const formattedOrderPlanning = ({ orderPlanning, setOrders }) => {
    const kardOrders = orderPlanning?.kardOrders;
    const result = [];
    if (kardOrders.length) {
        kardOrders?.forEach((kard, index) => {
            let order =
                index === 0
                    ? { ...orderPlanning, status: kard?.status, kardOrder: { ...kard }, index }
                    : { status: kard?.status, index, kardOrder: { ...kard } };

            //--------------------- ADD MPQ ------------------------------------------------

            order = {
                ...order,
                mpq_kemas: kard?.orderPlanning?.sku?.mpq_kemas,
            };
            result.push(order);
        });
    } else {
        result.push({ ...orderPlanning, status: 'OK', index: 0 });
    }

    setOrders(result);
};

export const columnBgHandler = ({ status }) => {
    switch (status) {
        case 'QUEUE':
            return '#FFFFE9';
        case 'INBOUND':
            return '#FFFFE9';
        case 'PENDING':
            return '#FFFFE9';
        case 'DONE':
            return 'white';
        case 'IN PROGRESS':
            return '#F0FAF4';
        default:
            return 'white';
    }
};

import { isNumber } from 'lodash';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onChangeInput = ({ type, setFormData, value, formData }) => {
    const obj = { ...formData };
    obj[type] = value;

    setFormData({ ...obj });
};

const onValidation = ({ batchBooking }) => {
    return isNumber(batchBooking);
};

export const onSubmitForm = async ({
    toast,
    setIsSubmitted,
    setIsSubmitLoading,
    onResetForm,
    setIsSuccessSubmit,
    id,
    batchBooking,
    reFetchOrderPlanningHandler,
}) => {
    try {
        setIsSubmitted(true);
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        const userId = cookies.get('userId');

        const isValid = onValidation({ batchBooking });

        if (!isValid) {
            toast({
                position: 'top-center',
                description: 'Field tidak boleh kosong!',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setIsSubmitLoading(true);

        await Axios({
            method: 'PATCH',
            url: `/order-planning/${id}`,
            headers: { Authorization: 'Bearer' + userToken },
            data: {
                batch_booking: Number(batchBooking),
                user_id: Number(userId),
            },
        });

        reFetchOrderPlanningHandler();

        setIsSuccessSubmit(true);
        setIsSubmitLoading(false);
        onResetForm();
    } catch (error) {
        console.log(error);
    }
};

import Axios from '@services/axios';
import { find, forEach, isEmpty } from 'lodash';
import Cookies from 'universal-cookie';

export const onChangeFormData = ({ type, setFormData, value, formData }) => {
    const obj = { ...formData };
    obj[type] = value;
    setFormData({ ...obj });
};

const onMappingObject = ({ selectedAsset, data }) => {
    let obj = {};
    forEach(data, (value, key) => {
        const isNotEqual = value !== selectedAsset[key];
        if (isNotEqual) {
            obj = { ...obj, [key]: value };
        }
    });

    return obj;
};

const onValidation = ({ formData, selectedView, nameAsset = [], isEditModal }) => {
    if (isEditModal) {
        return !isEmpty(formData.name);
    }
    if (selectedView !== 'zona') {
        return !isEmpty(formData.zone);
    }
    if (formData.name) {
        return isEmpty(formData.name);
    }
    return !isEmpty(nameAsset);
};

const onDataFormatting = ({ formData, selectedView, isEditModal, selectedAsset, nameAsset }) => {
    let data = { ...formData, names: nameAsset };

    if (selectedView === 'zona') {
        data = {
            ...data,
            sediaan_id: data?.material?.id,
        };

        delete data.material;
    } else {
        data = { ...data, zona_id: data?.zone?.id };
    }

    if (isEditModal) {
        data = onMappingObject({ selectedAsset, data });
        delete data.names;
    }

    if (!isEditModal) {
        delete data.name;
    }

    delete data.zone;

    return data;
};

export const onSubmitForm = async ({
    selectedAsset,
    isEditModal,
    formData,
    toast,
    onClose,
    setIsSubmitLoading,
    reFetchAssetHandler,
    setIsSubmitted,
    onResetForm,
    selectedView,
    selectedMaterial,
    setIsSuccessSubmit,
    nameAsset,
}) => {
    setIsSubmitted(true);
    const isValid = onValidation({ formData, selectedView, nameAsset, isEditModal });
    if (!isValid) {
        toast({
            position: 'top-center',
            description: 'Field tidak boleh kosong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
        return;
    }

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    setIsSubmitLoading(true);

    const data = onDataFormatting({
        formData,
        isEditModal,
        selectedView,
        selectedAsset,
        selectedMaterial,
        nameAsset,
    });
    const url = isEditModal ? `/${selectedView}/${selectedAsset.id}` : `/${selectedView}`;
    const method = isEditModal ? 'PATCH' : 'POST';

    try {
        await Axios({
            method,
            url,
            headers: { Authorization: 'Bearer ' + userToken },
            data,
        });

        await reFetchAssetHandler();

        onResetForm();

        setIsSuccessSubmit(true);
        onClose();
    } catch (error) {
        console.log(error);
        const message = error?.response?.data?.errors[0]?.message;
        toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        setIsSubmitLoading(false);
    }
};

const onMappingZone = ({ zona_id, zoneList }) => {
    return find(zoneList, { id: zona_id });
};

const onMappingMaterial = ({ sediaan_id, materials }) => {
    return find(materials, { id: sediaan_id });
};

export const mappedSelectedAsset = ({ setFormData, selectedAsset, zoneList, materials }) => {
    const { name, zona_id, sediaan_id } = selectedAsset;
    setFormData({
        name,
        zone: onMappingZone({ zona_id, zoneList }),
        material: onMappingMaterial({ materials, sediaan_id }),
    });
};

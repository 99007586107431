import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onDownloadTemplateUpload = async ({ setIsLoading, toast }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: '/order-planning/template/batch-booking',
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'template_upload_order_planning';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        await toast({
            position: 'center-top',
            description: 'Berhasil Download Template',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    } catch (error) {
        const message = error?.response?.data?.errors?.length ? error?.response?.data?.errors[0]?.message : null;
        console.log(error);
        toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setIsLoading(false);
    }
};

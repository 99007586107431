import { createContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@chakra-ui/react';
import SectionTab from './section/sectionTab';
import SectionProductionStatus from './section/sectionProductionStatus';
import SectionListOrderStatus from './section/SectionListOrderStatus';
// import AutoRefreshNotification from './components/AutoRefreshNotification';
import { getStreamline, redirectPathByAppType } from './functions';
import { tabMenuOrderStatus } from './section/sectionTab/const';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import './styles.scss';

export const MainDashboardContext = createContext({});

const MainDashboard = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const appType = cookies.get('appType');
    const { selectedMaterial } = useSelector((reducer) => reducer.materialSlice);

    const [isOpenListStreamLine, setIsOpenListStreamLine] = useState(false);
    const [isLoadingStreamline, setIsLoadingStreamline] = useState(false);

    const [searchStreamline, setSearchStreamline] = useState('');
    const [selectedFilterStreamline, setSelectedFilterStreamline] = useState([]);

    const [selectedTab, setSelectedTab] = useState('');
    const [streamline, setStreamline] = useState([]);
    const [filterOrderStatus, setFilterOrderStatus] = useState(tabMenuOrderStatus);
    const fetchData = () => {
        getStreamline({
            setStreamlines: setStreamline,
            setIsLoading: setIsLoadingStreamline,
            sediaanId: selectedMaterial?.id,
        });
    };
    useEffect(() => {
        if (selectedMaterial?.id) {
            fetchData();
        }
    }, [selectedMaterial]);
    useEffect(() => {
        if (appType) {
            redirectPathByAppType({ navigate });
        }
    }, [appType]);
    return (
        <MainDashboardContext.Provider
            value={{
                setSelectedFilterStreamline,
                selectedFilterStreamline,
                searchStreamline,
                setSearchStreamline,
                selectedTab,
                isOpenListStreamLine,
                setIsOpenListStreamLine,
                filterOrderStatus,
                setFilterOrderStatus,
                fetchData,
            }}
        >
            {/* <AutoRefreshNotification /> */}
            <Box className="main-dashboard__container" id="main-dashboard__container">
                <SectionTab
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    streamline={streamline}
                    isLoading={isLoadingStreamline}
                />
                {tabMenuOrderStatus.map((value, index) => {
                    if (value.value === selectedTab) {
                        return (
                            <SectionListOrderStatus
                                key={index}
                                filterOrderStatus={filterOrderStatus}
                                streamlines={streamline}
                                selectedMaterial={selectedMaterial}
                                selectedFilterStreamline={selectedFilterStreamline}
                                searchStreamline={searchStreamline}
                            />
                        );
                    }
                })}
                {selectedTab === 'production-status' && (
                    <SectionProductionStatus
                        selectedFilterStreamline={selectedFilterStreamline}
                        searchStreamline={searchStreamline}
                    />
                )}
            </Box>
        </MainDashboardContext.Provider>
    );
};

export default MainDashboard;

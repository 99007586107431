import { Box, Flex } from '@chakra-ui/react';
import TableHead from '@pagesPhase2/demandAnticipation/components/tableHead';
import TableColumn from '@pagesPhase2/demandAnticipation/components/tableColumn';
import './styles.scss';

const SectionTable = ({ items, onOpenModal, isEdit, numbers }) => {
    return (
        <>
            <Flex
                className={`section-table-demand_anticipation ${
                    items.length <= 0 && 'hide-scrollbar-section-table__container__table-demand_anticipation'
                }`}
            >
                <Box className="section-table">
                    <TableHead isEdit={isEdit} />
                    <Box className="columns__container" bg="white">
                        {items.map((item, index) => (
                            <TableColumn
                                demandPerWeek={item.demandPerWeek}
                                demandPerMonth={item?.demandPerMonth}
                                key={index}
                                dataItem={item}
                                isEdit={isEdit}
                                onOpenModal={onOpenModal}
                                numbers={numbers}
                                index={index}
                            />
                        ))}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;

import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Box, Text, Flex, Button } from '@chakra-ui/react';
import CloseIcon from '@pages/mainDashboard/components/AutoRefreshNotification/Icon';
import { initialForm, operatorList } from './const';
import FieldMenu from '@components/fieldMenu';
import { ArrowUpIconBlack, ResetIcon } from '@asset/icons';
import useFetch from 'hooks/useFetch';
import { IconMinusDisabled } from './icons';

import './styles.scss';

function Index({
    handleApplyFilter,
    onClick,
    value,
    outlineLabel,
    handleResetFilter,
    onClose,
    isOpen,
    handleChangeExtraInput,
    handleClearFilter,
}) {
    const { data } = useFetch({
        api: `material/filter?attributes=transition_code&sort=transition_code asc&limit=10&page=1&q=`,
    });
    const dataTransition = () => {
        return data?.list
            ?.filter((item) => Boolean(item?.transition_code))
            ?.map((item) => ({ name: item?.transition_code }));
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Box className="modal-container-filter-multiple-material-config">
                <ModalOverlay />
                <ModalContent maxW="433px">
                    <Flex className="modal-filter-multiple-header">
                        <Text className="modal-header-text">Filter</Text>
                        <Box onClick={onClose} cursor="pointer">
                            <CloseIcon />
                        </Box>
                    </Flex>
                    <ModalBody className="modal-body">
                        <Box>
                            {initialForm.map((form, index) => {
                                const inputHelper = form.option.filter((item) => item.option);
                                const isHasExtraInput = Boolean(inputHelper?.length > 0);
                                if (form.apiOption) {
                                    return (
                                        <Box key={index} className="container-input">
                                            <Box className="fieldMenu-container-single">
                                                <Text className="label">{form.title}</Text>
                                                <FieldMenu
                                                    onClick={(selected) => onClick({ item: form, selected })}
                                                    buttonName={
                                                        <span>
                                                            <span>
                                                                {value[form.name] ? (
                                                                    <span className="selected-role-text">
                                                                        {value[form.name]}
                                                                    </span>
                                                                ) : (
                                                                    <span className="FieldMenu-placeholder">
                                                                        {form.placeholder}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </span>
                                                    }
                                                    menuList={dataTransition()}
                                                    keyName="name"
                                                    menuClassName="menu-container__user-management"
                                                    menuButtonClassName="menu-button"
                                                    menuItemClassNam="menu-item"
                                                    stylePropsMenuButtonClassName={{
                                                        width: form.width,
                                                    }}
                                                    menuListClassName="menuList-container"
                                                    icon={<ArrowUpIconBlack />}
                                                />
                                            </Box>
                                            {Boolean(value[form.name]) && (
                                                <Button
                                                    onClick={() => handleClearFilter({ form })}
                                                    variant="ghost"
                                                    className="clear-global-filter-jenis-material"
                                                >
                                                    <ResetIcon color="#1746a2" />
                                                </Button>
                                            )}
                                        </Box>
                                    );
                                }
                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                        }}
                                        className="container-input"
                                    >
                                        <Box className="fieldMenu-container-single">
                                            <Text className="label">{form.title}</Text>
                                            <FieldMenu
                                                onClick={(selected) => onClick({ item: form, selected })}
                                                buttonName={
                                                    <span>
                                                        <span>
                                                            {value[form.name] ? (
                                                                <span className="selected-role-text">
                                                                    {value[form.name]}
                                                                </span>
                                                            ) : (
                                                                <span className="FieldMenu-placeholder">
                                                                    {form.placeholder}
                                                                </span>
                                                            )}
                                                        </span>
                                                    </span>
                                                }
                                                menuList={form.option}
                                                keyName="name"
                                                menuClassName="menu-container__user-management"
                                                menuButtonClassName="menu-button"
                                                menuItemClassNam="menu-item"
                                                stylePropsMenuButtonClassName={{
                                                    width: form.width,
                                                }}
                                                menuListClassName="menuList-container"
                                                icon={<ArrowUpIconBlack />}
                                            />
                                        </Box>
                                        {Boolean(value[form.name]) && !isHasExtraInput && (
                                            <Button
                                                onClick={() => handleClearFilter({ form })}
                                                variant="ghost"
                                                className="clear-global-filter-jenis-material"
                                            >
                                                <ResetIcon color="#1746a2" />
                                            </Button>
                                        )}

                                        {isHasExtraInput &&
                                        Boolean(operatorList.find((item) => item === value?.[form.name])) ? (
                                            inputHelper
                                                ?.filter((i) => i.name === value?.[form.name])
                                                ?.map((field, fieldIndex) => {
                                                    return (
                                                        <Flex key={fieldIndex} alignItems="center" gap="5px">
                                                            {field.option?.map((extra, extraIndex) => {
                                                                if (extra.type !== 'input') {
                                                                    return <Box key={extraIndex}>{extra?.type}</Box>;
                                                                }
                                                                return (
                                                                    <input
                                                                        type="number"
                                                                        disabled={extra?.disabled}
                                                                        key={extraIndex}
                                                                        value={
                                                                            Number(
                                                                                value?.[form?.name + '_extra']?.[
                                                                                    extra?.name
                                                                                ]
                                                                            ).toString() || ''
                                                                        }
                                                                        onChange={(input) =>
                                                                            handleChangeExtraInput({
                                                                                input: input.target.value,
                                                                                extra,
                                                                                form,
                                                                                field,
                                                                            })
                                                                        }
                                                                        className={`extra-input ${
                                                                            extra?.disabled && 'extra-input-disabled'
                                                                        }`}
                                                                    />
                                                                );
                                                            })}
                                                            <Box ml="7px">
                                                                <Button
                                                                    onClick={() => handleClearFilter({ form })}
                                                                    variant="ghost"
                                                                    className="clear-global-filter-jenis-material"
                                                                >
                                                                    <ResetIcon color="#1746a2" />
                                                                </Button>
                                                            </Box>
                                                        </Flex>
                                                    );
                                                })
                                        ) : (
                                            <Box>
                                                {isHasExtraInput && (
                                                    <Flex alignItems="center" gap="5px">
                                                        <input disabled className="extra-input-disabled" />
                                                        <IconMinusDisabled />
                                                        <input disabled className="extra-input-disabled" />
                                                    </Flex>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    </ModalBody>

                    <ModalFooter className="ModalFooter">
                        <Box className="btn-modal-close" onClick={handleResetFilter}>
                            <Text className="text-modal-close">{outlineLabel}</Text>
                        </Box>
                        <Box className={`btn-apply-filter`} onClick={handleApplyFilter}>
                            <Text className="text-apply-filter">Apply Filter</Text>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;

import { isEmpty, upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, Button, Spinner } from '@chakra-ui/react';
import ModalTemplate from '@components/modalTemplate';

import { tableList, tableListDelayReporting } from './const';

import CreateReportProblemModal from '../createReportProblemModal';
import ModalSolvedProblem from '../modalStopProblem';
import TableHead from '../tableHeadDetailProdcutStatus';
import TableColumn from '../tableColumnDetailProductStatus';
import EmptyProblem from '../emptyProblem';
import CardStatus from '../cardStatus';
import {
    getLastStatusHandler,
    getTotalLeadTimeHandler,
    getTotalActualTimeHandler,
    getAllHistoriesHandler,
    fetchDetailProductStatusHandler,
    getProblemReporting,
    filteringListNomoAndSnfgComponent,
} from './function';
import ListItems from '../listItem';
import { CloseIcon } from './icons';

import './styles.scss';
import { useSelector } from 'react-redux';

const DetailProductStatus = ({ isOpenDetail, onClose, selectedDataProductStatus, refetchProgessStatus }) => {
    const { accessPermission } = useSelector((reducer) => reducer.accessPermissionSlice);

    const [isOpenReport, setOpenReport] = useState(false);
    const [isOpenStopProblem, setOpenStopProblem] = useState(false);
    const [seeMoreNomo, setSeeMoreNomo] = useState(false);
    const [seeMoreSNFGComponent, setSeeMoreSNFGComponent] = useState(false);
    const [detailKards, setDetailKards] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStopProblem, setSelectedStopProblem] = useState();

    const togglerSeeMoreNomo = () => setSeeMoreNomo(!seeMoreNomo);
    const togglerSeeMoreSNFGComponent = () => setSeeMoreSNFGComponent(!seeMoreSNFGComponent);

    const refetchProductStatusHandler = () => {
        refetchProgessStatus();
        fetchDetailProductStatusHandler({ kard: selectedDataProductStatus?.kard, setDetailKards, setIsLoading });
    };

    useEffect(() => {
        if (!isEmpty(selectedDataProductStatus)) {
            fetchDetailProductStatusHandler({ kard: selectedDataProductStatus?.kard, setDetailKards, setIsLoading });
        }
    }, [selectedDataProductStatus]);

    return (
        <ModalTemplate
            isOpen={isOpenDetail}
            width={'80%'}
            onClose={onClose}
            modalContentCustomStyle={{
                height: '85vh',
                marginTop: 0,
                alignSelf: 'center',
                overflow: 'auto',
                justifySelf: 'center',
                maxWidth: '1540px',
            }}
        >
            <Flex className="section-head-detail-card__container">
                <Text className="head-title__container">{selectedDataProductStatus.name}</Text>
                <Box cursor="pointer" onClick={onClose}>
                    <CloseIcon />
                </Box>
            </Flex>

            <Box className="detail-prodcut-status__container">
                {isLoading && (
                    <Box className="loading-state">
                        <Spinner />
                    </Box>
                )}
                {!isLoading && !isEmpty(detailKards) && (
                    <>
                        {/* DETAIL 1 */}
                        <Box className="card__container">
                            <Box mb="32px">
                                <Text className="text-head__streamline">
                                    {upperCase(detailKards?.sku?.streamline?.name) || '-'}
                                </Text>
                                <Text className="text-head" pt={'23px'} maxW="547px">
                                    {detailKards?.sku?.nama_item || '-'}
                                </Text>
                            </Box>
                            <Flex
                                justify="space-between"
                                pb="42px"
                                borderBottom="1px solid #D7D7D7"
                                boxSizing="border-box"
                                mb="42px"
                            >
                                <Box>
                                    <ListItems show title="ODOO" value={detailKards?.sku?.odoo_code || '-'} />
                                    <Flex>
                                        <Box className="nomos-container">
                                            {filteringListNomoAndSnfgComponent({ kards: detailKards?.kards })
                                                ?.length ? (
                                                filteringListNomoAndSnfgComponent({ kards: detailKards?.kards }).map(
                                                    ({ nomo }, idx) => {
                                                        return (
                                                            <ListItems
                                                                show={seeMoreNomo || idx === 0}
                                                                title={idx === 0 ? 'NOMO' : ' '}
                                                                value={nomo || '-'}
                                                            />
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <ListItems show title={'NOMO'} value={'-'} />
                                            )}
                                        </Box>
                                        {filteringListNomoAndSnfgComponent({ kards: detailKards?.kards })?.length >
                                            0 && (
                                            <Text className="see-more" onClick={togglerSeeMoreNomo}>
                                                {seeMoreNomo ? 'Tutup' : 'Lihat lebih'}
                                            </Text>
                                        )}
                                    </Flex>
                                    <ListItems show title="SNFG" value={detailKards?.snfg || '-'} />

                                    <Flex>
                                        <Box className="nomos-container">
                                            {filteringListNomoAndSnfgComponent({ kards: detailKards?.kards })
                                                ?.length ? (
                                                filteringListNomoAndSnfgComponent({ kards: detailKards?.kards }).map(
                                                    ({ snfg_komponen }, idx) => {
                                                        return (
                                                            <ListItems
                                                                show={seeMoreSNFGComponent || idx === 0}
                                                                title={idx === 0 ? 'SNFG Komponen' : ' '}
                                                                value={snfg_komponen || '-'}
                                                            />
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <ListItems show title={'SNFG Komponen'} value={'-'} />
                                            )}
                                        </Box>
                                        {filteringListNomoAndSnfgComponent({ kards: detailKards?.kards })?.length >
                                            0 && (
                                            <Text className="see-more" onClick={togglerSeeMoreSNFGComponent}>
                                                {seeMoreSNFGComponent ? 'Tutup' : 'Lihat lebih'}
                                            </Text>
                                        )}
                                    </Flex>
                                </Box>

                                <Box>
                                    <Text className="text-head__status">Status</Text>
                                    <Box display={'flex'} gap="38px">
                                        <CardStatus
                                            title="Current Status"
                                            value={getLastStatusHandler({ histories: detailKards?.histories })}
                                            bg="#B5EEA1"
                                        />
                                        <CardStatus
                                            title="Expected Total Lead Time"
                                            value={getTotalLeadTimeHandler({
                                                total: detailKards?.total_expected_duration,
                                            })}
                                            bg="#7086B0"
                                            color="white"
                                        />
                                        <CardStatus
                                            title="Actual Total LeadTime"
                                            value={getTotalActualTimeHandler({ histories: detailKards?.histories })}
                                            bg="#EBF4F4"
                                        />
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>

                        {getProblemReporting({ kendalas: detailKards?.kendalas })?.length === 0 && (
                            <EmptyProblem
                                onClick={() => setOpenReport(true)}
                                isDisabled={!accessPermission?.mainDashboard?.isCanInputKendala}
                            />
                        )}

                        {/* DETAIL 3 */}
                        {getProblemReporting({ kendalas: detailKards?.kendalas })?.length > 0 && (
                            <Box className="card__container">
                                <Flex justifyContent={'space-between'}>
                                    <Text className="text-head__delay">Delay Reporting</Text>
                                    <Flex experimental_spaceX={5}>
                                        <Button
                                            isDisabled={!accessPermission?.mainDashboard?.isCanInputKendala}
                                            className="report-problem__button"
                                            onClick={() => setOpenReport(true)}
                                        >
                                            Report Problem
                                        </Button>
                                    </Flex>
                                </Flex>
                                <TableHead tableList={tableListDelayReporting} isDetail />
                                <Box className="card-table__container">
                                    {getProblemReporting({ kendalas: detailKards?.kendalas })?.map((data, idx) => (
                                        <TableColumn
                                            key={data?.id}
                                            idx={idx}
                                            tableList={tableListDelayReporting}
                                            obj={data}
                                            setOpenStopProblem={setOpenStopProblem}
                                            setSelectedStopProblem={setSelectedStopProblem}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}

                        {/* DETAIL 2 */}
                        <Box className="card__container">
                            <Text className="text-head">Historical Proses</Text>
                            <TableHead tableList={tableList} isDetail />
                            <Box className="card-table__container">
                                {getAllHistoriesHandler({ histories: detailKards?.histories, detailKards })?.map(
                                    (data, idx) => (
                                        <TableColumn key={data?.id} idx={idx} tableList={tableList} obj={data} />
                                    )
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>

            <CreateReportProblemModal
                isOpen={isOpenReport}
                onClose={() => setOpenReport(false)}
                selectedDataProductStatus={selectedDataProductStatus}
                refetchProductStatusHandler={refetchProductStatusHandler}
            />
            <ModalSolvedProblem
                refetchProductStatusHandler={refetchProductStatusHandler}
                isOpen={isOpenStopProblem}
                onClose={() => setOpenStopProblem(false)}
                selectedStopProblem={selectedStopProblem}
            />
        </ModalTemplate>
    );
};

export default DetailProductStatus;

import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const RealTimeClock = () => {
    const location = useLocation();
    const [date, setDate] = useState(new Date());

    const getTimeHandler = () => setDate(new Date());

    useEffect(() => {
        const timer = setInterval(() => getTimeHandler(), 1000);
        return () => clearInterval(timer);
    }, []);
    return <Text className="time-text__head-info" style={location.pathname === '/lobby' ? { color: '#223647' } : { color: 'none' }}>{date.toLocaleTimeString('en-GB')}</Text>;
};

export default RealTimeClock;

import { Flex, Box } from '@chakra-ui/react';
import { tableListOrderConfirmation } from '../../const';
import './styles.scss';

const TableHead = () => {
    return (
        <Flex className="table-head__container__order_confirmation">
            {tableListOrderConfirmation.map((head) => {
                if (head.children) {
                    return (
                        <Box className="table-head__item" flexDirection="column" width={head.width} key={head.name}>
                            <Flex className="table-head__parent" w="fit-content" pl="6px">
                                {head.name}
                            </Flex>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}
        </Flex>
    );
};

export default TableHead;

import Axios from '@services/axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchMaterialItemSetHandler = async ({
    page,
    setMaterialItem,
    setTotalData,
    setTotalPage,
    setIsLoading,
    selectedPerPage,
    searchQuery,
    jenisMaterial,
    supplier,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    let supplierFilter = supplier?.join(';');
    const filterStringfy = queryString.stringify(
        {
            q: searchQuery,
            page,
            limit: selectedPerPage,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material-item-set/?${filterStringfy}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { materialItemSets, totalData, totalPage } = data.data;
        setTotalData(totalData);
        setTotalPage(totalPage);
        setMaterialItem(materialItemSets);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setMaterialItem([]);
    } finally {
        setIsLoading(false);
    }
};



export const onDeletedAccount = async ({
    setIsLoading,
    selectedItem,
    reFetchMaterialItemSetHandler,
    togglerDeleteModal,
    toast,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        setIsLoading(true);

        await Axios({
            method: 'DELETE',
            url: `/material-item-set/${selectedItem.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        await reFetchMaterialItemSetHandler();
        await toast({
            position: 'center-top',
            description: 'delete material item set success',
            status: 'success',
            duration: 1500,
            isClosable: true,
        });
    } catch (error) {
        console.log(error);
        const errMessage = error.response?.data?.message || 'An error occurred while deleting';
        toast({
            position: 'top-center',
            description: errMessage,
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
        togglerDeleteModal();
        setIsLoading(false);
    }
};

export const onValidation = ({ data, typeform }) => {
    if (typeform?.includes('supplier')) {
        const { supplier_name, supplier_ref } = data;
        return supplier_name && supplier_ref && !isEmpty(supplier_name) && !isEmpty(supplier_ref);
    } else {
        const { supplier_name, supplier_ref } = data;
        return supplier_name && supplier_ref && !isEmpty(supplier_name) && !isEmpty(supplier_ref);
    }
};

export const validationForm = ({ formData, toast, setIsSubmitted, typeform, setModalConfirm }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsSubmitted(true);
    let data = { ...formData };

    const isValid = onValidation({ data, typeform });
    if (!isValid) {
        toast({
            position: 'top-center',
            description: 'Field tidak boleh kosong!',
            status: 'error',
            duration: 2500,
            isClosable: true,
        });
        return;
    }

    setModalConfirm({ open: true, typeform });
};

export const onFormDataEdit = ({ item, setFormData, listMaterial, formData }) => {
    setFormData((prev) => ({
        ...prev,
        supplier_name: item?.['supplier.name'],
        supplier_ref: item?.supplier_ref,
        main_component_kode_item: listMaterial[0]?.kode_item,
        main_component_internal_name: listMaterial[0]?.internal_name,
        main_component_id: null,
        field_set_components: [
            {
                id_dummy: Math.floor(Math.random() * 10000) + 1,
                set_component_internal_name: listMaterial[1]?.trade_name,
                set_component_kode_item: listMaterial[1]?.kode_item,
                set_component_id: null,
            },
        ],
    }));
};
import { Tr, Th } from '@chakra-ui/react';

import './styles.scss';

function Index({ tableHead }) {
    return (
        <Tr className="recap-order-head-table-container">
            {tableHead?.map((head, index) => {
                /// KONDISI JIKA TABLE YANG TIDAK FREEZE (STICKY)
                const isCellNotFreeze = !head.is_freeze && index === 6 ? '13px' : '0px';
                return (
                    <Th
                        className="recap-order-head-table"
                        sx={{
                            position: head.is_freeze && 'sticky',
                            [head.align || 'left']: head.is_freeze && head.freezePosition,
                            zIndex: head.is_freeze && 1,
                            textAlign: head.is_action ? 'center' : 'left',
                            minWidth: head.cellWidth || '200px',
                            pl: index === 0 ? '12px' : isCellNotFreeze,
                            borderRight: head.isRightBorder && '1px solid  #cecece',
                        }}
                        key={index}
                    >
                        {head.name}
                    </Th>
                );
            })}
        </Tr>
    );
}

export default Index;

import { Box, Image, Text } from "@chakra-ui/react";
import logo from './page-error-icon.svg';
import './styles.scss';

const PageError = () => {
  return (
      <Box className="page-error__container">
          <Box className="icon">
              <Image src={logo}/>
          </Box>
          <Text className="title">Page Error!</Text>
          <Text className="desc">Saat ini page sedang dalam perbaikan untuk performa yang lebih baik</Text>
      </Box>
  );
};

export default PageError;
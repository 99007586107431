import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const ListItems = ({ title = '', value = '', show }) => {
    return (
        <Box className="wrapper__container" display={show ? 'flex' : 'none'}>
            <Text className="wrapper-item" w="117px" mr="16px">
                {title}
            </Text>
            <Text className="wrapper-item" opacity={title === ' ' ? 0 : 1}>
                :
            </Text>
            <Text className="wrapper-item" ml="16px">
                {value}
            </Text>
        </Box>
    );
};

export default ListItems;

import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export async function getUserProfile({ setData, setIsloading }) {
    const cookies = new Cookies();
    try {
        const { data } = await Axios({
            method: 'GET',
            url: '/user/profile',
            headers: { Authorization: 'Bearer ' + cookies.get('userToken') },
        });
        setData(data?.data?.user);
        setIsloading(false);
    } catch (error) {
        console.log(error);
    }
}

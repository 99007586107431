import { Flex, Text, Box, Tooltip } from '@chakra-ui/react';

const Available = ({ available }) => {
    let availableText = ``;
    return (
        <Flex overflow="hidden">
            {available?.length > 0 && (
                <Text className="available-text" mr="4px">
                    Available in
                </Text>
            )}

            {available?.map(({ name }, idx) => {
                const nameString = name + (idx !== available?.length - 1 ? ', ' : '');
                availableText += nameString;

                if (idx < 3) {
                    return (
                        <Text className="available-text" mr="4px" whiteSpace="nowrap">
                            {name} {idx !== available?.length - 1 && ','}
                        </Text>
                    );
                } else {
                    return <></>;
                }
            })}

            {available?.length > 3 && (
                <Tooltip label={`Available in ${availableText}`}>
                    <Box className="box-available" cursor="pointer">
                        <Text>{available?.length - 3}+</Text>
                    </Box>
                </Tooltip>
            )}
        </Flex>
    );
};

export default Available;

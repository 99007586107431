import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import './styles.scss';

const StreamlineCard = ({ idx, value, streamlineColor }) => {
    const [height, setHeight] = useState(152);

    useEffect(() => {
        const el = document.getElementById(`table-column__container__main-dashboard_${idx}`);
        //------------------------SET HEIGHT AS MAIN PARENT -----------------------------------------
        if (el) {
            setHeight(el.clientHeight);
        }
    }, [idx]);

    return (
        <Box className="streamline-box__container" height={height} bg={streamlineColor}>
            <Text className="streamline-text">{value}</Text>
        </Box>
    );
};

export default StreamlineCard;

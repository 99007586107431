import { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Box, Text, Flex, Checkbox } from '@chakra-ui/react';
import CloseIcon from '@pages/mainDashboard/components/AutoRefreshNotification/Icon';
import { SearchIcon, CloseIcon as CloseIconBlue } from './icons';
import { handleCheckboxChange, fetchUserInfinite } from './function';
import InfiniteScroll from 'react-infinite-scroll-component';
import './styles.scss';

function Index({ isOpen, onClose, user, setUser, handleApplyFilter, handleResetFilter }) {
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [search, setSearch] = useState('');

    useEffect(() => {
        fetchUserInfinite({ page, setUser, user, setPage, setTotalData, search });
    }, []);

    const getMoreData = () => {
        fetchUserInfinite({ page, setUser, user, setPage, setTotalData, moreData: true, search });
    };

    const onSearchHandler = () => {
        fetchUserInfinite({ page: 1, setUser, user, setPage, setTotalData, search });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Box className="modal-container-filter-name">
                <ModalOverlay />
                <ModalContent maxW="544px">
                    <Flex className="modal-filter-name-header">
                        <Text className="modal-header-text">Filter by name</Text>

                        <Box onClick={onClose} cursor="pointer">
                            <CloseIcon />
                        </Box>
                    </Flex>
                    <ModalBody className="ModalBody">
                        <Text className="label-input">Nama yang dipilih</Text>
                        <Box className="box-selected-user">
                            {/* stackoverflow start */}
                            {/* https://stackoverflow.com/a/45014215 */}
                            {/* issue : https://stackoverflow.com/questions/45014094/how-do-i-fix-expected-to-return-a-value-at-the-end-of-arrow-function-warning */}
                            {/* answer by Kris Selbekk : https://stackoverflow.com/users/1064572/kris-selbekk */}
                            {user
                                ?.filter((item) => item.checked)
                                ?.map((item, index) => {
                                    return (
                                        <Flex key={index} className="box-card-selected-user">
                                            <Text className="text-selected-user">{item.name}</Text>
                                            <Box
                                                cursor="pointer"
                                                onClick={() =>
                                                    handleCheckboxChange({
                                                        checked: false,
                                                        selectedUser: item,
                                                        user,
                                                        setUser,
                                                    })
                                                }
                                            >
                                                <CloseIconBlue />
                                            </Box>
                                        </Flex>
                                    );
                                })}
                            {/* stackoverflow end */}
                        </Box>
                        <Text className="label-input">Pilih nama</Text>

                        <Box position="relative" mt="16px">
                            <input
                                className="input-box"
                                placeholder="cari nama item"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchHandler();
                                    }
                                }}
                            />
                            <Box className="search-icon" onClick={onSearchHandler}>
                                <SearchIcon />
                            </Box>
                        </Box>
                        <Text className="label-list-user-text">List nama</Text>
                        <Box className="container-list-user">
                            <div id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={user.length}
                                    next={getMoreData}
                                    hasMore={user.length === totalData ? false : true}
                                    loader={<Text fontSize="12px">Loading...</Text>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {user.map((item, index) => {
                                        return (
                                            <Flex key={index} justifyContent="space-between" pb="10px">
                                                <Text className="list-user-text">{item?.name || 'User'}</Text>
                                                <Checkbox
                                                    isChecked={item.checked}
                                                    onChange={(checked) =>
                                                        handleCheckboxChange({
                                                            checked: checked.target.checked,
                                                            selectedUser: item,
                                                            user,
                                                            setUser,
                                                        })
                                                    }
                                                    className="checkbox-custom-style"
                                                />
                                            </Flex>
                                        );
                                    })}
                                </InfiniteScroll>
                            </div>
                        </Box>
                    </ModalBody>

                    <ModalFooter className="ModalFooter">
                        <Box className="btn-modal-close" onClick={handleResetFilter}>
                            <Text className="text-modal-close">Reset Filter</Text>
                        </Box>
                        <Box className="btn-apply-filter" onClick={handleApplyFilter}>
                            <Text className="text-apply-filter">Apply Filter</Text>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;

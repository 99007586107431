import { Flex, Box } from '@chakra-ui/react';
import React from 'react';
import { SearchIcon } from '@pages/mainDashboard/icon';
import { CloseIcon } from '@pages/mainDashboard/components/detailProductStatus/icons';
import './styles.scss';

function Index({ setSearchStreamline, isOpen, setIsOpen }) {
    const [searchStreamlineTemp, setSearchStreamlineTemp] = React.useState('');
    const onSearchHandler = () => {
        setSearchStreamline(searchStreamlineTemp);
        setIsOpen(!isOpen);
    };
    const onKeyEnter = ({ key }) => {
        if (key === 'Enter') {
            setSearchStreamline(searchStreamlineTemp);
        }
    };
    return (
        <Flex>
            <input
                className={isOpen ? 'input-search-streamline-open' : 'input-search-streamline-close'}
                placeholder="Cari SKU...."
                onChange={(e) => {
                    setSearchStreamlineTemp(e.target.value);
                }}
                onKeyDown={(e) => onKeyEnter(e)}
            />
            <Box className="section-search" ml={isOpen ? 0 : '8px'} onClick={onSearchHandler}>
                <Box className="section-search-icon">{isOpen ? <CloseIcon /> : <SearchIcon />}</Box>
            </Box>
        </Flex>
    );
}

export default Index;

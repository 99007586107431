import { IconMinus, IconMinusDisabled } from './icons';

export const operatorList = ['Between', 'Equal', 'More Than', 'Less Than', 'More Than Equal', 'Less Than Equal'];
export const initialForm = [
    {
        type: 'single',
        width: '335px',
        name: 'status',
        title: 'Status',
        option: [
            {
                name: 'Flow Through',
            },
            {
                name: 'Immediately',
            },
            {
                name: 'Interchangable',
            },
        ],
        placeholder: 'pilih status',
    },
    {
        type: 'single',
        width: '335px',
        name: 'transition',
        title: 'Transition',
        apiOption: true,
        option: [],
        placeholder: 'pilih kode transition',
    },
    {
        type: 'single',
        width: '170px',
        name: 'lt_Produksi',
        title: 'LT Produksi',
        option: [
            {
                name: 'Between',
                operator: '-',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinus />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                    },
                ],
            },
            {
                name: 'Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
        ],
        placeholder: 'Pilih satuan',
    },
    {
        type: 'single',
        width: '170px',
        name: 'index_mpq_vs_baseline',
        title: 'Index MPQ vs Baseline',
        option: [
            {
                name: 'Between',
                operator: '-',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinus />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                    },
                ],
            },
            {
                name: 'Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
        ],
        placeholder: 'Pilih satuan',
    },
    {
        type: 'single',
        width: '170px',
        name: 'index_mpq_s_vs_mpq_pti',
        title: 'Index MPQ S vs MPQ PTI',
        option: [
            {
                name: 'Between',
                operator: '-',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinus />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                    },
                ],
            },
            {
                name: 'Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
        ],
        placeholder: 'Pilih satuan',
    },
    {
        type: 'single',
        width: '170px',
        name: 'index_maks_vs_baseline',
        title: 'Index Maks vs Baseline',
        option: [
            {
                name: 'Between',
                operator: '-',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinus />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                    },
                ],
            },
            {
                name: 'Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than Equal',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
        ],
        placeholder: 'Pilih satuan',
    },
];

import { Box } from '@chakra-ui/react';
import './styles.scss';

const ScreenTemplate = ({ children, isOpen, sx }) => {
    if (isOpen) {
        return (
            <Box sx={{ ...sx }} className="screen-template-v2__container">
                {children}
            </Box>
        );
    } else {
        return <></>;
    }
};

export default ScreenTemplate;

import { Box, CircularProgress, Text } from '@chakra-ui/react';
import CustomButton from '@components/button';
import './styles.scss';

const UplaodLoader = () => (
    <Box className="upload-loader__container__orderplanning">
        <Box className="box">
            <CircularProgress size="70px" isIndeterminate color="#1746a2" />
            <Text className="text-loading">Uploading File.....</Text>
            <CustomButton btnClassName="cancel-button" variant="outline">
                Cancel
            </CustomButton>
        </Box>
    </Box>
);

export default UplaodLoader;

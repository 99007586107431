import { useContext } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { tableListOrderDelivery } from '../../const';
import { tableOrderDeliveryContext } from '../..';
import './styles.scss';

const TableColumn = ({ orderData, index, orderDelivery }) => {
    const { openModalEdit, onChangeStateEdit } = useContext(tableOrderDeliveryContext)
    return (
        <Flex className="table-column table-column__container__order_delivery">
            {tableListOrderDelivery.map((item, idx) => {
                return (
                    <Box
                        background={'white'}
                        key={item.name}
                        w={item.width}
                        flex={item.flex}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: orderData[item.value] !== null ? orderData[item.value] : '-',
                            orderData,
                            openModalEdit,
                            index,
                            orderDelivery,
                            onChangeStateEdit
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

import { OrderManagementIcon } from '@asset/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import CodeItemSearch from './codeItem';
import TradeNameSearch from './tradeName';
import './styles.scss';

const SectionHead = () => {
    return (
        <div>
            <Flex className="order-confirmation-box-header">
                <Flex gap="10px" alignItems="center">
                    <Box>
                        <OrderManagementIcon />
                    </Box>
                    <Text className="text-order-confirmation">Order Confirmation</Text>
                </Flex>
                <Flex className="action-button-container" alignItems="center">
                    <CodeItemSearch />
                    <Box mx="12px">
                        <TradeNameSearch />
                    </Box>
                </Flex>
            </Flex>
        </div>
    );
};

export default SectionHead;

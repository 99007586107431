import React from 'react';
import { useContext } from 'react';
import { OrderManagementIcon } from '@asset/icons';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import CodeItemSearch from './codeItem';
import TradeNameSearch from './tradeName';
import { EditIcon } from '@asset/icons';
import InputDateETA from './inputDateETA';
import { tableOrderDeliveryContext } from '../../index.js';
import './styles.scss';

const SectionHead = () => {
    const { isEdit, setIsEdit } = useContext(tableOrderDeliveryContext);
    return (
        <div>
            <Flex className="order-delivery-box-header">
                <Flex gap="10px" alignItems="center">
                    <Box>
                        <OrderManagementIcon />
                    </Box>
                    <Text className="text-order-delivery">Order Delivery</Text>
                </Flex>
                <Flex className="action-button-container" alignItems="center">
                    {!isEdit && <CodeItemSearch />}
                    {!isEdit && (
                        <Box mx="12px">
                            <TradeNameSearch />
                        </Box>
                    )}
                    {!isEdit && (
                          <Box>
                          <InputDateETA />
                      </Box>
                    )}
                    {!isEdit && <Box className="divider" />}
                    {isEdit ? (
                        <Button className="button-edit" onClick={() => setIsEdit(false)}>
                            cancel
                        </Button>
                    ) : (
                        <Button className="button-edit" onClick={() => setIsEdit(true)}>
                            <Box mr="5px">
                                <EditIcon />
                            </Box>
                            edit
                        </Button>
                    )}
                </Flex>
            </Flex>
        </div>
    );
};

export default SectionHead;

import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import dateFormat from 'dateformat';

export const onFormatDate = (value) => {
    const date = new Date(value);
    return dateFormat(date, 'yyyy-mm-dd');
};

export const fetchDataOrderDelivery = async ({
    setOrderDelivery,
    selectedPerPage,
    setIsLoading,
    setTotalData,
    setTotalPage,
    jenisMaterial,
    supplier,
    filter,
    page,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const { itemCode, trade_name, inputDate } = filter;
    setIsLoading(true);

    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            page,
            limit: 10,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
            eta_konfirmasi: inputDate,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/raw-data-order?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { rawDataOrder, totalData, totalPage } = data.data;
        setOrderDelivery(rawDataOrder);
        setTotalData(totalData);
        setTotalPage(totalPage);
    } catch (error) {
        console.log(error);
        setOrderDelivery([]);
        setTotalData(0);
        setTotalPage(0);
    } finally {
        setIsLoading(false);
    }
};

import { Box, Flex } from '@chakra-ui/react';

import './styles.scss';

const TableHeadDetailProductStatus = ({ tableList }) => (
    <Flex className="table-head__container__detail-product-status">
        {tableList.map((head) => (
            <Box width={'full'} className="table-head__item" id={`table-head__item-${head.value}`} key={head.name}>
                {head.name}
            </Box>
        ))}
    </Flex>
);

export default TableHeadDetailProductStatus;

import { Flex, Box, Text } from '@chakra-ui/react';
import { tableListRekapDelivery } from '../../const';
import './styles.scss';

const TableHead = () => {
    return (
        <Flex className="table-head__container__rekap-delivery">
            {tableListRekapDelivery.map((head) => {
                if (head.children) {
                    return (
                        <Box className="table-head__item" flexDirection="column" width={head.width} key={head.name}>
                            <Flex className="table-head__parent" w="fit-content" pl="6px">
                                {head.name}
                            </Flex>
                            <Flex justify="space-between" w="100%">
                                {head.children.map((child, index) => {
                                    return (
                                        <Box key={index} className="table-head__child-container">
                                            <Box className="table-head__child">
                                                <Text>{child}</Text>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Flex>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}
        </Flex>
    );
};

export default TableHead;

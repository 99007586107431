import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';

export const fetchStreamlineHandler = async ({
    page,
    setStreamlines,
    setTotalData,
    setTotalPage,
    setIsLoading,
    searchQuery,
    filter,
    selectedMaterial,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    setIsLoading(true);

    const { zone } = filter;

    const filterStringify = queryString.stringify(
        { zona_id: zone?.id, q: searchQuery, page, includeLine: true, sediaan_id: selectedMaterial?.id, sort: "name,asc" },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/streamline/list?limit=10&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { totalData, totalPage, streamline } = data.data;
        setTotalPage(totalPage);
        setTotalData(totalData);
        setStreamlines(streamline);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setStreamlines([]);
    } finally {
        setIsLoading(false);
    }
};

export const fetchAllModalDataHandler = async ({ setModalAttribute, selectedMaterial }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        let zoneList = await Axios({
            method: 'GET',
            url: `/zona/list?limit=&page=1&sediaan_id=${selectedMaterial?.id}&sort=name,asc`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let timbangList = await Axios({
            method: 'GET',
            url: `/timbang/list?sort=name,asc`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let olah1List = await Axios({
            method: 'GET',
            url: `/olah1/list?sort=name,asc`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let olah2List = await Axios({
            method: 'GET',
            url: `/olah2/list?sort=name,asc`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let kemasList = await Axios({
            method: 'GET',
            url: `/kemas/list?sort=name,asc`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        zoneList = zoneList.data.data.zona;
        timbangList = timbangList.data.data.timbang;
        olah1List = olah1List.data.data.olah1;
        olah2List = olah2List.data.data.olah2;
        kemasList = kemasList.data.data.kemas;

        setModalAttribute({
            zoneList,
            timbangList,
            olah1List,
            olah2List,
            kemasList,
        });
    } catch (error) {
        console.log(error);
    }
};

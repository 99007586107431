import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import dateFormat from 'dateformat';
import queryString from 'query-string';

export const getWeekNumber = () => {
    const now = new Date();
    const week = dateFormat(now, 'W');
    return week;
};

const orderPlanningFormattedHandler = ({ listSkus }) => {
    // --------------- MAPPING ------------------------------
    const newOrderPlanning = listSkus?.map((item) => {
        return {
            ...item,
            streamline: item?.streamline?.name,
            stock_nasional: {
                standar_stock_nasional: item?.standar_stock_nasional,
                stock_nasional: item?.stock_nasional,
            },
            stock_ndc: {
                stock_ndc: item?.stock_ndc,
                stock_ndc_palet: item?.stock_ndc_palet,
            },
            safety: {
                qty_storage: item?.critical_point?.safety?.strgNdc,
                pcs: item?.critical_point?.safety?.pcs,
            },
            rop: {
                qty_storage: item?.critical_point?.rop?.strgNdc,
                pcs: item?.critical_point?.rop?.pcs,
            },
            maximum: {
                qty_storage: item?.critical_point?.maximum?.strgNdc,
                pcs: item?.critical_point?.maximum?.pcs,
            },
            umur_stock: item?.orderPlannings[0]?.umur_stock,
            order_request: item?.orderPlannings[0]?.request_batch,
            batch_inprogress_order: item?.orderPlannings[0]?.batch_inprogress_order,
            batch_inprogress_prod: item?.orderPlannings[0]?.batch_inprogress_prod,
            done: item?.orderPlannings[0]?.batch_done,
            batch_booking: item?.orderPlannings[0]?.batch_booking,
        };
    });

    return newOrderPlanning;
};

const removeDoneKardHandler = ({ kards }) => {

    return kards?.map((items) => {
        return {
            ...items,
            kardOrders: items?.kardOrders?.filter((kard) => {
                if (kard?.status === "DONE") {
                    return kard?.order_planning_id === items?.orderPlannings[0]?.id
                } else {
                    return kard
                }
            })
        }
    })
}

export const fetchOrderPlanningHandler = async ({
    page,
    sortName,
    setOrderPlanning,
    setTotalData,
    setTotalPage,
    setIsLoading,
    searchQuery,
    selectedMaterial,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    setIsLoading(true);

    const filterStringify = queryString.stringify(
        {
            sort: `${sortName.sort},${sortName.typeBol ? 'asc' : 'desc'}`,
            sediaan_id: selectedMaterial?.id,
            q: searchQuery,
            page,
            limit: 10,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/order-planning/list?${filterStringify}`,

            headers: { Authorization: 'Bearer' + userToken },
        });
        const { listSkus, totalData, totalPage } = data.data;

        const newOrderPlanning = orderPlanningFormattedHandler({ listSkus });
        const kards = removeDoneKardHandler({ kards: newOrderPlanning })
        setTotalData(totalData);
        setTotalPage(totalPage);
        setOrderPlanning(kards);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setOrderPlanning([]);
    } finally {
        setIsLoading(false);
    }
};

export const getColorHandler = ({ status }) => {
    switch (status) {
        case 'QUEUE':
            return '#FDFF97';
        case 'INBOUND':
            return '#FDDDEC';
        case 'PENDING':
            return '#FFCCCC';
        case 'DONE':
            return '#CCE2FF';
        case 'IN PROGRESS':
            return '#D9F2E3';
        default:
            return '#F9DFB9';
    }
};

export const sortSwitch = ({ previousState, currentState }) => {
    if (previousState.sort !== currentState.sortValue) {
        return { sort: currentState.sortValue, typeBol: previousState.typeBol };
    } else {
        return { sort: currentState.sortValue, typeBol: !previousState.typeBol };
    }
};

export const getColorText = ({ status }) => {
    switch (status) {
        case 'QUEUE':
            return '#72741E';
        case 'INBOUND':
            return '#F22B89';
        case 'PENDING':
            return '#CC0019';
        case 'DONE':
            return '#004199';
        case 'IN PROGRESS':
            return '#267346';
        default:
            return '#EA961A';
    }
};

export const getStatus = ({ status }) => {
    switch (status) {
        case 'QUEUE':
            return 'Antrian Order';
        case 'INBOUND':
            return 'Antrian Konfirmasi';
        case 'PENDING':
            return 'Antrian Pending';
        case 'DONE':
            return 'Done';
        case 'IN PROGRESS':
            return 'In Process';
        case 'OK':
            return 'OK';
        default:
            return '';
    }
};

import { Flex, Box, Text, IconButton } from '@chakra-ui/react';
import { tableList } from '../../const';
import { sortSwitch } from '@pages/orderPlanning/function';
import './styles.scss';
import { IconSortHeaderTable } from '@pages/orderPlanning/icons';

const TableHead = ({ setSortName }) => {
    return (
        <Flex className="table-head__container__order-planning">
            {tableList.map((head, idx) => {
                return (
                    <Box
                        id={`table-head__item--${idx}`}
                        className="table-head__item"
                        flexDirection="column"
                        width={`${head.width}px`}
                        key={head.name}
                        borderRight={head.withBorderRight && '1px solid #cecece'}
                    >
                        {head.children ? (
                            <>
                                <Flex className="table-head__parent table-head__parent-multiple-child">
                                    {head.name}
                                </Flex>
                                <Flex justify="space-between" w="100%">
                                    {head.children.map((child) => (
                                        <Box className="table-head__child-container">
                                            <Box className="table-head__child">
                                                <Text>{child}</Text>
                                            </Box>
                                        </Box>
                                    ))}
                                </Flex>
                            </>
                        ) : (
                            <>
                                <Flex className="table-head__parent">
                                    {head.name}
                                    {head.sortValue && (
                                        <IconButton
                                            onClick={() =>
                                                setSortName((previousState) =>
                                                    sortSwitch({ previousState, currentState: head })
                                                )
                                            }
                                            minW={0}
                                            ml={7.75}
                                            h={0}
                                            p={0}
                                            icon={<IconSortHeaderTable />}
                                        />
                                    )}
                                </Flex>
                                <Box className="table-head__child">-</Box>
                            </>
                        )}
                    </Box>
                );
            })}

            <Box className="floating-action-head table-head__item table-head__item__grey-box">
                <Text fontWeight={500}>ACTION</Text>
                <Box className="grey-box">-</Box>
            </Box>
        </Flex>
    );
};

export default TableHead;

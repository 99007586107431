import { Box, Button, Center, Flex, Text, useToast } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { find } from 'lodash';

import ModalTemplate from '@components/modalTemplate';
import { CloseIconSm, IconAddSm, IconCheckSm } from './icons';
import { onSelectedItem, onRemoveItem } from './functions';

import './styles.scss';

const ModalSelected = ({ isOpen, items, onClose, onSubmitSelected }) => {
    const [itemSelected, setItemSelected] = useState([]);
    const toast = useToast();

    useEffect(() => {
        if (items?.currentArr?.length) {
            setItemSelected(items.currentArr);
        }
    }, [items?.currentArr]);

    return (
        <ModalTemplate width={543} isOpen={isOpen} onClose={onClose}>
            <Box className="modal-select-line__container">
                <Flex className="modal-head">{items?.title}</Flex>
                <Box className="modal-body">
                    <Text className="modal-body__label">{items?.labelTop || 'Dipilih'}</Text>
                    <Flex className="modal-body__selected-container">
                        {itemSelected.map((item) => (
                            <Flex className="selected-box" key={item?.id}>
                                <Text className="selected-box__name">{item.name}</Text>
                                <Center
                                    cursor="pointer"
                                    onClick={() => onRemoveItem({ value: item, setItemSelected, itemSelected })}
                                >
                                    <CloseIconSm color={'white'} fontSize={5} />
                                </Center>
                            </Flex>
                        ))}
                    </Flex>
                    <Text className="modal-body__label" mb="14px">
                        {items?.title}
                    </Text>
                    <Flex className="modal-body__choice-container">
                        {items?.arr?.map((item) => (
                            <Flex
                                bg={find(itemSelected, { id: item.id }) ? '#D3DBEB' : '#eaeaea'}
                                className="choice-box"
                                key={item?.id}
                                onClick={() => onSelectedItem({ value: item, setItemSelected, itemSelected, toast })}
                            >
                                <Text
                                    className="selected-box__name"
                                    color={find(itemSelected, { id: item.id }) ? '#1746A2' : '#2d2d2d'}
                                >
                                    {item?.name}
                                </Text>
                                {find(itemSelected, { id: item.id }) ? <IconCheckSm /> : <IconAddSm />}
                            </Flex>
                        ))}
                    </Flex>
                </Box>
                <Box className="modal-footer__container">
                    <Button
                        bg={itemSelected.length < 1 ? '#d1daec' : '#1746A2'}
                        className="button-submit"
                        onClick={() => {
                            onSubmitSelected({ type: items?.type, arr: itemSelected });
                            onClose({});
                            setItemSelected([]);
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        className="button-cancel"
                        onClick={() => {
                            setItemSelected([]);
                            onClose({});
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </ModalTemplate>
    );
};

export default ModalSelected;

import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';

export const fetchDataDeliveryRecap = async ({
    filter,
    page,
    setDeliveryRecap,
    setTotalData,
    setTotalPage,
    setIsLoading,
    selectedPerPage,
    jenisMaterial,
    supplier,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    const { itemCode, trade_name } = filter;

    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier.join(';');
    const filterStringify = queryString.stringify(
        {
            page,
            limit: selectedPerPage,
            supplier_name: supplierFilter,
            jenis_material_id: jenisMaterial.id,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
        },
        { skipEmptyString: true }
    );
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/raw-data-order?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { rawDataOrder, totalData, totalPage } = data.data;
        setDeliveryRecap(rawDataOrder);
        setTotalData(totalData);
        setTotalPage(totalPage);
    } catch (error) {
        console.log(error);
        setDeliveryRecap([]);
        setTotalData(0);
        setTotalPage(0);
    } finally {
        setIsLoading(false);
    }
};

import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const onChangeTabName = (value) => `NAMA ${value}`;

export const fetchAssetsHandler = async ({
    page,
    setAssets,
    setTotalData,
    setTotalPage,
    setIsLoading,
    searchQuery,
    selectedView,
    setZoneList,
    selectedMaterial,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    setIsLoading(true);

    const filterStringify = queryString.stringify(
        { q: searchQuery, page, sediaan_id: selectedMaterial.id, sort: "name,asc" },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/${selectedView}/list?limit=10&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { totalData, totalPage } = data.data;
        setTotalPage(totalPage);
        setTotalData(totalData);
        setAssets(data?.data[selectedView]);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setAssets([]);
    } finally {
        setIsLoading(false);
    }
};

export const fetchZoneListHandler = async ({ setZoneList, selectedMaterial }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/zona/list?sediaan_id=${selectedMaterial.id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { zona } = data.data;
        setZoneList(zona);
    } catch (error) {
        console.log(error);
    }
};

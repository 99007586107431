import { ArchiveBlackIcon, DownloadBlackIcon, EditBlackIcon } from '@asset/icons';
import { isAllowed } from '@helpers/roleAccess';


export const optionMenuDotMC = () => {
    const list = [];

    if (isAllowed({ pageName: 'Material Config', permissionName: 'Edit Material Config' })) {
        list.push({
            name: 'Archived List',
            section: 'archived',
            icon: <ArchiveBlackIcon />,
        });
    }

    if (isAllowed({ pageName: 'Material Config', permissionName: 'Create Item Set' })) {
        list.push({
            name: 'Add Item Set',
            section: 'add-item-set',
            icon: <EditBlackIcon />,
        });
    }

    if (isAllowed({ pageName: 'Material Config', permissionName: 'Upload Material Config' })) {
        list.push({
            name: 'Upload',
            section: 'upload_file',
            icon: <ArchiveBlackIcon />,
        });
    }
    if (isAllowed({ pageName: 'Material Config', permissionName: 'Upload Material Raci' })) {
        list.splice(2, 0, {
            name: 'Upload RACI',
            section: 'upload_raci',
            icon: <ArchiveBlackIcon />,
        });
    }

    if (isAllowed({ pageName: 'Material Config', permissionName: 'Download Material Config' })) {
        list.push({
            name: 'Download Data',
            section: 'main',
            icon: <DownloadBlackIcon />,
        });
    }

    if (isAllowed({ pageName: 'Material Config', permissionName: 'Download Material Raci' })) {
        list.push({
            name: 'Download RACI',
            section: 'main',
            icon: <DownloadBlackIcon />,
        });
    }

    return list;
};

export const optionMenuFilter = [
    {
        name: 'ALL',
    },
    {
        name: 'BEST LABEL, PT',
    },
    {
        name: 'BUMIMULIA INDAH LESTARI, PT',
    },
    {
        name: 'BUMIMULIA INDAH LESTARI, PT',
    },
    {
        name: 'CHUNJING GLASS PRODUCTS CO.LTD',
    },
    {
        name: 'CHUNJING GLASS PRODUCTS CO.LTD',
    },
    {
        name: 'random 1',
    },
    {
        name: 'random 2',
    },
    {
        name: 'random 3',
    },
    {
        name: 'random 4',
    },
    {
        name: 'random 5',
    },
    {
        name: 'random 6',
    },
    {
        name: 'random 7',
    },
];

import { Flex, Box } from '@chakra-ui/react';
import { useContext } from 'react';
import { AssetManagementContext } from '@pages/assetManagement';
import { tableList } from '../../const';
import './styles.scss';
import { useSelector } from 'react-redux';

const TableColumn = ({ idx, asset }) => {
    const { onSelectedAsset, onChangeModalState } = useContext(AssetManagementContext);
    const { accessPermission } = useSelector((reducer) => reducer.accessPermissionSlice);

    return (
        <Flex className="table-column-sticky table-column__container__asset-management">
            {tableList.map((item) => {
                return (
                    <Box
                        borderBottom="1px solid #F3F2F2"
                        background={idx % 2 ? '#FFFF' : 'white'}
                        flex={item.flex}
                        key={item.name}
                        w={item.width}
                        className="column-sticky__item"
                    >
                        {item.component({
                            onChangeModalState,
                            onSelectedAsset,
                            asset,
                            value: asset[item.value] || '-',
                            idx,
                            accessPermission,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

export const formDefaultValue = {
    raw_material_status: '',
    raw_material_note: '',
    raw_packaging_status: '',
    raw_packaging_note: '',
};

export const textModalCreateOrderPlanning = {
    header: 'KETERSEDIAAN MATERIAL',
    title_one: 'Ketersediaan Bahan Baku',
    title_two: 'Ketersediaan Packaging',
};

export const dummyStatus = [
    {
        val: '1',
        label: 'Ready',
    },
    {
        val: '2',
        label: 'Not Ready',
    },
];

export const randomStatus = () => {
    const arr = ['Inbound', 'Pending', 'Queue', 'Done'];
    const randomIndex = Math.floor(Math.random() * arr.length);
    const randomElement = arr[randomIndex];
    return randomElement;
};

export const initialForm = [
    {
        title: 'status',
        option: [
            {
                name: 'All',
                status: '',
            },
            {
                name: 'Produksi',
                status: 'PRODUCTION',
            },
            {
                name: 'Oke',
                status: 'OK',
            },
            {
                name: 'OverStock',
                status: 'OVERSTOCK',
            },
        ]
    }
]
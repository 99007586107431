import { Box, Flex } from '@chakra-ui/react';
import TableHead from '@pagesPhase2/materialConfiguration/components/tableHead';
import TableColumn from '@pagesPhase2/materialConfiguration/components/tableColumn';
import './styles.scss';

const SectionTable = ({
    listMaterial,
    onEditHandler,
    onOpenModal,
    onModalDelete,
    onSelectedValue,
    onClickWarning,
    togglerDeleteMaterialModal,
    setSort,
    sort,
}) => {
    return (
        <>
            <Flex
                className={`section-table-material-configuration ${
                    listMaterial.length <= 0 && 'hide-scrollbar-section-table__container__table-material-configuration'
                }`}
            >
                <Box className="section-table">
                    <TableHead setSort={setSort} sort={sort} />
                    <Box className="columns__container" bg="white">
                        {listMaterial.map((material, index) => {
                            const shortenTradeName = material?.id === 39 && material?.trade_name.slice(0, 94);
                            return (
                                <TableColumn
                                    key={index}
                                    material={material}
                                    shortenTradeName={shortenTradeName}
                                    onEditHandler={() => onEditHandler(material)}
                                    onOpenModal={onOpenModal}
                                    onModalDelete={onModalDelete}
                                    onSelectedValue={onSelectedValue}
                                    onClickWarning={onClickWarning}
                                    togglerDeleteMaterialModal={togglerDeleteMaterialModal}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;

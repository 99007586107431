import React, { useContext } from 'react';
import { Box } from '@chakra-ui/react';
import { FilterIcon } from './icons';
import ModalMultiple from './modal';
import { handleClickMenu } from './function';
import { SupplierStockContext } from '@pagesPhase2/supplierStock';
import { initialValue } from './const';

import './styles.scss';

function ModalFilterStatus() {
    const { onChangeFilter, setPage: setMainPage } = useContext(SupplierStockContext);
    const [openModal, setOpenModal] = React.useState(false);
    const [filterSelected, setFilterSelected] = React.useState(initialValue);

    const handleClickStatus = (selected) => {
        setMainPage(1);
        handleClickMenu({ setFilterSelected, selected });
        onChangeFilter({ key: 'status', value: selected.status });
        setOpenModal(false);
    };

    return (
        <Box className="multiple-filter-supplier-stock">
            <ModalMultiple
                onClick={({ selected }) => handleClickStatus(selected)}
                outlineLabel="Reset Filter"
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                value={filterSelected}
            />
            <Box className="multiple-icon-filter" onClick={() => setOpenModal(true)}>
                <FilterIcon color={'#2D2D2D'} />
            </Box>
        </Box>
    );
}

export default ModalFilterStatus;

import React, { useEffect, useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { tableList } from '@pages/orderPlanning/const';
import { OrderPlanningContext } from '@pages/orderPlanning';
import './styles.scss';
import { FileIcon, EditIcon } from '@pages/orderPlanning/icons';
import { useSelector } from 'react-redux';
import { formattedOrderPlanning, columnBgHandler } from './functions';

const TableColumn = ({ orderPlanning }) => {
    const { accessPermission } = useSelector((reducer) => reducer.accessPermissionSlice);
    const [orders, setOrders] = useState([]);

    const { onSelectedOrderPlanning, togglerCreateOrderPlanning, togglerInputBatchBooking } =
        React.useContext(OrderPlanningContext);

    const onEditMaterial = ({ selected, isDisabled }) => {
        if (!isDisabled) {
            onSelectedOrderPlanning({ value: selected });
            togglerCreateOrderPlanning();
        }
    };

    const onInputBatchBooking = ({ isDisabled }) => {
        if (!isDisabled) {
            onSelectedOrderPlanning({ value: orderPlanning });
            togglerInputBatchBooking();
        }
    };

    useEffect(() => {
        formattedOrderPlanning({ orderPlanning, setOrders });
    }, [orderPlanning]);

    return (
        <Flex className="table-column table-column__container__order-planning" height="max-content">
            {orders?.map((order, indexBox) => {
                return (
                    <Flex height="max-content">
                        {tableList?.map((item, idx) => {
                            return (
                                <Box
                                    pt={indexBox === 0 && '12px'}
                                    id={`column__item--${idx}`}
                                    borderRight={item.withBorderRight && '1px solid #cecece'}
                                    w={item.width}
                                    bg="white"
                                >
                                    <Box
                                        h="100%"
                                        key={item.name}
                                        className={`column__item ${item.children && 'column-item__multiple-child'}`}
                                        bg={columnBgHandler({ status: order.status })}
                                    >
                                        {item.component({
                                            onEditMaterial,
                                            onInputBatchBooking,
                                            value: order[item.value],
                                            bg: columnBgHandler({ status: order.status }),
                                            index: order.index,
                                        })}
                                    </Box>
                                </Box>
                            );
                        })}

                        <Box className="floating-action-column">
                            <>
                                <Flex className="floating-action__button" pt={order.index === 0 ? '15px' : '0'}>
                                    <Box
                                        opacity={
                                            accessPermission?.dashboardKondisiSku?.isCanEditPlanningNextWeek &&
                                            order?.index === 0
                                                ? 1
                                                : 0.2
                                        }
                                        className="box-icon"
                                        border="1px solid #1746a2"
                                        cursor={
                                            accessPermission?.dashboardKondisiSku?.isCanEditPlanningNextWeek &&
                                            order?.index === 0
                                                ? 'pointer'
                                                : 'not-allowed'
                                        }
                                        onClick={() => {
                                            if (accessPermission?.dashboardKondisiSku?.isCanEditPlanningNextWeek) {
                                                onInputBatchBooking({ isDisabled: order.index !== 0 });
                                                return;
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </Box>
                                    <Box
                                        opacity={
                                            accessPermission?.dashboardKondisiSku?.isCanAction &&
                                            (order.status === 'INBOUND' || order.status === 'PENDING')
                                                ? 1
                                                : 0.2
                                        }
                                        cursor={
                                            accessPermission?.dashboardKondisiSku?.isCanAction &&
                                            (order.status === 'INBOUND' || order.status === 'PENDING')
                                                ? 'pointer'
                                                : 'not-allowed'
                                        }
                                        className="box-icon"
                                        border="1px solid #2D2D2D"
                                        onClick={() => {
                                            if (accessPermission?.dashboardKondisiSku?.isCanAction) {
                                                onEditMaterial({
                                                    selected: order.kardOrder,
                                                    isDisabled:
                                                        order.status !== 'INBOUND' && order.status !== 'PENDING',
                                                });
                                                return;
                                            }
                                        }}
                                    >
                                        <FileIcon />
                                    </Box>
                                </Flex>
                            </>
                        </Box>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

const defaultSideMenu = () => {
    const cookies = new Cookies();
    const isHide = cookies.get('sideMenuState') === 'true';
    return isHide;
};

export const sideMenuSlice = createSlice({
    name: 'sideMenu',
    initialState: {
        isHide: defaultSideMenu(),
    },
    reducers: {
        toggleExtendSideMenu: (state, action) => {
            const cookies = new Cookies();
            cookies.set('sideMenuState', action.payload);
            state.isHide = action.payload;
        },
    },
});

export const { toggleExtendSideMenu } = sideMenuSlice.actions;

export default sideMenuSlice.reducer;

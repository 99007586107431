import { useContext } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { tableList } from '@pages/skuConfiguration/const';
import './styles.scss';
import { skuConfigurationContext } from '@pages/skuConfiguration';

const TableColumn = ({ idx, sku }) => {
    const { onChangeModalState, onSelectedSku, onSelectedSkuExport, selectedChecked, isShowDelete, setIsShowDelete } =
        useContext(skuConfigurationContext);
    const onEditHandler = () => {
        onChangeModalState({ value: 'modal-edit', isEdit: true });
    };

    const onRemoveHandler = () => {
        setIsShowDelete(!isShowDelete);
    };

    return (
        <Flex className="table-column table-column__container__sku-configuration">
            {tableList.map((item, idx) => {
                return (
                    <Box
                        background={'white'}
                        key={item.name}
                        w={item.width}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: sku[item.value] || '-',
                            onEditHandler,
                            onRemoveHandler,
                            onSelectedSku,
                            selectedChecked,
                            sku,
                            onSelectedSkuExport,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;

import { useContext } from 'react';
import { Text, Box, Flex } from '@chakra-ui/react';
import ModalTemplate from '@components/modalTemplate';
import { tableOrderDeliveryContext } from '@pagesPhase2/orderManagement/sections/sectionOrderDelivery';
import './styles.scss';

const CreateModalEditNotes = ({
    isOpen,
    onClose,
    value,
    onClick,
    onChange,
    onClearNotes,
    onEditNotes,
    onSaveNotes,
}) => {
    const { enable } = useContext(tableOrderDeliveryContext);

    return (
        <ModalTemplate
            width={280}
            isOpen={isOpen}
            onClose={onClose}
            modalContentCustomStyle={{
                height: '232px',
                position: 'absolute',
                top: '166px',
                left: '888px',
                maxWidth: '280px',
            }}
        >
            <Box className="create-modal-edit-notes__container-order-delivery">
                <Text className="text-note">Note</Text>
                <form>
                    <textarea className="textarea-modal-edit" value={value} disabled={!enable} onChange={onChange} />
                </form>
                <Flex mb="21px" mt="70px" justifyContent="flex-end">
                    <Box className={`btn btn-clear ${enable && 'btn-bg-unactive'}`} onClick={onClearNotes}>
                        clear note
                    </Box>
                    <Box className={`btn btn-edit ${enable && 'btn-bg-unactive'}`} onClick={onEditNotes}>
                        edit note
                    </Box>
                    <Box className={`btn btn-save ${enable && 'btn-save-bg-unactive'}`} onClick={onSaveNotes}>
                        save note
                    </Box>
                </Flex>
            </Box>
        </ModalTemplate>
    );
};

export default CreateModalEditNotes;

export const formDefaultValue = {
    name: '',
    email: '',
    password: '',
    role: {},
    jenis_material: {},
    supplier_ref: '',
    supplier_name: {},
    materials: [],
};

export const listNeedMaterial = ['PPC', 'Produksi'];
export const listNormalForm = [
    'Super Admin',
    'Admin',
    'Produksi',
    'NDC',
    'Viewer',
    'Logistik',
    'Distplan',
    'PPC',
    'SAP PM',
    'SAP RM',
    'Logistik PM',
    'Logistik RM',
    'SSC PM',
    'SSC RM',
    'Superior',
    'Admin NDC to Manufacturing',
    'Admin Warehouse to Supplier',
];
export const listSupplierForm = ['Supplier PM', 'Supplier RM'];
export const listDisabledJenisMaterial = ['Admin', 'Super Admin', 'Superior'];

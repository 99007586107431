import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchInfiniteMainComponent = async ({
    page,
    setMainData,
    setLoadingSearch,
    setPage,
    moreData = false,
    setTotalData,
    search,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setLoadingSearch(true);
    const filterStringify = queryString.stringify({ q: search, page }, { skipEmptyString: true });
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/config/list?&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { listMaterial, totalData } = data.data;
        if (moreData && !search) {
            setMainData((prev) => [...prev, ...listMaterial]);
            setPage(page + 1);
        } else {
            setMainData(listMaterial);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
    } catch (error) {
        console.log(error);
        setLoadingSearch(false);
    }
};

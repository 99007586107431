import { useState } from 'react';
import { useToast, Text } from '@chakra-ui/react';
import ModalDelete from '@components/modalDelete';
import './styles.scss';

const DeleteModal = ({
    isOpen,
    onClose,
    selectedUser,
    onRemoveAccount,
    togglerDeleteUserModal,
    reFetchUserHandler,
}) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <ModalDelete
            isOpen={isOpen}
            onClose={onClose}
            title="Delete Account"
            isLoading={isLoading}
            onSubmit={() =>
                onRemoveAccount({
                    setIsLoading,
                    selectedUser,
                    togglerDeleteUserModal,
                    reFetchUserHandler,
                    toast,
                })
            }
            descriptionComponent={
                <Text className="description-text">
                    Apakah anda yakin ingin menghapus akun <span>{selectedUser.name}</span>?
                </Text>
            }
        />
    );
};

export default DeleteModal;

import React, { useState, useEffect, useMemo } from 'react';
import { Flex, Box, Text, InputGroup, Input, InputRightElement, Menu, MenuList, Spinner } from '@chakra-ui/react';
import { ArrowUpIconBlack } from '@asset/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmptyField } from '@components/fieldMenu';
import { debounce, isEmpty } from 'lodash';
import { fetchInfiniteMainComponent } from './function';
import './styles.scss';

const MainComponent = ({ onChangeValHandler, isSubmitted, kode_item, internal_name, disabled }) => {
    const [mainData, setMainData] = useState([]);
    const [openInfiniteScroll, setOpenInfiniteScroll] = useState(false);
    const [page, setPage] = useState(1);
    const [debounceVal, setDebounceVal] = useState('');
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [search, setSearch] = useState('');
    const [totalData, setTotalData] = useState(0);
    const getMoreData = (hasMore = false) => {
        fetchInfiniteMainComponent({
            page,
            setMainData,
            mainData,
            setPage,
            setTotalData,
            moreData: hasMore,
            search: debounceVal,
            setLoadingSearch: setLoadingSearch,
        });
    };
    const handleChangeDebounce = (text) => {
        setTotalData(1);
        setDebounceVal(text.target.value);
        setPage(() => {
            return 1;
        });
    };
    const debounceSearch = useMemo(() => debounce(handleChangeDebounce, 500), []);
    const handleClickSupplierName = (item) => {
        setSearch(item?.name);
        onChangeValHandler(item);
        setOpenInfiniteScroll(false);
    };

    useEffect(() => {
        getMoreData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceVal]);

    return (
        <>
            <Flex className="main-component__container">
                <Box mr="34">
                    <Text className="title-fieldmenu">
                        kode item main<span style={{ color: 'red' }}>*</span>
                    </Text>
                    <Box position="relative">
                        <InputGroup>
                            <Input
                                className={`fieldmenu-input__main-component ${
                                    isSubmitted && isEmpty(kode_item) && 'required-field__input'
                                }`}
                                placeholder={'Masukan kode item'}
                                disabled={disabled}
                                onFocus={() => setOpenInfiniteScroll(true)}
                                value={kode_item || search}
                                height="48px"
                                onChange={(text) => {
                                    setSearch(text.target.value);
                                    debounceSearch(text);
                                }}
                            />
                            <InputRightElement height="48px" right="12px">
                                <Box className="divider_input_infinite_scroll" />
                                {loadingSearch ? <Spinner size="sm"   /> : <ArrowUpIconBlack />}
                            </InputRightElement>
                        </InputGroup>
                        <Menu isOpen={openInfiniteScroll} onClose={() => setOpenInfiniteScroll(false)}>
                            <MenuList mt="55px" zIndex={20} w={'210px'}>
                                <div id="scrollableDiv" className="scroll-container">
                                    <InfiniteScroll
                                        dataLength={mainData.length}
                                        next={() => getMoreData(true)}
                                        hasMore={mainData.length === totalData ? false : true}
                                        scrollableTarget="scrollableDiv"
                                        loader={
                                            mainData.length === 0 ? (
                                                <Text className="loading_fetch_next_page">Loading...</Text>
                                            ) : mainData.length < 9 ? null : (
                                                <Text className="loading_fetch_next_page">Loading...</Text>
                                            )
                                        }
                                        endMessage={
                                            <Text className="loading_fetch_next_page">No more data is displayed</Text>
                                        }
                                    >
                                        {mainData.length === 0 && !loadingSearch ? (
                                            <Text className="no_data_infinite_scroll">
                                                Tidak Bisa Menemukan "{debounceVal}"
                                            </Text>
                                        ) : (
                                            mainData?.map((item, index) => {
                                                return (
                                                    <Box
                                                        onClick={() => handleClickSupplierName(item)}
                                                        className="list_item_infinite"
                                                        key={index}
                                                    >
                                                        {item?.kode_item}
                                                    </Box>
                                                );
                                            })
                                        )}
                                    </InfiniteScroll>
                                </div>
                            </MenuList>
                        </Menu>
                        {isSubmitted && isEmpty(kode_item) && (
                            <Text className="required-field__text">*Masukan Kode Item</Text>
                        )}
                    </Box>
                </Box>
                <Box>
                    <Text className="title-fieldmenu">
                        internal name<span style={{ color: 'red' }}>*</span>
                    </Text>
                    <EmptyField className="input-field-disabled" buttonName={internal_name || 'Terisi Otomatis'} />
                    {isSubmitted && isEmpty(internal_name) && (
                        <Text className="required-field__text">*Masukan Internal Name</Text>
                    )}
                </Box>
            </Flex>
        </>
    );
};

export default MainComponent;

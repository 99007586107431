import { isAllowed } from '@helpers/roleAccess';
import {
    AssetManagement,
    StreamlineManagement,
    // PackagingIcon,
    // MaterialRawIcon,
    RecapOrderIcon,
    // OrderHeatMapIcon,
    OrderPlanningIcon,
    SkuConfigurationIcon,
    MainDashboardIcon,
    OrderManagementIcon,
    MaterialConfigurationIcon,
    RawDataOrderIcon,
    SupplierStockIcon,
    DemandAnticipationIcon,
    UserManagementIcon,
    RawDataOrderIconPhase2,
} from '../@components/sideMenu/icons';

export const routeList = [
    {
        name: 'Main Dashboard',
        link: '/',
        isShowMaterialFilter: true,
        isShowInSideMenu: true,
        isHideFullScreenReload: false,
        icon: ({ isActive }) => <MainDashboardIcon isActive={isActive} />,
    },
    {
        name: 'Order Planning',
        link: '/order-planning',
        isShowMaterialFilter: true,
        isShowInSideMenu: true,
        isHideFullScreenReload: false,
        description: '',
        icon: ({ isActive }) => <OrderPlanningIcon isActive={isActive} />,
    },
    {
        name: 'SKU Configuration',
        link: '/sku-configuration',
        isShowMaterialFilter: true,
        isShowInSideMenu: true,
        isHideFullScreenReload: false,
        description: 'Penambahan data SKU beserta konfigurasi',
        icon: ({ isActive }) => <SkuConfigurationIcon isActive={isActive} />,
    },
    // {
    //     name: 'Order Heat Map',
    //     link: '/order-heatmap',
    //     isShowMaterialFilter: true,
    //     icon: ({ isActive }) => <OrderHeatMapIcon isActive={isActive} />,
    // },
    {
        name: 'Recap Order',
        link: '/rekap-order',
        isShowMaterialFilter: true,
        isShowInSideMenu: true,
        isHideFullScreenReload: true,
        description: 'Kamu bisa melihat semua history order dalam DPPS',
        icon: ({ isActive }) => <RecapOrderIcon isActive={isActive} />,
    },

    // {
    //     name: 'Material Raw',
    //     link: '/material-raw',
    //     isShowMaterialFilter: true,
    //     icon: ({ isActive }) => <MaterialRawIcon isActive={isActive} />,
    // },
    // {
    //     name: 'Packaging',
    //     link: '/packaging',
    //     isShowMaterialFilter: true,
    //     icon: ({ isActive }) => <PackagingIcon isActive={isActive} />,
    // },
    {
        name: 'Streamline Management',
        link: '/streamline-management',
        isShowMaterialFilter: true,
        isShowInSideMenu: true,
        isHideFullScreenReload: false,
        icon: ({ isActive }) => <StreamlineManagement isActive={isActive} />,
        description: 'Pengaturan serta penambahan streamline beserta line di dalamnya',
    },
    {
        name: 'Asset Management',
        link: '/asset-management',
        isShowMaterialFilter: true,
        isShowInSideMenu: true,
        isHideFullScreenReload: false,
        icon: ({ isActive }) => <AssetManagement isActive={isActive} />,
        description: 'Mengatur kebutuhan zona streamline dan line yang ada',
    },
];

export const routeListPhase2 = (paramId) => {
    const routeList = [
        // {
        //     name: 'Order Management',
        //     link: '/order-management',
        //     isShowMaterialFilter: true,
        //     isHideFullScreenReload: true,
        //     isShowInSideMenu: true,
        //     icon: ({ isActive }) => <OrderManagementIcon isActive={isActive} />,
        // },

        {
            name: 'Raw Data Order Table Rekap Delivery',
            link: '/raw-data-order/table-rekap-delivery',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            isShowInSideMenu: false,
            description: '',
            icon: () => <></>,
        },
        {
            name: 'Material Configuration Add New Item Set',
            link: '/material-configuration/add-new-item-set',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            description: '',
            isShowInSideMenu: false,
            icon: () => <></>,
        },
        {
            name: 'Order Management Table Order Confirmation',
            link: '/order-management/table-order-confirmation',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            description: '',
            isShowInSideMenu: false,
            icon: () => <></>,
        },
        {
            name: 'Order Management Table Order Delivery',
            link: '/order-management/table-order-delivery',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            description: '',
            isShowInSideMenu: false,
            icon: () => <></>,
        },
        {
            name: 'Archive List',
            link: '/material-configuration/archive-list',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            description: '',
            isShowInSideMenu: false,
            icon: () => <></>,
        },
    ];

    if (isAllowed({ pageName: "Supplier's Stock", permissionName: "Upload Supplier's Stock" })) {
        routeList.push({
            name: 'Supplier Stock Upload',
            link: '/supplier-stock/upload-item',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            description: '',
            isShowInSideMenu: false,
            icon: () => <></>,
        });
    }
    if (isAllowed({ pageName: 'Material Config', permissionName: 'Upload Material Config' })) {
        routeList.push({
            name: 'Material Configuration Upload Item',
            link: '/material-configuration/upload-item',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            description: '',
            isShowInSideMenu: false,
            icon: () => <></>,
        });
    }
    if (isAllowed({ pageName: 'Material Config', permissionName: 'View Material Config' })) {
        routeList.splice(1, 0, {
            name: 'Material Configuration',
            link: '/material-configuration',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            isShowInSideMenu: true,
            description: 'Mengatur perencanaan dan memantau status SKU pada minggu berjalan',
            icon: ({ isActive }) => <MaterialConfigurationIcon isActive={isActive} />,
        });
    }
    if (isAllowed({ pageName: 'Material Config', permissionName: 'Create Material Config' })) {
        routeList.push({
            name: 'Material Configuration Add New Item',
            link: '/add-new-item-material-config',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            description: '',
            isShowInSideMenu: false,
            icon: () => <></>,
        });
    }
    if (isAllowed({ pageName: 'Material Config', permissionName: 'Edit Material Config' })) {
        routeList.push({
            name: 'Material Configuration Edit New Item',
            link: `/add-new-item-material-config/${paramId}`,
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            description: '',
            isShowInSideMenu: false,
            icon: () => <></>,
        });
    }
    if (isAllowed({ pageName: "Supplier's Stock", permissionName: "View Supplier's Stock" })) {
        routeList.push({
            name: 'Supplier’s Stock',
            link: '/supplier-stock',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            isShowInSideMenu: true,
            icon: ({ isActive }) => <SupplierStockIcon isActive={isActive} />,
            description: 'Pengaturan serta penambahan streamline beserta line di dalamnya',
        });
    }
    if (isAllowed({ pageName: 'Demand Anticipation', permissionName: 'View Demand Anticipation' })) {
        routeList.push({
            name: 'Demand Anticipation',
            link: '/demand-anticipation',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            isShowInSideMenu: true,
            icon: ({ isActive }) => <DemandAnticipationIcon isActive={isActive} />,
            description: 'Mengatur kebutuhan zona streamline dan line yang ada',
        });
    }
    if (isAllowed({ pageName: 'Demand Anticipation', permissionName: 'Upload Demand Anticipation' })) {
        routeList.push({
            name: 'Demand Anticipation',
            link: '/demand-anticipation/upload-item',
            isShowMaterialFilter: true,
            isHideFullScreenReload: true,
            isShowInSideMenu: false,
            icon: () => <></>,
            description: '',
        });
    }
    if (isAllowed({ pageName: 'Raw Data Order', permissionName: 'View Raw Data Order' })) {
        // routeList.push({
        //     name: 'Raw Data Order',
        //     link: '/raw-data-order',
        //     isShowMaterialFilter: true,
        //     isHideFullScreenReload: true,
        //     isShowInSideMenu: true,
        //     description: 'Penambahan data SKU beserta konfigurasi',
        //     icon: ({ isActive }) => <RawDataOrderIconPhase2 isActive={isActive} />,
        // });
    }

    return routeList;
};

// route backup
// export const routeListPhase2 = [
//     {
//         name: 'Order Management',
//         link: '/order-management',
//         isShowMaterialFilter: true,
//         isHideFullScreenReload: true,
//         isShowInSideMenu: true,
//         icon: ({ isActive }) => <OrderManagementIcon isActive={isActive} />,
//     },
//     {
//         name: 'Material Configuration',
//         link: '/material-configuration',
//         isShowMaterialFilter: true,
//         isHideFullScreenReload: true,
//         isShowInSideMenu: true,
//         description: 'Mengatur perencanaan dan memantau status SKU pada minggu berjalan',
//         icon: ({ isActive }) => <MaterialConfigurationIcon isActive={isActive} />,
//     },
//     {
//         name: 'Raw Data Order',
//         link: '/raw-data-order',
//         isShowMaterialFilter: true,
//         isHideFullScreenReload: true,
//         isShowInSideMenu: true,
//         description: 'Penambahan data SKU beserta konfigurasi',
//         icon: ({ isActive }) => <RawDataOrderIconPhase2 isActive={isActive} />,
//     },
//     {
//         name: 'Material Configuration Add New Item Set',
//         link: '/material-configuration/add-new-item',
//         isShowMaterialFilter: true,
//         isHideFullScreenReload: true,
//         description: '',
//         isShowInSideMenu: false,
//         icon: () => <></>,
//     },
//     {
//         name: 'Order Management Table Order Confirmation',
//         link: '/order-management/table-order-confirmation',
//         isShowMaterialFilter: true,
//         isHideFullScreenReload: true,
//         description: '',
//         isShowInSideMenu: false,
//         icon: () => <></>,
//     },
//     {
//         name: 'Order Management Table Order Delivery',
//         link: '/order-management/table-order-delivery',
//         isShowMaterialFilter: true,
//         isHideFullScreenReload: true,
//         description: '',
//         isShowInSideMenu: false,
//         icon: () => <></>,
//     },
//     {
//         name: 'Archive List',
//         link: '/material-configuration/archive-list',
//         isShowMaterialFilter: true,
//         isHideFullScreenReload: true,
//         description: '',
//         isShowInSideMenu: false,
//         icon: () => <></>,
//     },
//     // {
//     //     name: 'Order Heat Map',
//     //     link: '/order-heatmap',
//     //     isShowMaterialFilter: true,
//     //     icon: ({ isActive }) => <OrderHeatMapIcon isActive={isActive} />,
//     // },
//     // {
//     //     name: 'Order Recap',
//     //     link: '/order-recap',
//     //     isShowMaterialFilter: true,
//     //     icon: ({ isActive }) => <OrderRecapIcon isActive={isActive} />,
//     // },

//     // {
//     //     name: 'Material Raw',
//     //     link: '/material-raw',
//     //     isShowMaterialFilter: true,
//     //     icon: ({ isActive }) => <MaterialRawIcon isActive={isActive} />,
//     // },
//     // {
//     //     name: 'Packaging',
//     //     link: '/packaging',
//     //     isShowMaterialFilter: true,
//     //     icon: ({ isActive }) => <PackagingIcon isActive={isActive} />,
//     // },
//     {
//         name: 'Supplier’s Stock',
//         link: '/supplier-stock',
//         isShowMaterialFilter: true,
//         isShowInSideMenu: true,
//         isHideFullScreenReload: true,
//         icon: ({ isActive }) => <SupplierStockIcon isActive={isActive} />,
//         description: 'Pengaturan serta penambahan streamline beserta line di dalamnya',
//     },
//     {
//         name: 'Demand Anticipation',
//         link: '/demand-anticipation',
//         isShowMaterialFilter: true,
//         isHideFullScreenReload: true,
//         isShowInSideMenu: true,
//         icon: ({ isActive }) => <DemandAnticipationIcon isActive={isActive} />,
//         description: 'Mengatur kebutuhan zona streamline dan line yang ada',
//     },
// ];

export const routeListUserMangement = [
    {
        name: 'User Management',
        link: '/user-management',
        isShowMaterialFilter: false,
        isHideFullScreenReload: true,
        icon: ({ isActive }) => <UserManagementIcon isActive={isActive} />,
        description: 'Mengatur, menambahkan, serta meninjau akun pengguna sesuai role',
    },
];

export function FailedIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="132" height="132" fill="none" viewBox="0 0 132 132">
            <circle cx="65.998" cy="65.901" r="65.901" fill="#FE6565"></circle>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="10"
                d="M88.496 43.74l-45 45M43.496 43.74l45 45"
            ></path>
        </svg>
    );
}

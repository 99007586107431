import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { reverse, sortBy } from 'lodash';
import { getFilterOrderStatus } from '../sectionTab/components/filter/function';
import { getWeekHandler } from '@components/header/functions';
import { sortingStreamlineByNameHandler } from '../sectionProductionStatus/functions';

const orderPlanningByKardOrderHandler = ({ orderPlanning }) => {
    const arr = [];
    orderPlanning?.forEach((item) => {
        item?.kardOrders?.forEach((order) => {
            arr.push({ ...item, kardData: { ...order } });
        });
    });
    return arr;
};
export const orderPlanningByStreamlineHandler = ({ orders, streamlines }) => {
    return streamlines?.map((streamline) => {
        return {
            ...streamline,
            orders: orders?.filter((order) => order?.streamline_id === streamline?.id),
        };
    });
};

export const sortingStreamlineByOrder = ({ streamlines }) => {
    const sorting = sortBy(streamlines, ({ orders }) => orders?.length);
    const reverseSorting = reverse(sorting);
    return reverseSorting;
};

export const removeEmptyOrderHandler = ({ arr }) => {
    return arr?.filter(({ orders }) => orders?.length);
};

export const fetchOrderByStreamline = async ({
    setOrderByStreamline,
    streamlines,
    sediaan_id,
    searchStreamline,
    selectedFilterStreamline,
    setIsLoading,
}) => {
    const cookies = new Cookies();
    const hasFilter = getFilterOrderStatus({ cookieFilter: cookies.get('filterListOrderStatus') || [] });
    const userToken = cookies.get('userToken');
    const tabEndPoint = cookies.get('tab');
    const isDoneTabMenuSelected = cookies.get('tab')?.includes('DONE') ? getWeekHandler() : null;
    setIsLoading(true);
    const filterStringify = queryString.stringify(
        {
            sortOrder: true,
            includeKard: true,
            status: tabEndPoint || 'QUEUE',
            q: searchStreamline,
            streamline_id: selectedFilterStreamline?.join(),
            sediaan_id,
            week: isDoneTabMenuSelected,
            [hasFilter.selectedEndpoint]: hasFilter.selectedValueEndpoint, /// endpoint filter order status
        },
        { skipEmptyString: true, skipNull: true }
    );

    let orders = [];

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `order-planning/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const orderPlanning = data?.data?.listSkus;
        const orderPlanningByKardOrder = orderPlanningByKardOrderHandler({ orderPlanning });
        const orderPlanningByStreamline = orderPlanningByStreamlineHandler({
            orders: orderPlanningByKardOrder,
            streamlines,
        });

        // const orders = sortingStreamlineByOrder({ streamlines: orderPlanningByStreamline });
        orders = sortingStreamlineByNameHandler({ arr: orderPlanningByStreamline });
        orders = removeEmptyOrderHandler({ arr: [...orders] });

        setOrderByStreamline(orders);
    } catch (error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
};

import { Box, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '../../icons';
import CodeItemSearch from '../sectionHead/codeItem';
import TradeNameSearch from '../sectionHead/tradeName';
import { DownloadBlueIcon, FilterIcon } from '../../icons';
import ModalFilterMultiple from './modalFilterMultiple';
import './styles.scss';

const SectionHead = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Flex className="rekap-delivery-box-header">
                <Flex gap="10px" alignItems="center" cursor="pointer" onClick={() => navigate('/raw-data-order')}>
                    <Box>
                        <ChevronLeftIcon />
                    </Box>
                    <Text className="text-order-confirmation">Rekap Delivery</Text>
                </Flex>
                <Flex className="action-button-container" alignItems="center">
                    <CodeItemSearch />
                    <Box mx="12px">
                        <TradeNameSearch />
                    </Box>
                    <ModalFilterMultiple />
                    <Box className="divider" />
                    <Box className="box-download-data">
                        <DownloadBlueIcon />
                        <Text className="text-download-data">Download Data</Text>
                    </Box>
                </Flex>
            </Flex>
        </div>
    );
};

export default SectionHead;

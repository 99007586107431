import { Flex, Box, Text, Input } from '@chakra-ui/react';

import EditableStatus from '../demandAnticipation/components/editableStatus';
import EditableNotes from '../demandAnticipation/components/editableNotes';
import { findWeekYear, pastYearDemand, currentYearDemand } from './function';
import { isAllowed } from '@helpers/roleAccess';

export const tableList = [
    {
        name: 'no',
        value: 'index',
        width: 70,
        children: ['-'],
        component: ({ index, numbers }) => <Text className="column-text">{numbers[index] + 1}</Text>,
    },
    {
        name: 'kode item',
        value: 'kode_item',
        width: 140,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'nama item',
        width: 288,
        value: 'trade_name',
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value}</Text>,
    },
    {
        name: 'supplier',
        value: 'supplier_name',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'UOM',
        value: 'uom',
        width: 75,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'baseline',
        value: 'baseline_pcs',
        width: 79,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'status',
        width: 150,
        value: 'status',
        children: ['act/npd'],
        component: ({ value, dataItem, id }) => (
            <Box className="td-status-container">
                {Boolean(dataItem?.demand_anticipation_id) &&
                isAllowed({
                    pageName: 'Demand Anticipation',
                    permissionName: 'Edit Demand Anticipation',
                }) ? (
                    <EditableStatus dataItem={dataItem} id={id} value={value} />
                ) : (
                    <Text className="column-text">{value || '-'}</Text>
                )}
            </Box>
        ),
    },
    {
        name: 'notes',
        width: 250,
        value: 'notes',
        children: ['Free Text'],
        component: ({ dataItem }) => <EditableNotes dataItem={dataItem} />,
    },
    {
        name: 'addtional order',
        width: 120,
        value: 'additional_order',
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'W44',
        year: '-',
        width: 40,
        value: '',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(44, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W45',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(45, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W46',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(46, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W47',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(47, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W48',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(48, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W49',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(49, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W50',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(50, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W51',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(51, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W52',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(52, pastYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W1',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(1, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W2',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(2, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W3',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(3, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W4',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(4, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W5',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(5, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W6',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(6, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W7',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(7, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W8',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(8, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W9',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(9, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W10',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(10, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W11',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(11, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W12',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(12, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W13',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(13, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W14',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(14, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W15',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(15, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W16',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(16, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W17',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(17, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W18',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(18, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W19',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(19, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W20',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(20, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W21',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(21, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W22',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(22, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: '23',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(23, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W24',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(24, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W25',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(25, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W26',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(26, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W27',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(27, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W28',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(28, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W29',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(29, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W30',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(30, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W31',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(31, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W32',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(32, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W33',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(33, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W34',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(34, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W35',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(35, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W36',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(36, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W37',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(37, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W38',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(38, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W39',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(39, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W40',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(40, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W41',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(41, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W42',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(42, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W43',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(43, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    {
        name: 'W44',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{findWeekYear(44, currentYearDemand, demandPerWeek)}</Text>
        ),
    },
    // {
    //     name: 'W5',
    //     year: '',
    //     width: 40,
    //     value: 'value_w',
    //     children: ['pcs'],
    //     component: ({ value }) => <Text className="column-text">{value}</Text>,
    // },
    // {
    //     name: 'W5',
    //     year: '',
    //     width: 40,
    //     value: 'value_w',
    //     children: ['pcs'],
    //     component: ({ value }) => <Text className="column-text">{value}</Text>,
    // },
];

export const optionValueStatus = [
    {
        name: 'Activity',
        value: 1,
    },
    {
        name: 'NPD',
        value: 2,
    },
    {
        name: 'Activity & NPD',
        value: 3,
    },
    {
        name: '-',
        value: null,
    },
];

export const dummyDatas = {
    no: 57,
    kode_item: 1234567,
    nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
    uom: 'Pcs',
    supplier: 'Metalipak Kreasindo, PT',
    baseline: '12.3456',
    status: 'Act & NPD',
    notes: 'Isi notes......',
    addtional_order: 2578,
    value_w: 999.21,
};

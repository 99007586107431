import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchTradeNameInfiniteScroll = async ({
    page,
    setTradeName,
    tradeNameChoosed,
    setLoadingSearch,
    setPage,
    moreData = false,
    setTotalData,
    search,
    supplierRef,
    jenisMaterial,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const ref = supplierRef?.join(';');
    const filterStringify = queryString.stringify(
        {
            attributes: 'trade_name',
            page,
            q: search,
            sort: 'trade_name asc',
            supplier_ref: ref,
            jenis_material_id: jenisMaterial?.id,
        },
        { skipEmptyString: true }
    );
    setLoadingSearch(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/filter?limit=15&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { list, totalData } = data.data;
        list?.map((item) => {
            item['checked'] = false;
            tradeNameChoosed?.map((choosedItem) => {
                if (item?.trade_name === choosedItem?.trade_name && choosedItem?.checked) {
                    item['checked'] = true;
                }
            });
            return item;
        });
        if (moreData && !search) {
            setTradeName((prev) => [...prev, ...list]);
            setPage(page + 1);
        } else {
            setTradeName(list);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
    } catch (error) {
        console.log(error);
        setLoadingSearch(false);
    }
};

export const handleCheckboxChange = ({
    checked,
    selectedData,
    tradeName,
    setTradeName,
    tradeNameChoosed,
    setTradeNameChoosed,
}) => {
    let clone = [...tradeName];
    let cloneTradeNameChoosed = [...tradeNameChoosed];
    if (checked) {
        cloneTradeNameChoosed.push({ ...selectedData, checked });
    }
    if (!checked) {
        const unChecked = cloneTradeNameChoosed?.filter((item) => item?.trade_name !== selectedData?.trade_name);
        cloneTradeNameChoosed = unChecked;
    }
    clone.map((userItem) => {
        if (userItem?.trade_name === selectedData?.trade_name) {
            userItem.checked = checked;
        }
        return userItem;
    });
    setTradeNameChoosed(cloneTradeNameChoosed);
    setTradeName(clone);
};

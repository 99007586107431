import { Flex, Box, Text } from '@chakra-ui/react';
import { WarningIcon } from './icons';
import { getColorHandler, getColorTextHandler } from './function';

export const tableList = [
    {
        name: 'status',
        value: '',
        sortValue: 'status',
        width: 100,
        children: ['-'],
        component: ({ ordersData }) => (
            <Box className="box-column-status" background={getColorHandler({ status: ordersData?.status })}>
                <Text className="text-colum-status" color={getColorTextHandler({ status: ordersData?.status })}>
                    {ordersData?.status}
                </Text>
            </Box>
        ),
    },
    {
        name: <WarningIcon color={'#2D2D2D'} />,
        value: 'is_warning',
        width: 55,
        children: ['-'],
        component: ({ value }) => (
            <Flex alignItems={'center'} pl={'16px'} pt={'16px'}>
                <WarningIcon color={value ? '#FF7777' : '#FFFF'} />
            </Flex>
        ),
    },
    {
        name: 'kode item',
        width: 150,
        value: 'kode_item',
        sortValue: 'kode_item',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'nama item',
        width: 320,
        value: 'trade_name',
        sortValue: 'trade_name',
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value || '-'}</Text>,
    },
    {
        name: 'kanban card',
        width: 90,
        value: 'kanban_card',
        sortValue: 'kanban_card',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'order card',
        width: 90,
        value: 'order_card',
        sortValue: 'order_card',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'UOM',
        width: 50,
        value: 'uom',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'baseline per day',
        value: 'baseline_per_day',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value.toFixed(2)}</Text>,
    },
    {
        name: 'ROP',
        value: 'stock_pti_rop',
        width: 70,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'max stock',
        value: 'stock_pti_max',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'OSPO',
        value: 'ospo',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'OSPO update',
        value: 'ospo_update',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'SOH PTI',
        value: 'soh_pti',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'order',
        value: 'order_pcs',
        width: 150,
        children: ['pcs', 'kanban'],
        component: ({ ordersData }) => (
            <Box display="flex" justifyContent="space-between" gap="65px">
                {['order_qty', 'order_kanban'].map((item, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {ordersData[item] || '-'}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'in preparation',
        value: 'preparation_pcs',
        width: 150,
        children: ['pcs', 'kanban'],
        component: ({ ordersData }) => (
            <Box display="flex" justifyContent="space-between" gap="65px">
                {['in_preparation_qty', 'in_preparation_kanban'].map((item, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {ordersData[item] || '-'}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'on delivery',
        value: 'delivery_pcs',
        width: 150,
        children: ['pcs', 'kanban'],
        component: ({ ordersData }) => (
            <Box display="flex" justifyContent="space-between" gap="65px">
                {['on_delivery_qty', 'on_delivery_kanban'].map((item, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {ordersData[item] || '-'}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'quality checking',
        value: 'quality',
        width: 150,
        children: ['pcs', 'kanban'],
        component: ({ ordersData }) => (
            <Box display="flex" justifyContent="space-between" gap="65px">
                {['quality_checking_qty', 'quality_checking_kanban'].map((item, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {ordersData[item] || '-'}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
];

const setRandomStatus = () => {
    const arr = ['Urgent', 'Order', 'OK'];
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
};

export const objDummy = {
    status: setRandomStatus(),
    kode_item: 12345678,
    nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
    kanban_card: 12345,
    order_card: 12345,
    baseline_perday: '150,000',
    rop: '220,000',
    max_stock: '402,000',
    ospo: '800,000',
    ospo_update: '620,000',
    ospo_pti: '620,000',
    order_pcs: '620,000',
    order_kanban: 42,
    preparation_pcs: '800,000',
    preparation_kanban: 'PO000123',
    delivery_pcs: '800,000',
    delivery_kanban: 8,
    quality_checking_pcs: '800,000',
    quality_checking_kanban: 8,
};

import { Tr, Td, Button, Box } from '@chakra-ui/react';

import './styles.scss';

function Index({ tableBody, tableHead }) {
    return (
        <>
            {tableBody?.map((body, bodyIndex) => {
                return (
                    <Tr className="body-table-container" key={bodyIndex}>
                        {tableHead.map((head, headIndex) => {
                            /// KONDISI JIKA TABLE YANG TIDAK FREEZE (STICKY)
                            const isCellNotFreeze = !head.is_freeze && headIndex === 6 ? '13px' : '0px';
                            return (
                                <Td
                                    className="body-table-cell"
                                    sx={{
                                        position: head.is_freeze && 'sticky',
                                        [head.align || 'left']: head.is_freeze && head.freezePosition,
                                        zIndex: head.is_freeze && 1,
                                        bgColor: head.is_freeze && '#fff',
                                        borderRight: head.isRightBorder && '1px solid  #cecece',
                                        pl: headIndex === 0 ? '12px' : isCellNotFreeze,
                                    }}
                                    key={headIndex}
                                >
                                    {body[head.id]}
                                </Td>
                            );
                        })}
                    </Tr>
                );
            })}
        </>
    );
}

export default Index;

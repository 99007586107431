import React, { useContext } from 'react';
import ModalTemplate from '@components/modalTemplate';
import { Box, Text, Textarea, Button, Flex, FormHelperText, FormControl } from '@chakra-ui/react';
import DropDownSelectMenu from '@pages/mainDashboard/components/cardOrderStatus/section/modal/components/dropDownSelectMenu';
import { status, formDefaultValue } from './const';
import { useToast } from '@chakra-ui/toast';
import { reducer, isFormHasEmptyValue, initialFormState } from './function';
import { MainDashboardContext } from '@pages/mainDashboard';

import './styles.scss';

function Index({ isOpen, onClose, selectedOrderPlanning }) {
    const [initialValue, dispatch] = React.useReducer(reducer, formDefaultValue);
    const [isError, setIsError] = React.useState(initialFormState);
    const [loading, setLoading] = React.useState(false);
    const { fetchData } = useContext(MainDashboardContext);
    const toast = useToast();
    const handleCloseModal = () => {
        onClose();
        setLoading(false);
        setIsError(initialFormState);
        dispatch({ type: 'reset', setIsError });
    };
    const handleSave = () => {
        isFormHasEmptyValue({
            setIsError,
            initialValue,
            data: initialValue.data_send_to_be,
            selectedOrderPlanning,
            handleCloseModal,
            setLoading,
            toast,
            fetchData,
        });
    };
    return (
        <FormControl>
            <ModalTemplate width={544} title="Ketersediaan Material" isOpen={isOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        p: '19px 0px 18px 0px',
                        borderBottom: '0.5px solid #D7D7D7',
                    }}
                >
                    <Text className="title-modal">Ketersediaan Material</Text>
                </Box>
                <Box className="formContainer">
                    <Box
                        sx={{
                            p: '32px 22px',
                            borderBottom: '0.5px solid #D7D7D7',
                        }}
                    >
                        <Text className="modal-title-form">Ketersediaan Bahan Baku</Text>
                        <Box className="input-text-area-container">
                            <Text className="label-pilih-status">Pilih status</Text>
                            <DropDownSelectMenu
                                error={isError.raw_material_status}
                                onClickMenuItem={(value) => {
                                    dispatch({
                                        type: 'raw_material_status',
                                        value: value.val,
                                        label: value.label,
                                        setIsError,
                                    });
                                }}
                                keyMap="label"
                                width="100%"
                                menuList={status}
                                titleMenuButton={initialValue.label_value.status_bahan_baku || 'pilih status'}
                            />
                            <Text className="label-masukan-notes">Masukan Notes</Text>
                            <Textarea
                                onChange={(i) => {
                                    dispatch({ type: 'raw_material_note', value: i.target.value, setIsError });
                                }}
                                value={initialValue.data_send_to_be.raw_material_note}
                                className="input-container"
                                placeholder="Masukan notes"
                                sx={{
                                    resize: 'none',
                                    border: `0.5px solid ${isError.raw_material_note ? 'red' : '#D7D7D7'} !important`,
                                }}
                            />
                            {isError.raw_material_note && (
                                <FormHelperText className="error-text">* field ini tidak boleh kosong</FormHelperText>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            p: '32px 22px 0px 22px',
                        }}
                    >
                        <Text className="modal-title-form">Ketersediaan Packaging</Text>
                        <Box className="input-text-area-container">
                            <Text className="label-pilih-status">Pilih status</Text>
                            <DropDownSelectMenu
                                error={isError.raw_packaging_status}
                                onClickMenuItem={(value) => {
                                    dispatch({
                                        type: 'raw_packaging_status',
                                        value: value.val,
                                        label: value.label,
                                        setIsError,
                                    });
                                }}
                                keyMap="label"
                                width="100%"
                                menuList={status}
                                titleMenuButton={initialValue.label_value.status_packaging || 'pilih status'}
                            />
                            <Text className="label-masukan-notes">Masukan Notes</Text>
                            <Textarea
                                onChange={(i) => {
                                    dispatch({ type: 'raw_packaging_note', value: i.target.value, setIsError });
                                }}
                                value={initialValue.data_send_to_be.raw_packaging_note}
                                className="input-container"
                                placeholder="Masukan notes"
                                sx={{
                                    resize: 'none',
                                    border: `0.5px solid ${isError.raw_packaging_note ? 'red' : '#D7D7D7'} !important`,
                                }}
                            />
                            {isError.raw_packaging_note && (
                                <FormHelperText className="error-text">* field ini tidak boleh kosong</FormHelperText>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Flex gap="22px" justifyContent="center" mb="41px" mt="42px">
                    <Button onClick={handleCloseModal} className="btn-modal-cancel">
                        Cancel
                    </Button>
                    <Button isLoading={loading} onClick={handleSave} className="btn-modal-save">
                        Save
                    </Button>
                </Flex>
            </ModalTemplate>
        </FormControl>
    );
}

export default Index;
